import React, { useEffect, useState } from "react";
import SearchInput from "../SearchInput";
import MultiCombobox from "../MultiCombobox";

const typeOptions = [
  {
    value: "form",
    label: "Form",
  },
  {
    value: "paths",
    label: "Paths",
  },
  {
    value: "walkthroughs",
    label: "Walkthroughs",
  },
];

const statusOptions = [
  {
    value: "completed",
    label: "Completed",
  },
  {
    value: "in progress",
    label: "In Progress",
  },
];

function InteractionHistoryToolbar({ table }) {
  const [typeFilters, setTypeFilters] = useState([]);
  const [statusFilters, setStatusFilters] = useState([]);

  useEffect(() => {
    const column = table.getColumn("type");
    console.log(column.getFilterValue(), "HERE")
    if (typeFilters.length > 0) {
      column.setFilterValue(typeFilters);
    } else {
      column.setFilterValue(undefined);
    }
  }, [typeFilters, table]);

  useEffect(() => {
    const column = table.getColumn("status");
    if (statusFilters.length > 0) {
      column.setFilterValue(statusFilters);
    } else {
      column.setFilterValue(undefined);
    }
  }, [statusFilters, table]);

  return (
    <div className="avatar-model-toolbar">
      <SearchInput
        placeholder="Search Material"
        value={table.getColumn("materialName")?.getFilterValue() ?? ""}
        setValue={(value) =>
          table.getColumn("materialName").setFilterValue(value)
        }
      />
      <div className="toolbar-filter-section">
        <MultiCombobox
          options={typeOptions}
          filters={typeFilters}
          setFilters={setTypeFilters}
          placeholder="Type"
        />
        <MultiCombobox
          options={statusOptions}
          filters={statusFilters}
          setFilters={setStatusFilters}
          placeholder="Status"
        />
      </div>
    </div>
  );
}

export default InteractionHistoryToolbar;
