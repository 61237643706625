import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { http_get } from '../components/pages/utils/api_client';
import { API_ENDPOINTS } from '../components/pages/utils/endpoints';

export const fetchCompanyDetails = createAsyncThunk(
  'company/fetchCompanyDetails',
  async () => {
    let company = {};
    await http_get(API_ENDPOINTS.GET_COMPANY)
      .then((res) => {
        company = res.data;
      })
      .catch((err) => {
        throw new Error('Failed to fetch company details');
      });
    return company;
  }
);

const companySlice = createSlice({
  name: 'company',
  initialState: {
    data: {},
    loading: false,
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCompanyDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCompanyDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchCompanyDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  }
});

export default companySlice.reducer;

export const getCompany = (state) => state.company.data;

export const getCompanyDomains = (state) => state.company.data.domains;
