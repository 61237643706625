import React from "react";
import Chat from "components/assets/images/chat.svg";

function EmptyMessage() {
  return (
    <div class="cover-no-chat-content">
      <div class="flex-chat-content-messages">
        <div class="image-chat-content-messages">
          <img src={Chat} alt="chat" />
        </div>
        <div class="text-chat-content-messages">
          <h3>There is nothing here yet!</h3>
          <p>Select contact to start the conversation</p>
        </div>
      </div>
    </div>
  );
}

export default EmptyMessage;
