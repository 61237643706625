import React, { useState } from "react";
import Header from "./Header";
import Elements from "./Elements";
import Library from "./Library";
import Design from "./Design";
import EditorDesign from "../../../../../organisms/editor-design/EditorDesign";

const SideToolbar = () => {
  const [activeSection, setActiveSection] = useState("design");
  const [open, setOpen] = useState(false)

  return (
    <div className={`${open ? 'un-editor-options' : 'design-editor-close'}`}>
      {open ? 
        <>
          {/* <Header
            activeSection={activeSection}
            setActiveSection={setActiveSection}
          /> */}
          <div className="menu-tab switch-component-button-parts">
            <div
              onClick={() => setOpen(false)}
              className="enu-tab button-switch-component active"
              style={{ cursor: "pointer" }}
            >
              Design
           </div>
          </div>
          {activeSection === "design" && <EditorDesign />}
          {/* {activeSection === "elements" && <Elements />} */}
          {/* {activeSection === "library" && <Library />} */}
        </>
      :
        <div className="action-area" onClick={() => setOpen(true)}>
          <p>Design</p>
        </div>
      }
    </div>
  );
}

export default SideToolbar;
