import React from 'react'
import { Provider } from 'react-redux';
import { store } from '../../../redux/store';
import EditPagePreview from './EditPagePreview';

const EditPagePreviewWrapper = ({ id, current_user }) => {
  return (
    <Provider store={store}>
      <EditPagePreview id={id} current_user={current_user} />
    </Provider>
  )
}

export default EditPagePreviewWrapper