import React from "react";

export const videoColumns = [
  {
    accessorKey: "materialName",
    header: ({ table }) => (
      <div className="text-table-avatar-model">Material name</div>
    ),
    cell: ({ row }) => {
      return (
        <div className="text-table-avatar-model">
          {row.getValue("materialName")}
        </div>
      );
    },
  },
  {
    accessorKey: "status",
    filterFn: "customArray",
    header: ({ table }) => (
      <div className="text-table-avatar-model">Status</div>
    ),
    cell: ({ row }) => {
      return (
        <div className="button-flex">
          <div
            className={`btn-avatar-progress ${row.getValue("status") === "completed" ? "completed" : ""
              }`}
          >
            <span className="icon-btn-avatar-progress"></span>
            <span
              className="text-btn-avatar-progress"
              style={{ textTransform: "capitalize" }}
            >
              {row.getValue("status")}
            </span>
          </div>
        </div>
      );
    },
  },
  {
    accessorKey: "views",
    header: ({ table }) => <div className="text-table-avatar-model">Views</div>,
    cell: ({ row }) => {
      return (
        <div className="text-table-avatar-model">{row.getValue("views")}</div>
      );
    },
  },
  {
    id: "details",
    header: ({ table }) => (
      <div className="text-table-avatar-model">Details</div>
    ),
    cell: ({ row }) => {
      return (
        <div className="button-flex text-center">
          <a className="btn-avatar-details" href="#">
            <span className="text-btn-avatar-details">Details</span>
            <span className="icon-btn-avatar-details">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.88642 5.21967C7.59352 5.51257 7.59353 5.98744 7.88642 6.28033L11.6061 9.99998L7.88642 13.7197C7.59352 14.0126 7.59353 14.4874 7.88642 14.7803C8.17931 15.0732 8.65419 15.0732 8.94708 14.7803L13.1971 10.5303C13.49 10.2374 13.49 9.76254 13.1971 9.46965L8.94708 5.21967C8.65418 4.92678 8.17931 4.92678 7.88642 5.21967Z"
                  fill="currentColor"
                />
              </svg>
            </span>
          </a>
        </div>
      );
    },
  },
];
