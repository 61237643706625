import React, { useEffect, useState } from 'react';
import TextInput from '../../../atoms/text-input/TextInput';
import Button from '../../../atoms/button/Button';
import { ArrowRight } from '../../../assets/Icons';
import { API_ENDPOINTS } from '../../utils/endpoints';
import { useDispatch, useSelector } from 'react-redux';
import {
    getOnboardingData,
    setOnboardingData
} from '../../../../redux/onboardingSlice';
import { http_put } from '../../utils/api_client';

export const CompanyInfoTitle = () => {
    return (
        <div className="header">
            <h1 className="title">
                What is your company's
                <br /> name and website?
            </h1>
            <p className="small">
                Please enter your company name and
                <br /> we will form your default subdomain
            </p>
        </div>
    );
};

const CompanyInfo = ({ onChangeStep }) => {
    const dispatch = useDispatch();
    const data = useSelector((state) => getOnboardingData(state));
    const [isLoading, setIsLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);

    const saveData = () => {
        setIsLoading(true);
        http_put(API_ENDPOINTS.PUT_COMPANY, {
            company_name: data.companyName,
            website: data.companyWebsite
        })
            .then((res) => {
                dispatch(setOnboardingData({ domain: res.data.domain }));
                onChangeStep();
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        dispatch(setOnboardingData({ [name]: value }));
    };

    useEffect(() => {
        if (data.companyName) {
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
        }
    }, [data]);

    return (
        <>
            <TextInput
                label="Company name"
                name="companyName"
                placeholder="Type company name"
                onChange={handleChange}
                value={data.companyName}
                required
                lineInput
            />
            <TextInput
                label="Company website"
                name="companyWebsite"
                placeholder="Type company website"
                onChange={handleChange}
                value={data.companyWebsite}
                lineInput
            />
            <Button
                className="lg"
                disabled={isDisabled}
                isLoading={isLoading}
                onClick={saveData}
            >
                Next
                <ArrowRight />
            </Button>
        </>
    );
};

export default CompanyInfo;
