import React from 'react';
import { removeActiveLayer } from '../../../redux/toolsSlice';
import { removeLayerConfig, removeLayer } from '../../../redux/stepsSlice';

const ContextMenu = ({ options, activeLayer, stepId, dispatch, onClick }) => {
  const contextActionClicked = (e) => {
    switch (e) {
      case 'Copy':
        console.log('Copy');
        break;
      case 'Paste':
        console.log('Paste');
        break;
      case 'Edit':
        console.log('Edit');
        break;
      case 'Delete':
        dispatch(removeActiveLayer());
        dispatch(removeLayerConfig());
        dispatch(removeLayer({ stepId: stepId, layerId: activeLayer.id }));
        break;
      default:
        break;
    }
    onClick();
  };

  const render = () => {
    return options.map((option) => {
      return (
        <div
          className="un-context-menu__item"
          onClick={() => contextActionClicked(option)}
          key={option}
        >
          {option}
        </div>
      );
    });
  };

  return <div className="un-context-menu">{render()}</div>;
};

export default ContextMenu;
