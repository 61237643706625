import React from 'react';
import { HistoryIcon } from '../../assets/Icons';

const History = () => {
    return (
        <div className="un-action">
            <HistoryIcon />
        </div>
    );
};

export default History;
