import React, { useEffect, useState } from 'react';
import { PlusGreyIcon } from '../../assets/Icons';
import { useSelector, useDispatch } from 'react-redux';
import {
  addStep,
  allSteps,
  updateStep,
  deleteStep,
  removeLayerConfig
} from '../../../redux/stepsSlice';
import {
  setActiveStep,
  getActiveStep,
  removeActiveLayer
} from '../../../redux/toolsSlice';
import Button from '../../atoms/button/Button';

const StepSidebar = () => {
  const dispatch = useDispatch();
  const steps = useSelector((state) => allSteps(state));
  const activeStep = useSelector((state) => getActiveStep(state));
  const [editingStep, setEditingStep] = useState(null);
  const material_type = window?.location?.pathname?.split('/')?.[2];

  const handleNewStep = () => {
    dispatch(addStep({ material_type }));
  };

  const isActiveStep = (index) => {
    if (activeStep === undefined) return false;
    return activeStep.id === index;
  };

  const handleStepChange = (step) => {
    dispatch(setActiveStep(step));
    dispatch(removeActiveLayer());
    dispatch(removeLayerConfig());
  };

  const handleDoubleClick = (step) => {
    setEditingStep(step.id);
  };

  const handleEditChange = (e) => {
    const updatedStep = {
      ...activeStep,
      [e.target.name]: e.target.value
    };
    dispatch(updateStep(updatedStep));
  };

  const handleCancelEdit = () => {
    setEditingStep(null);
  };

  const handleRemoveStep = (stepId) => {
    dispatch(deleteStep(stepId));

    if (steps.length > 1) {
      const newActiveStep = steps.find((step) => step.id !== stepId);
      dispatch(setActiveStep(newActiveStep));
    }
  };

  const renderSteps = () => {
    if (steps === undefined) return;
    return steps.map((step, index) => {
      const className = `step-block ${isActiveStep(step.id) ? 'active' : ''}`;
      return (
        <div
          className={className}
          key={index}
          onClick={() => handleStepChange(step)}
          onDoubleClick={() => handleDoubleClick(step)}
        >
          {editingStep === step.id ? (
            <>
            <input
              type="text"
              name="name"
              value={step.name}
              onChange={handleEditChange}
              className="step-name"
            />
            <input
              type="text"
              name="title"
              value={step.title}
              onChange={handleEditChange}
              className="step-title"
            />
            {material_type !== "paths" &&
              <textarea
                type="text"
                name="description"
                value={step.description}
                onChange={handleEditChange}
                className="step-description"
              />
            }
            <div className="un-btn-group">
              <Button className={'ghost'} onClick={handleCancelEdit}>
                Save
              </Button>
              <Button
                className={'ghost red'}
                onClick={() => handleRemoveStep(step.id)}
              >
                Delete
              </Button>
            </div>
            </>
          ) : (
            <>
              <p className="step-name">{step.name}</p>
              <p className="step-title">{step.title}</p>
              {material_type !== "paths" &&
                <p className="step-description">{step.description}</p>
              }
            </>
          )}
        </div>
      );
    });
  };

  useEffect(() => {
    if (steps === undefined) return;
    dispatch(setActiveStep(steps[0]));
  }, []);

  return (
    <div className="un-steps-sidebar">
      <div className="un-header">
        <p>Steps</p>
        <div className="btn-add" onClick={handleNewStep}>
          <PlusGreyIcon />
        </div>
      </div>
      <div className="body">{renderSteps()}</div>
    </div>
  );
};

export default StepSidebar;
