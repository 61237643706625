import React, { useState, useEffect, useRef } from 'react';
import { SketchPicker } from 'react-color';

const ColorOption = ({ defaultValue, onChange }) => {
  const [show, setShow] = useState(false);
  const [color, setColor] = useState(defaultValue);
  const popoverRef = useRef();

  useEffect(() => {
    setColor(defaultValue);
  }, [defaultValue]);

  const handleChange = (color) => {
    const hexColor = convertRGBAToHex(color.rgb);
    setColor(hexColor);
    onChange(hexColor);
  };

  const convertRGBAToHex = (color) => {
    let r = color.r.toString(16);
    let g = color.g.toString(16);
    let b = color.b.toString(16);
    let a = Math.round(color.a * 255).toString(16);

    if (r.length === 1) r = '0' + r;
    if (g.length === 1) g = '0' + g;
    if (b.length === 1) b = '0' + b;
    if (a.length === 1) a = '0' + a;

    return '#' + r + g + b + a;
  };

  const handleClickOutside = (event) => {
    if (popoverRef.current && !popoverRef.current.contains(event.target)) {
      setShow(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="un-color-option" ref={popoverRef}>
      <div className="display" onClick={() => setShow(!show)}>
        <div className="preview-block" style={{ backgroundColor: color }}></div>
        {color.toUpperCase()}
      </div>
      {show && (
        <div className="options">
          <SketchPicker color={color} onChange={handleChange} />
        </div>
      )}
    </div>
  );
};

export default ColorOption;
