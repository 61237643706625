import React, { useEffect, useState } from 'react';
import TextInput from '../../../atoms/text-input/TextInput';
import Button from '../../../atoms/button/Button';
import { ArrowRight } from '../../../assets/Icons';
import axios from 'axios';
import { API_ENDPOINTS } from '../../utils/endpoints';
import { useDispatch, useSelector } from 'react-redux';
import {
    getOnboardingData,
    setOnboardingData
} from '../../../../redux/onboardingSlice';
import { CONSTS } from '../../utils/endpoints';
import { http_put } from '../../utils/api_client';

export const UserFormTitle = () => (
    <div className="header">
        <h1 className="title">What is your name?</h1>
    </div>
);

const UserForm = ({ onChangeStep }) => {
    const dispatch = useDispatch();
    const data = useSelector((state) => getOnboardingData(state));
    const [isLoading, setIsLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);

    const saveBio = () => {
        setIsLoading(true);
        http_put(API_ENDPOINTS.PUT_PROFILE, {
            first_name: data.firstName,
            last_name: data.lastName
        })
            .then((res) => {
                onChangeStep();
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        dispatch(setOnboardingData({ [name]: value }));
    };

    useEffect(() => {
        if (data.firstName && data.lastName) {
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
        }
    }, [data]);

    return (
        <>
            <TextInput
                label="First name"
                name="firstName"
                placeholder="Enter first name"
                onChange={handleChange}
                value={data.firstName}
                lineInput
                required
            />
            <TextInput
                label="Last name"
                name="lastName"
                placeholder="Enter last name"
                onChange={handleChange}
                value={data.lastName}
                lineInput
                required
            />
            <Button
                className="lg"
                disabled={isDisabled}
                isLoading={isLoading}
                onClick={saveBio}
            >
                Next
                <ArrowRight />
            </Button>
        </>
    );
};

export default UserForm;
