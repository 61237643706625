import React from 'react';

const MaterialRow = ({ name, type, description, metadata }) => {
    const { opens, completions, uniques, score } = metadata;

    return (
        <div className="card">
            <div className="card-header"></div>
            <div className="card-body">
                <h5 className="card-title">{material.name}</h5>
                <p className="card-text">{material.description}</p>
            </div>
            <div className="card-footer"></div>
        </div>
    );
};

export default MaterialRow;
