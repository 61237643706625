import React from 'react';
import { BrushIcon, TextInputIcon } from '../../assets/Icons';

const Brush = () => {
  return (
    <div
      className="tool"
      title="Blur"
      data-tooltip-id="tools-tooltip"
      data-tooltip-content="Blur - Hide sensitive information"
    >
      <BrushIcon />
    </div>
  );
};

export default Brush;
