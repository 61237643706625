import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PathsEditPage from './pages/paths/EditPage';
import DashboardIndexPage from './pages/dashboard/IndexPage';
import AudienceIndexPage from './pages/audience/IndexPage';
import { Provider } from 'react-redux';
import { store } from '../redux/store';
import MessagesIndexPage from "./pages/messages/IndexPage";
import DomainPage from "./pages/users/settings/DomainPage";
import TeamPage from "./pages/users/settings/TeamPage";
import DomainConfigurationPage from './pages/users/settings/components/domain_configuration/DomainConfiguration';

const AppRoutes = () => {
  return (
    <Provider store={store}>
      <Router>
        <Routes>
          <Route path="/:companySlug/audiences" element={<AudienceIndexPage />} />
          <Route path="/:companySlug/dashboard" element={<DashboardIndexPage />} />
          <Route path="/:companySlug/messages" element={<MessagesIndexPage />} />
          <Route path="/:companySlug/settings/domain" element={<DomainPage />} />
          <Route path="/:companySlug/settings/domain/:id/domainConfiguration" element={<DomainConfigurationPage />} />
          <Route path="/:companySlug/settings/team" element={<TeamPage />} />
          <Route path="/:companySlug/paths/:lmSlug/edit" element={<PathsEditPage />} />
        </Routes>
      </Router>
    </Provider>
  );
};


export default AppRoutes;
