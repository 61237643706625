import React, { useEffect } from 'react'
import PreviewHeader from './PreviewHeader'
import { useDispatch, useSelector } from 'react-redux';
import { getLoading, setConfig, setLoading, setSteps } from '../../../redux/walkthroughPreviewSlice';
import { API_ENDPOINTS } from '../utils/endpoints';
import { http_get } from '../utils/api_client';
import Loader from '../../organisms/loader/Loader';
import { getMaterial, setMaterial } from '../../../redux/materialSlice';
import StepViewer from './StepViewer';
import { setProfileData } from '../../../redux/profileSlice';
import { getValue } from '../utils/tools';

const EditPagePreview = ({ id, current_user }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => getLoading(state));

  const material = useSelector((state) => getMaterial(state));
  const { config } = material;

  const defaultColors = {
    config: config
  };

  const baBGColor = getValue(defaultColors, 'ba', 'baHeaderColor');

  const bottomBgColorstyle = {
    backgroundColor: baBGColor || 'white',
    minHeight: '100vh',
    paddingBottom: '70px'
  }

  useEffect(() => {
    dispatch(setLoading(true));
    dispatch(setProfileData(current_user));
    const url = `${API_ENDPOINTS.GET_LEARNING_MATERIALS}/${id}`;

    http_get(url)
      .then(({ data }) => {
        const config = data.config;
        const steps = data.data;
        dispatch(setSteps(steps));
        dispatch(setConfig(config));
        dispatch(setMaterial(data));
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  }, []);

  return (
    <>
      {isLoading ?
        <Loader />
        :
        <div style={bottomBgColorstyle}>
          <PreviewHeader />
          <StepViewer />
        </div>
      }
    </>
  )
}

export default EditPagePreview