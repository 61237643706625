import React, { useState } from "react";

function Accordion({ title = "", isInitActive = false, children }) {
  const [isActive, setIsActive] = useState(isInitActive);

  return (
    <div className={`accordion-cover un-accordion ${isActive ? "active" : ""}`}>
      <div
        onClick={() => setIsActive((prev) => !prev)}
        className="header-accordion header-switch-accordion"
      >
        <div className="text-switch-accordion">{title}</div>
        <div className="icon-accordion">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.21967 12.2803C5.51256 12.5732 5.98744 12.5732 6.28033 12.2803L9.99998 8.56066L13.7197 12.2803C14.0126 12.5732 14.4874 12.5732 14.7803 12.2803C15.0732 11.9874 15.0732 11.5126 14.7803 11.2197L10.5303 6.96967C10.2374 6.67678 9.76254 6.67678 9.46965 6.96967L5.21967 11.2197C4.92678 11.5126 4.92678 11.9874 5.21967 12.2803Z"
              fill="currentColor"
            />
          </svg>
        </div>
      </div>
      <div
        className={`body-accordion accordion-content ${
          isActive ? "active body-switch-accordion" : ""
        }`}
        id={`accordion-body-${title.toLowerCase()}`}
      >
        {children}
      </div>
    </div>
  );
}

export default Accordion;
