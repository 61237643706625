import React from "react";
import KpiBox from "../KpiBox";

const makeClassName = (value) => {
  if (value === "engaged") {
    return "engaged";
  } else if (value === "at risk") {
    return "risk";
  } else {
    return "";
  }
};

function HeaderExtraData({ profile }) {
  return (
    <div className="top-avatar-data">
      <div className="flex-avatar-data">
        <div className="image-cover-avatar-data">
          <img
            className="image-avatar-data"
            src={profile.learner.imageUrl}
            alt=""
          />
        </div>
        <div className="content-avatar-data">
          <h3 className="name-avatar-data">{profile.learner.name}</h3>
          <div className="mail-cover-avatar-data">
            <a className="avatar-mail" href={`mailto:${profile.learner.email}`}>
              {profile.learner.email}
            </a>
          </div>
          <div className="activity-status-avatar-data">
            <div
              className={`activity-status ${makeClassName(
                profile.activityStatus
              )}`}
              style={{ textTransform: "capitalize" }}
            >
              {profile.activityStatus}
            </div>
          </div>
        </div>
      </div>
      <div className="wrapper-avatar-progress-bar">
        <div className="header-avatar-progress-bar">
          <div className="text-avatar-progress-bar">Completion Rate</div>
          <div className="counter-avatar-progress-bar">
            {profile.completionRate}%
          </div>
        </div>
        <div className="avatar-progress-bar">
          <div
            className="active-avatar-progress-bar"
            style={{ width: `${profile.completionRate}%` }}
          ></div>
        </div>
      </div>
      <div className="kpi-avatar-data">
        <KpiBox
          title="Unique Visits"
          value={profile.uniqueVisits}
          arrowType="up"
        />
        <KpiBox title="Opens" value={profile.opens} arrowType="up" />
        <KpiBox
          title="Completions"
          value={profile.completions}
          arrowType="down"
        />
        <KpiBox
          title="Satisfaction Score"
          value={profile.satisfactionScore}
          arrowType="down"
        />
      </div>
    </div>
  );
}

export default HeaderExtraData;
