import React, { useEffect, useState } from 'react';

const TextAreaOption = ({ defaultValue, onChange }) => {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleChange = (event) => {
    setValue(event.target.value);
    onChange(event.target.value);
  };

  return (
    <div className="un-textarea-option">
      <textarea style={{ width: '100%' }} rows={10} type="text" onChange={handleChange} defaultValue={value} />
    </div>
  );
};

export default TextAreaOption;
