import React, { useEffect, useState } from 'react';
import Button from '../../../atoms/button/Button';
import { ArrowRight } from '../../../assets/Icons';
import { API_ENDPOINTS } from '../../utils/endpoints';
import { useDispatch, useSelector } from 'react-redux';
import {
    getOnboardingData,
    setOnboardingData
} from '../../../../redux/onboardingSlice';
import { http_put } from '../../utils/api_client';

export const CompanySizeTitle = () => {
    return (
        <div className="header">
            <h1 className="title">How many people work in the company?</h1>
        </div>
    );
};

const CompanySize = ({ onChangeStep }) => {
    const dispatch = useDispatch();
    const data = useSelector((state) => getOnboardingData(state));
    const [isLoading, setIsLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const options = [
        'Just Me',
        '2-5',
        '6-10',
        '11-25',
        '26-50',
        '51-200',
        '200-1000',
        '1000-10,000',
        '10,000+'
    ];

    const saveData = () => {
        setIsLoading(true);
        http_put(API_ENDPOINTS.PUT_COMPANY, {
            company_size: data.companySize
        })
            .then((res) => {
                onChangeStep();
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleChange = (value) => {
        const name = 'companySize';
        dispatch(setOnboardingData({ [name]: value }));
    };

    useEffect(() => {
        if (data.companySize) {
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
        }
    }, [data]);

    return (
        <>
            <div className="company-size">
                {options.map((option, index) => {
                    return (
                        <div
                            key={index}
                            className={`size-option ${
                                data.companySize === option ? 'selected' : ''
                            }`}
                            onClick={() => handleChange(option)}
                        >
                            {option}
                        </div>
                    );
                })}
            </div>
            <Button
                className="lg"
                isLoading={isLoading}
                onClick={saveData}
                disabled={isDisabled}
            >
                Next
                <ArrowRight />
            </Button>
        </>
    );
};

export default CompanySize;
