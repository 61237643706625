import React from 'react'
import { useSelector } from 'react-redux'
import { getActiveStep, setActiveStep } from '../../../redux/toolsSlice';
import { ELEMENTS } from './components/authoring/content/Consts';
import { LargeTextContentPreview } from './components/authoring/content/fields/LargeTextContent';
import { BodyTextContentPreview } from './components/authoring/content/fields/BodyTextContent';
import { VideoContentPreview } from './components/authoring/content/fields/VideoContent';
import EditorPreviewEmbed from '../walkthroughs/EditorPreviewEmbed';
import { ImageContentPreview } from './components/authoring/content/fields/ImageContent';
import { EmbedCodeContentPreview } from './components/authoring/content/fields/EmbedCodeContent';
import { AttachmentContentPreview } from './components/authoring/content/fields/AttachmentContent';
import { ShortAnswerContentPreview } from './components/authoring/content/fields/ShortAnswerContent';
import { LongAnswerContentPreview } from './components/authoring/content/fields/LongAnswerContent';
import { CheckboxContentPreview } from './components/authoring/content/fields/CheckboxContent';
import { MultiSelectContentPreview } from './components/authoring/content/fields/MultiSelectContent';
import { FileUploadContentPreview } from './components/authoring/content/fields/FileUploadContent';
import { DateIcon } from '../../assets/Icons';
import ButtonGroup from './components/authoring/content/fields/ButtonGroup';
import moment from 'moment';

const StepPreviewContent = ({ moveToNextStep, isComplete }) => {

  const activeStep = useSelector((state) => getActiveStep(state));

  const renderElement = (layer) => {
    switch (layer.type) {
      case ELEMENTS.LARGE_TEXT:
        return <LargeTextContentPreview layer={layer} />
      case ELEMENTS.BODY:
        return <BodyTextContentPreview layer={layer} />
      case ELEMENTS.IMAGE:
        return <ImageContentPreview layer={layer} />
      case ELEMENTS.VIDEO:
        return <VideoContentPreview layer={layer} />;
      case ELEMENTS.WALKTHROUGH:
        return <EditorPreviewEmbed id={layer.source} layer={layer} />;
      case ELEMENTS.EMBEDDED_CODE:
        return <EmbedCodeContentPreview layer={layer} />;
      case ELEMENTS.ATTACHMENT:
        return <AttachmentContentPreview layer={layer} />;
      case ELEMENTS.SHORT_ANSWER:
        return <ShortAnswerContentPreview layer={layer} />;
      case ELEMENTS.LONG_ANSWER:
        return <LongAnswerContentPreview layer={layer} />;
      case ELEMENTS.MULTI_SELECT:
        return <MultiSelectContentPreview layer={layer} />;
      case ELEMENTS.CHECKBOX:
        return <CheckboxContentPreview layer={layer} />;
      case ELEMENTS.FILE_UPLOAD:
        return <FileUploadContentPreview layer={layer} />;

      // case ELEMENTS.SMALL_TEXT:
      //   return (
      //     <DropWrapper>
      //       <h2>{layer.content}</h2>
      //     </DropWrapper>
      //   )
      // case ELEMENTS.BODY:
      //   return (
      //     <BodyTextContent
      //       layer={layer}>
      //     </BodyTextContent>
      //   )
      // case ELEMENTS.SHORT_ANSWER:
      //   return (
      //     <ShortAnswerContent
      //       layer={layer}>
      //     </ShortAnswerContent>
      //   )
      // case ELEMENTS.LONG_ANSWER:
      //   return (
      //     <LongAnswerContent
      //       layer={layer}>
      //     </LongAnswerContent>
      //   )
      // case ELEMENTS.CHECKBOX:
      //   return (
      //     <CheckboxContent
      //       layer={layer}>
      //     </CheckboxContent>
      //   )
      // case ELEMENTS.QUOTES:
      //   return <blockquote>{layer.content}</blockquote>;
      // case ELEMENTS.LONG_TEXT_FIELD:
      //   return <textarea>{layer.content}</textarea>;
      // case ELEMENTS.INPUT_FIELD:
      //   return <input value={layer.content} />;
      // case ELEMENTS.ATTACHMENT:
      //   return (
      //     <AttachmentContent
      //       layer={layer}>
      //     </AttachmentContent>
      //   );
      // case ELEMENTS.TWO_COLUMNS:
      //   return <div>{layer.content}</div>;
      // case ELEMENTS.THREE_COLUMNS:
      //   return <div>{layer.content}</div>;
      // case ELEMENTS.IMAGE:
      //   return (
      //     <ImageContent
      //       layer={layer}
      //     ></ImageContent>
      //   );
      // case ELEMENTS.VIDEO:
      //   return <VideoContent layer={layer} />;
      // case ELEMENTS.WALKTHROUGH:
      //   return <WalkthroughContent layer={layer} />;
      // case ELEMENTS.EMBEDDED_CODE:
      //   return (
      //     <EmbedCodeContent
      //       layer={layer}>
      //     </EmbedCodeContent>
      //   );
      // default:
      //   return <EmptyContent />;
      default:
        return (
          <>default</>
        )
    }

  }

  let today = new Date();
  const dueDateObj = new Date(activeStep.dueDate);
  const timeDiff = dueDateObj.getTime() - today.getTime();
  const daysDifference = Math.ceil(timeDiff / (1000 * 3600 * 24));
  let displayValue;
  if (daysDifference == 0) {
    displayValue = 'Today';
  } else if (daysDifference == 1) {
    displayValue = 'Tomorrow';
  } else {
    displayValue = "in " + daysDifference + " days";
  }

  return (
    <div className='preview-element-list-wrapper'>
      {activeStep?.layers.filter(ele => ele.id == 'main').map((layer, index) => {
        return (
          <div key={`${layer.id}_${index}`} className='preview-element-wrapper'>
            {renderElement(layer)}
          </div>
        )
      })}
      {activeStep.dueDate &&
        <div className='flex-design-form'>
          <DateIcon />
          <p>
            Due {displayValue}, {moment(activeStep.dueDate).format('MMMM DD, YYYY')}
          </p>
        </div>
      }
      {activeStep?.layers.filter(ele => ele.id !== 'main').map((layer, index) => {
        return (
          <>
            <div key={`${layer.id}_${index}`} className='preview-element-wrapper'>
              {renderElement(layer)}
            </div>
          </>
        )
      })}
      <ButtonGroup moveToNextStep={moveToNextStep} isComplete={isComplete} />
    </div>
  )
}

export default StepPreviewContent