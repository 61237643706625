import React from 'react';
import { Provider } from 'react-redux';
import { store } from '../../../../redux/store';
import LMLineTab from '../../../organisms/line_tab/LMLineTab';
import { CONSTS } from '../../utils/endpoints';
import ShareAccess from '../../../organisms/learning_materials/ShareAccess';
import LMTable from '../../../organisms/learning_materials/LMTable';
import { docsData } from './data';

const IndexPage = () => {

  return (
    <Provider store={store}>
      <div className="un-page-container__learning-materials">
        <div className="header">
          <div className="title">
            <h1>Docs</h1>
          </div>
        </div>
        <LMLineTab defaultTab={1} tabName={CONSTS.DOCS} />
        <ShareAccess />
        <LMTable LMType={CONSTS.DOCS} data={docsData} />
      </div>
    </Provider>
  );
};

export default IndexPage;
