import React from 'react';
import { LineIcon } from '../../assets/Icons';

const Image = () => {
    return (
        <div className="tool">
            <LineIcon />
        </div>
    );
};

export default Image;
