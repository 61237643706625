import { createSlice } from '@reduxjs/toolkit';

export const notifySlice = createSlice({
  name: 'notification',
  initialState: {
    content: '',
    type: '',
  },
  reducers: {
    setNotification: (state, action) => {
      state.content = action.payload.content;
      state.type = action.payload.type;
    },
    clearNotification: (state) => {
      state.content = '';
      state.type = '';
    }
  }
});

export const {
  setNotification,
  clearNotification } = notifySlice.actions;

export const getNotification = (state) => state.notification;

export default notifySlice.reducer;
