import React from 'react';

const CloseIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 6L6 18"
        stroke="#1A1A1A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 6L18 18"
        stroke="#1A1A1A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const ModalWrapper = ({ modalId, children, footer, size, onCloseModal }) => {
  const closeModal = () => {
    onCloseModal();
  };

  return (
    <div
      className="modal fade"
      id={modalId}
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
    >
      <div className={`modal-dialog ${size}`} role="document" id="modal_dialog">
        <div className="modal-content">
          <button
            className="close"
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            onClick={closeModal}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <g fill="none" fillRule="evenodd">
                <path d="M0 0L24 0 24 24 0 24z" />
                <path
                  fill="#657489"
                  d="M12 10.586L16.95 5.636 18.364 7.05 13.414 12 18.364 16.95 16.95 18.364 12 13.414 7.05 18.364 5.636 16.95 10.586 12 5.636 7.05 7.05 5.636z"
                />
              </g>
            </svg>
          </button>
          <div className="modal-body">
            <div className="m-body">
              {children}
            </div>
            <div className="m-bottom">
              {footer}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ModalWrapper.defaultProps = {
  size: 'lg big',
  footer: null,
  onCloseModal: () => {},
};

export default ModalWrapper;
