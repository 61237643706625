import { createSlice } from '@reduxjs/toolkit';

export const walkthroughPreviewSlice = createSlice({
  name: 'walkthroughPreview',
  initialState: {
    activeStep: null,
    steps: [],
    currPage: null,
    index: 0,
    config: [],
    loading: true,
    completed: false
  },
  reducers: {
    setActiveStep: (state, action) => {
      state.activeStep = action.payload;
    },
    setSteps: (state, action) => {
      state.steps = action.payload;
      state.activeStep = action.payload[0];
    },
    setCurrPage: (state, action) => {
      state.currPage = action.payload;
    },
    setIndex: (state, action) => {
      if (state.steps.length > 0 && action.payload < state.steps.length) {
        state.index = action.payload;
        state.currPage = state.steps[action.payload];
      }
    },
    setNextIndex: (state) => {
      if (state.steps.length > 0 && state.index < state.steps.length - 1) {
        state.index = state.index + 1;
        state.currPage = state.steps[state.index];
      }else{
        state.completed = true;
      }
    },
    setPrevIndex: (state) => {
      if (state.steps.length > 0 && state.index > 0) {
        state.index = state.index - 1;
        state.currPage = state.steps[state.index];
      }
    },
    setConfigColors: (state, action) => {
      state.configColors = action.payload;
    },
    setConfig: (state, action) => {
      state.config = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setCompleted: (state, action) => {
      state.completed = action.payload;
    }
  }
});

export const {
  setActiveStep,
  setSteps,
  setCurrPage,
  setIndex,
  setConfigColors,
  setNextIndex,
  setPrevIndex,
  setConfig,
  setLoading,
  setCompleted
} = walkthroughPreviewSlice.actions;

export const getSteps = (state) => state.walkthroughPreview.steps;

export const getIndex = (state) => state.walkthroughPreview.index;

export const getCurrStep = (state) => state.walkthroughPreview.activeStep;

export const isCompleted = (state) => state.walkthroughPreview.completed;

export const selectSteps = (state) => state.walkthroughPreview.steps;

export const selectCurrPage = (state) => state.walkthroughPreview.currPage;

export const selectCurrPageIdx = (state) =>
  state.walkthroughPreview.currPageIdx;

export const getConfig = (state) => state.walkthroughPreview.config;

export const getLoading = (state) => state.walkthroughPreview.loading;

export default walkthroughPreviewSlice.reducer;
