import React from 'react';
import { getValue } from '../../pages/utils/tools';

const StepIndicator = ({ steps, index, onChange, configColors }) => {
  const currentStep = steps[index];
  const setCurrent = (index) => () => {
    onChange(index);
  };

  const tnTextColor = getValue(configColors, 'tn', 'tnTextColor');
  const tnBGColor = getValue(configColors, 'tn', 'tnBGColor');
  const style = {
    '--background-color': tnTextColor || configColors.primary,
    '--primary-color': tnBGColor || configColors.background
  };

  const renderStep = (step, index) => {
    const isFirst = step.id === steps[0].id;
    const currIndex = steps.findIndex((step) => step.id === currentStep.id);
    const activeClass = currIndex + 1 > index ? 'active' : '';

    return (
      <div
        key={index}
        className="step-indicator__item"
        onClick={setCurrent(index)}
      >
        {!isFirst && <div className={`line ${activeClass}`} style={style} />}
        <div className={`step ${activeClass}`} style={style}>
          {index + 1}
        </div>
      </div>
    );
  };

  return (
    <div className="step-indicator">
      <div className="step-indicator__steps">
        {steps.map((step, index) => renderStep(step, index))}
      </div>
    </div>
  );
};

export default StepIndicator;
