import React from "react";
import Image1 from "components/assets/images/img-1.png"
import ChatAvatar from "components/assets/images/chat-avatar.png"
import ImageAttachment from "components/assets/images/image-attch.png"

function ChatFeed() {
  return (
    <div class="message-chat">
      <div class="cover-date-message-chat">
        <div class="date-message-chat">Today, Jun 20</div>
      </div>
      {/* Add messages loop here */}
      <div class="item-message-chat">
        <div class="head-item-message-chat">
          <div class="avatar-item-message-chat">
            <img src={Image1} alt="Willard Purnell" />
          </div>
          <div class="content-item-message-chat">
            <div class="name-item-message-chat">Willard Purnell</div>
            <div class="time-item-message-chat">07:00 pm</div>
          </div>
        </div>
        <div class="body-item-message-chat">
          <div class="list-item-message-chat">
            <div class="text-sender">
              Hi there! I'm having some trouble filling out the Medication form
              on the 4th step of the course. I'm not sure what details to
              provide. Can you help me with this?
            </div>
          </div>
        </div>
      </div>
      <div class="item-message-chat recipient-message">
        <div class="head-item-message-chat">
          <div class="avatar-item-message-chat">
            <img src={ChatAvatar} alt="Willard Purnell" />
          </div>
          <div class="content-item-message-chat">
            <div class="name-item-message-chat">Freja Begum</div>
            <div class="time-item-message-chat">05:20 pm</div>
          </div>
        </div>
        <div class="body-item-message-chat">
          <div class="list-item-message-chat">
            <div class="text-sender">
              Hi there! I'm having some trouble filling out the Medication form
              on the 4th step of the course. I'm not sure what details to
              provide. Can you help me with this?
            </div>
          </div>
        </div>
      </div>
      <div class="item-message-chat">
        <div class="head-item-message-chat">
          <div class="avatar-item-message-chat">
            <img src={Image1} alt="Willard Purnell" />
          </div>
          <div class="content-item-message-chat">
            <div class="name-item-message-chat">Willard Purnell</div>
            <div class="time-item-message-chat">07:00 pm</div>
          </div>
        </div>
        <div class="body-item-message-chat">
          <div class="list-item-message-chat">
            <div class="text-sender">
              Thanks for your help! I'm not sure what kind of medication details
              I should provide, like do I need to include dosage, frequency, or
              any other specific information?
            </div>
            <div class="attached-message-chat">
              <img src={ImageAttachment} />
            </div>
          </div>
        </div>
      </div>
      <div class="item-message-chat recipient-message">
        <div class="head-item-message-chat">
          <div class="avatar-item-message-chat">
            <img src={ChatAvatar} alt="Willard Purnell" />
          </div>
          <div class="content-item-message-chat">
            <div class="name-item-message-chat">Freja Begum</div>
            <div class="time-item-message-chat">05:20 pm</div>
          </div>
        </div>
        <div class="body-item-message-chat">
          <div class="list-item-message-chat">
            <div class="text-sender">
              Great question! When filling out the Medication form, it's
              important to include the following details: Medication name,
              Dosage, Frequency, Start date, End date
            </div>
          </div>
        </div>
      </div>
      <div class="item-message-chat">
        <div class="head-item-message-chat">
          <div class="avatar-item-message-chat">
            <img src={Image1} alt="Willard Purnell" />
          </div>
          <div class="content-item-message-chat">
            <div class="name-item-message-chat">Willard Purnell</div>
            <div class="time-item-message-chat">07:00 pm</div>
          </div>
        </div>
        <div class="body-item-message-chat">
          <div class="list-item-message-chat">
            <div class="text-sender">Thank you for clarifying! </div>
          </div>
        </div>
      </div>
      <div class="item-message-chat recipient-message">
        <div class="head-item-message-chat">
          <div class="avatar-item-message-chat">
            <img src={ChatAvatar} alt="Willard Purnell" />
          </div>
          <div class="content-item-message-chat">
            <div class="name-item-message-chat">Freja Begum</div>
            <div class="time-item-message-chat">05:20 pm</div>
          </div>
        </div>
        <div class="body-item-message-chat">
          <div class="list-item-message-chat">
            <div class="text-sender">
              You're very welcome! If you encounter any more issues or have
              further questions, don't hesitate to reach out.
            </div>
          </div>
        </div>
      </div>
      <div class="divider-message-chat">
        <span class="text-divider-message-chat">New</span>
      </div>
      <div class="item-message-chat">
        <div class="head-item-message-chat">
          <div class="avatar-item-message-chat">
            <img src={Image1} alt="Willard Purnell" />
          </div>
          <div class="content-item-message-chat">
            <div class="name-item-message-chat">Willard Purnell</div>
            <div class="time-item-message-chat">07:00 pm</div>
          </div>
        </div>
        <div class="body-item-message-chat">
          <div class="list-item-message-chat">
            <div class="text-sender">
              Great, I'll keep going through the materials{" "}
            </div>
          </div>
        </div>
      </div>
      <div class="item-message-chat">
        <div class="head-item-message-chat">
          <div class="avatar-item-message-chat">
            <img src={Image1} alt="Willard Purnell" />
          </div>
          <div class="content-item-message-chat">
            <div class="name-item-message-chat">Willard Purnell</div>
            <div class="time-item-message-chat">07:00 pm</div>
          </div>
        </div>
        <div class="body-item-message-chat">
          <div class="list-item-message-chat">
            <div class="text-sender">Thanks a lot!</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChatFeed;
