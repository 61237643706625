import React, { useState } from 'react';
import RegistrationLayout from '../../layouts/registration/RegistrationLayout';
import IndustryForm, { IndustryFormTitle } from './steps/industry-form';
import UserForm, { UserFormTitle } from './steps/user-form';
import CompanyInfo, { CompanyInfoTitle } from './steps/company-info';
import CompanyDetails, { CompanyDetailsTitle } from './steps/company-details';
import CompanySize, { CompanySizeTitle } from './steps/company-size';
import TeamInvite, { TeamInviteTitle } from './steps/team-invite';
import { ONBOARDING_STATES } from '../../../utils/enum';
import EmailVerification from './email-verification';

const OnboardingLayout = () => {
    const totalSteps = 6;
    const components = [
        {
            title: <UserFormTitle />,
            component: <UserForm />,
            state: ONBOARDING_STATES.USER_BIO
        },
        {
            title: <IndustryFormTitle />,
            component: <IndustryForm />,
            state: ONBOARDING_STATES.INDUSTRY_INFO
        },
        {
            title: <CompanyInfoTitle />,
            component: <CompanyInfo />,
            state: ONBOARDING_STATES.COMPANY_INFO
        },
        {
            title: <CompanyDetailsTitle />,
            component: <CompanyDetails />,
            state: ONBOARDING_STATES.COMPANY_DETAILS
        },
        {
            title: <CompanySizeTitle />,
            component: <CompanySize />,
            state: ONBOARDING_STATES.COMPANY_SIZE
        },
        {
            title: <TeamInviteTitle />,
            component: <TeamInvite />,
            state: ONBOARDING_STATES.INVITE_TEAM_MEMBERS
        }
    ];

    const [currStep, setCurrStep] = useState(0);
    const [currComponent, setCurrComponent] = useState(components[currStep]);
    const [Component, setComponent] = useState(() => currComponent.component);
    const [title, setTitle] = useState(currComponent.title);
    const [isComplete, setIsComplete] = useState(false);

    const handleNextStep = () => {
        if (currStep < totalSteps - 1) {
            setCurrStep(currStep + 1);
            setCurrComponent(components[currStep + 1]);
            setComponent(components[currStep + 1].component);
            setTitle(components[currStep + 1].title);
        }

        if (currStep === totalSteps - 1) {
            setIsComplete(true);
        }
    };

    const onBackClick = () => {
        if (currStep > 0) {
            setCurrStep(currStep - 1);
            setCurrComponent(components[currStep - 1]);
            setComponent(components[currStep - 1].component);
            setTitle(components[currStep - 1].title);
        }
    };

    return (
        <>
            {!isComplete && (
                <RegistrationLayout
                    title={title}
                    currStep={currStep + 1}
                    totalSteps={totalSteps}
                    onBackClick={onBackClick}
                >
                    {React.cloneElement(Component, {
                        onChangeStep: handleNextStep
                    })}
                </RegistrationLayout>
            )}
            {isComplete && <EmailVerification />}
        </>
    );
};

export default OnboardingLayout;
