import React, { useEffect, useState } from 'react';
import Dropdown from 'react-dropdown';

const DropdownSelect = ({
  name,
  value,
  type,
  placeholder,
  options,
  label,
  onChange,
  onValidation,
  className,
  required,
  lineInput,
  error,
  validationCallback
}) => {
  const style = {};
  const [errorMsg, setErrorMsg] = useState(error);

  useEffect(() => {
    setErrorMsg(error);
  }, [error]);

  if (lineInput) {
    className += ' line-input';
  }

  if (errorMsg) {
    className += ' error';
  }

  const handleChange = (e) => {
    onChange(e);
    setErrorMsg('');
  };

  const handleMouseout = (e) => {
    if (required && !e.target.value) {
      setErrorMsg('This field is required');
      onValidation(e.target.name, false);
      return;
    }

    // validate with html5 validation
    if (e.target.validity && !e.target.validity.valid) {
      setErrorMsg(e.target.validationMessage);
      onValidation(e.target.name, false);
      return;
    }

    if (validationCallback) {
      const result = validationCallback(e);
      setErrorMsg(result);
      onValidation(e.target.name, false);
      return;
    }

    onValidation(e.target.name, true);
  };

  const renderDropdown = () => {
    return (
      <Dropdown
        className={className}
        options={options}
        onChange={handleChange}
        value={value}
        placeholder={placeholder}
      />
    );
  };

  return (
    <div className="un-dropdown-input">
      <div className='un-dropdown'>
        <label htmlFor={name}>
          {label}
          {required && <span className="required">*</span>}
        </label>
        {renderDropdown()}
        <p className="error-message">{errorMsg}</p>        
      </div>
    </div>
  );
};

DropdownSelect.defaultProps = {
  onChange: () => {},
  onValidation: () => {},
  className: '',
  placeholder: '',
  label: '',
  name: '',
  type: 'text',
  value: '',
  options: [],
  required: false,
  lineInput: false,
  error: '',
  validationCallback: () => {}
};

export default DropdownSelect;
