import React, { memo, useEffect, useLayoutEffect, useRef, useState } from "react";
import { getActiveStep, selectActiveLayer, setActiveLayer } from "../../../../../../../redux/toolsSlice";
import { getLayerObjConfig, removeLayerConfig, setLayerConfig, updateLayer } from '../../../../../../../redux/stepsSlice';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

export const EmbedCodeLayerDefaults = (id) => {
  return {
    id: id,
    type: 'embedded_code',
    source: ''
  };
}

function EmbedCodeContent({ layer }) {
  const dispatch = useDispatch();
  const layerObjConfig = useSelector(
    (state) => getLayerObjConfig(state, layer),
    shallowEqual
  );
  const activeStep = useSelector((state) => getActiveStep(state));
  const stepId = activeStep.id;
  const activeLayer = useSelector((state) => selectActiveLayer(state));

  const defaultConfig = [
    {
      layerId: layer.id,
      sectionId: 'embedded_code',
      sectionTitle: 'Embed Element',
      values: [
        {
          id: 'source',
          title: 'Embed Code',
          type: 'textarea',
          value: layer.source || '',
          direction: 'column'
        }
      ]
    }
  ];

  const editElement = () => {
    removeFocusElement();
    dispatch(setActiveLayer(layer));
    dispatch(setLayerConfig(defaultConfig));
  }

  const removeFocusElement = () => {
    dispatch(setActiveLayer({}));
    dispatch(removeLayerConfig());
  }

  const handleSourceChange = (e) => {
    setSource(e.target.value);
  }

  useEffect(() => {
    if (layerObjConfig) {
      const newLayer = {
        ...layer,
        ...layerObjConfig
      };
      dispatch(updateLayer({ stepId: stepId, layer: newLayer }));
    }
  }, [layerObjConfig]);

  return (
    <div className='video-wrapper'>
      {layer.source ?
        <EmbedCodeContentPreview onClick={editElement} layer={layer} />
      :
        <div className="cover-upload-form" tabIndex={0} onClick={editElement}>
          <div className="filed-upload-form icon-filed-upload-form">
            <header className="text-upload-form">
              Use the sidebar to embed code to this area. <br />It will appear here once added
            </header>
          </div>
        </div>
      }
    </div>
  )
}

export const EmbedCodeContentPreview = memo(({layer, onClick}) => {
  const elRef = useRef();

  useLayoutEffect(() => {
		const range = document.createRange();
		range.selectNode(elRef.current);
		const documentFragment = range.createContextualFragment(layer.source);
		elRef.current.innerHTML = '';
		elRef.current.append(documentFragment);
	}, []);

  return(
    <div
      ref={elRef} 
      tabIndex={0}
      onClick={onClick}
    />
  )
});

export default EmbedCodeContent;