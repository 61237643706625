import React, { useEffect, useState } from 'react';
import Toolbar from '../../organisms/toolbar/Toolbar';
import StepSidebar from '../../organisms/step-sidebar/StepSidebar';
import DesignCanvas from '../../organisms/design-canvas/DesignCanvas';
import EditorOptions from '../../organisms/editor-options/EditorOptions';
import { useDispatch, useSelector } from 'react-redux';
import { loadSteps } from '../../../redux/stepsSlice';
import Loader from '../../organisms/loader/Loader';
import { setMaterial, getMaterial } from '../../../redux/materialSlice';
import { setMainConfig } from '../../../redux/stepsSlice';
import { setLearningMaterialId } from '../../../redux/toolsSlice';
import { http_get } from '../utils/api_client';
import { API_ENDPOINTS } from '../utils/endpoints';
import { fetchCompanyDetails, getCompany } from '../../../redux/companySlice';

const Editor = ({ id }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setLearningMaterialId(id));
  }, [id]);

  const [isLoading, setIsLoading] = useState(true);
  const material = useSelector((state) => getMaterial(state));
  const companyName = material.company_name;
  const materialName = material.name;

  const loadMaterial = () => {
    setIsLoading(true);
    http_get(API_ENDPOINTS.GET_WALKTHROUGH(id))
      .then((res) => {
        const steps = res.data.data;
        dispatch(setMaterial(res.data));
        dispatch(setMainConfig({config: res.data.config, type: 'walkthrough'}));
        dispatch(loadSteps(steps));
      })
      .catch((err) => { })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    loadMaterial();
    dispatch(fetchCompanyDetails());
  }, []);

  return (
    <>
      {isLoading && <Loader />}
      {!isLoading && (
        <>
          <Toolbar
            id={id}
            companyName={companyName}
            materialName={materialName}
          />
          <div className="editor-body">
            <StepSidebar />
            <DesignCanvas />
            <EditorOptions />
          </div>
        </>
      )}
    </>
  );
};

export default Editor;
