import React from "react";
import DashboardToolbar from "./components/dashboard/DashboardToolbar";
import DashboardDataCounter from "./components/dashboard/DashboardDataCounter";
import EngagedUsers from "./components/dashboard/EngagedUsers";
import EngagedMaterial from "./components/dashboard/engaged-material";
import SidebarImage from "components/assets/images/sidebar-image.png";
function DashboardAuthoring() {
  return (
    <div className="page-wrapper">
      <div className="dashboard-wrapper-flex container">
        <div className="content-dashboard-wrapper">
          <DashboardToolbar />
          <DashboardDataCounter />
          <EngagedUsers />
          <EngagedMaterial />
        </div>
      </div>
    </div>
  );
}

export default DashboardAuthoring;
