import React, { useEffect, useState, useRef } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getActiveStep, selectActiveLayer, setActiveLayer } from '../../../../../../../redux/toolsSlice';
import { getLayerObjConfig, removeLayerConfig, setLayerConfig, updateLayer } from '../../../../../../../redux/stepsSlice';
import FontOption from '../../../../../../organisms/editor-design/options/FontOption';

export const LargeTextLayerContentDefaults = (id) => {
  return {
    id: id,
    type: 'large_text',
    fontSize: 34,
    color: '#000000',
    fontFamily: 'Open Sans',
    content: "This is large title"
  };
}

function LargeTextContent({ layer }) {

  const [isEditing, setIsEditing] = useState(false);
  const [draw, setDraw] = useState(false);

  const textRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      setDraw(true);
    }, 1000);
  }, []);

  const dispatch = useDispatch();
  const layerObjConfig = useSelector((state) => getLayerObjConfig(state, layer), shallowEqual);

  const activeStep = useSelector((state) => getActiveStep(state));
  const stepId = activeStep.id;
  const activeLayer = useSelector((state) => selectActiveLayer(state));

  useEffect(() => {
    if (layerObjConfig) {
      const newLayer = { ...layer, ...layerObjConfig };
      dispatch(updateLayer({ stepId, layer: newLayer }));
    }
  }, [layerObjConfig]);

  const defaultConfig = [
    {
      layerId: layer.id,
      sectionId: 'text',
      sectionTitle: 'Large Title Element',
      values: [
        {
          id: 'fill',
          title: 'Text Color',
          type: 'color',
          value: layer.fill || '#000000'
        },
        {
          id: 'font',
          title: 'Font',
          type: 'font',
          value: layer.font || 'Open Sans'
        },
        {
          id: 'fontSize',
          title: 'Font Size',
          type: 'px-size',
          value: layer.fontSize || 34
        },
      ]
    }
  ];

  const EditElement = () => {
    removeFocusElement();
    dispatch(setActiveLayer(layer));
    dispatch(setLayerConfig(defaultConfig));
    setIsEditing(true);
  }

  const removeFocusElement = () => {
    dispatch(setActiveLayer({}));
    dispatch(removeLayerConfig());
    setIsEditing(false);
  }

  const updateText = () => {
    const newLayer = {
      ...layer,
      content: textRef.current ? textRef.current.value : layer.text
    };
    dispatch(updateLayer({ stepId: stepId, layer: newLayer }));
  };

  const handleEditBlur = (e) => {
    dispatch(setActiveLayer({}));
    dispatch(removeLayerConfig());
    setIsEditing(false);
    updateText();
  };

  const onPressEnter = (e) => {
    if (e.key === 'Enter' || e.key === "Escape") {
      dispatch(setActiveLayer({}));
      dispatch(removeLayerConfig());
      setIsEditing(false);
      updateText();
    }
  };

  return (
    <>
      <div className="container-large-text">
        {isEditing ? (
          <>
            <input
              ref={textRef}
              defaultValue={layer.content}
              onBlur={handleEditBlur}
              onKeyDown={onPressEnter}
              style={{
                fontSize: layer.fontSize,
                color: layer.fill,
                border: 'none',
                padding: 0,
                margin: 0,
                background: 'none',
                outline: 'none',
                fontFamily: layer.font,
                width: '100%',
                height: '100%',
                fontWeight: 'bold', // added
              }}
            />
          </>
        ) : (
          <>
            {draw &&
              <LargeTextContentPreview layer={layer} EditElement={EditElement} />
            }
          </>
        )}
      </div>
      <div hidden>
        <FontOption defaultValue={layer.font} />
      </div>
    </>
  )
}

export const LargeTextContentPreview = ({ layer, EditElement }) => {
  return (
    <p
      tabIndex={0}
      onClick={EditElement}
      style={{
        fontSize: layer.fontSize,
        color: layer.fill,
        fontFamily: layer.font,
        fontWeight: 'bold', // added
      }}>
      {layer.content}
    </p>
  )
}

export default LargeTextContent;