import { createSlice } from '@reduxjs/toolkit';

export const materialSlice = createSlice({
  name: 'material',
  initialState: {},
  reducers: {
    setMaterial: (state, action) => {
      return action.payload;
    },
    updateMaterial: (state, action) => {
      return {
        ...state,
        ...action.payload
      };
    }
  }
});

export const { setMaterial, updateMaterial } = materialSlice.actions;

export const getMaterial = (state) => state.material;

export default materialSlice.reducer;
