import React, { useEffect } from 'react'
import { store } from '../../../redux/store';
import { Provider, useDispatch } from 'react-redux';
import { setMaterial } from '../../../redux/materialSlice';
import PublishedContentView from './PublishedContentView';

const PublishedView = ({ learning_material, current_user }) => {

  return (
    <Provider store={store}>
      <PublishedContentView material={learning_material} current_user={current_user} />
    </Provider>
  )
}

export default PublishedView