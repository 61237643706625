import React from "react";

import moment from "moment";
import MultiCombobox from "../MultiCombobox";

const options = [
  {
    value: "form",
    label: "Form",
  },
  {
    value: "paths",
    label: "Paths",
  },
  {
    value: "walkthroughs",
    label: "Walkthroughs",
  },
];

function Notes({ profile }) {
  return (
    <div id="tab-4" className="tab-content-avatar-model">
      <div className="header-tab-avatar mb-8">
        <div className="item-header-tab-avatar">
          <h3 className="heading-tab-avatar">Notes</h3>
        </div>
      </div>
      {/* <!-- Notes Avtar Model Rich --> */}
      <div className="notes-avatar-model-rich">
        <div className="avatar-rich-model">
          <img src={profile.learner.imageUrl} alt="" />
        </div>
        <div className="cover-input-rich-model">
          <textarea
            className="input-rich-text"
            placeholder="What is on your mind ?"
          ></textarea>
          <div className="actions-rich-model">
            <div className="item-actions-rich-model">
              <a className="link-actions-rich-model" href="#">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M15.7 7.27907V15.15C15.7 17.2763 13.9763 19 11.85 19C9.7237 19 8 17.2763 8 15.15V7.56667C8 6.14913 9.14913 5 10.5667 5C11.9842 5 13.1333 6.14913 13.1333 7.56667V15.112C13.1333 15.8208 12.5588 16.3954 11.85 16.3954C11.1413 16.3954 10.5667 15.8208 10.5667 15.112V8.25581"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </a>
            </div>
            <div className="item-actions-rich-model">
              <a className="link-actions-rich-model" href="#">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M8.76162 14.0884C9.11219 13.8079 9.6227 13.8626 9.90612 14.2094C9.9185 14.2238 10.0848 14.412 10.3882 14.6016C10.7363 14.8192 11.2699 15.0455 12 15.0455C12.7301 15.0455 13.2637 14.8192 13.6118 14.6016C13.7856 14.493 13.9587 14.3653 14.0939 14.2094C14.3773 13.8626 14.8878 13.8079 15.2384 14.0884C15.5912 14.3707 15.6484 14.8855 15.3662 15.2384C15.1185 15.5369 14.8067 15.7845 14.4791 15.9893C13.9181 16.3399 13.0881 16.6818 12 16.6818C10.9119 16.6818 10.0819 16.3399 9.52091 15.9893C9.19452 15.7853 8.87914 15.5376 8.63384 15.2384C8.35156 14.8855 8.40876 14.3707 8.76162 14.0884Z"
                    fill="currentColor"
                  />
                  <path
                    d="M10.4091 10.4091C10.4091 11.0367 9.90032 11.5454 9.27272 11.5454C8.64513 11.5454 8.13636 11.0367 8.13636 10.4091C8.13636 9.78149 8.64513 9.27272 9.27272 9.27272C9.90032 9.27272 10.4091 9.78149 10.4091 10.4091Z"
                    fill="currentColor"
                  />
                  <path
                    d="M14.7273 11.5454C15.3549 11.5454 15.8636 11.0367 15.8636 10.4091C15.8636 9.78149 15.3549 9.27272 14.7273 9.27272C14.0997 9.27272 13.5909 9.78149 13.5909 10.4091C13.5909 11.0367 14.0997 11.5454 14.7273 11.5454Z"
                    fill="currentColor"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3ZM4.63636 12C4.63636 7.93318 7.93318 4.63636 12 4.63636C16.0668 4.63636 19.3636 7.93318 19.3636 12C19.3636 16.0668 16.0668 19.3636 12 19.3636C7.93318 19.3636 4.63636 16.0668 4.63636 12Z"
                    fill="currentColor"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
        <button type="button" className="btn-send-rich">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.79925 11.3629C5.14303 9.68415 4.68306 8.38602 4.47909 7.55628C4.22753 6.53292 3.80476 5.39983 4.22702 4.36801C4.48361 3.741 5.02366 3.2355 5.7485 3.06244C6.69852 2.83561 7.59566 3.28465 8.411 3.72149C10.189 4.67412 19.4871 9.53745 21.7317 10.7183C23.0425 11.4079 24.9288 12.1464 24.9981 13.88C25.0652 15.5575 23.442 16.3118 22.1823 16.9745C17.5791 19.3959 13.0044 21.8723 8.38737 24.2673C7.54804 24.7026 6.62369 25.1767 5.65175 24.9309C4.85624 24.7298 4.32401 24.1392 4.11309 23.4818C3.92625 22.8994 4.00601 22.3001 4.14535 21.7191C4.55954 19.9921 5.13786 18.3039 5.77964 16.6497C6.20917 15.5425 6.97181 14.6276 7.93045 13.9949C6.98248 13.3651 6.22759 12.4586 5.79925 11.3629ZM12.2164 13.2525C13.2409 13.4589 13.7581 13.5702 13.8667 13.7485C13.9644 13.9091 13.9637 14.1115 13.8649 14.2714C13.7498 14.4576 13.1859 14.5672 12.058 14.7861C11.2804 14.9371 10.6421 15.0604 10.4457 15.0965C9.163 15.3326 8.10109 16.1955 7.64422 17.3731C7.09782 18.7814 6.61316 20.1891 6.22801 21.649C5.83905 23.1233 5.77403 23.3698 7.46647 22.4919C12.1402 20.0676 16.7736 17.5652 21.431 15.1096C22.4672 14.5631 22.9972 14.2701 22.9999 13.9723C23.0026 13.6773 22.4878 13.3776 21.4355 12.8222C16.7842 10.3671 12.1025 7.96829 7.46647 5.4844C5.80099 4.59208 5.87828 4.90166 6.3199 6.67061C6.65865 8.02749 7.15318 9.33311 7.66199 10.6347C8.12045 11.8075 9.1795 12.6665 10.458 12.9025C11.0457 13.0109 11.6305 13.1344 12.2164 13.2525Z"
              fill="currentColor"
            />
          </svg>
        </button>
      </div>
      {/* <!-- End Notes Avtar Model Rich --> */}
      {/* <!-- Avtar Model Toolbar --> */}
      <div className="avatar-model-toolbar justify-end">
        <MultiCombobox options={options} placeholder="Modified" filters={[]}/>
      </div>
      {/* <!-- End Avtar Model Toolbar --> */}
      {/* <!-- List Notes Model --> */}
      <div className="list-notes-model">
        {profile.notes.map((note, index) => {
          return (
            <div className="item-list-notes-model">
              <div className="note-model">
                <div className="header-note-model">
                  <div className="item-note-model">
                    <div className="flex-avatar-note-model">
                      <div className="avatar-note-model">
                        <img src={profile.learner.imageUrl} alt="" />
                      </div>
                      <div className="name-avatar-note-model">
                        {profile.learner.name}
                      </div>
                    </div>
                  </div>
                  <div className="item-note-model">
                    <div className="text-avatar-note-model">
                      {moment(note.createdAt).fromNow()} at{" "}
                      {moment(note.createdAt).format("h:mm")}
                    </div>
                  </div>
                </div>
                <div className="content-note-model">
                  <p>{note.content}</p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      {/* <!-- End List Notes Model --> */}
    </div>
  );
}

export default Notes;
