import React from "react";

import Avatar1 from "components/assets/images/avatar-1.png";
import Avatar2 from "components/assets/images/avatar-4.png";
import Avatar3 from "components/assets/images/avatar-8.png";
import Avatar4 from "components/assets/images/avatar-9.png";
import Avatar5 from "components/assets/images/avatar-5.png";

const userData = [
  {
    learner: {
      name: "Kevin Leonard",
      title: "All types of materials",
    },
    userId: "JD123456",
    completions: 5,
    completionRate: 90,
    imageUrl: Avatar1,
  },
  {
    learner: {
      name: "Ana Simmons",
      title: "All types of materials",
    },
    userId: "JD789152",
    completions: 15,
    completionRate: 30,
    imageUrl: Avatar2,
  },
  {
    learner: {
      name: "Max Smith",
      title: "Walkthrough",
    },
    userId: "JD648120",
    completions: 30,
    completionRate: 70,
    imageUrl: Avatar3,
  },
  {
    learner: {
      name: "Natali Goodwin",
      title: "All types of materials",
    },
    userId: "JD914257",
    completions: 60,
    completionRate: 90,
    imageUrl: Avatar4,
  },
  {
    learner: {
      name: "Mikael Collins",
      title: "Paths, Docs, Form",
    },
    userId: "JD642158",
    completions: 5,
    completionRate: 60,
    imageUrl: Avatar5,
  },
];

function MostEngagedUsers() {
  return (
    <div className="item-engaged-material">
      <div className="material-stat">
        <div className="header-engaged-user">
          <div className="item-header-engaged-user">
            <h3 className="title-size-18">Most Engaged Users</h3>
            <p className="content-size-14">All statistics</p>
          </div>
        </div>
        <div className="full-width scroll-block">
          <div className="table-dashboard custom-table-most-engaged">
            <div className="head-table-dashboard">
              <div className="tr-table-dashboard">
                <div className="td-table-dashboard">
                  <div className="text-table-dashboard">Learners</div>
                </div>
                <div className="td-table-dashboard">
                  <div className="text-table-dashboard">User ID</div>
                </div>
                <div className="td-table-dashboard">
                  <div className="text-table-dashboard">Completions</div>
                </div>
                <div className="td-table-dashboard">
                  <div className="text-table-dashboard">Completion Rate</div>
                </div>
              </div>
            </div>
            <div className="body-table-dashboard">
              {userData.map((data, index) => {
                return (
                  <div key={index} className="tr-table-dashboard">
                    <div className="td-table-dashboard">
                      <div className="visitor">
                        <div className="icon-visitor">
                          <img
                            className="avatar-image"
                            src={data.imageUrl}
                            alt={data.learner.name}
                          />
                        </div>
                        <div className="content-visitor">
                          <h3 className="title-visitor">{data.learner.name}</h3>
                          <div className="subtitle-visitor">
                            {data.learner.title}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="td-table-dashboard">
                      <div className="text-table-dashboard color-blue-dark">
                        {data.userId}
                      </div>
                    </div>
                    <div className="td-table-dashboard">
                      <div className="flex-badges">
                        <div className="text-table-dashboard">
                          {data.completions}
                        </div>
                      </div>
                    </div>
                    <div className="td-table-dashboard">
                      <div className="rating-data-table">
                        <div className="counter-progress-bar">
                          {data.completionRate}%
                        </div>
                        <div className="rating-progress-bar">
                          <div
                            className="current-progress-bar"
                            style={{ width: `${data.completionRate}%` }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MostEngagedUsers;
