import React, { useEffect } from 'react'
import { getActiveLayers, removeActiveTool, selectActiveLayer, setActiveLayer } from '../../../../../../../redux/toolsSlice';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getActiveStep, getLayerObjConfig, removeLayerConfig, setLayerConfig, updateLayer } from '../../../../../../../redux/stepsSlice';
import EditorPreviewEmbed from '../../../../../walkthroughs/EditorPreviewEmbed';
import { getMaterial } from '../../../../../../../redux/materialSlice';
 
export const walkthroughLayerDefaults = (id) => {
  return {
    id: id,
    type: 'walkthrough',
    material_id: ''
  };
}

function WalkthroughContent({ layer }) {
  const dispatch = useDispatch();
  const layerObjConfig = useSelector(
    (state) => getLayerObjConfig(state, layer),
    shallowEqual
  );
  const activeStep = useSelector((state) => getActiveStep(state));
  const stepId = activeStep.id;
  const activeLayer = useSelector((state) => selectActiveLayer(state));
  const material = useSelector((state) => getMaterial(state));
  const defaultConfig = [
    {
      layerId: layer.id,
      sectionId: 'walkthrough',
      sectionTitle: 'Walkthrough Element',
      values: [
        {
          id: 'source',
          title: 'Select a Walkthrough from the list below',
          type: 'walkthrough-select',
          value: layer.source || "",
          direction: 'column'
        }
      ]
    }
  ];
  
  const editElement  = () => {
    dispatch(setActiveLayer(layer));
    dispatch(setLayerConfig(defaultConfig));
  }

  const removeFocusElement = () => {
    dispatch(setActiveLayer({}));
    dispatch(removeLayerConfig());
  }

  useEffect(() => {
    if (layerObjConfig) {
      const newLayer = {
        ...layer,
        ...layerObjConfig
      };
      dispatch(updateLayer({ stepId: stepId, layer: newLayer }));
    }
  }, [layerObjConfig]);
  
  return (
    <div className='wk-wrapper'>
      {layer.source ?
        <div onClick={editElement}>
          <EditorPreviewEmbed id={layer.source} parent_visibility={material.visibility} />
          {/* {activeLayer && activeLayer.id == layer.id && 
            <button className='btn btn-primary save-btn' onClick={removeFocusElement}>Save</button>
          } */}
        </div>
      :
        <div className="cover-upload-form" tabIndex={0} onClick={editElement}>
          <div className="filed-upload-form icon-filed-upload-form">
            <header className="text-upload-form">
              Use the sidebar to add the walkthrough.<br/>It will appear here once added
            </header>
          </div>
        </div>
      }
    </div>
  )
}

export default WalkthroughContent