import React, { useEffect } from 'react';
import { Grid, List, MaterialIcon, VideoIcon } from '../../../assets/Icons';
import Walkthroughs from './Walkthroughs';
import Videos from './Videos';
import Paths from './Paths';
import LineTab from '../../../organisms/line_tab/LineTab';
import { API_ENDPOINTS, CONSTS } from '../../utils/endpoints';
import { Provider } from 'react-redux';
import { store } from '../../../../redux/store';
import { http_get } from '../../utils/api_client';

export const DISPLAY_STYLES = {
  GRID: 'grid',
  LIST: 'list'
};

const IndexPage = () => {
  const [displayStyle, setDisplayStyle] = React.useState(DISPLAY_STYLES.GRID);
  const [activeTab, setActiveTab] = React.useState(CONSTS.PATHS);
  const [countData, setCountData] = React.useState({
    walkthroughs_count: 0,
    videos_count: 0,
    paths_count: 0
  });
  const pages = [
    {
      name: (
        <div className="lm-tab">
          <MaterialIcon /> Path
          <div className="counter">{countData.paths_count}</div>
        </div>
      ),
      path: CONSTS.PATHS,
      component: <Paths displayStyle={displayStyle} />
    },
    {
      name: (
        <div className="lm-tab">
          <MaterialIcon /> Walkthroughs
          <div className="counter">{countData.walkthroughs_count}</div>
        </div>
      ),
      path: CONSTS.WALKTHROUGHS,
      component: <Walkthroughs displayStyle={displayStyle} />
    }
  ];
  const [activeComp, setActiveComp] = React.useState(pages[0].component);

  const switchActiveComp = (tab) => {
    const page = pages.find((page) => page.name === tab);
    setActiveComp(page.component);
    setActiveTab(page.path);
    window.scrollTo(0, 0);
    window.history.pushState({}, '', `?tab=${page.path}`);
  };

  useEffect(() => {
    http_get(API_ENDPOINTS.GET_LEARNING_MATERIALS_COUNT)
      .then((response) => {
        setCountData(response.data);
      })
  }, []);

  const getDefaultTab = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get('tab');
    const tabName = tab ? tab : CONSTS.PATHS;
    const pageIndex = pages.findIndex((page) => page.path === tabName);
    return pageIndex !== -1 ? pageIndex : 0;
  }

  return (
    <Provider store={store}>
      <div className="un-page-container__learning-materials">
        <div className="header">
          <div className="title">
            <h1>Walkthroughs*</h1>
          </div>
          <div className="actions">
            <Grid
              className={displayStyle === DISPLAY_STYLES.GRID ? 'active' : ''}
              onClick={() => setDisplayStyle(DISPLAY_STYLES.GRID)}
            />
            <List
              className={displayStyle === DISPLAY_STYLES.LIST ? 'active' : ''}
              onClick={() => setDisplayStyle(DISPLAY_STYLES.LIST)}
            />
          </div>
        </div>
        <LineTab
          tabs={pages.map((page) => page.name)}
          defaultTab={getDefaultTab()}
          onChange={switchActiveComp}
        />
        {activeComp}
      </div>
    </Provider>
  );
};

export default IndexPage;
