import React from "react";
import LabelAndAssignee from "./form-fields/LabelAndAssignee";
import Avatar from "../../../../assets/images/avatar-1.png";
import DateDropdown from "./form-fields/DateDropdown";

const options = [
  {
    name: "Eleanor Pena",
    email: "johndoe@example.com",
    imageUrl: Avatar,
  },
  {
    name: "Eleanor Pena",
    email: "johndoe@example.com",
    imageUrl: Avatar,
  },
  {
    name: "Eleanor Pena",
    email: "johndoe@example.com",
    imageUrl: Avatar,
  },
  {
    name: "Eleanor Pena",
    email: "johndoe@example.com",
    imageUrl: Avatar,
  },
];

function SettingsForm() {
  return (
    <div className="design-form-path">
      <LabelAndAssignee title="Assignees" options={options} />
      <DateDropdown />
    </div>
  );
}

export default SettingsForm;
