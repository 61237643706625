import React from "react";
import getLMColumns from "./columns";
import DataTable from './datatable/DataTable';

function LMTable({LMType, data}) {
  return (
    <section className="data-table-wrapper relative-table-fixed">
      <DataTable
        columns={getLMColumns(LMType)}
        data={data}
        isPaginationEnabled={true}
        customTable={true}
      />
    </section>
  );
}

LMTable.defaultProps = {
  LMType: '',
  data: [],
};


export default LMTable;