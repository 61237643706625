import React, { useState } from 'react';
import axios from 'axios';
import { CrossIcon } from '../../assets/Icons';
import { API_ENDPOINTS } from '../../pages/utils/endpoints';
import { useSelector } from 'react-redux';
import { getOnboardingData } from '../../../redux/onboardingSlice';
import { http_multipart_post } from '../../pages/utils/api_client';

const FileUploader = ({
  name,
  value,
  label,
  onChange,
  className,
  image_type,
  acceptTypes
}) => {
  const loader = require('../../assets/loader.svg');
  const data = useSelector((state) => getOnboardingData(state));
  const [preview, setPreview] = useState(value);
  const [isLoading, setIsLoading] = useState(false);

  const handleFileSelect = () => {
    document.getElementById(`${name}-file-uploader`).click();
  };

  const asyncUploadFile = async (e) => {
    setIsLoading(true);
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      const formData = new FormData();
      formData.append('image', file);
      formData.append('image_type', image_type);
      if (data.email && data.password) {
        formData.append('email', data.email);
        formData.append('password', data.password);
      }

      try {
        await http_multipart_post(API_ENDPOINTS.POST_UPLOAD_FILE, formData)
          .then((response) => {
            const imageUrl = response.data.image_url;
            setPreview(imageUrl);
            onChange({
              target: { name, value: imageUrl }
            });
          })
          .finally(() => {
            setIsLoading(false);
          });
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }
  };

  const removeFile = () => {
    setPreview(null);
    onChange({ target: { name, value: '' } });
  };

  return (
    <div className={`un-file-uploader ${className}`}>
      <div className="top-section">
        <div className="header">
          <label htmlFor={name}>{label}</label>
          <p className="small">Allowed file types: png, jpg, jpeg</p>
        </div>
        <button onClick={handleFileSelect}>Upload</button>
      </div>
      <div className="preview-container">
        {isLoading && (
          <div className="preview">
            <img src={loader} alt="Preview" />
          </div>
        )}
        {preview && (
          <>
            <div className="preview">
              <img src={preview} alt="Preview" />
              <div className="close" onClick={removeFile}>
                <CrossIcon />
              </div>
            </div>
          </>
        )}
      </div>
      <input
        type="file"
        id={`${name}-file-uploader`}
        name={name}
        onChange={asyncUploadFile}
        style={{ display: 'none' }}
        accept={acceptTypes}
      />
    </div>
  );
};

FileUploader.defaultProps = {
  onChange: () => {},
  className: '',
  label: '',
  name: '',
  value: null,
  image_type: '',
  acceptTypes: '.png, .jpg, .jpeg'
};

export default FileUploader;
