import React, { useState, useRef, useEffect } from 'react';
import { UploadIcon } from '../../../../../assets/Icons';

const DomainLogoUploader = ({ label, value, name, acceptedExtensions, logoDetails, setLogoDetails }) => {
  const acceptedExtensionString = acceptedExtensions.join(', ').toUpperCase();
  const [filePreview, setFilePreview] = useState(null);
  const [isDraggingOver, setIsDraggingOver] = useState(false); // Track whether a file is being dragged over
  // const material = useSelector((state) => getMaterial(state));

  const fileInputRef = useRef(null);

  const handleFileSelect = async (e) => {
    const input = e.target;
    const file = input.files[0];

    if (file) {
      const reader = new FileReader();
      // if (response) {
      //   alert('Logo added successfully.')
      // }
      // else {
      //   alert('Something went wrong.');
      // }
      reader.onload = (e) => {
        setFilePreview(e.target.result);
      };

      reader.readAsDataURL(file);
      setLogoDetails(file)
    }
  };

  const handleOuterDivClick = () => {
    fileInputRef.current.click();
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDraggingOver(true); // Add class when file is dragged over
  };

  const handleDragLeave = () => {
    setIsDraggingOver(false); // Remove class when user moves the file out
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    setIsDraggingOver(false); // Remove class when file is dropped
    const droppedFiles = e.dataTransfer.files;

    if (droppedFiles.length > 0) {
      const file = droppedFiles[0];
      // const response = await uploadImage(droppedFiles[0]);
      // if (response) {
      //   alert('Logo added successfully.')
      // }
      // else {
      //   alert('Something went wrong.');
      // }
      const reader = new FileReader();

      reader.onload = (e) => {
        setFilePreview(e.target.result);
      };

      reader.readAsDataURL(file);

      setLogoDetails(file)
    }
  };

  const uploadImage = async (file) => {
    const formData = new FormData();
    formData.append('image', file);
    formData.append('image_type', 'favicon')
  };

  const handleRemoveImage = () => {
    setFilePreview(null); // Clear the file preview when the remove button is clickeds
    setLogoDetails(null)
  };

  const divClassName = `un-drag-drop-uploader ${isDraggingOver ? 'drag-over' : ''
    }`;

  const filePreviewText = filePreview && !value ? (
    <div>
      <button onClick={handleRemoveImage} className="remove-button">
        x
      </button>
      <div style={{ height: "200px", width: "300px" }}>
        <img src={filePreview} alt="File Preview" height={"100%"} width={"100%"} className="domain-preview-image" />
      </div>
    </div>
  ) : (
    (!value || value === 'usernurture-logo-dark.png') && (
      <div onClick={handleOuterDivClick}>
        <div className="filed-upload-form icon-filed-upload-form">
          <span className="icon-upload-form">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.2071 16.7071C16.8166 17.0976 16.1834 17.0976 15.7929 16.7071L12.5 13.4142L9.20711 16.7071C8.81658 17.0976 8.18342 17.0976 7.79289 16.7071C7.40237 16.3166 7.40237 15.6834 7.79289 15.2929L11.7929 11.2929C12.1834 10.9024 12.8166 10.9024 13.2071 11.2929L17.2071 15.2929C17.5976 15.6834 17.5976 16.3166 17.2071 16.7071Z"
                fill="currentColor"
              ></path>
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.5 21C11.9477 21 11.5 20.5523 11.5 20L11.5 12C11.5 11.4477 11.9477 11 12.5 11C13.0523 11 13.5 11.4477 13.5 12L13.5 20C13.5 20.5523 13.0523 21 12.5 21Z"
                fill="currentColor"
              ></path>
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.47059 3.00546C10.7238 2.95948 11.9703 3.20425 13.116 3.72056C14.2616 4.23684 15.2756 5.01074 16.0827 5.98252C16.7219 6.75205 17.2183 7.62992 17.5508 8.57311L17.9529 8.57311C19.1357 8.57234 20.2868 8.95622 21.2376 9.66594C22.1883 10.3756 22.8893 11.3732 23.2429 12.5118C23.5963 13.6502 23.5851 14.8734 23.2108 16.0049C22.8364 17.1367 22.117 18.121 21.1533 18.8125C20.7045 19.1345 20.0798 19.0317 19.7578 18.583C19.4358 18.1342 19.5386 17.5095 19.9873 17.1875C20.6034 16.7455 21.0687 16.1121 21.312 15.3768C21.5553 14.6412 21.5627 13.8451 21.3328 13.1049C21.103 12.3649 20.6493 11.7225 20.0412 11.2687C19.4335 10.815 18.7026 10.5725 17.9544 10.5731L16.8089 10.5731C16.3505 10.5731 15.9508 10.2615 15.8391 9.81699C15.602 8.87432 15.1588 8.00033 14.5442 7.26039C13.9297 6.52052 13.1601 5.93415 12.2942 5.54395C11.4285 5.15378 10.4882 4.96947 9.54392 5.00412C8.59965 5.03877 7.67448 5.29153 6.83793 5.74441C6.00129 6.19734 5.27443 6.83902 4.71319 7.62256C4.15191 8.40617 3.77123 9.31087 3.60109 10.2688C3.43095 11.2267 3.47605 12.2115 3.7328 13.1488C3.98955 14.0859 4.4509 14.9499 5.08072 15.6761C5.44258 16.0933 5.39769 16.7249 4.98046 17.0868C4.56323 17.4486 3.93166 17.4037 3.5698 16.9865C2.74241 16.0325 2.13909 14.9008 1.80388 13.6772C1.46867 12.4536 1.40993 11.1688 1.63191 9.919C1.85389 8.6692 2.35106 7.48574 3.08726 6.45793C3.82352 5.43005 4.77998 4.58425 5.88577 3.98561C6.99165 3.38692 8.21739 3.05145 9.47059 3.00546Z"
                fill="currentColor"
              ></path>
            </svg>
          </span>
          <header className="text-upload-form">
            <span className="heighlight-text-upload-form">
              Click to upload
            </span>{" "}
            or drag and drop{" "}
            <span>SVG, PNG, JPG or GIF (max 400*800px) </span>
          </header>
        </div>
      </div>
    )
  );

  return (
    <div
      className={divClassName}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <p>{label}</p>
      <div className="drag-drop-box">
        {(!value || value !== 'usernurture-logo-dark.png') ? null : (
          <div className="drag-icon" onClick={handleOuterDivClick}>
            <UploadIcon />
          </div>
        )}
        {(!filePreview && value && value !== 'usernurture-logo-dark.png') && (
          <>
            <button onClick={handleRemoveImage} className="remove-button" >
              x
            </button>
            <div style={{ height: "200px", width: "300px" }} onClick={handleOuterDivClick}>
              <img src={value} alt="File Preview" height={"100%"} width={"100%"} className="domain-preview-image" />
            </div>
          </>
        )}
        {(filePreview && value && value !== 'usernurture-logo-dark.png') && (
          <>
            <button onClick={handleRemoveImage} className="remove-button">
              x
            </button>
            <div style={{ height: "200px", width: "300px" }} onClick={handleOuterDivClick}>
              <img src={filePreview} alt="File Preview" height={"100%"} width={"100%"} className="domain-preview-image" />
            </div>
          </>
        )}
        {filePreviewText}
      </div>
      <input
        type="file"
        name={name}
        ref={fileInputRef}
        accept="image/x-png,image/gif,image/jpeg"
        onChange={handleFileSelect}
        style={{ display: 'none' }}
      />
    </div>
  );
};

DomainLogoUploader.defaultProps = {
  name: '',
  label: 'Logo',
  value: '',
  acceptedExtensions: ['jpg', 'jpeg', 'png', 'gif']
};

export default DomainLogoUploader;
