import React, { useState } from "react";
import CardIcon from "./CardIcon";
import {
  Attachment,
  Body,
  Columns,
  DataEntry,
  DataSelection,
  EmbedCode,
  Image,
  InputField,
  LargeTitle,
  LongTextField,
  MultipleSelection,
  Quiz,
  Quotes,
  Quotes2,
  SatisfactionScore,
  SmallTitle,
  Video,
  Survey,
  UploadForm,
  ShortAnswerIcon,
  LongAnswerIcon,
  MultipleChoiceIcon,
  MultiSelectIcon,
  CheckBoxIcon,
  Walkthrough,
} from "../../../assets/icons";
import { useDispatch, useSelector } from "react-redux";
import { addLayerToActive, getActiveStep, fetchActiveStep, addLayerAfter } from "../../../../../../redux/stepsSlice";
import { generateRandomId } from "../../../../../pages/utils/tools";
import { ELEMENTS } from "../content/Consts";
import { WalkthroughIcon } from "../../../../../organisms/sidenav/SidenavIcons";

const Elements = ({ closeModal, afterLayerId, setSelectedElement }) => {

  const dispatch = useDispatch();

  const activeStep = useSelector((state) => getActiveStep(state));
  const stepId = activeStep.id;

  const [selectedCard, setSelectedCard] = useState(null);

  const handleCardClick = (id) => {
    setSelectedElement(id);
    setSelectedCard(id);
  };

  const renderCard = (field, index) => {
    return (
      <CardIcon
        key={index}
        title={field.title}
        icon={field.icon}
        callback={() => elementCallback(field.type)}
        onClick={() => handleCardClick(field.type)}
        isSelected={selectedCard === field.type}
      />
    )
  }

  const elementCallback = (type) => {
    dispatch(fetchActiveStep()).then(() => {
      if (afterLayerId) {
        const newLayer = { id: generateRandomId(), type: type, content: "Hello world over here" };
        dispatch(addLayerAfter({ stepId, afterLayerId, newLayer }));
        closeModal && closeModal();
      }
      else {
        dispatch(addLayerToActive({ layer: { id: generateRandomId(), type: type, content: "Hello world over here" } }));
        closeModal && closeModal();
      }
    });

  }

  const elementFields = [
    {
      title: "Large Title",
      icon: <LargeTitle />,
      type: ELEMENTS.LARGE_TEXT
    },
    {
      title: "Body",
      icon: <Body />,
      type: ELEMENTS.BODY
    },
    {
      title: "Image",
      icon: <Image />,
      type: ELEMENTS.IMAGE
    },
    {
      title: "Video",
      icon: <Video />,
      type: ELEMENTS.VIDEO
    },
    {
      title: "Walkthroughs",
      icon: <WalkthroughIcon />,
      type: ELEMENTS.WALKTHROUGH
    },
    {
      title: "Embed Code",
      icon: <EmbedCode />,
      type: ELEMENTS.EMBEDDED_CODE,
    },
    {
      title: "Attachment",
      icon: <Attachment />,
      type: ELEMENTS.ATTACHMENT
    },
    {
      title: "Short Answer",
      icon: <ShortAnswerIcon />,
      type: ELEMENTS.SHORT_ANSWER
    },
    {
      title: "Long Answer",
      icon: <LongAnswerIcon />,
      type: ELEMENTS.LONG_ANSWER
    },
    {
      title: "Mutiple Choice",
      icon: <MultipleChoiceIcon />,
      type: ELEMENTS.MULTIPLE_CHOICE
    },
    {
      title: "Multi Select",
      icon: <MultiSelectIcon />,
      type: ELEMENTS.MULTI_SELECT
    },
    {
      title: "Checkbox",
      icon: <CheckBoxIcon />,
      type: ELEMENTS.CHECKBOX
    },
    {
      title: "File Upload",
      icon: <i class="fa fa-upload fa-2x" aria-hidden="true"></i>,
      type: ELEMENTS.FILE_UPLOAD
    },
    {
      title: "Date",
      icon: <i class="fa fa-calendar fa-2x" aria-hidden="true"></i>,
      type: ELEMENTS.DATE
    },
    {
      title: "Time",
      icon: <i class="fa fa-clock-o fa-2x" aria-hidden="true"></i>,
      type: ELEMENTS.TIME
    },
  ]

  const textFields = [
    {
      title: "Large Title",
      icon: <LargeTitle />,
      type: ELEMENTS.LARGE_TEXT
    },
    {
      title: "Small Title",
      icon: <SmallTitle />,
      type: ELEMENTS.SMALL_TEXT
    },
    {
      title: "Body",
      icon: <Body />,
      type: ELEMENTS.BODY
    },
    {
      title: "Long Text Field",
      icon: <LongTextField />,
      type: ELEMENTS.LONG_TEXT_FIELD
    },
    {
      title: "Input Field",
      icon: <InputField />,
      type: ELEMENTS.INPUT_FIELD
    },
    {
      title: "Attachment",
      icon: <Attachment />,
      type: ELEMENTS.ATTACHMENT
    },
   // {
   //   title: "2 Columns",
   //   icon: <Columns />,
   //   type: ELEMENTS.TWO_COLUMNS
   // },
  ];

  const contentFields = [
    {
      title: "Image",
      icon: <Image />,
      type: ELEMENTS.IMAGE
    },
    {
      title: "Video",
      icon: <Video />,
      type: ELEMENTS.VIDEO
    },
    //{
    //  title: "Body",
    //  icon: <Body />,
    //  type: ELEMENTS.BODY,
    //},
    //{
    //  title: "Quotes",
    //  icon: <Quotes2 />,
    //  type: ELEMENTS.QUOTES,
    //},
    {
      title: "Embed Code",
      icon: <EmbedCode />,
      type: ELEMENTS.EMBEDDED_CODE,
    },
  ];

  const formFields = [
    {
      title: "Data Entry",
      icon: <DataEntry />,
      type: ELEMENTS.DATA_ENTRY
    },
    {
      title: "Data Selection",
      icon: <DataSelection />,
      type: ELEMENTS.DATA_SELECTION
    },
    {
      title: "Multiple Selection",
      icon: <MultipleSelection />,
      type: ELEMENTS.MULTIPLE_SELECTION
    },
    {
      title: "Survey",
      icon: <Survey />,
      type: ELEMENTS.SURVEY
    },
    //{
    //  title: "Quiz",
    //  icon: <Quiz />,
    //  type: ELEMENTS.QUIZ
    //},
    {
      title: "Upload form",
      icon: <UploadForm />,
      type: ELEMENTS.UPLOAD_FORM
    },
    {
      title: "Satisfaction Score",
      icon: <SatisfactionScore />,
      type: ELEMENTS.SATISFACTION_SCORE
    },
  ];

  const renderAccordion = () => {
    return (
      <>
        <div className="element-group">
          {elementFields.map((field, index) => {
            return (
              renderCard(field, index)
            );
          })}
        </div>
      </>
    )
  }

  return (
    <div id="component-1" className="tab-content pane-switch-component-parts">
      <div className="accordion-wrapper switch-accordion-wrapper">
        {stepId && (
          renderAccordion()
        )}
      </div>
    </div>
  );
}

export default Elements;
