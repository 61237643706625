import React from 'react';
import ColorOption from './options/ColorOption';
// import ImageOption from './options/ImageOption';
import TextOption from './options/TextOption';
import FontOption from './options/FontOption';
import FontSizeOption from './options/FontSizeOption';
import NumberOption from './options/NumberOption';
import LineStyleOption from './options/LineStyleOption';
import FontStyleOption from './options/FontStyleOption';
import JustifyHorizontal from './options/JustifyHorizontal';
import JustifyVertical from './options/JustifyVertical';
import TextAreaOption from './options/TextAreaOption';
import LabelAndMultipleChoice from '../../../components/pages/paths/components/authoring/side-toolbar/design/form-fields/LabelAndMultipleChoice'
import SelectOption from './options/WalkthroughSelectOption';
import WalkthroughSelectOption from './options/WalkthroughSelectOption';
import UploadOption from './options/UploadOption';

const DesignOption = ({ text, optionType, value, onChange, options, direction }) => {
  let component = '';
  switch (optionType) {
    case 'color':
      component = <ColorOption defaultValue={value} onChange={onChange} />;
      break;
    case 'image':
      component = <ImageOption />;
      break;
    case 'text':
      component = <TextOption defaultValue={value} onChange={onChange} />;
      break;
    case 'textarea':
      component = <TextAreaOption defaultValue={value} onChange={onChange} />;
      break;
    case 'font':
      component = <FontOption defaultValue={value} onChange={onChange} />;
      break;
    case 'font-size':
    case 'px-size':
      component = <FontSizeOption defaultValue={value} onChange={onChange} />;
      break;
    case 'walkthrough-select':
      component = <WalkthroughSelectOption defaultValue={value} onChange={onChange} options={options} />;
      break;
    case 'number':
      component = <NumberOption defaultValue={value} onChange={onChange} />;
      break;
    case 'line-style':
      component = <LineStyleOption defaultValue={value} onChange={onChange} />
    case 'fontStyle':
      component = <FontStyleOption defaultValue={value} onChange={onChange} options={options} />
      break;
    case 'justifyHorizontal':
      component = <JustifyHorizontal defaultValue={value} onChange={onChange} options={options} />
      break;
    case 'justifyVertical':
      component = <JustifyVertical defaultValue={value} onChange={onChange} options={options} />
      break;
    case 'multiSelect':
      component = <LabelAndMultipleChoice defaultValue={value} onChange={onChange} options={options} />
      break;
    case 'upload':
      component = <UploadOption defaultValue={value} onChange={onChange} />
      break;
    default:
      component = <div>Default</div>;
  }
  return (
    <div className="un-design-option" style={{ alignItems: 'flex-start', flexDirection: direction ? direction : 'row' }}>
      <div className="property-title">{text}</div>
      <div style={{ width: direction === 'column' ? '100%' : 'auto' }}>{component}</div>
    </div>
  );
};

DesignOption.defaultProps = {
  text: '',
  optionType: '',
  value: '',
  onChange: () => { },
  options: []
};

export default DesignOption;
