import React from "react";
import ButtonGroup from "./fields/ButtonGroup";
import Header from "./fields/Header";
import QnA from "./fields/QnA";
import SelectedWrapper from "./SelectedWrapper";
import ProgressBar from "./fields/ProgressBar";

function QuizContent({ isSelected = false }) {
  if (isSelected) {
    return (
      <SelectedWrapper>
        <Header />
        <div className="template-preview">
          <ProgressBar title="Question" index={1} />
          <QnA />
          <ButtonGroup />
        </div>
      </SelectedWrapper>
    );
  }
  return (
    <div className="cover-template-preview">
      <Header />
      <div className="template-preview">
        <ProgressBar title="Question" index={1} />
        <QnA />
        <ButtonGroup />
      </div>
    </div>
  );
}

export default QuizContent;
