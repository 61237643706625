import React from "react";

function SidebarCard({ index = 1, title = "", description = "", isActive = false, onClick }) {
  return (
    <div onClick={onClick} className="item-list-step">
      <div className={`card-list-step ${isActive ? "active" : ""}`}>
        <h4 className="size-14 text-weight-medium one-line-text">
          {index}. {title}
        </h4>
        <p>{description}</p>
      </div>
    </div>
  );
}

export default SidebarCard;
