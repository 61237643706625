import React, { useEffect, useState } from 'react';

const TextArea = ({
  name,
  value,
  type,
  placeholder,
  label,
  onChange,
  onValidation,
  className,
  required,
  lineInput,
  error,
  validationCallback
}) => {
  const style = {};
  const [errorMsg, setErrorMsg] = useState(error);

  useEffect(() => {
    setErrorMsg(error);
  }, [error]);

  if (lineInput) {
    className += ' line-input';
  }

  if (errorMsg) {
    className += ' error';
  }

  const handleChange = (e) => {
    onChange(e);
    setErrorMsg('');
  };

  const handleMouseout = (e) => {
    if (required && !e.target.value) {
      setErrorMsg('This field is required');
      onValidation(e.target.name, false);
      return;
    }

    // validate with html5 validation
    if (e.target.validity && !e.target.validity.valid) {
      setErrorMsg(e.target.validationMessage);
      onValidation(e.target.name, false);
      return;
    }

    if (validationCallback) {
      const result = validationCallback(e);
      setErrorMsg(result);
      onValidation(e.target.name, false);
      return;
    }

    onValidation(e.target.name, true);
  };

  return (
    <div className="un-text-area">
      <label htmlFor={name}>
        {label}
        {required && <span className="required">*</span>}
      </label>
      <textarea
        id={name}
        type={type}
        style={style}
        className={className}
        name={name}
        placeholder={placeholder}
        onChange={handleChange}
        onBlur={handleMouseout}
        value={value}
        data-validity={errorMsg ? 'invalid' : 'valid'}
      ></textarea>
      <p className="error-message">{errorMsg}</p>
    </div>
  );
};

TextArea.defaultProps = {
  onChange: () => {},
  onValidation: () => {},
  className: '',
  placeholder: '',
  label: '',
  name: '',
  type: 'text',
  value: '',
  required: false,
  lineInput: false,
  error: '',
  validationCallback: () => {}
};

export default TextArea;
