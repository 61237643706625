import React from 'react';
import { API_ENDPOINTS } from '../../../../../../utils/endpoints';
import { http_delete } from '../../../../utils/api_client';

const TeamMemberModal = ({ id }) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    http_delete(API_ENDPOINTS.DELETE_TEAM_MEMBER(id))
      .then((res) => {
        hideModal();
      })
      .finally(() => {});
  };

  const hideModal = () => {
    $('#teamMemberModal').modal('hide');
  };

  return (
    <div
      className="modal fade"
      id="teamMemberModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myModalLabel2"
    >
      <div
        className="modal-dialog un-settings-modal"
        role="document"
        id="modal_dialog"
      >
        <div className="modal-content team-member-modal">
          <h2>Are you sure to delete?</h2>
          <div className="modal-btn-list">
            <button className="modal-btn" onClick={hideModal}>
              No
            </button>
            <button className="modal-btn active" onClick={handleSubmit}>
              Yes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamMemberModal;
