import React, { useEffect, useRef, useState } from "react";
import { TrashIcon } from "../../../../../assets/icons";

function LabelAndAssignee({ title = "", options }) {
  const [isActive, setIsActive] = useState(false);

  const containerRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setIsActive(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [containerRef]);

  useEffect(() => {
    if (isActive) {
      document.getElementById("accordion-body-settings").style.overflowY =
        "visible";
    } else {
      document.getElementById("accordion-body-settings").style.overflowY =
        "hidden";
    }
  }, [isActive]);

  return (
    <div className="item-design-form-path">
      <div className="flex-design-form">
        <div className="label-design-form">
          <div className="size-12">{title}</div>
        </div>
        <div ref={containerRef} className="value-design-form">
          <div className="flex-input-design-form">
            <div className="item-input-design-form">
              <div
                onClick={() => setIsActive((prev) => !prev)}
                className={`assignees-input-cover ${isActive ? "active" : ""}`}
              >
                <div className="list-active-assignees scroll-block">
                  {/* add selected values here */}
                </div>
                <input
                  type="text"
                  className="assignees-input"
                  placeholder="Enter name or email"
                />
                <div className={`popup-assignees ${isActive ? "active" : ""}`}>
                  <div className="data-popup-assignees">
                    <div className="scroll-block max-height-184">
                      <div className="list-popup-assignees">
                        {options.map((option, index) => {
                          return (
                            <div key={index} className="item-popup-assignees">
                              <div className="button-popup-assignees">
                                <div className="cover-input-checkbox-assignees">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    height="20px"
                                    width="20px"
                                    version="1.1"
                                    id="Capa_1"
                                    viewBox="0 0 452.986 452.986"
                                    xmlSpace="preserve"
                                  >
                                    <path
                                      style={{ fill: "currentColor" }}
                                      d="M0,0v452.986h452.986V0H0z M156.669,361.354L56.019,209.495l27.222-13.59l71.356,92.905     l224.315-197.2l18.874,22.649L156.669,361.354z"
                                    />
                                  </svg>
                                </div>
                                <div className="data-button-popup-assignees">
                                  <div className="avatar-button-popup-assignees">
                                    <img src={option.imageUrl} alt="" />
                                  </div>
                                  <div className="content-button-popup-assignees">
                                    <div className="name-button-popup-assignees">
                                      {option.name}
                                    </div>
                                    <div className="email-button-popup-assignees">
                                      {option.email}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div className="footer-data-popup-assignees">
                      <div className="button-group-popup-assignees">
                        <button className="border-btn-assignees" type="button">
                          Cancel
                        </button>
                        <button className="btn-assignees" type="button">
                          Apply
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="button-delete">
              <TrashIcon />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LabelAndAssignee;
