import { createSlice } from '@reduxjs/toolkit';

export const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState: {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    industryName: '',
    industryRole: ''
  },
  reducers: {
    setName: (state, action) => {
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
    },
    setIndustry: (state, action) => {
      state.industryName = action.payload.industryName;
      state.industryRole = action.payload.industryRole;
    },
    setOnboardingData: (state, action) => {
      return {
        ...state,
        ...action.payload
      };
    }
  }
});

export const { setName, setIndustry, setOnboardingData } =
  onboardingSlice.actions;

export const getOnboardingData = (state) => state.onboarding;

export default onboardingSlice.reducer;
