import React from 'react';
import ProfileAvatar from "./components/profile/ProfileAvatar";
import EditProfileForm from "./components/profile/EditProfileForm";
import { Provider } from 'react-redux';
import { store } from '../../../../redux/store';

const ProfilePage = () => {
  return (
    <Provider store={store}>
      <div className="flex-profile-data">
          <div className="page-padding">
            <ProfileAvatar/>
            <EditProfileForm/>
          </div>
      </div>
    </Provider>
  );
};

export default ProfilePage;
