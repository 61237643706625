import React, { useState } from 'react';
import { CogIcon } from '../../assets/Icons';
import SettingsModal from '../settings-modal/SettingsModal';

const Settings = () => {
  const [open, setOpen] = useState(false);

  const showSettings = () => {
    $('#settingsModal').modal('show');
  };

  const onClose = () => {
    setOpen(false);
  }

  const openModal = () => {
    setOpen(true);
    $('#settingsModal').modal('show');
  }

  return (
    <>
      <SettingsModal open={open} onClose={onClose} />
      <div className="un-action" onClick={openModal}>
        <CogIcon />
      </div>
    </>
  );
};

export default Settings;
