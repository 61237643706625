import React, { useEffect, useState, useRef } from "react";
import { getActiveStep, selectActiveLayer, setActiveLayer } from "../../../../../../../redux/toolsSlice";
import { getLayerObjConfig, removeLayerConfig, setLayerConfig, updateLayer } from '../../../../../../../redux/stepsSlice';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import FontOption from "../../../../../../organisms/editor-design/options/FontOption";

export const CheckboxLayerDefaults = (id) => {
  return {
    id: id,
    type: 'checkbox',
    label: 'Enter your label',
    fontSize: 14,
    color: '#000000',
    fontFamily: 'Open Sans',
  };
}

function CheckboxContent({ layer }) {

  const textRef = useRef(null);

  const dispatch = useDispatch();
  const layerObjConfig = useSelector((state) => getLayerObjConfig(state, layer), shallowEqual);

  const activeStep = useSelector((state) => getActiveStep(state));
  const stepId = activeStep.id;
  const activeLayer = useSelector((state) => selectActiveLayer(state));
  const isEditing = activeLayer && activeLayer.id == layer.id;

  useEffect(() => {
    if (layerObjConfig) {
      const newLayer = { ...layer, ...layerObjConfig };
      dispatch(updateLayer({ stepId, layer: newLayer }));
    }
  }, [layerObjConfig]);

  const defaultConfig = [
    {
      layerId: layer.id,
      sectionId: 'checkbox',
      sectionTitle: 'Checkbox Element',
      values: [
        {
          id: 'fill',
          title: 'Text Color',
          type: 'color',
          value: layer.fill || '#000000'
        },
        {
          id: 'font',
          title: 'Font',
          type: 'font',
          value: layer.font || 'Open Sans'
        },
        {
          id: 'fontSize',
          title: 'Font Size',
          type: 'px-size',
          value: layer.fontSize || 14
        },
      ]
    }
  ];

  const EditElement = () => {
    dispatch(setActiveLayer(layer));
    dispatch(setLayerConfig(defaultConfig));
  }

  const removeFocusElement = () => {
    dispatch(setActiveLayer({}));
    dispatch(removeLayerConfig());
  }

  const updateText = () => {
    const newLayer = {
      ...layer,
      label: textRef.current ? textRef.current.value : layer.label
    };
    dispatch(updateLayer({ stepId: stepId, layer: newLayer }));
  };

  const handleEditBlur = (e) => {
    dispatch(setActiveLayer({}));
    dispatch(removeLayerConfig());
    updateText();
  };

  const onPressEnter = (e) => {
    if (e.key === 'Enter' || e.key === "Escape") {
      dispatch(setActiveLayer({}));
      dispatch(removeLayerConfig());
      updateText();
    }
  };

  return (
    <>
      <div className="container-large-text">
        {isEditing ? (
          <>
            <input
              className="dropdown-data-checkbox mr-2"
              type="checkbox"
            />
            <input
              className="checkbox-editor"
              type="text"
              ref={textRef}
              defaultValue={layer.label}
              onChange={(e) => textRef.current.value = e.target.value}
              onBlur={handleEditBlur}
              onKeyDown={onPressEnter}
              placeholder="Enter your label"
              style={{
                fontSize: layer.fontSize,
                color: layer.fill,
                fontFamily: layer.font,
              }}
            />
          </>
        ) : (
          <>
            <CheckboxContentPreview layer={layer} EditElement={EditElement} />
          </>
        )}
      </div>
    </>
  )
}

export const CheckboxContentPreview = ({ layer, EditElement }) => {
  return (
    <>
      <input
        className="dropdown-data-checkbox mr-2"
        type="checkbox"
      />
      <span
        tabIndex={0}
        onClick={EditElement}
        style={{
          fontSize: layer.fontSize,
          color: layer.fill,
          fontFamily: layer.font,
        }}>
        {layer.label}
      </span>
      <div hidden>
        <FontOption defaultValue={layer.font} />
      </div>
    </>
  )
}

export default CheckboxContent;