import React, { useEffect } from "react";
import TextBreadcrumb from "./TextBreadcrumb";
import UndoRedo from "./UndoRedo";
import ZoomButton from "./ZoomButton";
import Alignment from "./Alignment";
import ScreenToggle from "./ScreenToggle";
import UserAction from "./UserAction";
import Actions from "./Actions";
import SaveAndPublish from "./SaveAndPublish";
import { getCompany } from "../../../../../redux/companySlice";
import { getMaterial } from "../../../../../redux/materialSlice";
import { useSelector } from "react-redux";
import { allSteps, getMainConfig } from "../../../../../redux/stepsSlice";
import { API_ENDPOINTS } from "../../../utils/endpoints";
import { http_put } from "../../../utils/api_client";

function Toolbar({ companySlug, lmSlug }) {
  const company = useSelector(getCompany);
  const steps = useSelector((state) => allSteps(state));
  const config = useSelector((state) => getMainConfig(state));
  const material = useSelector((state) => getMaterial(state));
  const handleSave = () => {
    if (lmSlug === null) return;
    http_put(API_ENDPOINTS.PUT_PATH(lmSlug), { data: steps, config: config })
    .then((res) => {
      setTimeout(() => { }, 2000);
    })
    .catch((err) => { });
  };

  // save after 5 seconds
  useEffect(() => {
    const timeout = setTimeout(() => {
      handleSave();
    }, 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, [steps, config]);

  return (
    <div className="path-authoring-toolbar un-toolbar">
      <TextBreadcrumb
        parentTitle={company.name}
        subTitle={material?.name}
      />
      <div className="item-path-authoring-toolbar un-tools">
        {/* <div className="buttons-path-authoring">
          <UndoRedo />
          <div className="divider-buttons-path-authoring" />
          <ZoomButton />
          <div className="divider-buttons-path-authoring" />
          <Alignment />
          <div className="divider-buttons-path-authoring" />
          <ScreenToggle />
        </div> */}
      </div>
      <div className="item-path-authoring-sloat right">
        {/* <UserAction /> */}
        <Actions />
        <SaveAndPublish />
      </div>
    </div>
  );
}

export default Toolbar;
