import React, { useEffect, useRef } from 'react';
import { LineIcon } from '../../assets/Icons';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  setActiveTool,
  selectActiveTool,
  selectActiveLayer,
  setActiveLayer,
  removeActiveLayer
} from '../../../redux/toolsSlice';
import { Transformer, Rect, Group, Shape } from 'react-konva';
import {
  updateLayer,
  removeLayerConfig,
  setLayerConfig,
  getLayerObjConfig,
  removeLayer
} from '../../../redux/stepsSlice';

const Line = () => {
  const dispatch = useDispatch();
  const activeTool = useSelector((state) => selectActiveTool(state));

  const setActive = () => {
    dispatch(setActiveTool('line'));
  };

  return (
    <div
      className={`tool ${activeTool === 'line' ? 'active' : ''}`}
      title="Arrow Shape"
      data-tooltip-id="tools-tooltip"
      data-tooltip-content="Arrow Shape"
      onClick={setActive}
    >
      <LineIcon />
    </div>
  );
};

export default Line;

export const lineLayerDefaults = (id, mouseDown) => {
  const width = 150;
  const height = 4;
  const pos = mouseDown;

  return {
    id: id,
    type: 'line',
    x: pos.x,
    y: pos.y,
    width: width,
    height: height,
    stroke: '#000000',
    strokeWidth: 1,
    rotation: 0,
    arrowSize: 10
  };
};

function lineToAngle(ctx, x1, y1, length, angle) {
  angle = (angle - 90) * Math.PI / 180;
  var x2 = x1 + length * Math.cos(angle),
  y2 = y1 + length * Math.sin(angle);

  // ctx.beginPath();
  ctx.moveTo(x1, y1);
  ctx.lineTo(x2, y2);

  return {
    x: x2,
    y: y2
  };
}

function draw_arrow(ctx, x1, y1, length, arrowSize) {
  let angle = 90;
  var pos = lineToAngle(ctx, x1, y1, length, angle);
  lineToAngle(ctx, pos.x, pos.y, arrowSize, angle + 135);
  lineToAngle(ctx, pos.x, pos.y, arrowSize, angle - 135);
  ctx.moveTo(x1, y1); // don't remove this line 
}

export const RenderLine = ({ layer, stepId }) => {
  const shapeRef = useRef();
  const transformerRef = useRef();
  const dispatch = useDispatch();
  const activeLayer = useSelector((state) => selectActiveLayer(state));
  const isSelected =
    activeLayer &&
    activeLayer.layerId === layer.id &&
    activeLayer.stepId === stepId;
  const defaultConfig = [
    {
      layerId: layer.id,
      sectionId: 'arrowShape',
      sectionTitle: 'Element > Arrow Shape',
      values: [
        {
          id: 'stroke',
          title: 'Border Color',
          type: 'color',
          value: layer.stroke || '#ffffff'
        },
        {
          id: 'arrowSize',
          title: 'Arrow Size',
          type: 'px-size',
          value: layer.arrowSize || 10
        },
        {
          id: 'strokeWidth',
          title: 'Stroke Width',
          type: 'px-size',
          value: layer.strokeWidth || 1
        },
        {
          id: 'lineStyle',
          title: 'Line  Style',
          type: 'line-style',
          value: layer.lineStyle || 'Solid'
        }
      ]
    }
  ];
  const layerObjConfig = useSelector(
    (state) => getLayerObjConfig(state, layer),
    shallowEqual
  );

  useEffect(() => {
    if (layerObjConfig) {
      const newLayer = {
        ...layer,
        ...layerObjConfig
      };
      dispatch(updateLayer({ stepId: stepId, layer: newLayer }));
    }
  }, [layerObjConfig]);

  const handleKeyDown = (e) => {
    if (e.key === 'Delete' && isSelected && confirm('Are you sure?')) {
      dispatch(removeActiveLayer());
      dispatch(removeLayerConfig());
      dispatch(removeLayer({ stepId: stepId, layerId: layer.id }));
    }
  };

  useEffect(() => {
    if (isSelected) {
      transformerRef.current.nodes([shapeRef.current]);
      // transformerRef.current.nodes([groupRef.current]);
      transformerRef.current.getLayer().batchDraw();
      window.addEventListener('keydown', handleKeyDown);
      return () => {
        window.removeEventListener('keydown', handleKeyDown);
      };
    } else {
      if(!activeLayer?.stepId && !activeLayer?.layerId){
        dispatch(removeLayerConfig());
      }
    }
  }, [isSelected]);

  const handleLayerUpdate = (e) => {
    changeCursor('default');
    const newLayer = {
      ...layer,
      x: e.target.x(),
      y: e.target.y(),
    };
    dispatch(updateLayer({ stepId: stepId, layer: newLayer }));
  };

  const handleLayerTransform = (e) => {
    const node = shapeRef.current;
    const scaleX = node.scaleX();
    const scaleY = node.scaleY();

    // we will reset it back
    node.scaleX(1);
    node.scaleY(1);
    const newLayer = {
      ...layer,
      x: node.x(),
      y: node.y(),
      rotation: node.rotation(),
      // set minimal value
      width: Math.max(5, node.width() * scaleX),
      height: Math.max(node.height() * scaleY),
    }

    dispatch(updateLayer({ stepId: stepId, layer: newLayer }));
  };

  const setActive = () => {
    dispatch(setActiveLayer({ stepId: stepId, layerId: layer.id }));
    dispatch(setLayerConfig(defaultConfig));
  };

  const changeCursor = (cursorType) => {
    const container = document.querySelector('.konvajs-content');
    if (container) {
      container.style.cursor = cursorType;
    }
  };

  const renderNode = () => {
    return (
      <>
        <Shape
          x={layer.x}
          y={layer.y}
          onClick={setActive}
          onTap={setActive}
          ref={shapeRef}
          height={layer.height}
          width={layer.width}
          stroke={layer.stroke}
          rotation={layer.rotation}
          strokeWidth={layer.strokeWidth}
          dash={layer.lineStyle === 'Dotted' ? [0,layer.strokeWidth*1.5,0,0] : layer.lineStyle === "Dashed" ? [layer.strokeWidth, layer.strokeWidth*2] : []}
          lineCap="round"
          lineJoin="bevel"            
          hitStrokeWidth={50}
          draggable
          onMouseEnter={() => changeCursor('move')}
          onMouseOver={() => changeCursor('move')}
          onMouseLeave={() => changeCursor('default')}
          onDragStart={() => changeCursor('move')}
          sceneFunc={function (context, shape) {
            const width = shape.width();
            const height = shape.height();
            context.beginPath();
            context.moveTo(0, 0);
            draw_arrow(context, 0, layer.height/2, layer.width, layer.arrowSize);
            context.closePath();
            // (!) Konva specific method, it is very important
            context.fillStrokeShape(shape);
          }}
          onDragEnd={handleLayerUpdate}
          onTransformEnd={handleLayerTransform}
        />
        {isSelected && (
          <Transformer
            ref={transformerRef}
            enabledAnchors={['middle-right', 'middle-left']}
            anchorStyleFunc = {(anchor) => {
              // anchor is Konva.Rect instance
              // you manually change its styling
              anchor.cornerRadius(10);
            }}
            flipEnabled={false}
            boundBoxFunc={(oldBox, newBox) => {
              // limit resize
              if (Math.abs(newBox.width) < 2 || Math.abs(newBox.height) < 2) {
                return oldBox;
              }
              return newBox;
            }}
          />
        )}
      </>

    );
  };

  return <>{renderNode()}</>;
};

export const RenderPreviewLine = ({ layer }) => {
  const renderNode = () => {
    return (
      <Group x={layer.x} y={layer.y}>
        <Shape
          height={layer.height}
          width={layer.width}
          fill={layer.fill}
          stroke={layer.stroke}
          strokeWidth={layer.strokeWidth}
          rotation={layer.rotation}
          key={layer.id}
          dash={layer.lineStyle === 'Dotted' ? [0,layer.strokeWidth*1.5,0,0] : layer.lineStyle === "Dashed" ? [layer.strokeWidth, layer.strokeWidth*2] : []}
          lineCap="round"
          lineJoin="bevel"            
          sceneFunc={function (context, shape) {
            const width = shape.width();
            const height = shape.height();
            context.beginPath();
            context.moveTo(0, 0);
            draw_arrow(context, 0, layer.height/2, layer.width, layer.arrowSize);
            context.closePath();
            // (!) Konva specific method, it is very important
            context.fillStrokeShape(shape);
          }}
        />
      </Group>
    );
  };

  return <>{renderNode()}</>;
};
