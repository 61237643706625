import React from "react";

function FileUpload2({title = ""}) {
  return (
    <div className="cover-upload-form">
      <div className="text-label-upload">{title}</div>
      <div className="filed-upload-form">
        <header className="text-upload-form">
          Choose a file <span>Max file size: 15 MB</span>
        </header>
        <button className="button-upload-form">
          <span className="icon-button-upload-form">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.50005 0C7.72105 0 7.93297 0.0878001 8.0893 0.244075L11.4226 3.57741C11.748 3.90285 11.748 4.43048 11.4226 4.75592C11.0972 5.08136 10.5695 5.08136 10.2441 4.75592L8.33338 2.84517V10C8.33338 10.4602 7.9603 10.8333 7.50005 10.8333C7.0398 10.8333 6.66672 10.4602 6.66672 10V2.84517L4.75595 4.75592C4.43052 5.08136 3.90287 5.08136 3.57744 4.75592C3.25201 4.43048 3.252 3.90285 3.57744 3.57741L6.9108 0.244075C7.06705 0.0878001 7.27905 0 7.50005 0ZM0.833366 9.16667C1.2936 9.16667 1.6667 9.53975 1.6667 10C1.6667 10.8143 1.67073 11.0998 1.71473 11.3211C1.912 12.3127 2.68724 13.088 3.67897 13.2853C3.90017 13.3293 4.18566 13.3333 5.00003 13.3333H10C10.8144 13.3333 11.0999 13.3293 11.321 13.2853C12.3128 13.088 13.088 12.3127 13.2853 11.3211C13.3293 11.0998 13.3334 10.8143 13.3334 10C13.3334 9.53975 13.7065 9.16667 14.1667 9.16667C14.627 9.16667 15 9.53975 15 10C15 10.0327 15 10.0647 15 10.0964C15.0002 10.7778 15.0003 11.2422 14.92 11.6462C14.5912 13.2991 13.2991 14.5912 11.6462 14.9199C11.2422 15.0003 10.7778 15.0002 10.0965 15C10.0648 15 10.0326 15 10 15H5.00003C4.96739 15 4.93523 15 4.90355 15C4.22222 15.0002 3.75782 15.0003 3.35382 14.9199C1.70094 14.5912 0.408866 13.2991 0.080091 11.6462C-0.00026737 11.2422 -0.000159099 10.7778 1.59009e-05 10.0965C2.42343e-05 10.0647 3.25902e-05 10.0327 3.25902e-05 10C3.25902e-05 9.53975 0.373124 9.16667 0.833366 9.16667Z"
                fill="currentColor"
              />
            </svg>
          </span>
          <span className="text-button-upload-form">Browse file</span>
        </button>
        <input className="file-upload-form" type="file" />
      </div>
    </div>
  );
}

export default FileUpload2;
