import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { http_get } from '../components/pages/utils/api_client';
import { API_ENDPOINTS } from '../components/pages/utils/endpoints';

export const fetchProfile = createAsyncThunk(
  'profile/fetchProfile',
  async () => {
    let profile = {};
    await http_get(API_ENDPOINTS.GET_PROFILE)
      .then((res) => {
        profile = res.data;
      })
      .catch((err) => {
        throw new Error('Failed to fetch profile');
      });
    return profile;
  }
);

const profileSlice = createSlice({
  name: 'profile',
  initialState: {
    data: {},
    loading: false,
    error: null
  },
  reducers: {
    setProfileData: (state, action) => {
      state.data = {...state.data, ...action.payload};
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.profile;
      })
      .addCase(fetchProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  }
});

export const { setProfileData } = profileSlice.actions;

export default profileSlice.reducer;

export const getProfile = (state) => state.profile.data;
