import React, { useEffect, useState } from 'react';
import { ChevronLeft } from '../../assets/Icons';
import { ENDPOINTS } from '../../pages/utils/endpoints';

const RegistrationLayout = ({
    children,
    title,
    currStep,
    totalSteps,
    onBackClick
}) => {
    const logo = require('../../assets/logo.png');
    const progressWidth = (currStep / totalSteps) * 100;

    return (
        <div className="un-registration-layout">
            <div className="header">
                <a href={ENDPOINTS.ROOT}>
                    <img src={logo} alt="logo" />
                </a>
                <a
                    rel="nofollow"
                    data-method="delete"
                    href={ENDPOINTS.USER_SIGNOUT}
                >
                    Logout
                </a>
            </div>
            <div className="content">
                <div className="form-container">
                    <div className="back-icon" onClick={onBackClick}>
                        <ChevronLeft />
                        Back
                    </div>
                    <div className="progress-bar">
                        <div className="counter">
                            {currStep}/{totalSteps}
                        </div>
                        <div
                            className="bar"
                            style={{ width: `${progressWidth}%` }}
                        ></div>
                    </div>
                    {title}
                    <div className="yield-container">{children}</div>
                </div>
            </div>
        </div>
    );
};

RegistrationLayout.defaultProps = {
    title: '',
    currentStep: '',
    totalSteps: 3,
    onBackClick: () => {}
};

export default RegistrationLayout;
