import React, { useEffect, useState } from 'react';
import TextInput from '../../atoms/text-input/TextInput';
import PasswordInput from '../../atoms/password-input/PasswordInput';
import CheckboxInput from '../../atoms/checkbox-input/CheckboxInput';
import Button from '../../atoms/button/Button';
import axios from 'axios';
import { API_ENDPOINTS, CONSTS, ENDPOINTS } from '../utils/endpoints';
import { useDispatch, useSelector } from 'react-redux';
import {
  getOnboardingData,
  setOnboardingData
} from '../../../redux/onboardingSlice';

const CreateAccount = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => getOnboardingData(state));
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [formErrors, setFormErrors] = useState({});
  var logo = require('../../assets/logo.png');

  const handleFormUpdate = (event) => {
    const { target } = event;
    const { name } = target;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    dispatch(setOnboardingData({ [name]: value }));
  };

  const registerUser = () => {
    setIsLoading(true);

    axios
      .post(API_ENDPOINTS.REGISTER, {
        email: data.email,
        password: data.password,
        password_confirmation: data.password_confirmation,
        terms: data.terms
      })
      .then((response) => {
        setIsLoading(false);
        localStorage.setItem(CONSTS.AUTH_TOKEN, response.data.token);
        window.location.href = ENDPOINTS.ONBOARDING;
      })
      .catch((error) => {
        setFormErrors(JSON.parse(error.response.data.error));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const signUpForm = (
    <div className="container">
      <img src={logo} alt="logo" />
      <h1 className="title">Create your account</h1>
      <p className="subtext">No credit card required to get started.</p>
      <div className="form-container">
        <TextInput
          label="Email"
          name="email"
          type="email"
          placeholder="Email address"
          onChange={handleFormUpdate}
          value={data.email}
          error={formErrors.email?.join(',')}
          required
        />
        <PasswordInput
          showStrength
          label="Password"
          name="password"
          placeholder="Password"
          onChange={handleFormUpdate}
          value={data.password}
          error={formErrors.password}
        />
        <PasswordInput
          label="Repeat Password"
          name="password_confirmation"
          placeholder="Repeat Password"
          onChange={handleFormUpdate}
          value={data.password_confirmation}
          validationCallback={() => {
            return data.password_confirmation === data.password
              ? ''
              : "Passwords don't match";
          }}
          error={formErrors.password_confirmation}
        />
        <CheckboxInput
          label="I agree to the Terms of Service and Privacy Policy"
          name="terms"
          onChange={handleFormUpdate}
          value={data.terms}
        />
        <Button
          onClick={registerUser}
          isLoading={isLoading}
          disabled={isDisabled}
        >
          Create Account
        </Button>
        <p>
          Already have an account? <a href={ENDPOINTS.LOGIN}>Login</a>
        </p>
      </div>
    </div>
  );

  const validEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  useEffect(() => {
    setIsDisabled(
      !data.email ||
        !data.password ||
        !data.password_confirmation ||
        !data.terms ||
        data.password !== data.password_confirmation ||
        !validEmail(data.email)
    );
    localStorage.setItem(CONSTS.AUTH_TOKEN, '');
  }, [data]);

  return <div className="un-registrations">{signUpForm}</div>;
};

export default CreateAccount;
