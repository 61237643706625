import React from "react";
import PathAvatar from "../../assets/images/path-avatar.png";

function UserAction() {
  return (
    <div className="path-avatar-sloat">
      <div className="avatar-path-toolpar">
        <div className="cover-avatar-path-toolpar">
          <img src={PathAvatar} alt="" />
        </div>
        <div className="user-avatar-path-toolpar">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.375 3C5.13236 3 4.125 4.00736 4.125 5.25C4.125 6.49264 5.13236 7.5 6.375 7.5C7.61764 7.5 8.625 6.49264 8.625 5.25C8.625 4.00736 7.61764 3 6.375 3ZM2.625 5.25C2.625 3.17893 4.30393 1.5 6.375 1.5C8.44607 1.5 10.125 3.17893 10.125 5.25C10.125 7.32107 8.44607 9 6.375 9C4.30393 9 2.625 7.32107 2.625 5.25ZM15 5.25C15.4142 5.25 15.75 5.58579 15.75 6V7.5H17.25C17.6642 7.5 18 7.83579 18 8.25C18 8.66421 17.6642 9 17.25 9H15.75V10.5C15.75 10.9142 15.4142 11.25 15 11.25C14.5858 11.25 14.25 10.9142 14.25 10.5V9H12.75C12.3358 9 12 8.66421 12 8.25C12 7.83579 12.3358 7.5 12.75 7.5H14.25V6C14.25 5.58579 14.5858 5.25 15 5.25ZM1.09835 11.5984C1.80161 10.8951 2.75544 10.5 3.75 10.5H9C9.99456 10.5 10.9484 10.8951 11.6517 11.5984C12.3549 12.3016 12.75 13.2554 12.75 14.25V15.75C12.75 16.1642 12.4142 16.5 12 16.5C11.5858 16.5 11.25 16.1642 11.25 15.75V14.25C11.25 13.6533 11.0129 13.081 10.591 12.659C10.169 12.2371 9.59674 12 9 12H3.75C3.15326 12 2.58097 12.2371 2.15901 12.659C1.73705 13.081 1.5 13.6533 1.5 14.25V15.75C1.5 16.1642 1.16421 16.5 0.75 16.5C0.335786 16.5 0 16.1642 0 15.75V14.25C0 13.2554 0.395088 12.3016 1.09835 11.5984Z"
              fill="currentColor"
            />
          </svg>
        </div>
      </div>
    </div>
  );
}

export default UserAction;
