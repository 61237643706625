import React from "react";

function Walkthroughs({ setChat }) {
  return (
    <div id="tabchat-pane1" className="tab-chat-pane">
      <div className="wrapper-message-list">
        {Array.from({ length: 5 }).map((_, index) => {
          return (
            <div
              onClick={() => setChat(index)}
              key={index}
              className="item-message-list"
            >
              <div className="left-message-list">
                <div className="arrow-message-list">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M8.21967 5.71967C7.92678 6.01257 7.92678 6.48744 8.21967 6.78033L11.9393 10.5L8.21967 14.2197C7.92678 14.5126 7.92678 14.9874 8.21967 15.2803C8.51257 15.5732 8.98744 15.5732 9.28033 15.2803L13.5303 11.0303C13.8232 10.7374 13.8232 10.2625 13.5303 9.96965L9.28033 5.71967C8.98743 5.42678 8.51256 5.42678 8.21967 5.71967Z"
                      fill="currentColor"
                    />
                  </svg>
                </div>
                <div className="flex-name-message-list">
                  <div className="name-message-list">Willard, me</div>
                  <div className="counter-message-list">
                    {index + 1 * (Math.random() * 12).toFixed()}
                  </div>
                </div>
                <div className="badge-message-list">new</div>
                <div className="flex-content-message-list">
                  <div className="subject-message-list">
                    Medication - Step 4
                  </div>
                  <div className="content-message-list">
                    Hi there! I'm having some trouble filling out the Medication
                    form on the 4th step of the course. I'm not sure what
                    details to provide. Can you help me with this?!
                  </div>
                </div>
              </div>
              <div className="time-message-list">07:00 pm</div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Walkthroughs;
