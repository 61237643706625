import React from "react";

function TextForm() {
  // color library can be used
  return (
    <div className="design-form-path gap-16">
      <div className="item-design-form-path">
        <div className="flex-design-form">
          <div className="label-design-form">
            <div className="size-12">Color</div>
          </div>
          <div className="value-design-form">
            <div className="cover_colorpicker">
              <input
                id="colorPicker"
                type="color"
                className="input_colorpicker"
                value="#100F0F"
              />
              <input
                id="textcolorPicker"
                type="text"
                className="input_textcolorPicker"
                value="#100F0F"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="item-design-form-path">
        <div className="flex-design-form">
          <div className="label-design-form">
            <div className="size-12">Font</div>
          </div>
          <div className="value-design-form">
            <div className="form-select">
              <select
                className="selected-item"
                style={{
                  width: "100%",
                  backgroundColor: "transparent",
                  display: "block",
                }}
              >
                <option value="0">Inter</option>
                <option value="1">Lato</option>
                <option value="2">Monospace</option>
                <option value="3">Georgia</option>
                <option value="4">Serif</option>
                <option value="5">Cursive</option>
                <option value="6">Fantasy</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TextForm;
