import React from "react";
import { messagesList } from "../../../data";
import ProfileImage from "components/assets/images/img-1.png";

function MessagesSidebar({ activeMessage, setActiveMessage }) {
  return (
    <div className="sidebar-messages">
      <div className="head-sidebar-messages">
        <div className="wrapper-search-input">
          <div className="search-input-cover">
            <input
              className="input-search"
              type="text"
              placeholder="Search Learner"
            />
            <div className="search-input-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M2.44472 7.22218C2.44472 9.92222 4.63353 12.111 7.33357 12.111C8.59282 12.111 9.74087 11.6349 10.6075 10.8529C10.6401 10.7755 10.6878 10.703 10.7508 10.64C10.8139 10.577 10.8864 10.5292 10.9638 10.4967C11.7461 9.62995 12.2224 8.48169 12.2224 7.22218C12.2224 4.52214 10.0336 2.33333 7.33357 2.33333C4.63353 2.33333 2.44472 4.52214 2.44472 7.22218ZM12.1793 11.1257C13.0403 10.0582 13.5558 8.70038 13.5558 7.22218C13.5558 3.78576 10.77 1 7.33357 1C3.89715 1 1.11139 3.78576 1.11139 7.22218C1.11139 10.6586 3.89715 13.4444 7.33357 13.4444C8.81148 13.4444 10.169 12.9291 11.2364 12.0684L13.8619 14.6939C14.1223 14.9543 14.5444 14.9543 14.8047 14.6939C15.0651 14.4336 15.0651 14.0115 14.8047 13.7511L12.1793 11.1257Z"
                  fill="currentColor"
                ></path>
              </svg>
            </div>
          </div>
        </div>
        <div className="sort-messages">
          <div className="button-sort-messages">
            <div className="icon-button-sort-messages">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
              >
                <path
                  d="M6.5 4.532C6.94183 4.532 7.3 4.91772 7.3 5.39354V13.8L9.13432 11.9663C9.4227 11.6779 9.87649 11.6557 10.1903 11.8998L10.2657 11.9663C10.5781 12.2787 10.5781 12.7853 10.2657 13.0977L7.06569 16.2977C6.75327 16.6101 6.24673 16.6101 5.93431 16.2977L2.73431 13.0977C2.4219 12.7853 2.4219 12.2787 2.73431 11.9663C3.04673 11.6539 3.55327 11.6539 3.86569 11.9663L5.7 13.8V5.39354C5.7 4.94944 6.01201 4.58383 6.41283 4.53706L6.5 4.532ZM15.0657 4.73431L18.2657 7.93431C18.5781 8.24673 18.5781 8.75327 18.2657 9.06569C17.9533 9.3781 17.4467 9.3781 17.1343 9.06569L15.3 7.2312V15.7C15.3 16.1418 14.9418 16.5 14.5 16.5C14.0582 16.5 13.7 16.1418 13.7 15.7V7.2312L11.8657 9.06569C11.5533 9.3781 11.0467 9.3781 10.7343 9.06569C10.4219 8.75327 10.4219 8.24673 10.7343 7.93431L13.9343 4.73431C14.2467 4.4219 14.7533 4.4219 15.0657 4.73431Z"
                  fill="currentColor"
                />
              </svg>
            </div>
            <div className="text-button-sort-messages">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M15.2803 8.21967C14.9874 7.92678 14.5126 7.92678 14.2197 8.21967L10.5 11.9393L6.78033 8.21967C6.48743 7.92678 6.01256 7.92678 5.71967 8.21967C5.42678 8.51257 5.42678 8.98744 5.71967 9.28033L9.96969 13.5303C10.2626 13.8232 10.7375 13.8232 11.0304 13.5303L15.2803 9.28033C15.5732 8.98743 15.5732 8.51256 15.2803 8.21967Z"
                  fill="currentColor"
                />
              </svg>
            </div>
            <div className="arrow-button-sort-messages">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M15.2803 8.21967C14.9874 7.92678 14.5126 7.92678 14.2197 8.21967L10.5 11.9393L6.78033 8.21967C6.48743 7.92678 6.01256 7.92678 5.71967 8.21967C5.42678 8.51257 5.42678 8.98744 5.71967 9.28033L9.96969 13.5303C10.2626 13.8232 10.7375 13.8232 11.0304 13.5303L15.2803 9.28033C15.5732 8.98743 15.5732 8.51256 15.2803 8.21967Z"
                  fill="currentColor"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div className="sidebar-message-list">
        {messagesList.map((message, index) => {
          const isActive = activeMessage === message.name;

          return (
            <div
              key={index}
              className={`message-card ${message.isNew ? "unread" : ""} ${
                isActive ? "select" : ""
              }`}
              onClick={() => setActiveMessage(message.name)}
            >
              <div className="image-message-card">
                <img src={ProfileImage} alt="willard" />
              </div>
              <div className="content-message-card">
                <div className="name-message-card">{message.name}</div>
                <div className="text-message-card">
                  {message.message.slice(0, 40)}
                </div>
              </div>
              <div className="right-message-card">
                <div className="gap-4-message-card">
                  <div className="button-message-card">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="21"
                      height="21"
                      viewBox="0 0 21 21"
                      fill="none"
                    >
                      <path
                        d="M14.2 5.77907V13.65C14.2 15.7763 12.4763 17.5 10.35 17.5C8.2237 17.5 6.5 15.7763 6.5 13.65V6.06667C6.5 4.64913 7.64913 3.5 9.06669 3.5C10.4842 3.5 11.6333 4.64913 11.6333 6.06667V13.612C11.6333 14.3208 11.0588 14.8954 10.35 14.8954C9.64125 14.8954 9.06669 14.3208 9.06669 13.612V6.75581"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="button-message-card">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="21"
                      height="21"
                      viewBox="0 0 21 21"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M10.9619 4.41234C10.7915 4.00057 10.2083 4.00057 10.0379 4.41234L8.69292 7.66271C8.58359 7.92692 8.33354 8.10598 8.04819 8.12439L4.61051 8.34617C4.16547 8.37488 3.97782 8.92775 4.31345 9.22142L7.03545 11.6032C7.24552 11.787 7.33613 12.0722 7.27064 12.3436L6.49584 15.5535C6.39274 15.9806 6.85115 16.3235 7.23174 16.1039L10.1251 14.4346C10.3571 14.3008 10.6428 14.3008 10.8747 14.4346L13.7681 16.1039C14.1487 16.3235 14.6071 15.9806 14.504 15.5535L13.7292 12.3436C13.6637 12.0722 13.7543 11.787 13.9644 11.6032L16.6864 9.22142C17.022 8.92775 16.8343 8.37488 16.3893 8.34617L12.9516 8.12439C12.6663 8.10598 12.4162 7.92692 12.3069 7.66271L10.9619 4.41234ZM8.65187 3.83881C9.33342 2.19175 11.6664 2.19175 12.3479 3.83881L13.5143 6.65757L16.4859 6.84928C18.2661 6.96413 19.0166 9.1756 17.6741 10.3503L15.2938 12.433L15.9621 15.2015C16.3745 16.91 14.5409 18.2815 13.0185 17.4032L10.4999 15.9501L7.98132 17.4032C6.45896 18.2815 4.62532 16.91 5.03771 15.2015L5.70597 12.433L3.32569 10.3503C1.98319 9.1756 2.73376 6.96413 4.51394 6.84928L7.48549 6.65757L8.65187 3.83881Z"
                        fill="currentColor"
                      />
                    </svg>
                  </div>
                </div>
                <div className="counter-message-card">2</div>
                <div className="time-message-card">{message.timestamp}</div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default MessagesSidebar;
