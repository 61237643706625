import React, { useState, useEffect } from 'react';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { API_ENDPOINTS } from '../../../pages/utils/endpoints';
import { http_get } from '../../../../utils/api_client';

const WalkthroughSelectOption = ({ defaultValue, onChange }) => {
  const [value, setValue] = useState(`${defaultValue}`);
  const [list, setList] = useState([]);

  useEffect(() => {
    // if(list){
    //   setValue(list.filter(ele => ele.id === defaultValue));      
    // }
    setValue(list.filter(ele => parseInt(ele.value) === parseInt(defaultValue))?.[0]);
  }, [defaultValue, list]);

  const handleChange = (option) => {
    setValue(option);
    onChange(option.value);
  };

  const getWalkthroughList = () => {
    const params = { by: 'aasm_state', keyword: 'published' };
    http_get(API_ENDPOINTS.SEARCH_WALKTHROUGH, params)
      .then((response) => {
        setList(response?.data?.map(ele => { return{ value: ele.id.toString(), label: ele.name}}));
      })
  }

  useEffect(() => {
    getWalkthroughList();
  }, [])

  return (
    <Dropdown
      options={list}
      onChange={handleChange}
      value={value}      
      placeholder="Select an option"
      className="un-dropdown-option"
    />
  );
}

export default WalkthroughSelectOption;
