import React, { useState, useEffect } from "react";
import LabelAndToggle from "./LabelAndToggle";
import { CheckAnswer, CogIcon, TrashIcon } from "../../../../../assets/icons";
import { v4 as uuidv4 } from 'uuid';
import { useDrag, useDrop } from 'react-dnd';

const ItemTypes = {
  ELEMENT: 'element',
};

function AnswerItem({ answer, index, handleAnswerChange, toggleCheckAnswer, deleteAnswer, moveAnswer, visibility }) {
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.ELEMENT,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: ItemTypes.ELEMENT,
    drop: (item) => {
      moveAnswer(item.index, index);
    },
  });

  return (
    <div key={index} className="item-list-flex-design" ref={(node) => drag(drop(node))}>
      <div
        className={`flex-third-col-design ${answer.isChecked ? "active" : ""
          }`}
      >
        <div className="icon-flex-third-col-design">
          <CogIcon />
        </div>
        <div className="filed-design-form flex-full">
          <input
            type="text"
            className="input-design-form"
            placeholder="Write here..."
            value={answer.value}
            onChange={(e) => handleAnswerChange(index, e.target.value)}
          />
        </div>
        {visibility &&
          <div
            onClick={() => toggleCheckAnswer(index)}
            className="question-correct-answer">
            <CheckAnswer />
          </div>
        }
        <div
          onClick={() => deleteAnswer(index)}
          className="button-delete"
        >
          <TrashIcon />
        </div>
      </div>
    </div>
  );
}

// pass the label if needed
function LabelAndMultipleChoice({ defaultValue, onChange, options, visibility = false }) {

  const [answers, setAnswers] = useState(
    Array.isArray(defaultValue)
      ? defaultValue
      : [{
        value: "currently employed",
        isChecked: false,
      },
      {
        value: "Retired",
        isChecked: false,
      },]);

  useEffect(() => {
    if (Array.isArray(defaultValue)) {
      setAnswers(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    onChange(answers);
  }, [answers, onChange]);

  const handleAnswerChange = (index, newValue) => {
    setAnswers((prev) =>
      prev.map((answer, i) =>
        i === index ? { ...answer, value: newValue } : answer
      )
    );
  };

  const toggleCheckAnswer = (index) => {
    setAnswers((prev) =>
      prev.map((answer, i) =>
        i === index ? { ...answer, isChecked: !answer.isChecked } : answer
      )
    );
  };

  const deleteAnswer = (index) => {
    setAnswers((prev) => prev.filter((_, i) => i !== index));
  };

  const addNewAnswer = () => {
    setAnswers((prev) => [
      ...prev,
      {
        id: uuidv4(),
        value: "",
        isChecked: false,
      },
    ]);
  };

  const moveAnswer = (dragIndex, hoverIndex) => {
    const dragAnswer = answers[dragIndex];
    setAnswers((prev) => {
      const newAnswers = [...prev];
      newAnswers.splice(dragIndex, 1);
      newAnswers.splice(hoverIndex, 0, dragAnswer);
      return newAnswers;
    });
  };

  return (
    <div className="item-design-form-path">
      <div className="list-flex-design list-question-design">
        {visibility && <LabelAndToggle title="Set correct answer" checked={true} />}
        {answers && answers.map((answer, index) => (
          <AnswerItem
            key={index}
            answer={answer}
            index={index}
            handleAnswerChange={handleAnswerChange}
            toggleCheckAnswer={toggleCheckAnswer}
            deleteAnswer={deleteAnswer}
            moveAnswer={moveAnswer}
            visibility={visibility}
          />
        ))}
      </div>
      <div className="cover-add-btn-design">
        <button
          onClick={addNewAnswer}
          type="button"
          className="button-add btn-add-question"
        >
          Add
        </button>
      </div>
    </div>
  );
}

export default LabelAndMultipleChoice;
