import React, { useEffect } from 'react'
import { Provider, useDispatch } from 'react-redux';
import { setMaterial } from '../../../redux/materialSlice';
import { setConfig, setSteps } from '../../../redux/walkthroughPreviewSlice';
import PreviewHeader from './PreviewHeader';
import StepViewer from './StepViewer';
import { setProfileData } from '../../../redux/profileSlice';

const PublishedContentView = ({ material, current_user }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setMaterial(material));
    dispatch(setSteps(material.data));
    dispatch(setConfig(material.config));
    dispatch(setProfileData(current_user));
  }, []);

  return (
    <>
      <div style={{ backgroundColor: '#F6F8F9' }}>
        <PreviewHeader />
        <StepViewer />
      </div>
    </>
  )
}

export default PublishedContentView