import React, { useState, useEffect } from 'react';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

const FontSizeOption = ({ defaultValue, onChange }) => {
  const [value, setValue] = useState(`${defaultValue}`);
  useEffect(() => {
    setValue(`${defaultValue} px`);
  }, [defaultValue]);

  let options = [];
  for (let i = 0; i <= 240; i++) {
    options.push({ value: i, label: `${i} px` });
  }

  const handleChange = (option) => {
    setValue(option.label);
    onChange(option.value);
  };

  return (
    <>
      <Dropdown
        options={options}
        onChange={handleChange}
        value={value}
        placeholder="Select an option"
        className="un-dropdown-option"
      />
    </>
  );
};

export default FontSizeOption;
