import React, { useState } from "react";

function ChatNavbar() {
  const [isActive, setIsActive] = useState(false);

  return (
    <div class="message-topbar">
      <div class="left-message-topbar">
        <div class="flex-subject-message-topbar">
          <div class="head-subject-message-topbar">
            <div class="arrow-subject-message-topbar">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12.7803 15.2803C13.0732 14.9874 13.0732 14.5126 12.7803 14.2197L9.06066 10.5L12.7803 6.78033C13.0732 6.48744 13.0732 6.01256 12.7803 5.71967C12.4874 5.42678 12.0126 5.42678 11.7197 5.71967L7.46967 9.96969C7.17678 10.2626 7.17678 10.7375 7.46967 11.0304L11.7197 15.2803C12.0126 15.5732 12.4874 15.5732 12.7803 15.2803Z"
                  fill="currentColor"
                />
              </svg>
            </div>
            <div class="name-subject-message-topbar">
              Subject: Medication - Step 4
            </div>
          </div>
          <div class="information-subject-message">
            <div
              onClick={() => setIsActive((prev) => !prev)}
              class="button-information-subject-message"
            >
              <div class="text-information-subject-message">Information</div>
              <div class="arrow-information-subject-message">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                >
                  <path
                    d="M5.32167 5.5C4.53258 5.5 4.05437 6.37115 4.47801 7.03688L7.65634 12.0314C8.04929 12.6489 8.95071 12.6489 9.34366 12.0314L12.522 7.03688C12.9456 6.37115 12.4674 5.5 11.6783 5.5L5.32167 5.5Z"
                    fill="currentColor"
                  />
                </svg>
              </div>
            </div>
            <div
              style={{ display: isActive ? "block" : "" }}
              class="wrapper-popup-information-subject-message"
            >
              <div class="popup-information-subject-message">
                <div class="flex-information-subject-message">
                  <div class="label-information-subject-message">Sender:</div>
                  <div class="value-information-subject-message">
                    Willard Purnell (willie.jennings@example.com)
                  </div>
                </div>
                <div class="flex-information-subject-message">
                  <div class="label-information-subject-message">Created:</div>
                  <div class="value-information-subject-message">
                    Tue Aug 30, 2022, 8 a.m
                  </div>
                </div>
                <div class="flex-information-subject-message">
                  <div class="label-information-subject-message">
                    Last update:
                  </div>
                  <div class="value-information-subject-message">
                    Tue Aug 30, 2022, 8 a.m
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="right-message-topbar">
        <div class="pill--message-topbar">w</div>
      </div>
    </div>
  );
}

export default ChatNavbar;
