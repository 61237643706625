import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import ColumnHeader from "./components/datatable/extra/ColumnHeader";
import Modal from "./components/Modal";
import AvatarModal from "./components/avatar-modal";

function IndeterminateCheckbox({ indeterminate, className = "", ...rest }) {
  const ref = useRef(null);

  useEffect(() => {
    if (typeof indeterminate === "boolean") {
      ref.current.indeterminate = rest.checked && indeterminate;
    }
  }, [ref, indeterminate, rest.checked]);

  return (
    <input
      type="checkbox"
      ref={ref}
      className={className + " cursor-pointer data-checkbox"}
      {...rest}
    />
  );
}

export const audienceColumns = [
  {
    id: "checkbox",
    header: ({ table }) => (
      <IndeterminateCheckbox
        {...{
          checked: table.getIsAllRowsSelected(),
          indeterminate: table.getIsSomeRowsSelected(),
          onChange: table.getToggleAllRowsSelectedHandler(),
        }}
      />
    ),
    cell: ({ row }) => {
      return (
        <div style={{ padding: "0.5rem 0" }}>
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        </div>
      );
    },
  },
  {
    accessorKey: "learner",
    filterFn: "nested",
    header: ({ column }) => <ColumnHeader column={column} title="Learners" />,
    cell: ({ row }) => {
      return (
        <div className="avatar-info-wrapper">
          <div className="avatar-image-cover">
            <img
              className="avatar-image"
              src={row.getValue("learner").imageUrl}
              alt="learner"
            />
          </div>
          <div className="avatar-content-cover">
            <Modal
              trigger={
                <h3 className="avatar-name">{row.getValue("learner").name}</h3>
              }
            >
              <AvatarModal profile={row.original} />
            </Modal>
            <div className="avatar-mail-cover">
              <a
                className="avatar-mail"
                href={`mailto:${row.getValue("learner").email}`}
              >
                {row.getValue("learner").email}
              </a>
            </div>
          </div>
        </div>
      );
    },
  },
  {
    accessorKey: "userId",
    header: ({ column }) => <ColumnHeader column={column} title="Customer ID" />,
    cell: ({ row }) => {
      return (
        <div className="text-data-table color-blue-dark">
          {row.getValue("userId")}
        </div>
      );
    },
  },
  {
    accessorKey: "activityStatus",
    filterFn: "customArray",
    header: ({ column }) => (
      <ColumnHeader column={column} title="Activity Status" />
    ),
    cell: ({ row }) => {
      const value = row.getValue("activityStatus");

      const makeclassName = () => {
        if (value === "engaged") {
          return "engaged";
        } else if (value === "at risk") {
          return "risk";
        } else {
          return "";
        }
      };

      return (
        <div
          className={`activity-status ${makeclassName()}`}
          style={{ textTransform: "capitalize" }}
        >
          {value.split("-").join(" ")}
        </div>
      );
    },
  },
  {
    accessorKey: "completionRate",
    header: ({ column }) => (
      <ColumnHeader column={column} title="Completion Rate" />
    ),
    cell: ({ row }) => {
      const progressValue = parseInt(row.getValue("completionRate"));

      return (
        <div className="rating-data-table">
          <div className="counter-progress-bar">{progressValue}%</div>
          <div className="rating-progress-bar">
            <div
              className="current-progress-bar"
              style={{ width: `${progressValue}%` }}
            ></div>
          </div>
        </div>
      );
    },
  },
  {
    accessorKey: "totalStudyTime",
    header: ({ column }) => (
      <ColumnHeader column={column} title="Time Spent" />
    ),
    cell: ({ row }) => {
      return (
        <div className="text-data-table">{row.getValue("totalStudyTime")}</div>
      );
    },
  },
  {
    accessorKey: "learningStart",
    header: ({ column }) => (
      <ColumnHeader column={column} title="Join Date" />
    ),
    cell: ({ row }) => {
      return (
        <div className="text-data-table">
          {row.getValue("learningStart") ? moment(row.getValue("learningStart")).format("DD-MM-YYYY") : "-"}
        </div>
      );
    },
  },
  {
    accessorKey: "uniqueVisits",
    header: ({ column }) => (
      <ColumnHeader column={column} title="Unique Visits" />
    ),
    cell: ({ row }) => {
      return (
        <div className="text-data-table">{row.getValue("uniqueVisits")}</div>
      );
    },
  },
  {
    accessorKey: "opens",
    header: ({ column }) => <ColumnHeader column={column} title="Opens" />,
    cell: ({ row }) => {
      return <div className="text-data-table">{row.getValue("opens")}</div>;
    },
  },
  {
    accessorKey: "completions",
    header: ({ column }) => (
      <ColumnHeader column={column} title="Completions" />
    ),
    cell: ({ row }) => {
      return (
        <div className="text-data-table">{row.getValue("completions")}</div>
      );
    },
  },
  {
    accessorKey: "satisfactionScore",
    header: ({ column }) => (
      <ColumnHeader column={column} title="Satisfaction Score" />
    ),
    cell: ({ row }) => {
      return (
        <div className="text-data-table">
          {row.getValue("satisfactionScore")}
        </div>
      );
    },
  },
  {
    accessorKey: "recentActivity",
    header: ({ column }) => (
      <ColumnHeader column={column} title="Most Recent activity" />
    ),
    cell: ({ row }) => {
      return (
        <div className="text-data-table">
          {row.getValue("recentActivity") ? moment(row.getValue("recentActivity")).format("DD-MM-YYYY, hh:mm a") : "-"}
        </div>
      );
    },
  },
];
