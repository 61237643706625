import React, { useEffect, useState } from 'react';
import Accordion from '../../atoms/accordion/Accordion';
import DesignOption from './DesignOption';
import { useSelector, useDispatch } from 'react-redux';
import {
  getLayerConfig,
  getMainConfig,
  updateMainConfig,
  updateLayerConfig
} from '../../../redux/stepsSlice';

const EditorDesign = () => {
  const dispatch = useDispatch();
  const mainConfig = useSelector((state) => getMainConfig(state));
  const layerConfig = useSelector((state) => getLayerConfig(state));
  const [configs, setConfigs] = useState(
    layerConfig.length > 0 ? layerConfig : mainConfig
  );

  const updateConfig = (sectionId, titleId, newValue) => {
    const data = {
      sectionId: sectionId,
      titleId: titleId,
      value: newValue
    };
    if (layerConfig.length > 0) {
      dispatch(updateLayerConfig(data));
    } else {
      dispatch(updateMainConfig(data));
    }
  };

  useEffect(() => {
    if (layerConfig.length > 0) {
      setConfigs(layerConfig);
    } else {
      setConfigs(mainConfig);
    }
  }, [layerConfig, mainConfig]);

  const renderDesignOptions = ((sectionId, values) => {
    return values.map((value) => (
      <DesignOption
        key={value.id}
        text={value.title}
        optionType={value.type}
        value={value.value}
        options={value.options}
        direction={value?.direction || 'row'}
        onChange={(newValue) => {
          updateConfig(sectionId, value.id, newValue);
        }}
      />
    ));
  });

  const renderConfig = () => {
    return configs.map((config, index) => (
      <Accordion title={config.sectionTitle} key={index}>
        {renderDesignOptions(config.sectionId, config.values)}
      </Accordion>
    ));
  };

  return <div className="un-editor-design">{renderConfig()}</div>;
};

export default EditorDesign;
