import React from "react";
import InviteTeam from "./components/team/InviteTeam";
import AdminUsers from "./components/team/AdminUsers";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const TeamPage = () => {
  return (
      <div className="container-team">
        <InviteTeam/>
        <AdminUsers/>
        <ToastContainer />
      </div>
  );
}

export default TeamPage;