import axios from 'axios';
import { CONSTS } from './endpoints';
import { getCookie } from './tools';

const getAuthToken = () => {
  return getCookie(CONSTS.AUDIENCE_TOKEN);
}

const headers = {
  'Content-Type': 'application/json',
  'Accept': 'application/json',
  'Authorization': `Bearer ${getAuthToken()}`
};

export const http_get = (url, data = {}, params = {}) => {
  return axios.get(url,{
    headers: headers,
    params: data
  });
};

export const http_post = (url, data = {}, params = {}) => {
  return axios.post(url, data, {
    headers: headers,
    params
  });
};

export const http_multipart_post = (url, data = {}, params = {}) => {
  return axios.post(url, data, {
    headers: headers,
    params
  });
};

export const http_put = (url, data = {}, params = {}) => {
  return axios.put(url, data, {
    headers: headers,
    params
  });
};
