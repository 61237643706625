import React, { useEffect, useRef, useState } from "react";
import { exportToCsv, exportToExcel, exportToPdf } from "../../../lib/utils";

const options = [
  {
    id: "excel",
    value: "Excel",
  },
  {
    id: "pdf",
    value: "PDF",
  },
  {
    id: "csv",
    value: "CSV",
  },
];

function ExportModal({ table }) {
  const [isActive, setIsActive] = useState(false);

  const containerRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setIsActive(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [containerRef]);

  useEffect(() => {
    if (isActive) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }, [isActive]);

  const [selectedOption, setSelectedOption] = useState("");
  const [fileName, setFileName] = useState("");

  const handleSubmit = () => {
    if (!selectedOption || selectedOption === "") {
      alert("Please select an option first!");
      return;
    }
    if (!fileName || fileName === "") {
      alert("Please enter your fileName!");
      return;
    }
    if (
      !table.getSelectedRowModel().rows ||
      table.getSelectedRowModel().rows.length === 0
    ) {
      alert("Please select some rows first!");
      return;
    }

    const selectedRows = table.getSelectedRowModel().rows.map((row) => {
      const { subRows, ...rest } = row.original;
      return rest;
    });

    const flatData = selectedRows.map((row) => ({
      ...row,
      learner: row.learner.name,
      learningGroup: row.learningGroup[0],
    }));

    if (selectedOption === "excel") {
      // excel won't work with nested objects
      exportToExcel({ excelData: flatData, fileName });
    } else if (selectedOption === "csv") {
      exportToCsv({ excelData: selectedRows, fileName });
    } else if (selectedOption === "pdf") {
      exportToPdf({ excelData: flatData, fileName });
    }
  };

  return (
    <>
      <a
        onClick={() => setIsActive(true)}
        id="show-export-modal"
        className="export-btn"
        href="#"
      >
        Export
      </a>
      <div className={`modal-export-wrapper ${isActive ? "active" : ""}`}>
        <div className="modal-export-data" ref={containerRef}>
          <div className="head-modal-export">
            <h3 className="title-modal-export">Export Data</h3>
            <div
              onClick={() => setIsActive(false)}
              id="close-export-modal"
              className="close-modal"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M6.17498 5L9.75001 1.42502C9.90834 1.27502 10 1.06667 10 0.833333C10 0.375 9.625 0 9.16667 0C8.93333 0 8.72498 0.0916483 8.57498 0.241648L5 3.82502L1.42502 0.241648C1.27502 0.0916483 1.06667 0 0.833333 0C0.375 0 0 0.375 0 0.833333C0 1.06667 0.0916483 1.27502 0.241648 1.42502L3.82502 5L0.24999 8.57498C0.0916565 8.72498 0 8.93333 0 9.16667C0 9.625 0.375 10 0.833333 10C1.06667 10 1.27502 9.90835 1.42502 9.75835L5 6.17498L8.57498 9.75001C8.72498 9.90834 8.93333 10 9.16667 10C9.625 10 10 9.625 10 9.16667C10 8.93333 9.90835 8.72498 9.75835 8.57498L6.17498 5Z"
                  fill="currentColor"
                />
              </svg>
            </div>
          </div>
          <div className="middle-modal-export">
            <form className="form-modal-export">
              <div className="item-form-export">
                <label className="label-modal-export">File Name</label>
                <input
                  className="input-export"
                  type="text"
                  placeholder="Type export name"
                  value={fileName}
                  onChange={(e) => {
                    setFileName(e.target.value);
                  }}
                />
              </div>
              <div className="item-form-export">
                <label className="label-modal-export mb-16">
                  Export Format
                </label>
                <div className="radio-input-list">
                  {options.map((option) => {
                    return (
                      <label
                        key={option.id}
                        className="radio-export-cover"
                        htmlFor={option.id}
                      >
                        <input
                          className="export-radio"
                          type="radio"
                          id={option.id}
                          name="export_format"
                          value={option.id}
                          onChange={(e) => {
                            setSelectedOption(option.id);
                          }}
                          checked={selectedOption === option.id}
                        />
                        <span className="text-export-radio">
                          {option.value}
                        </span>
                      </label>
                    );
                  })}
                </div>
              </div>
            </form>
          </div>
          <div className="footer-modal-export">
            <div className="item-footer-modal-export">
              <p className="text-weight-medium">
                Numbers {table.getSelectedRowModel().rows.length ?? 0} users
              </p>
            </div>
            <div className="item-footer-modal-export">
              <div className="flex-button-modal">
                <button
                  onClick={() => setIsActive(false)}
                  className="btn-border"
                  type="button"
                >
                  Discard
                </button>
                <button
                  onClick={handleSubmit}
                  className="model-export-btn"
                  type="button"
                >
                  Export
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ExportModal;
