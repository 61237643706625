import React, { useEffect, useRef, useState } from 'react';
import { Image } from 'react-konva';
import { useDispatch } from 'react-redux';
import { setActiveLayer } from '../../../redux/toolsSlice';

const URLImage = ({ src, layerId, stepId }) => {
  const dispatch = useDispatch();
  const [imageObj, setImageObj] = useState(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const imageRef = useRef();
  const loadingSrc = require('../../assets/loading.gif');

  useEffect(() => {
    setImageObj(null);
    const image = new window.Image();
    image.src = src;

    image.onload = () => {
      const height = (image.height / image.width) * 1024.0;
      const width = image.width > 1024 ? 1024 : image.width;

      setImageObj(image);
      setDimensions({ width: 1024, height });

      const stage = imageRef.current.getStage();
      stage.width(width);
      stage.height(height);
      stage.batchDraw();
    };

    return () => {
      image.onload = null;
    };
  }, [src]);

  const handleLayerClick = () => {
    dispatch(setActiveLayer({ stepId, layerId }));
  };

  return (
    <Image
      image={imageObj}
      ref={imageRef}
      width={dimensions.width}
      height={dimensions.height}
      onClick={handleLayerClick}
    />
  );
};

export const RenderURLImage = ({ layer }) => {
  const dispatch = useDispatch();

  const setLayerActive = () => {
    dispatch(setActiveLayer({ stepId: layer.stepId, layerId: layer.id }));
  };

  return (
    <URLImage
      src={layer.src}
      x={layer.x}
      y={layer.y}
      layerId={layer.id}
      stepId={layer.stepId}
      onClick={setLayerActive}
    />
  );
};

export default URLImage;
