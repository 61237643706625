import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import Authoring from './components/authoring';
import Toolbar from './components/toolbar';
import { useDispatch } from 'react-redux';
import { fetchCompanyDetails } from "../../../redux/companySlice";
import { API_ENDPOINTS } from "../utils/endpoints";
import { setMaterial } from "../../../redux/materialSlice";
import Loader from "../../organisms/loader/Loader";
import { http_get } from "../utils/api_client";
import { loadSteps, setMainConfig } from "../../../redux/stepsSlice";

const EditPage = () => {
  const dispatch = useDispatch();
  const { companySlug, lmSlug } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const loadMaterial = () => {
    setIsLoading(true);
    http_get(API_ENDPOINTS.GET_PATH(lmSlug))
      .then((res) => {
        const steps = res.data.data;
        dispatch(setMaterial(res.data));
        dispatch(setMainConfig({config: res.data.config, type: 'path'}));
        dispatch(loadSteps(steps));
      })
      .catch((err) => { })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    loadMaterial();
    dispatch(fetchCompanyDetails());
  }, []);

  return (
    <>
      {isLoading && <Loader />}
      {!isLoading && (
        <div>
          <Toolbar companySlug={companySlug} lmSlug={lmSlug} />
          <Authoring />
        </div>
      )}
    </>
  );
}

export default EditPage;