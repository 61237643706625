import React, {useEffect} from 'react';
import StepIndicator from '../../organisms/step-indicator/StepIndicator';
import Screen from '../../organisms/screen/Screen';
import Loader from '../../organisms/loader/Loader';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSteps,
  setIndex,
  getIndex, getConfig,
  getLoading,
} from '../../../redux/walkthroughPreviewSlice';
import FontOption from '../../organisms/editor-design/options/FontOption';
import { getEmbedConfig, getEmbedIndex, getEmbedLoading, getEmbedSteps, setEmbedIndex } from '../../../redux/walkthroughEmbedPreviewSlice';

const StepsViewer = ({embed = false, id}) => {
  const dispatch = useDispatch();
  const steps = useSelector((state) => embed ? getEmbedSteps(state, id) : getSteps(state));
  const index = useSelector((state) => embed ? getEmbedIndex(state, id) : getIndex(state));
  const pageConfig = useSelector((state) => embed ? getEmbedConfig(state, id) : getConfig(state));
  const isLoading = useSelector((state) => embed ? getEmbedLoading(state, id) : getLoading(state));

  const handleStepChange = (index) => {
    if (index === undefined) return;
    dispatch(embed ? setEmbedIndex({data: index, id: id}) : setIndex(index));
  };

  const defaultColors = {
    primary: '#3BA4B4',
    background: '#F6F8F9',
    text: '#363841',
    btnColor: '#3BA4B4',
    btnTextColor: '#FFFFFF',
    config: pageConfig
  };

  const style = {
    backgroundColor: defaultColors.background,
    color: defaultColors.text
  };

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      {!isLoading && (
        <div style={style}>
          <StepIndicator
            index={index}
            steps={steps}
            onChange={(index) => handleStepChange(index)}
            configColors={defaultColors}
          />
          <Screen
            onChange={(index) => handleStepChange(index)}
            configColors={defaultColors}
            steps={steps}
            index={index}
            embed={embed}
            id={id}
          />
          <div hidden>
            <FontOption />
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default StepsViewer;
