import React from "react";

// pass Question and Options if needed
function QnA() {
  return (
    <div className="cover-question-form-block">
      <div className="list-question-form-block">
        <div className="item-list-question-form-block active">
          <div className="title-question-form-block">What is your job status?</div>
          <div className="list-question-form">
            <div className="item-list-question-form">
              <div
                className="cover-radio-question-form correct-ans"
                for="currently-employed"
              >
                <span className="icon-question-form"></span>
                <span className="text-radio-question-form">Currently Employed</span>
              </div>
            </div>
            <div className="item-list-question-form">
              <div className="cover-radio-question-form  wrong-ans" for="Retired">
                <span className="icon-question-form"></span>
                <span className="text-radio-question-form">Retired</span>
              </div>
            </div>
            <div className="item-list-question-form">
              <div className="cover-radio-question-form wrong-ans" for="Other">
                <span className="icon-question-form"></span>
                <span className="text-radio-question-form">Other</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QnA;
