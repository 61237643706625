import React from "react";
import CustomInput from "../CustomInput";
import moment from "moment";

function Details({ profile }) {
  return (
    <div id="tab-1" className="tab-content-avatar-model">
      <div className="header-tab-avatar">
        <div className="item-header-tab-avatar">
          <h3 className="heading-tab-avatar">Details</h3>
        </div>
      </div>
      <div className="list-details-avatar">
        <div className="item-list-details-avatar">
          <div className="label-list-details-avatar">
            <div className="text-list-details-avatar">First Name</div>
          </div>
          <CustomInput value={profile.learner.name} />
        </div>
        <div className="item-list-details-avatar">
          <div className="label-list-details-avatar">
            <div className="text-list-details-avatar">Last Name</div>
          </div>
          <CustomInput value={profile.learner.name} />
        </div>
        <div className="item-list-details-avatar">
          <div className="label-list-details-avatar">
            <div className="text-list-details-avatar">Email</div>
          </div>
          <CustomInput value={profile.learner.email} />
        </div>
        <div className="item-list-details-avatar">
          <div className="label-list-details-avatar">
            <div className="text-list-details-avatar">User ID</div>
          </div>
          <div className="value-list-details-avatar">
            <div className="value-text-details-avatar">{profile.userId}</div>
          </div>
        </div>
        <div className="item-list-details-avatar">
          <div className="label-list-details-avatar">
            <div className="text-list-details-avatar">Learning start</div>
          </div>
          <div className="value-list-details-avatar">
            <div className="value-text-details-avatar">
              {moment(profile.learningStart).format("DD-MM-YYYY")}
            </div>
          </div>
        </div>
        <div className="item-list-details-avatar">
          <div className="label-list-details-avatar">
            <div className="text-list-details-avatar">Total study time</div>
          </div>
          <div className="value-list-details-avatar">
            <div className="value-text-details-avatar">
              {profile.totalStudyTime}
            </div>
          </div>
        </div>
        <div className="item-list-details-avatar">
          <div className="label-list-details-avatar">
            <div className="text-list-details-avatar">Recent activity</div>
          </div>
          <div className="value-list-details-avatar">
            <div className="value-text-details-avatar">
              {moment(profile.recentActivity).format("DD-MM-YYYY, h:mm A")}
            </div>
          </div>
        </div>
        <div className="item-list-details-avatar">
          <div className="label-list-details-avatar">
            <div className="text-list-details-avatar">Learning group</div>
          </div>
          <div className="value-list-details-avatar">
            <div className="flex-avatar-group">
              {profile.learningGroup.map((item, index) => {
                return (
                  <div key={index} className="item-flex-avatar-group">
                    <div
                      className="btn-avatar-group"
                      style={{ textTransform: "capitalize" }}
                    >
                      {item}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Details;
