import React, { useState } from "react";
import { CogIcon, TrashIcon, PlusIcon } from "../../../assets/icons";
import ElementSelectorModal from "./ElementSelectorModal";
import Elements from "../side-toolbar/Elements";
import { useSelector, useDispatch } from 'react-redux';
import { fetchActiveStep, removeLayer, swapLayers, addLayerAfter, removeLayerConfig } from "../../../../../../redux/stepsSlice";
import { getActiveStep, addLayerToActive } from "../../../../../../redux/stepsSlice";
import { useDrag, useDrop } from 'react-dnd';
import { generateRandomId } from "../../../../../pages/utils/tools";
import { selectActiveLayer, setActiveLayer } from "../../../../../../redux/toolsSlice";
import { LargeTextLayerContentDefaults } from "./fields/LargeTextContent";
import { videoLayerDefaults } from "./fields/VideoContent";
import { BodyTextLayerContentDefaults } from "./fields/BodyTextContent";
import { EmbedCodeLayerDefaults } from "./fields/EmbedCodeContent";
import { ImageLayerDefaults } from "./fields/ImageContent";
import { AttachmentLayerDefaults } from "./fields/AttachmentContent.jsx";
import SelectedWrapper from "./SelectedWrapper.jsx";
import { ShortAnswerLayerDefaults } from "./fields/ShortAnswerContent.jsx";
import { LongAnswerLayerDefaults } from "./fields/LongAnswerContent.jsx";
import { CheckboxLayerDefaults } from "./fields/CheckboxContent.jsx";
import { MultiSelectLayerDefaults } from "./fields/MultiSelectContent.jsx";
import { FileUploadLayerDefaults } from "./fields/FileUploadContent.jsx";

const ItemTypes = {
  ELEMENT: 'element',
};

const ElementWrapper = ({ children, layer }) => {

  const [isVisible, setIsVisible] = useState(false);
  const [selectedElement, setSelectedElement] = useState(null);

  const dispatch = useDispatch();

  const activeStep = useSelector((state) => getActiveStep(state));
  const activeLayer = useSelector((state) => selectActiveLayer(state));
  const stepId = activeStep.id;

  const handleAddClick = (layer) => {
    dispatch(setActiveLayer({}));
    dispatch(removeLayerConfig());
    setIsVisible(!isVisible);
  }

  const getLayerDefault = (type) => {
    switch (type) {
      case 'large_text':
        return LargeTextLayerContentDefaults(generateRandomId());
      case 'video':
        return videoLayerDefaults(generateRandomId());
      case 'body':
        return BodyTextLayerContentDefaults(generateRandomId());
      case 'embedded_code':
        return EmbedCodeLayerDefaults(generateRandomId());
      case 'image':
        return ImageLayerDefaults(generateRandomId());
      case 'attachment':
        return AttachmentLayerDefaults(generateRandomId());
      case 'short_answer':
        return ShortAnswerLayerDefaults(generateRandomId());
      case 'long_answer':
        return LongAnswerLayerDefaults(generateRandomId());
      case 'checkbox':
        return CheckboxLayerDefaults(generateRandomId());
      case 'multi_select':
        return MultiSelectLayerDefaults(generateRandomId());
      case 'file_upload':
        return FileUploadLayerDefaults(generateRandomId());
      default:
        return {
          id: generateRandomId(),
          type: selectedElement,
          content: 'Hello world over here',
        }
    }
  }

  const handleInsertElement = () => {
    if (!selectedElement) return;
    dispatch(fetchActiveStep()).then(() => {
      const newLayer = getLayerDefault(selectedElement);
      if (layer.id) {
        dispatch(addLayerAfter({ stepId, afterLayerId: layer.id, newLayer }));
      } else {
        dispatch(addLayerToActive({ layer: newLayer }));
      }
      setIsVisible(false);
    });
  };

  // To delete the selected element
  const handleDeleteClick = () => {
    if (confirm('Are you sure you want to delete the element?')) {
      dispatch(removeLayer({ stepId: activeStep.id, layerId: layer.id }));
      dispatch(setActiveLayer({}));
      dispatch(removeLayerConfig());
    }
  }

  const handleClose = () => setIsVisible(false);

  // Drag source
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.ELEMENT,
    item: { id: layer.id },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  // Drop target
  const [, drop] = useDrop({
    accept: ItemTypes.ELEMENT,
    drop: (item) => {
      dispatch(swapLayers({ stepId: activeStep.id, layerId1: item.id, layerId2: layer.id }));
    },
  });

  const removeFocusElement = (e) => {
    if (e.target?.role === 'element-wrapper') {
      dispatch(setActiveLayer({}));
      dispatch(removeLayerConfig());
    }
  }

  return (
    <div role="element-wrapper" style={{ display: "flex", justifyContent: "flex-start", alignItems: 'center' }} ref={(node) => drag(drop(node))} onClick={removeFocusElement}>
      <div style={{ display: "flex" }}>
        <button type="button" onClick={() => handleDeleteClick()}>
          <TrashIcon />
        </button>
        <button type="button" onClick={() => handleAddClick(layer)}>
          <PlusIcon />
        </button>
        <button type="button" className="mr-2">
          <CogIcon />
        </button>
      </div>
      <SelectedWrapper visibility={activeLayer && activeLayer.id == layer.id}>
        {children}
      </SelectedWrapper>
      {
        isVisible &&
        <ElementSelectorModal
          title="Add Element to Paths"
          show={isVisible}
          onClose={handleClose}
          onInsert={handleInsertElement}
          children={<Elements closeModal={handleClose} afterLayerId={layer.id} setSelectedElement={setSelectedElement} />}
        />
      }
    </div>
  );
}

export default ElementWrapper;
