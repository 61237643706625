import { faker } from "@faker-js/faker";

const range = (len) => {
  const arr = [];
  for (let i = 0; i < len; i++) {
    arr.push(i);
  }
  return arr;
};

const newPerson = () => {
  return {
    learner: {
      name: faker.person.fullName(),
      email: "example@gmail.com",
      imageUrl: faker.image.avatar(),
    },
    userId: "JD" + faker.number.int(100000),
    activityStatus: faker.helpers.shuffle(["engaged", "at risk", "neutral"])[0],
    completionRate: faker.number.int(100),
    totalStudyTime:
      faker.number.int(100) + "h " + faker.number.int(100) + "min",
    learningGroup: faker.helpers
      .shuffle([
        "technology",
        "education",
        "business",
        "marketing",
        "medicine",
        "contracts",
        "professionals",
      ])
      .slice(0, faker.number.int({ min: 2, max: 3 })),
    learningStart: "2024-01-03T07:00:00.000Z",
    uniqueVisits: faker.number.int(100),
    opens: faker.number.int(1000),
    completions: faker.number.int(1000),
    satisfactionScore: faker.number.int(100),
    recentActivity: "2024-01-03T07:00:00.000Z",
    filesUploaded: faker.helpers.multiple(
      function () {
        return {
          fileName: faker.company.name(),
          uploadedAt: faker.date.past(),
          type: faker.helpers.shuffle(["form", "paths", "walkthroughs"])[0],
        };
      },
      {
        count: 20,
      }
    ),
    notes: faker.helpers.multiple(
      function () {
        return {
          content: faker.commerce.productDescription(),
          createdAt: faker.date.past(),
        };
      },
      {
        count: 8,
      }
    ),
    materials: faker.helpers.multiple(
      function () {
        return {
          materialName: faker.commerce.productName(),
          type: faker.helpers.shuffle(["form", "paths", "walkthroughs"])[0],
          rating: {
            number: faker.number.int({ min: 0, max: 5 }),
            type: faker.helpers.shuffle(["completed", "in progress"])[0],
          },
          date: faker.date.past(),
        };
      },
      {
        count: 8,
      }
    ),
    interactions: faker.helpers.multiple(
      function () {
        return {
          materialName: faker.commerce.productName(),
          type: faker.helpers.shuffle(["form", "paths", "walkthroughs"])[0],
          status: faker.helpers.shuffle(["completed", "in progress"])[0],
          studyTime: faker.date.past(),
        };
      },
      {
        count: 9,
      }
    ),
    videos: faker.helpers.multiple(
      function () {
        return {
          materialName: faker.commerce.productName(),
          status: faker.helpers.shuffle(["completed", "in progress"])[0],
          views: faker.number.int({min:2, max: 8}),
        };
      },
      {
        count: 3,
      }
    ),
  };
};

export function makeData(...lens) {
  const makeDataLevel = (depth = 0) => {
    const len = lens[depth];
    return range(len).map((d) => {
      return {
        ...newPerson(),
        subRows: lens[depth + 1] ? makeDataLevel(depth + 1) : undefined,
      };
    });
  };

  return makeDataLevel();
}
