import React, { useEffect, useState } from 'react';
import { SearchIcon } from '../../assets/Icons';

const SearchInput = ({
    name,
    value,
    type,
    placeholder,
    label,
    onChange,
    onValidation,
    className,
    required,
    lineInput,
    error,
    validationCallback,
    options,
}) => {
    const [errorMsg, setErrorMsg] = useState(error);
    const [suggestions, setSuggestions] = useState([]);

    useEffect(() => {
        setErrorMsg(error);
    }, [error]);

    // Combine the class names in a more readable way
    const inputClassName = `${className} ${lineInput ? 'line-input' : ''} ${
        errorMsg ? 'error' : ''
    }`.trim();

    const handleChange = (e) => {
        const { value } = e.target;
        onChange(e); // This might need to be updated depending on your state management
        setErrorMsg('');

        if (value) {
            const filteredOptions = options.filter((option) =>
                option.toLowerCase().includes(value.toLowerCase()),
            );
            setSuggestions(filteredOptions);
        } else {
            setSuggestions([]); // Clear suggestions when input is cleared
        }
    };

    const handleBlur = (e) => {
        if (required && !e.target.value) {
            setErrorMsg('This field is required');
            onValidation(e.target.name, false);
            return;
        }

        // validate with html5 validation
        if (e.target.validity && !e.target.validity.valid) {
            setErrorMsg(e.target.validationMessage);
            onValidation(e.target.name, false);
            return;
        }

        if (validationCallback) {
            const result = validationCallback(e);
            setErrorMsg(result);
            onValidation(e.target.name, false);
            return;
        }

        onValidation(e.target.name, true);
    };

    // Updated to handle option selection
    const handleOptionClick = (option) => {
        onChange({ target: { name, value: option } });
        setErrorMsg('');
        setSuggestions([]);
    };

    const renderOptions = () => {
        return (
            suggestions.length > 0 && (
                <div className="options">
                    {suggestions.slice(0, 5).map((option, index) => (
                        <div
                            key={index}
                            className="option"
                            onClick={() => handleOptionClick(option)}
                        >
                            {option}
                        </div>
                    ))}
                </div>
            )
        );
    };

    return (
        <div
            className={`un-search-input ${errorMsg ? 'has-error' : ''}`.trim()}
        >
            {label && (
                <label htmlFor={name}>
                    {label}
                    {required && <span className="required">*</span>}
                </label>
            )}
            <div className="input-container">
                <input
                    id={name}
                    type={type}
                    className={inputClassName}
                    name={name}
                    placeholder={placeholder}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={value}
                />
                <SearchIcon />
            </div>
            {renderOptions()}
            {errorMsg && <p className="error-message">{errorMsg}</p>}
        </div>
    );
};

SearchInput.defaultProps = {
    onChange: () => {},
    onValidation: () => {},
    className: '',
    placeholder: '',
    label: '',
    name: '',
    type: 'text',
    value: '',
    required: false,
    lineInput: false,
    error: '',
    validationCallback: null,
    options: [],
};

export default SearchInput;
