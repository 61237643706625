import React from "react";
import LabelAndInput from "./form-fields/LabelAndInput";
import LabelAndMultipleChoice from "./form-fields/LabelAndMultipleChoice";
import LabelAndToggle from "./form-fields/LabelAndToggle";

function SingleSelection() {
  return (
    <div className="design-form-path">
      <LabelAndInput title="Question" placeholder="question" />
      <LabelAndMultipleChoice />
      <LabelAndToggle title="Add other option" checked={true} />
      <LabelAndToggle title="Add comment field" checked={false} />
      <LabelAndToggle title="Progress bar" checked={false} />
    </div>
  );
}

export default SingleSelection;
