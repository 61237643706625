import React from "react";
import { Provider } from "react-redux";
import SidenavContent from "./SidenavContent";
import { store } from "../../../redux/store";

const Sidenav = ({ userInfo }) => {

  return (
    <Provider store={store}>
      <SidenavContent userInfo={userInfo} />
    </Provider>
  )
}

export default Sidenav;