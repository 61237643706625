import React, { useState, useEffect } from 'react';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

const LineStyleOption = ({ defaultValue, onChange }) => {
  const [value, setValue] = useState(`${defaultValue} px`);
  useEffect(() => {
    setValue(`${defaultValue}`);
  }, [defaultValue]);

  let options = ['Solid', 'Dashed', 'Dotted'];

  const handleChange = (option) => {
    setValue(option.label);
    onChange(option.value);
  };

  return (
    <Dropdown
      options={options}
      onChange={handleChange}
      value={value}
      placeholder="Select an option"
      className="un-dropdown-option"
    />
  );
};

export default LineStyleOption;
