import React, { useState } from 'react';
import Tabs from '../tabs/Tabs';
import EditorLibrary from '../editor-library/EditorLibrary';
import EditorDesign from '../editor-design/EditorDesign';

const EditorOptions = () => {
  const tabs = ['Library', 'Design'];
  const [activeTab, setActiveTab] = useState();

  const handleSetTab = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="un-editor-options">
      <Tabs tabs={tabs} onChange={handleSetTab} />
      {activeTab === 'Library' && <EditorLibrary />}
      {activeTab === 'Design' && <EditorDesign />}
    </div>
  );
};

export default EditorOptions;
