import React from 'react'
import { useSelector } from 'react-redux';
import HubLayout from '../HubLayout'
import { getConfig } from "../../../../redux/walkthroughPreviewSlice";
import { getValue } from "../../utils/tools";
import PreviewPath from './PreviewPath';

const PathContainer = ({ id, children, audience }) => {
  const pageConfig = useSelector((state) => getConfig(state));

  const configColors = {
    primary: '#3BA4B4',
    background: '#F6F8F9',
    text: '#363841',
    btnColor: '#3BA4B4',
    btnTextColor: '#FFFFFF',
    config: pageConfig
  };

  const tnBGColor = getValue(configColors, 'tn', 'tnBGColor');
  const tnTextColor = getValue(configColors, 'tn', 'tnTextColor');

  const style = {
    backgroundColor: tnBGColor || configColors.background,
    color: tnTextColor || configColors.text
  };

  return (
    <HubLayout headerStyle={style} hideHeader={true} props={audience}>
      <PreviewPath id={id} />
    </HubLayout>
  )
}

export default PathContainer