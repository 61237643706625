import React, { useEffect, useState } from 'react';
import TextInput from '../../../atoms/text-input/TextInput';
import Button from '../../../atoms/button/Button';
import { ArrowRight } from '../../../assets/Icons';
import axios from 'axios';
import { API_ENDPOINTS, CONSTS } from '../../utils/endpoints';
import { useDispatch, useSelector } from 'react-redux';
import {
    getOnboardingData,
    setOnboardingData
} from '../../../../redux/onboardingSlice';
import SearchInput from '../../../atoms/search-input/SearchInput';
import INDUSTRY_LIST from '../../../../constants/industry_list';
import { http_put } from '../../utils/api_client';

export const IndustryFormTitle = () => {
    const data = useSelector((state) => getOnboardingData(state));

    return (
        <div className="header">
            <p className="small">Nice to meet you {data.firstName}!</p>
            <h1 className="title">
                What industry do you work in and your role?
            </h1>
        </div>
    );
};

const IndustryForm = ({ onChangeStep }) => {
    const dispatch = useDispatch();
    const data = useSelector((state) => getOnboardingData(state));
    const [isLoading, setIsLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);

    const saveData = () => {
        setIsLoading(true);
        http_put(API_ENDPOINTS.PUT_USER_BIO, {
            bio: {
                industry_name: data.industryName,
                industry_role: data.industryRole
            }
        })
            .then((res) => {
                onChangeStep();
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        dispatch(setOnboardingData({ [name]: value }));
    };

    useEffect(() => {
        if (data.industryName && data.industryRole) {
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
        }
    }, [data]);

    return (
        <>
            <SearchInput
                label={'Industry'}
                name="industryName"
                placeholder="Type of search"
                onChange={handleChange}
                value={data.industryName}
                options={INDUSTRY_LIST}
                lineInput
                required
            />
            <TextInput
                label="Role"
                name="industryRole"
                placeholder="Enter role"
                onChange={handleChange}
                value={data.industryRole}
                lineInput
                required
            />
            <Button
                className="lg"
                disabled={isDisabled}
                isLoading={isLoading}
                onClick={saveData}
            >
                Next
                <ArrowRight />
            </Button>
        </>
    );
};

export default IndustryForm;
