import React, { useEffect, useState } from "react";
import SearchInput from "../SearchInput";
import PdfFile from "../../assets/images/pdf-file.png";
import moment from "moment";
import MultiCombobox from "../MultiCombobox";

const options = [
  {
    value: "form",
    label: "Form",
  },
  {
    value: "paths",
    label: "Paths",
  },
  {
    value: "walkthroughs",
    label: "Walkthroughs",
  },
];

function FilesUploaded({ profile }) {
  const [files, setFiles] = useState(profile.filesUploaded);

  const [value, setValue] = useState("");

  useEffect(() => {
    if (value === "") {
      setFiles(profile.filesUploaded);
    }
    const newFiles = [...profile.filesUploaded].filter(
      (e) => e.fileName.includes(value) || e.type.includes(value)
    );
    setFiles(newFiles);
  }, [value, files, profile.filesUploaded]);

  return (
    <div id="tab-3" className="tab-content-avatar-model">
      <div className="header-tab-avatar mb-8">
        <div className="item-header-tab-avatar">
          <h3 className="heading-tab-avatar">Files Uploaded</h3>
        </div>
        <div className="item-header-tab-avatar">
          <div className="text-accordion-header-avatar">
            Total {profile.filesUploaded.length} fields
          </div>
        </div>
      </div>
      <div className="avatar-model-table">
        {/* <!-- Avtar Model Toolbar --> */}
        <div className="avatar-model-toolbar">
          <SearchInput value={value} setValue={setValue} />
          <MultiCombobox placeholder="Modified" options={options} filters={[]}/>
        </div>
        {/* <!-- End Avtar Model Toolbar --> */}
        <div className="list-card-avatar">
          {files.map((fileUploaded, index) => {
            return (
              <div key={index} className="item-card-avatar">
                <div className="card-avatar-model">
                  <div className="left-card-avatar-model">
                    <div className="file-card-avatar-model">
                      <img src={PdfFile} alt="" />
                    </div>
                    <div className="content-card-avatar-model">
                      <h3 className="title-card-avatar-model">
                        {fileUploaded.fileName}
                      </h3>
                      <div className="text-card-avatar-model">
                        {moment(fileUploaded.uploadedAt).fromNow()} at{" "}
                        {moment(fileUploaded).format("h:mm A")} UTC
                      </div>
                    </div>
                  </div>
                  <div className="right-card-avatar-model">
                    <a className="link-card-avatar-model" href="#">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M1.83938 0.480238C2.52445 0.113854 3.19831 0 4.60923 0H6.29212C6.73891 0 7.10111 0.362196 7.10111 0.808988C7.10111 1.25578 6.73891 1.61798 6.29212 1.61798H4.60923C3.2729 1.61798 2.92779 1.73298 2.60241 1.90699C2.30053 2.06844 2.06844 2.30053 1.90699 2.60241C1.73298 2.92779 1.61798 3.2729 1.61798 4.60923V11.3908C1.61798 12.7271 1.73298 13.0722 1.90699 13.3976C2.06844 13.6995 2.30053 13.9315 2.60241 14.093C2.92779 14.267 3.2729 14.382 4.60923 14.382H11.3908C12.7271 14.382 13.0722 14.267 13.3976 14.093C13.6995 13.9315 13.9315 13.6995 14.093 13.3976C14.267 13.0722 14.382 12.7271 14.382 11.3908V9.70785C14.382 9.26105 14.7442 8.89886 15.191 8.89886C15.6378 8.89886 16 9.26105 16 9.70785V11.3908C16 12.8017 15.8861 13.4755 15.5197 14.1606C15.2075 14.7444 14.7444 15.2075 14.1606 15.5197C13.4755 15.8861 12.8017 16 11.3908 16H4.60923C3.19831 16 2.52445 15.8861 1.83938 15.5197C1.25553 15.2075 0.792483 14.7444 0.480238 14.1606C0.113854 13.4755 0 12.8017 0 11.3908V4.60923C0 3.19831 0.113854 2.52445 0.480238 1.83938C0.792483 1.25553 1.25553 0.792483 1.83938 0.480238Z"
                          fill="currentColor"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M9.79774 0.808988C9.79774 0.362196 10.1599 0 10.6067 0H15.191C15.6378 0 16 0.362196 16 0.808988V5.39325C16 5.84004 15.6378 6.20224 15.191 6.20224C14.7442 6.20224 14.382 5.84004 14.382 5.39325V2.76206L8.48217 8.66192C8.16624 8.97785 7.65401 8.97785 7.33808 8.66192C7.02215 8.34599 7.02215 7.83377 7.33808 7.51784L13.2379 1.61798H10.6067C10.1599 1.61798 9.79774 1.25578 9.79774 0.808988Z"
                          fill="currentColor"
                        />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default FilesUploaded;
