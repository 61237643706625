import React, { useState, useEffect, useRef } from 'react';
import { TextIcon } from '../../assets/Icons';
import { Text, Group, Transformer } from 'react-konva';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  setActiveTool,
  selectActiveTool,
  setActiveLayer,
  selectActiveLayer,
  removeActiveLayer
} from '../../../redux/toolsSlice';
import {
  updateLayer,
  removeLayerConfig,
  setLayerConfig,
  getLayerObjConfig,
  removeLayer
} from '../../../redux/stepsSlice';
import { Html } from 'react-konva-utils';
import FontOption from '../editor-design/options/FontOption';

const TextTool = () => {
  const dispatch = useDispatch();
  const activeTool = useSelector((state) => selectActiveTool(state));
  const [className, setClassName] = useState('tool');

  const setActive = () => {
    dispatch(setActiveTool('text'));
  };

  useEffect(() => {
    if (activeTool === 'text') {
      setClassName('tool active');
    } else {
      setClassName('tool');
    }
  }, [activeTool]);

  return (
    <div
      className={className}
      title="Text"
      data-tooltip-id="tools-tooltip"
      data-tooltip-content="Text"
      onClick={setActive}
    >
      <TextIcon />
    </div>
  );
};

export const textLayerDefaults = (id, mouseDown) => {
  return {
    id: id,
    type: 'text',
    x: mouseDown.x,
    y: mouseDown.y,
    text: 'Text',
    fill: 'black',
    fontSize: 14,
    rotation: 0,
    fontStyle: 'NORMAL',
    // fontFamily: 'Open Sans',
    align: 'left',
    verticalAlign: 'middle'
  };
};

export const RenderPreviewText = ({ stepId, layer }) => {
  const [draw, setDraw] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setDraw(true);
    }, 1000);
  }, []);
  return (
    <>
      {draw &&
        <Text
          x={layer.x}
          y={layer.y}
          height={layer.height}
          width={layer.width}
          text={layer.text}
          fontSize={layer.fontSize}
          fill={layer.fill}
          rotation={layer.rotation}
          stroke={layer.stroke}
          dashEnabled={true}
          fontStyle={layer.fontStyle}
          fontFamily={layer.font}
          align={layer.justifyHorizontal}
          verticalAlign={layer.verticalAlign}
        />
      }
      <Html>
        <div hidden>
          <FontOption defaultValue={layer.font} />
        </div>
      </Html>
    </>
  );
};

export const RenderText = ({ stepId, layer }) => {
  const shapeRef = useRef();
  const transformerRef = useRef();
  const textRef = useRef(null);

  const dispatch = useDispatch();

  const [isEditing, setIsEditing] = useState(false);

  const activeLayer = useSelector(
    (state) => selectActiveLayer(state),
    shallowEqual
  );

  const isSelected =
    activeLayer &&
    activeLayer.layerId === layer.id &&
    activeLayer.stepId === stepId;

  const fontStyleOption = ['NORMAL', 'ITALIC', 'BOLD', 'BOLD ITALIC'];
  const justifyHorizontalOption = ['left', 'center', 'right'];
  const justifyVerticalOption = ['top', 'middle', 'bottom'];

  const defaultConfig = [
    {
      layerId: layer.id,
      sectionId: 'text',
      sectionTitle: 'Element > Text',
      values: [
        {
          id: 'font',
          title: 'Font',
          type: 'font',
          value: layer.font || 'Open Sans'
        },
        {
          id: 'fill',
          title: 'Font Color',
          type: 'color',
          value: layer.fill || '#ffffff'
        },
        {
          id: 'fontSize',
          title: 'Font Size',
          type: 'px-size',
          value: layer.fontSize || 14
        },
        {
          id: 'fontStyle',
          title: 'Font Style',
          type: 'fontStyle',
          value: layer.fontStyle || 'NORMAL',
          options: fontStyleOption
        },
        {
          id: 'justifyHorizontal',
          title: 'Justify(H)',
          type: 'justifyHorizontal',
          value: layer.justifyHorizontal || 'left',
          options: justifyHorizontalOption
        },
        {
          id: 'justifyVertical',
          title: 'Justify(V)',
          type: 'justifyVertical',
          value: layer.justifyVertical || 'middle',
          options: justifyVerticalOption
        }
      ]
    }
  ];

  const layerObjConfig = useSelector(
    (state) => getLayerObjConfig(state, layer),
    shallowEqual
  );

  useEffect(() => {
    if (layerObjConfig) {
      const newLayer = {
        ...layer,
        ...layerObjConfig
      };
      dispatch(updateLayer({ stepId: stepId, layer: newLayer }));
    }
  }, [layerObjConfig]);

  const handleKeyDown = (e) => {
    if (e.key === 'Delete' && isSelected && confirm('Are you sure you want to delete the element?')) {
      dispatch(removeActiveLayer());
      dispatch(removeLayerConfig());
      dispatch(removeLayer({ stepId: stepId, layerId: layer.id }));
    }
  };

  useEffect(() => {
    if (isSelected) {
      transformerRef.current.nodes([shapeRef.current]);
      transformerRef.current.getLayer().batchDraw();
      window.addEventListener('keydown', handleKeyDown);
      return () => {
        window.removeEventListener('keydown', handleKeyDown);
      };
    } else {
      dispatch(removeLayerConfig());
    }
  }, [activeLayer]);

  const handleLayerTransform = (e) => {
    const node = shapeRef.current;
    const scaleX = node.scaleX();
    const scaleY = node.scaleY();

    // we will reset it back
    node.scaleX(1);
    node.scaleY(1);

    const newLayer = {
      ...layer,
      x: node.x(),
      y: node.y(),
      rotation: node.rotation(),
      // set minimal value
      width: Math.max(5, node.width() * scaleX),
      height: Math.max(node.height() * scaleY),
      fontSize: layer.fontSize, // Update font size based on the scaleY
    }
    dispatch(updateLayer({ stepId: stepId, layer: newLayer }));
  };

  const handleLayerUpdate = (e) => {
    const target = e.target;
    const layerX = target.x();
    const layerY = target.y();

    const newLayer = {
      ...layer,
      x: layerX,
      y: layerY
    };

    dispatch(updateLayer({ stepId: stepId, layer: newLayer }));
  };

  const setActive = () => {
    dispatch(setActiveLayer({ stepId: stepId, layerId: layer.id }));
    dispatch(setLayerConfig(defaultConfig));
  };

  const enableEdit = () => {
    setActive();
    setIsEditing(true);
  };

  const handleEditBlur = (e) => {
    setIsEditing(false);
    updateText();
    if (e.target === shapeRef.current) {
      setIsEditing(false);
      updateText();
    }
  };

  const updateText = () => {
    const newLayer = {
      ...layer,
      text: textRef.current ? textRef.current.value : layer.text
    };
    dispatch(updateLayer({ stepId: stepId, layer: newLayer }));
  };

  const onPressEnter = (e) => {
    if (e.key === 'Enter' || e.key === "Escape") {
      setIsEditing(false);
      updateText();
    }
  };

  const [draw, setDraw] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setDraw(true);
    }, 1000);
  }, []);

  return (
    <>
      {isEditing ? (
        <Group
          x={layer.x}
          y={layer.y}
          height={layer.height}
          width={layer.width}
        >
          <Html
            divProps={{
              style: {
                position: 'absolute',
                top: 10,
                left: 10,
                width: layer.width,
                height: layer.height,
              }
            }}
          >
            <input
              ref={textRef}
              defaultValue={layer.text}
              onBlur={handleEditBlur}
              onKeyDown={onPressEnter}
              style={{
                fontSize: layer.fontSize,
                color: layer.fill,
                border: 'none',
                padding: 0,
                margin: 0,
                background: 'none',
                outline: 'none',
                fontStyle: layer.fontStyle,
                fontFamily: layer.font,
                textAlign: layer.justifyHorizontal,
                verticalAlign: layer.verticalAlign,
                width: '100%',
                height: '100%'
              }}
            />
          </Html>
        </Group>
      ) : (
        <>
          {draw &&
            <Text
              ref={shapeRef}
              x={layer.x}
              y={layer.y}
              height={layer.height}
              width={layer.width}
              text={layer.text}
              fontSize={layer.fontSize}
              fill={layer.fill}
              onDblClick={enableEdit}
              onClick={setActive}
              fontStyle={layer.fontStyle}
              draggable
              onDragEnd={handleLayerUpdate}
              onTransformEnd={handleLayerTransform}
              rotation={layer.rotation}
              stroke={layer.stroke}
              fontFamily={layer.font}
              align={layer.justifyHorizontal}
              verticalAlign={layer.justifyVertical}
            />
          }
          {isSelected && (
            <Transformer
              ref={transformerRef}
              enabledAnchors={['top-left', 'top-right', 'bottom-left', 'bottom-right']}
              keepRatio={false}
              flipEnabled={false}
              borderDash={[6, 2]}
              resizeEnabled={true}
              boundBoxFunc={(oldBox, newBox) => {
                // limit resize
                if (Math.abs(newBox.width) < 5 || Math.abs(newBox.height) < 5) {
                  return oldBox;
                }
                return newBox;
              }}
            />
          )}
        </>
      )}
      <Html>
        <div hidden>
          <FontOption defaultValue={layer.font} />
        </div>
      </Html>
    </>
  );
};

export default TextTool;
