import React from 'react';
import { EyeIcon } from '../../assets/Icons';
import { useSelector } from 'react-redux';
import { getMaterial } from '../../../redux/materialSlice';
import { getCompany } from '../../../redux/companySlice';
import { ENDPOINTS } from '../../pages/utils/endpoints';

const Preview = () => {
    const material = useSelector((state) => getMaterial(state));
    const company = useSelector((state) => getCompany(state));

    const showPreview = () => {
        window.location.href = ENDPOINTS.VIEW_WALKTHROUGH(company.slug, material.slug);
    };

    return (
        <div className="un-action" onClick={showPreview}>
            <EyeIcon />
        </div>
    );
};

export default Preview;
