import React, { useEffect, useState } from "react";
import { getActiveStep, selectActiveLayer, setActiveLayer } from "../../../../../../../redux/toolsSlice";
import { getLayerObjConfig, removeLayerConfig, setLayerConfig, updateLayer } from '../../../../../../../redux/stepsSlice';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { UploadIcon } from "../../../../../../assets/Icons";
import axios from 'axios';
import Loader from "../../../../../../organisms/loader/Loader";

export const AttachmentLayerDefaults = (id) => {
  return {
    id: id,
    type: 'attachment',
    source: '',
    fileName: '',
    fileType: '',
  };
}

const validateFileType = (fileType) => {
  const allowedTypes = [
    'application/pdf',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'text/csv',
  ];
  return allowedTypes.includes(fileType);
};

const validateFileSize = (fileSize) => {
  const fileSizeLimit = 15 * 1024 * 1024; // 15 MB
  return fileSize <= fileSizeLimit;
};


function AttachmentContent({ layer }) {

  const [dragging, setDragging] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const layerObjConfig = useSelector((state) => getLayerObjConfig(state, layer), shallowEqual);
  const activeStep = useSelector((state) => getActiveStep(state));
  const stepId = activeStep.id;
  const activeLayer = useSelector((state) => selectActiveLayer(state));

  const defaultConfig = [
    {
      layerId: layer.id,
      sectionId: 'attachment',
      sectionTitle: 'Attachment Element',
      values: []
    }
  ];

  const editElement = () => {
    removeFocusElement();
    dispatch(setActiveLayer(layer));
    dispatch(setLayerConfig(defaultConfig));
  }

  const removeFocusElement = () => {
    dispatch(setActiveLayer({}));
    dispatch(removeLayerConfig());
  }

  useEffect(() => {
    const newLayer = {
      ...layer,
      ...layerObjConfig
    };
    dispatch(updateLayer({ stepId: stepId, layer: newLayer }));

  }, layerObjConfig);

  const uploadFiles = async (file) => {
    setLoading(true);
    const formData = new FormData();
    formData.append('file', file);
    const url = '/api/v1/upload_files';
    const response = await axios
      .post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .finally(() => {
        setLoading(false);
      });
    return response.data;
  };

  const handleFileChange = (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    handleFile(file);
  }

  const handleDragOver = () => {
    setDragging(true);
  }

  const handleDrop = (event) => {
    event.preventDefault();
    setDragging(false);
    const file = event.dataTransfer.files[0];
    handleFile(file);
  }

  const handleFile = async (file) => {

    if (!validateFileType(file?.type)) {
      alert('Only DOCX, CSV and PDF files are allowed.');
      return;
    }

    if (!validateFileSize(file.size)) {
      alert('File size exceeds the maximum limit of 15 MB.');
      return;
    }

    const response = await uploadFiles(file);
    if (response) {
      const newLayer = {
        ...layer,
        source: response, //response as an URL
        fileName: file.name,
        fileType: file.type,
      };
      dispatch(updateLayer({ stepId: stepId, layer: newLayer }));
    }
    else (
      alert('Something went wrong.')
    )
  }

  return (
    <>
      <div
        className='video-wrapper'
        onDragOver={handleDragOver}
        onDrop={handleDrop}>
        {loading && <Loader text={'Uploading...'} />}
        {!loading &&
          (
            layer?.source ?
              <AttachmentContentPreview onClick={editElement} layer={layer} />
              :
              <div className="cover-upload-form" tabIndex={0} onClick={editElement}>
                <div className="filed-upload-form icon-filed-upload-form">
                  <header className="text-upload-form">
                    Upload your attachment here for your users to download
                  </header>
                  <label className="btn btn-primary save-btn">
                    <UploadIcon /> Browse File
                    <input
                      type="file"
                      accept=".docx,.csv,.pdf"
                      onChange={handleFileChange}
                      style={{ display: 'none' }}
                    />
                  </label>
                  {dragging && (
                    <div className="dragging-overlay">
                      Drop the attachment here
                    </div>
                  )}
                </div>
              </div>
          )
        }
      </div>
    </>
  )
}

const renderFileIcon = (type) => {
  const iconClassList = {
    'application/pdf': 'fa fa-file-pdf-o',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'fa fa-file-word-o',
    'application/zip': 'fa fa-file-zip-o',
    'text/csv': 'fa fa-file-text',
    'text/plain': 'fa fa-file-text',
  }
  return (
    <div className="mr-2">
      <i className={iconClassList[type] || 'fa fa-file-o'} aria-hidden="true"></i>
    </div>
  )
}

export const AttachmentContentPreview = ({ layer, onClick }) => {

  const handleDownloadFile = () => {
    const downloadLink = document.createElement('a');
    downloadLink.href = layer.source;
    downloadLink.download = layer.fileName;
    downloadLink.click();
  }

  return (
    <div className="horizontal-attachment-card" onClick={onClick}>
      <div className="card-content">
        <div className="row">
          <div className="col-md-10 flex">
            {renderFileIcon(layer.fileType)}
            <p className="file-title">{layer.fileName}</p>
          </div>
          <div className="col-md-2">
            <button
              className='btn btn-default'
              style={{ float: 'right' }}
              onClick={handleDownloadFile}>
              Download
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AttachmentContent;