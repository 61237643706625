import React, { useState } from "react";
import Sidebar from "./sidebar";
import SideToolbar from "./side-toolbar";
import MediaContent from "./content/MediaContent";
import QuizContent from "./content/QuizContent";
import DetailsContent from "./content/DetailsContent";
import VideoContent from "./content/fields/VideoContent";
import Editor from "./Editor";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from 'react-dnd-html5-backend'
import StepSidebar from '../../../../organisms/step-sidebar/StepSidebar';
import { setActiveLayer } from "../../../../../redux/toolsSlice";
import { removeLayerConfig } from "../../../../../redux/stepsSlice";
import { useDispatch } from "react-redux";

const Authoring = () => {
  const dispatch = useDispatch();
  
  const removeFocusElement = (e) => {
    if(e.target?.role === 'element-area'){
      dispatch(setActiveLayer({}));
      dispatch(removeLayerConfig());
    }
  }

  // add overflow hidden to the body if needed and maxheight to the following div
  return (
    <div
      className="main-content-path-authoring"
      style={{ maxHeight: "calc(100vh - 6px - 67px)" }}
    >
      <DndProvider backend={HTML5Backend}>
        <StepSidebar />
        <div role="element-area" className="contant-path-authoring scroll-block" onClick={removeFocusElement}>
          <Editor />
        </div>
        <SideToolbar />
      </DndProvider>
    </div>
  );
}

export default Authoring;
