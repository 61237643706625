import React from "react";
import LabelAndToggle from "./form-fields/LabelAndToggle";

function ActionsForm() {
  return (
    <div className="design-form-path">
      <LabelAndToggle title="Complete Button" checked={true} />
      <LabelAndToggle title="Reassign Button" checked={true} />
      <LabelAndToggle title="Comment Button" checked={true} />
      <LabelAndToggle title="Next Button" checked={true} />
    </div>
  );
}

export default ActionsForm;
