import React from "react";

function LabelAndToggle({ title, checked, setChecked }) {
  return (
    <div className="item-design-form-path">
      <div className="flex-design-form">
        <div className="label-design-form">
          <div className="size-12">{title}</div>
        </div>
        <div className="value-design-form">
          <div className="cover-input-switch-design">
            <label className="input-switch-design">
              <input
                className="input-checkbox-design"
                type="checkbox"
                checked={checked}
                onChange={(e) => setChecked(e.target.checked)}
              />
              <span className="input-slider-design round"></span>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LabelAndToggle;
