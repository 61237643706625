import * as XLSX from "xlsx";
import { Parser } from "@json2csv/plainjs";
import jsPDF from "jspdf";

export const exportToExcel = ({ excelData, fileName }) => {
  const worksheet = XLSX.utils.json_to_sheet(excelData);
  const workbook = XLSX.utils.book_new();

  XLSX.utils.book_append_sheet(workbook, worksheet, "data");
  XLSX.writeFile(workbook, `${fileName}.xlsx`);
};

export const exportToCsv = ({ excelData, fileName }) => {
  const parser = new Parser();
  const csv = parser.parse(excelData);
  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

  const link = document.createElement("a");
  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", `${fileName}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } else {
    console.error("Your browser does not support the download attribute.");
  }
};

export const exportToPdf = ({ excelData, fileName }) => {
  // Create a new jsPDF instance
  const doc = new jsPDF();

  excelData.forEach((profile, profileIndex) => {
    const titleArray = Object.keys(profile);
    const contentArray = titleArray.map((key) => profile[key]);

    // Add title and content to the PDF
    titleArray.forEach((titleText, index) => {
      const content =
        contentArray[index] !== undefined ? contentArray[index].toString() : "";

      const yPosition = 10 + index * 10; // Adjust the spacing between lines
      doc.text(titleText ?? "", 10, yPosition + index * 2); // Title
      doc.text(content ?? "", 100, yPosition + index * 2); // Content
    });

    if (profileIndex < excelData.length - 1) {
      doc.addPage();
    }
  });

  // Save the PDF with the specified filename
  doc.save(`${fileName}.pdf`);
};
