import React, {useState} from "react";
import ModalWrapper from ".././modal-wrapper/ModalWrapper";
import {AngryFace, CongratsScore, ExcitedFace, HappyFace, NeutralFace, SadFace} from "./Icons";
import Button from "../../atoms/button/Button";

const EmojiWrapper = ({children, isActive, value, onChange}) => {
  const classes = ['un-satisfaction-score__emoji']
  if (isActive) {
    classes.push('active');
  }
  const className = classes.join(' ');

  const setEmoji = () => {
    onChange(value);
  }

  return (
    <div className={className} onClick={setEmoji}>
      {children}
    </div>
  )
}

const SatisfactionScore = ({ onCloseModal, onSubmitScore }) => {
  const [activeEmoji, setActiveEmoji] = useState(null);
  const emojis = [
    {emoji: <AngryFace/>, value: 'angry'},
    {emoji: <SadFace/>, value: 'sad'},
    {emoji: <NeutralFace/>, value: 'neutral'},
    {emoji: <HappyFace/>, value: 'happy'},
    {emoji: <ExcitedFace/>, value: 'excited'}];

  const renderEmojis = () => {
    return emojis.map((emoji, index) => {
      return (
        <EmojiWrapper key={index} isActive={activeEmoji === emoji.value} value={emoji.value} onChange={setActiveEmoji}>
          {emoji.emoji}
        </EmojiWrapper>
      )
    });
  }

  const handleSubmitScore = () => {
    onSubmitScore(activeEmoji);
  }

  const handleCloseModal = () => {
    onCloseModal();
  }

  const footer = (
    <div className='un-satisfaction-score__bottom'>
      <Button onClick={handleSubmitScore} disabled={!activeEmoji}>Complete</Button>
    </div>
  )

  return (
    <ModalWrapper modalId={'satisfactoryScore'} onCloseModal={handleCloseModal} footer={footer} size={'lg medium'}>
      <div className="un-satisfaction-score">
        <div className='un-satisfaction-score__content'>
          <CongratsScore/>
          <p>
            <b className='title'>Great!</b>
            <br/>
            <b>You have completed this walkthrough</b>
            <br/>
            How much was this information useful?
          </p>
        </div>
        <div className='un-satisfaction-score__emojis'>
          {renderEmojis()}
        </div>
      </div>
    </ModalWrapper>
)
}

export default SatisfactionScore;