import React from 'react';

// get value from configColors
export const getValue = (configColors, sectionId, titleId) => {
  if (!configColors) return configColors.primary;
  if (!configColors.config) return configColors.primary;
  const section = configColors.config.find(
    (section) => section.sectionId === sectionId
  );
  if (section === undefined) return configColors.primary;
  const title = section.values.find((title) => title.id === titleId);
  return title?.value;
};

// get cookies
export const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2)
    return parts
      .pop()
      .split(';')
      .shift();
};

// set cookies
export const setCookie = (name, value, days) => {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000); // 24 hours
    expires = `; expires=${date.toGMTString()}`;
  }
  document.cookie = `${name}=${value}${expires}; path=/;SameSite=none;`;
};

// delete cookies
export const deleteCookie = (name) => {
  document.cookie = `${name}=; Max-Age=-99999999;`;
};

// Render escaped string
export const RenderEscapedString = (text) => {
  if (!text) return null;
  const tabReplacedText = text.replace(/\t/g, '    ');
  const newText = tabReplacedText.split('\n').map((item, index, array) => (
    <React.Fragment key={index}>
      {item}
      {index !== array.length - 1 && <br />}
    </React.Fragment>
  ));

  return <>{newText}</>;
}

export const generateRandomId = () => {
  return (
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15)
  );
};