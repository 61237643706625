import React, { useEffect, useState } from "react";
import Accordion from "./Accordion";
import ContentForm from "./design/ContentForm";
import SettingsForm from "./design/SettingsForm";
import SingleSelection from "./design/SingleSelection";
import ActionsForm from "./design/ActionsForm";
import TextForm from "./design/TextForm";
import { useDispatch, useSelector } from "react-redux";
import { getLayerConfig, getMainConfig, updateLayerConfig, updateMainConfig } from "../../../../../../redux/stepsSlice";
import DesignOption from "../../../../../organisms/editor-design/DesignOption";

const Design = () => {
  const dispatch = useDispatch();
  const mainConfig = useSelector((state) => getMainConfig(state));
  const layerConfig = useSelector((state) => getLayerConfig(state));
  const [configs, setConfigs] = useState(
    layerConfig.length > 0 ? layerConfig : mainConfig
  );

  const updateConfig = (sectionId, titleId, newValue) => {
    const data = {
      sectionId: sectionId,
      titleId: titleId,
      value: newValue
    };
    if (layerConfig.length > 0) {
      dispatch(updateLayerConfig(data));
    } else {
      dispatch(updateMainConfig(data));
    }
  };

  useEffect(() => {
    if (layerConfig.length > 0) {
      setConfigs(layerConfig);
    } else {
      setConfigs(mainConfig);
    }
  }, [layerConfig, mainConfig]);

  const renderDesignOptions = (sectionId, values) => {
    return values.map((value) => (
      <DesignOption
        key={value.id}
        text={value.title}
        optionType={value.type}
        value={value.value}
        options={value.options}
        onChange={(newValue) => {
          updateConfig(sectionId, value.id, newValue);
        }}
      />
    ));
  };

  const renderConfig = () => {
    return configs.map((config, index) => (
      <Accordion isInitActive className="un-accordion" title={config.sectionTitle} key={index}>
        {renderDesignOptions(config.sectionId, config.values)}
      </Accordion>
    ));
  };

  return (
    // <div id="component-3" className="tab-content pane-switch-component-parts 123" style={{ maxHeight: "calc(100vh - 50px)", overflowY: 'auto'}}>
      <div className="un-editor-design">
        {renderConfig()}
      </div>
    // </div>
  );
}

export default Design;
