import React, { useState, useEffect } from 'react';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

export default function FontStyleOption({ defaultValue, onChange, options }) {
  const [value, setValue] = useState(`${defaultValue} px`);
  useEffect(() => {
    setValue(`${defaultValue}`);
  }, [defaultValue]);


  const handleChange = (option) => {
    setValue(option.label);
    onChange(option.value);
  };

  return (
    <>
      <div>
        <Dropdown
          options={options}
          onChange={handleChange}
          value={value}
          placeholder="Select an option"
          className="un-dropdown-option"
        />
      </div>
    </>
  );
}
