import React, { useEffect } from 'react';
import StepsViewer from './StepsViewer';
import {
  setSteps,
  setConfig,
  setLoading
} from '../../../redux/walkthroughPreviewSlice';
import { API_ENDPOINTS } from '../utils/endpoints';
import { useDispatch } from 'react-redux';
import { http_get } from '../../pages/utils/api_client';

const EditorPreview = ({ id }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setLoading(true));
    const url = `${API_ENDPOINTS.GET_LEARNING_MATERIALS}/${id}`;

    http_get(url)
      .then((response) => {
        const config = response.data.config;
        const steps = response.data.data;
        dispatch(setSteps(steps));
        dispatch(setConfig(config));
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  }, []);

  return (
    <>
      <StepsViewer />
    </>
  );
};

export default EditorPreview;
