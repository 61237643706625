import React, { Fragment, cloneElement, useState } from "react";

function Modal({ trigger, children }) {
  const [isActive, setIsActive] = useState(false);

  return (
    <>
      <div
        style={{ cursor: "pointer" }}
        onClick={() => {
          setIsActive(true);
        }}
      >
        {trigger}
      </div>
      {isActive && cloneElement(children, { isActive, setIsActive })}
    </>
  );
}

export default Modal;
