const baseUrl = '/api/v1';
export const API_ENDPOINTS = {
  REGISTER: `${baseUrl}/registration`,
  RESEND_VERIFICATION: `${baseUrl}/registration/send_confirmation_email`,
  LOGIN: `/sign_in`,
  PUT_PROFILE: `${baseUrl}/profile`,
  GET_PROFILE: `${baseUrl}/profile`,
  PUT_PROFILE_ACTIVATE: `${baseUrl}/profile/activate`,
  DELETE_PROFILE_AVATAR: `${baseUrl}/profile/remove_avatar`,
  PUT_USER_BIO: `${baseUrl}/profile/bio`,
  GET_COMPANY: `${baseUrl}/company`,
  PUT_COMPANY: `${baseUrl}/company`,
  POST_UPLOAD_FILE: `${baseUrl}/uploads`,
  POST_INVITE_TEAM: `${baseUrl}/team/invite`,
  GET_LEARNING_MATERIALS: `${baseUrl}/learning_materials`,
  GET_LEARNING_MATERIALS_COUNT: `${baseUrl}/learning_materials/count`,
  LEARNING_MATERIAL: (id) => `${baseUrl}/learning_materials/${id}`,
  PUT_LEARNING_MATERIAL_VISIBILITY: (id) =>
    `${baseUrl}/learning_materials/${id}/visibility`,
  UPLOAD_LEARNING_MATERIAL: (id) => `${baseUrl}/uploads/learning_materials/${id}`,
  GET_WALKTHROUGH: (id) => `${baseUrl}/walkthroughs/${id}`,
  SEARCH_WALKTHROUGH: `${baseUrl}/walkthroughs/search`,
  PUBLISHED_DATA_WALKTHROUGH: (id) => `${baseUrl}/walkthroughs/${id}/published_data`,
  PUT_WALKTHROUGH: (id) => `${baseUrl}/walkthroughs/${id}`,
  PUT_WALKTHROUGH_SETTINGS: (id) => `${baseUrl}/walkthroughs/${id}/settings`,
  PUBLISH_WALKTHROUGH: (id) => `${baseUrl}/walkthroughs/${id}/publish`,
  GET_PATH: (id) => `${baseUrl}/paths/${id}`,
  PUT_PATH: (id) => `${baseUrl}/paths/${id}`,
  PUBLISH_PATH: (id) => `${baseUrl}/paths/${id}/publish`,
  POST_DOMAIN: `${baseUrl}/domains`,
  GET_DOMAINS: `${baseUrl}/domains`,
  GET_DOMAINS_SIGN_PAGE_DETAILS: (id) => `${baseUrl}/domains/${id}/sign_in_page_design`,
  PUT_DOMAINS_SIGN_PAGE_DETAILS: (id) => `${baseUrl}/domains/${id}/sign_in_page_design`,
  SEND_MAGIC_LINK: `${baseUrl}/magic_link/generate`
};

const hubBaseUrl = '/api/v1/hub';
export const HUB_ENDPOINTS = {
  GET_MATERIALS: `${hubBaseUrl}/materials`,
  GET_COMPANY_DETAILS: `${hubBaseUrl}/company`,
  GET_MATERIAL: (slug) => `${hubBaseUrl}/materials/${slug}`,
  GET_MATERIAL_SEARCH: `${hubBaseUrl}/search`,
  SAVE_RESPONSE: (id) => `${hubBaseUrl}/save_response/${id}`,
  GET_RESPONSE: (id) => `${hubBaseUrl}/fetch_response/${id}`,
};

export const HUB_VIEW_ENDPOINTS = {
  VIEW_MATERIAL: (company_slug, slug) => `/${company_slug}/hub/${slug}`,
};

export const ENDPOINTS = {
  LOGIN: `/sign_in`,
  USER_SIGNOUT: `/sign_out`,
  VIEW_WALKTHROUGH: (company_slug, slug) => `/${company_slug}/walkthroughs/${slug}`,
  EDIT_WALKTHROUGH: (company_slug, slug) => `/${company_slug}/walkthroughs/${slug}/edit`,
  VIEW_PATH: (company_slug, slug) => `/${company_slug}/paths/${slug}`,
  EDIT_PATH: (company_slug, slug) => `/${company_slug}/paths/${slug}/edit`,
  VIEW_LEARNING_MATERIALS: (slug) => `/${slug}/learning_materials`,
  ONBOARDING: `/onboarding`,
  ROOT: `/`
};

export const INTERNAL_ENDPOINTS = {
  POST_LIBRARY: `${baseUrl}/library`
};

export const CONSTS = {
  AUTH_TOKEN: 'auth_token',
  AUDIENCE_TOKEN: 'aud_token',
  WALKTHROUGH: 'walkthrough',
  VIDEO: 'video',
  PATH: 'path',
  WALKTHROUGHS: 'walkthroughs',
  VIDEOS: 'videos',
  PATHS: 'paths',
  DOCS: 'docs'
};
