import React, { useEffect, useState } from "react";
import { getActiveStep, selectActiveLayer, setActiveLayer } from "../../../../../../../redux/toolsSlice";
import { getLayerObjConfig, removeLayerConfig, setLayerConfig, updateLayer } from '../../../../../../../redux/stepsSlice';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

export const FileUploadLayerDefaults = (id) => {
  return {
    id: id,
    type: 'file_upload',
    source: '',
    fileName: '',
    fileType: '',
  };
}

function FileUploadContent({ layer }) {

  const [dragging, setDragging] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const layerObjConfig = useSelector((state) => getLayerObjConfig(state, layer), shallowEqual);
  const activeStep = useSelector((state) => getActiveStep(state));
  const stepId = activeStep.id;
  const activeLayer = useSelector((state) => selectActiveLayer(state));

  const defaultConfig = [
    {
      layerId: layer.id,
      sectionId: 'file_upload',
      sectionTitle: 'File Upload Element',
      values: []
    }
  ];

  const editElement = () => {
    removeFocusElement();
    dispatch(setActiveLayer(layer));
    dispatch(setLayerConfig(defaultConfig));
  }

  const removeFocusElement = () => {
    dispatch(setActiveLayer({}));
    dispatch(removeLayerConfig());
  }

  useEffect(() => {
    const newLayer = {
      ...layer,
      ...layerObjConfig
    };
    dispatch(updateLayer({ stepId: stepId, layer: newLayer }));

  }, layerObjConfig);

  const handleDragOver = () => {
    setDragging(true);
  }

  const handleDrop = (event) => {
    event.preventDefault();
    setDragging(false);
    const file = event.dataTransfer.files[0];
  }

  return (
    <>
      <FileUploadContentPreview
        onClick={editElement}
        layer={layer}
        dragging={dragging}
        onDragOver={handleDragOver}
        onDrop={handleDrop} />
    </>
  )
}

export const FileUploadContentPreview = ({ layer, onClick, dragging, onDragOver, onDrop }) => {

  return (
    <>
      <div
        className='video-wrapper'
        onDragOver={onDragOver}
        onDrop={onDrop}>
        <div className="cover-upload-form" tabIndex={0} onClick={onClick}>
          <div className="filed-upload-form icon-filed-upload-form">
            <header className="text-upload-form">
              Choose a file <br />Max file size: 15 MB
            </header>
            <label className="btn btn-primary save-btn">
              <i class="fa fa-upload fa-lg" aria-hidden="true"></i> Browse File
              <input
                type="file"
                accept=".docx,.csv,.pdf"
                // onChange={handleFileChange}
                style={{ display: 'none' }}
              />
            </label>
            {dragging && (
              <div className="dragging-overlay">
                Drop the image here
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default FileUploadContent;