import React from "react";
import Settings from "../../../../organisms/actions/Settings";
import { EyeIcon } from "../../../../assets/Icons";
import { getMaterial } from "../../../../../redux/materialSlice";
import { getCompany } from "../../../../../redux/companySlice";
import { useSelector } from "react-redux";
import { ENDPOINTS } from "../../../utils/endpoints";

function Actions() {
  const material = useSelector((state) => getMaterial(state));
  const company = useSelector((state) => getCompany(state));

  const showPreview = () => {
      window.location.href = ENDPOINTS.VIEW_PATH(company.slug, material.slug);
  };

  return (
    <div className="action-sloat-path un-actions">
      {/* <div className="item-action-sloat-path un-action">
        <div className="buttons-action-path">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M12 4C16.4183 4 20 7.58172 20 12C20 16.4183 16.4183 20 12 20C9.84951 20 7.83247 19.1467 6.34222 17.6559C6.08191 17.3955 6.08198 16.9734 6.34237 16.7131C6.60277 16.4528 7.02487 16.4529 7.28518 16.7133C8.52785 17.9563 10.2068 18.6667 12 18.6667C15.6819 18.6667 18.6667 15.6819 18.6667 12C18.6667 8.3181 15.6819 5.33333 12 5.33333C10.1743 5.33333 8.47351 6.07202 7.23819 7.33358L8.33333 7.33333C8.70152 7.33333 9 7.63181 9 8C9 8.34189 8.74264 8.62367 8.41108 8.66218L8.33333 8.66667H5.66667C5.32478 8.66667 5.043 8.40931 5.00449 8.07775L5 8V5.33333C5 4.96514 5.29848 4.66667 5.66667 4.66667C6.00856 4.66667 6.29034 4.92403 6.32885 5.25559L6.33333 5.33333L6.33315 6.3525C7.81117 4.86838 9.83299 4 12 4ZM12 8C12.3419 8 12.6237 8.25736 12.6622 8.58892L12.6667 8.66667V11.7239L14.4714 13.5286C14.7318 13.7889 14.7318 14.2111 14.4714 14.4714C14.2311 14.7117 13.8529 14.7302 13.5914 14.5269L13.5286 14.4714L11.5286 12.4714C11.4244 12.3672 11.3583 12.2321 11.3391 12.0876L11.3333 12V8.66667C11.3333 8.29848 11.6318 8 12 8Z"
              fill="currentColor"
            />
          </svg>
        </div>
      </div> */}
      <Settings />
      <div className="item-action-sloat-path un-action">
        <div className="buttons-action-path">
          <EyeIcon onClick={() => showPreview()} />
        </div>
      </div>
    </div>
  );
}

export default Actions;
