import React, { useState, useEffect } from 'react';
import FontPicker from 'font-picker-react';

// todo: remove api key
const FontOption = ({ defaultValue, onChange }) => {
  const pickerId = Math.random().toString(36).substr(2);
  const [font, setFont] = useState(defaultValue);

  useEffect(() => {
    setFont(defaultValue);
  }, [defaultValue]);

  const handleChange = (nextFont) => {
    setFont(nextFont.family);
    onChange(nextFont.family);
  };

  return (
    <div className="un-font-picker">
      <FontPicker
        apiKey="AIzaSyBg9ju65ESxNb45YatGrG-XNCuSjB9aiIg"
        activeFontFamily={font}
        pickerId={`fontpicker${pickerId}`}
        onChange={handleChange}
      />
    </div>
  );
};

FontOption.defaultProps = {
  defaultValue: 'Open Sans',
  onChange: () => { }
};

export default FontOption;
