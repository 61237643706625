import React, { useEffect, useState } from 'react';
import { ImageIcon } from '../../assets/Icons';
import axios from 'axios';

const EditorLibrary = () => {
  const [images, setImages] = useState([]);

  const loadImages = () => {
    axios
      .get('/api/v1/library')
      .then((response) => {
        setImages(response.data);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    loadImages();
  }, []);

  const handleDragStart = (event, imageId) => {
    event.dataTransfer.setData('imageId', imageId);
  };

  const renderImages = () => {
    return images.map((image) => {
      return (
        <div
          className="image-card"
          draggable="true" // Make the image card draggable
          onDragStart={(event) => handleDragStart(event, image.id)}
          key={image.id}
        >
          <div
            className="image"
            style={{
              backgroundImage: `url(${image.url})`
            }}
          />
          <div className="image-description">
            <ImageIcon />
            <p>{image.filename}</p>
          </div>
        </div>
      );
    });
  };

  return <div className="un-editor-library">{renderImages()}</div>;
};

export default EditorLibrary;
