export const messagesList = [
  {
    name: "Willard Purnell",
    message:
      "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Alias at inventore repudiandae, veritatis vero tempore reiciendis in quo laudantium aperiam molestias perspiciatis? Laborum laboriosam tempore dolor sunt quae illum tempora!",
    isNew: true,
    timestamp: "5m ago",
  },
  {
    name: "John Doe",
    message:
      "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Alias at inventore repudiandae, veritatis vero tempore reiciendis in quo laudantium aperiam molestias perspiciatis? Laborum laboriosam tempore dolor sunt quae illum tempora!",
    isNew: true,
    timestamp: "5m ago",
  },
  {
    name: "Cyndy Lillibridge",
    message:
      "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Alias at inventore repudiandae, veritatis vero tempore reiciendis in quo laudantium aperiam molestias perspiciatis? Laborum laboriosam tempore dolor sunt quae illum tempora!",
    isNew: false,
    timestamp: "10m ago",
  },
  {
    name: "Elanor Pera",
    message:
      "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Alias at inventore repudiandae, veritatis vero tempore reiciendis in quo laudantium aperiam molestias perspiciatis? Laborum laboriosam tempore dolor sunt quae illum tempora!",
    isNew: false,
    timestamp: "15m ago",
  },
  {
    name: "Lauralee Quintero",
    message:
      "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Alias at inventore repudiandae, veritatis vero tempore reiciendis in quo laudantium aperiam molestias perspiciatis? Laborum laboriosam tempore dolor sunt quae illum tempora!",
    isNew: false,
    timestamp: "20m ago",
  },
  {
    name: "Annabel Rohan",
    message:
      "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Alias at inventore repudiandae, veritatis vero tempore reiciendis in quo laudantium aperiam molestias perspiciatis? Laborum laboriosam tempore dolor sunt quae illum tempora!",
    isNew: false,
    timestamp: "23m ago",
  },
  {
    name: "Tanner Stafford",
    message:
      "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Alias at inventore repudiandae, veritatis vero tempore reiciendis in quo laudantium aperiam molestias perspiciatis? Laborum laboriosam tempore dolor sunt quae illum tempora!",
    isNew: false,
    timestamp: "25m ago",
  },
  {
    name: "Florencio Dorrance",
    message:
      "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Alias at inventore repudiandae, veritatis vero tempore reiciendis in quo laudantium aperiam molestias perspiciatis? Laborum laboriosam tempore dolor sunt quae illum tempora!",
    isNew: false,
    timestamp: "32m ago",
  },
];
