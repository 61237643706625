import React, { useState } from "react";
import Button from "../../../../atoms/button/Button";
import { ThunderIcon } from "../../../../assets/Icons";
import { API_ENDPOINTS } from "../../../utils/endpoints";
import { getMaterial } from "../../../../../redux/materialSlice";
import { useSelector } from "react-redux";
import { http_put } from "../../../utils/api_client";

function SaveAndPublish() {
  const [isPublishing, setIsPublishing] = useState(false);
  const material = useSelector((state) => getMaterial(state));

  const handleSave = () => {
    if (material.id === null) return;

    // http_put(API_ENDPOINTS.PUT_PATH(material.slug), { data: material.data, config: material.config })
    //   .then((res) => {
    //     setTimeout(() => { }, 2000);
    //   })
    //   .catch((err) => { });
  };

  const handlePublish = () => {
    setIsPublishing(true);
    http_put(API_ENDPOINTS.PUBLISH_PATH(material.slug))
      .then((res) => { })
      .catch((err) => { })
      .finally(() => {
        setIsPublishing(false);
      });
  };

  return (
    // <div className="action-page-path">
    //   <div className="button-save-action">Save</div>
    //   <div className="button-publish-action">
    //     <span className="icon-button-publish-action">
    //       <svg
    //         xmlns="http://www.w3.org/2000/svg"
    //         width="20"
    //         height="20"
    //         viewBox="0 0 20 20"
    //         fill="none"
    //       >
    //         <path
    //           fillRule="evenodd"
    //           clipRule="evenodd"
    //           d="M11.1653 0.902214C11.5046 1.04952 11.7062 1.40291 11.6603 1.76999L10.9441 7.49997H17.5001C17.8234 7.49997 18.1176 7.68702 18.2547 7.97984C18.3919 8.27266 18.3473 8.61838 18.1403 8.86679L9.80694 18.8668C9.57011 19.151 9.17428 19.245 8.83493 19.0977C8.49558 18.9504 8.29397 18.597 8.33986 18.2299L9.05611 12.5H2.50009C2.17674 12.5 1.88258 12.3129 1.74543 12.0201C1.60828 11.7273 1.6529 11.3815 1.85991 11.1331L10.1932 1.13314C10.4301 0.84895 10.8259 0.754907 11.1653 0.902214ZM4.27929 10.8333H10.0001C10.2391 10.8333 10.4666 10.9359 10.6248 11.1151C10.783 11.2943 10.8566 11.5328 10.827 11.77L10.3462 15.6163L15.7209 9.16663H10.0001C9.76107 9.16663 9.53355 9.06399 9.37536 8.8848C9.21717 8.70561 9.14355 8.46712 9.17319 8.22994L9.65398 4.38368L4.27929 10.8333Z"
    //           fill="currentColor"
    //         />
    //       </svg>
    //     </span>
    //     <span className="text-button-publish-action">Publish</span>
    //   </div>
    // </div>
    <div className="un-btn-group">
      <Button className={'transparent blue'} onClick={handleSave}>
        Save
      </Button>
      <Button
        className={'ghost blue'}
        onClick={handlePublish}
        isLoading={isPublishing}
      >
        <ThunderIcon />
        Publish
      </Button>
    </div>
  );
}

export default SaveAndPublish;
