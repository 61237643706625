import React, { useEffect, useState } from "react";
import { getActiveStep, selectActiveLayer, setActiveLayer } from "../../../../../../../redux/toolsSlice";
import { getLayerObjConfig, removeLayerConfig, setLayerConfig, updateLayer } from '../../../../../../../redux/stepsSlice';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { UploadIcon } from "../../../../../../assets/Icons";
import axios from 'axios';
import Loader from "../../../../../../organisms/loader/Loader";

export const ImageLayerDefaults = (id) => {
  return {
    id: id,
    type: 'image',
    source: ''
  };
}

const validateFileType = (fileType) => {
  const allowedTypes = ['image/jpg', 'image/jpeg', 'image/png'];
  return allowedTypes.includes(fileType);
};

const validateFileSize = (fileSize) => {
  const fileSizeLimit = 15 * 1024 * 1024; // 15 MB
  return fileSize <= fileSizeLimit;
};


function ImageContent({ layer }) {

  const [dragging, setDragging] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const layerObjConfig = useSelector((state) => getLayerObjConfig(state, layer), shallowEqual);
  const activeStep = useSelector((state) => getActiveStep(state));
  const stepId = activeStep.id;
  const activeLayer = useSelector((state) => selectActiveLayer(state));

  const defaultConfig = [
    {
      layerId: layer.id,
      sectionId: 'image',
      sectionTitle: 'Image Element',
      values: []
    }
  ];

  const editElement = () => {
    removeFocusElement();
    dispatch(setActiveLayer(layer));
    dispatch(setLayerConfig(defaultConfig));
  }

  const removeFocusElement = () => {
    dispatch(setActiveLayer({}));
    dispatch(removeLayerConfig());
  }

  useEffect(() => {
    if (layerObjConfig) {
      const newLayer = {
        ...layer,
        ...layerObjConfig
      };
      dispatch(updateLayer({ stepId: stepId, layer: newLayer }));
    }
  }, [layerObjConfig]);

  const uploadImage = async (file) => {
    setLoading(true);
    const formData = new FormData();
    formData.append('file', file);
    const url = '/api/v1/upload_files';
    const response = await axios
      .post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .finally(() => {
        setLoading(false);
      });
    return response.data;
  };

  const handleFileChange = (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    handleFile(file);
  }

  const handleDragOver = (event) => {
    setDragging(true);
  }

  const handleDrop = (event) => {
    event.preventDefault();
    setDragging(false);
    const file = event.dataTransfer.files[0];
    handleFile(file);
  }

  const handleFile = async (file) => {
    if (!validateFileType(file?.type)) {
      alert('Only JPG, PNG, and JPEG images are allowed.');
      return;
    }

    if (!validateFileSize(file.size)) {
      alert('File size exceeds the maximum limit of 15 MB.');
      return;
    }

    const response = await uploadImage(file);
    if (response) {
      const newLayer = {
        ...layer,
        source: response
      };
      dispatch(updateLayer({ stepId: stepId, layer: newLayer }));
    }
    else (
      alert('Something went wrong.')
    )
  }

  return (
    <>
      <div
        className='video-wrapper'
        onDragOver={handleDragOver}
        onDrop={handleDrop}>
        {loading && <Loader text={'Uploading...'} />}
        {!loading &&
          (layer?.source ?
            <ImageContentPreview layer={layer} height="400px" width="100%" alt="Uploaded Image" onClick={editElement} style={{ objectFit: 'contain' }} />
            :
            <div className="cover-upload-form" tabIndex={0} onClick={editElement}>
              <div className="filed-upload-form icon-filed-upload-form">
                <header className="text-upload-form">
                  Choose a file <br />Max file size: 15 MB
                </header>
                <label className="btn btn-primary save-btn">
                  <UploadIcon /> Browse File
                  <input
                    type="file"
                    accept=".jpg,.jpeg,.png"
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                  />
                </label>
                {dragging && (
                  <div className="dragging-overlay">
                    Drop the image here
                  </div>
                )}
              </div>
            </div>
          )
        }
      </div>
    </>
  )
}

export const ImageContentPreview = ({ layer, onClick, style, height = "400px", width = "100%" }) => {
  return <img height={height} width={width} src={layer.source} alt="Uploaded Image" onClick={onClick} style={{ objectFit: 'contain', ...style }} />
}

export default ImageContent;