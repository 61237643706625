import React, { useState, useEffect } from 'react';

const LineTab = ({ tabs, defaultTab, onChange }) => {
    const [currentTab, setCurrentTab] = useState(defaultTab);
    const isActiveTab = (index) => {
        return currentTab === index ? 'active' : '';
    };

    const handleChange = (index) => {
        setCurrentTab(index);
        const tab = tabs[index];
        onChange(tab);
    };

    // useEffect(() => {
    //     handleChange(currentTab);
    // }, []);

    const renderTabs = () => {
        return tabs.map((tab, index) => {
            return (
                <div
                    className={['tab', isActiveTab(index)].join(' ')}
                    key={index}
                    onClick={() => handleChange(index)}
                >
                    {tab}
                </div>
            );
        });
    };

    return (
        <div className="un-line-tabs">
            <div className="tabs-container">{renderTabs()}</div>
        </div>
    );
};

LineTab.defaultProps = {
    tabs: [],
    onChange: () => { },
    defaultTab: 0,
};

export default LineTab;
