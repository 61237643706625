import React from 'react';
import History from './History';
import Settings from './Settings';
import Preview from './Preview';

const Actions = () => {
    return (
        <div className="un-actions">
            <History />
            <Settings />
            <Preview />
        </div>
    );
};

export default Actions;
