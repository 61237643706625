import React from 'react';
import Undo from './Undo';
import Redo from './Redo';
import HotSpot from './HotSpot';
import TextInput from './TextInput';
import DropdownTool from './DropdownTool';
import Brush from './Brush';
import Line from './Line';
import Square from './Square';
import Crop from './Crop';
import TextTool from './TextTool';
import { Tooltip } from 'react-tooltip';

const Tools = () => {
  return (
    <div className="un-tools">
      <Undo />
      <Redo />
      <div className="separator" />
      <HotSpot />
      <TextInput />
      <DropdownTool />
      <div className="separator" />
      <Brush />
      <Line />
      <Square />
      <Crop />
      <TextTool />
      <Tooltip
        id="tools-tooltip"
        place="bottom"
        effect="solid"
        delayShow={500}
      ></Tooltip>
    </div>
  );
};

export default Tools;
