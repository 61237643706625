import React from 'react';
import { Provider } from 'react-redux';
import { store } from '../../../../redux/store';
import { Grid, List } from '../../../assets/Icons';
import Paths from './Paths';
import LMLineTab from '../../../organisms/line_tab/LMLineTab';
import { CONSTS } from '../../utils/endpoints';

export const DISPLAY_STYLES = {
  GRID: 'grid',
  LIST: 'list'
};

const IndexPage = () => {
  const [displayStyle, setDisplayStyle] = React.useState(DISPLAY_STYLES.GRID);

  return (
    <Provider store={store}>
      <div className="un-page-container__learning-materials">
        <div className="header">
          <div className="title">
            <h1>Paths</h1>
          </div>
          <div className="actions">
            <Grid
              className={displayStyle === DISPLAY_STYLES.GRID ? 'active' : ''}
              onClick={() => setDisplayStyle(DISPLAY_STYLES.GRID)}
            />
            <List
              className={displayStyle === DISPLAY_STYLES.LIST ? 'active' : ''}
              onClick={() => setDisplayStyle(DISPLAY_STYLES.LIST)}
            />
          </div>
        </div>
        <LMLineTab defaultTab={0} tabName={CONSTS.PATHS} />
        <Paths displayStyle={displayStyle} />
      </div>
    </Provider>
  );
};

export default IndexPage;
