import React, { useEffect, useState } from 'react';
import Button from '../../../atoms/button/Button';
import { CrossIcon, PlusGreyIcon } from '../../../assets/Icons';
import { API_ENDPOINTS } from '../../utils/endpoints';
import { useSelector } from 'react-redux';
import { getOnboardingData } from '../../../../redux/onboardingSlice';
import InviteInput from '../../../atoms/invite-input/InviteInput';
import { http_post } from '../../utils/api_client';

export const TeamInviteTitle = () => (
    <div className="header">
        <h1 className="title">Invite team members</h1>
        <p className="small">Send invitation link to team members</p>
    </div>
);

const TeamInvite = ({ onChangeStep }) => {
    const data = useSelector((state) => getOnboardingData(state));
    const [isLoading, setIsLoading] = useState(false);
    const [emailFields, setEmailFields] = useState([{ id: 1, email: '' }]);
    const [isDisabled, setIsDisabled] = useState(true);

    const addEmailField = () => {
        setEmailFields(
            emailFields.concat({ id: emailFields.length + 1, email: '' })
        );
    };

    const removeEmailField = (id) => {
        setEmailFields(emailFields.filter((field) => field.id !== id));
    };

    const handleEmailChange = (id, newValue) => {
        const updatedEmailFields = emailFields.map((field) => {
            if (field.id === id) {
                return { ...field, ...newValue };
            }
            return field;
        });
        setEmailFields(updatedEmailFields);
    };

    const sendInvite = () => {
        setIsLoading(true);
        http_post(API_ENDPOINTS.POST_INVITE_TEAM, {
            invites: emailFields
        })
            .then((res) => {
                onChangeStep();
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const inviteLater = () => {
        onChangeStep();
    };

    useEffect(() => {
        setIsDisabled(emailFields.some((field) => field.email === ''));
    }, [emailFields]);

    return (
        <div>
            <div className="email-fields">
                {emailFields.map((field, index) => (
                    <div key={field.id} className="email-field">
                        <InviteInput
                            name={`teamEmail${field.id}`}
                            value={field.email}
                            placeholder={`Type member's email`}
                            label={`Email`}
                            onChange={(e) =>
                                handleEmailChange(field.id, e.target.value)
                            }
                        />
                        {emailFields.length > 1 && (
                            <button onClick={() => removeEmailField(field.id)}>
                                <CrossIcon />
                            </button>
                        )}
                    </div>
                ))}
            </div>
            <button className="add-another" onClick={addEmailField}>
                <PlusGreyIcon />
                Add Another
            </button>
            <div className="btn-col-group">
                <Button
                    className="lg"
                    isLoading={isLoading}
                    onClick={sendInvite}
                    disabled={isDisabled}
                >
                    Send Invite
                </Button>
                <Button className="lg transparent blue" onClick={inviteLater}>
                    Invite Later
                </Button>
            </div>
        </div>
    );
};

export default TeamInvite;
