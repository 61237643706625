import React from "react";
import CompanyDetails from "../../registrations/steps/company-details";
import { Provider } from "react-redux";
import { store } from "../../../../redux/store";

const CompanyPage = () => {
  return <div>
    <h1>Company Page</h1>
    <Provider store={store}>
      <CompanyDetails />
    </Provider>
  </div>;
}

export default CompanyPage;