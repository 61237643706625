import React, { useEffect, useRef, useState } from 'react';
import { DropdownIcon } from '../../assets/Icons';
import {
  setActiveTool,
  selectActiveTool,
  setActiveLayer,
  selectActiveLayer
} from '../../../redux/toolsSlice';
import {
  updateLayer,
  removeLayerConfig,
  setLayerConfig,
  getLayerObjConfig
} from '../../../redux/stepsSlice';
import { setNextIndex } from '../../../redux/walkthroughPreviewSlice';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Rect, Transformer, Group, Layer, Text } from 'react-konva';
import { Html } from 'react-konva-utils';
import ContextMenu from '../context-menu/ContextMenu';

const DropdownTool = () => {
  const dispatch = useDispatch();
  const activeTool = useSelector((state) => selectActiveTool(state));
  const [className, setClassName] = useState('tool');

  const setActive = () => {
    dispatch(setActiveTool('dropdown'));
  };

  useEffect(() => {
    if (activeTool === 'dropdown') {
      setClassName('tool active');
    } else {
      setClassName('tool');
    }
  }, [activeTool]);

  return (
    <div
      className={className}
      title="Dropdown"
      data-tooltip-id="tools-tooltip"
      data-tooltip-content="Dropdown"
      onClick={setActive}
    >
      <DropdownIcon />
    </div>
  );
};

export const dropdownLayerDefaults = (id, mouseDown) => {
  const width = 100;
  const height = 30;
  const pos = mouseDown;

  return {
    id: id,
    type: 'dropdown',
    x: pos.x,
    y: pos.y,
    width: width,
    height: height,
    fill: '#FFFFFF',
    rotation: 0,
    stroke: '#000000',
    strokeWidth: 1,
    cornerRadius: 0
  };
};

export const RenderPreviewHotspot = ({ layer }) => {
  const dispatch = useDispatch();

  const nextPage = () => {
    dispatch(setNextIndex());
  };

  const renderNode = () => {
    return (
      <div>
        <Rect
          x={layer.x}
          y={layer.y}
          width={layer.width}
          height={layer.height}
          rotation={layer.rotation}
          fill={layer.fill}
          key={layer.id}
          onClick={nextPage}
          stroke={layer.stroke}
          strokeWidth={layer.strokeWidth}
          cornerRadius={layer.cornerRadius}
          onMouseOver={(e) => {
            const container = e.target.getStage().container();
            container.style.cursor = 'pointer';
          }}
          onMouseOut={(e) => {
            const container = e.target.getStage().container();
            container.style.cursor = 'default';
          }}
        />
      </div>
    );
  };

  return <>{renderNode()}</>;
};

// Author version of RenderSquare
export const RenderDropdown = ({ stepId, layer }) => {
  const shapeRef = useRef();
  const transformerRef = useRef();
  const dispatch = useDispatch();
  const activeLayer = useSelector((state) => selectActiveLayer(state));
  const isSelected =
    activeLayer &&
    activeLayer.layerId === layer.id &&
    activeLayer.stepId === stepId;
  const [menuVisible, setMenuVisible] = useState(false);
  const contextMenuOptions = ['Delete'];
  const defaultConfig = [
    {
      layerId: layer.id,
      sectionId: 'dropdown',
      sectionTitle: 'Element > Dropdown Menu',
      values: [
        {
          id: 'placeholder',
          title: 'Placeholder',
          type: 'text',
          value: layer.placeholder || ''
        },
        {
          id: 'pFontColor',
          title: 'P. Font Color',
          type: 'color',
          value: layer.pFontColor || '#000000'
        },
        {
          id: 'textColor',
          title: 'Font Color',
          type: 'color',
          value: layer.textColor || '#000000'
        },
        {
          id: 'fill',
          title: 'BG Color',
          type: 'color',
          value: layer.fill || '#11ffff44'
        },
        {
          id: 'stroke',
          title: 'Border Color',
          type: 'color',
          value: layer.stroke || '#000000'
        },
        {
          id: 'fontSize',
          title: 'Font Size',
          type: 'px-size',
          value: layer.fontSize || 12
        }
      ]
    },
    {
      layerId: layer.id,
      sectionId: 'dropdown-options',
      sectionTitle: 'Menu Options',
      values: [
        {
          id: 'option1',
          title: 'Option 1',
          type: 'multioptions',
          value: layer.option1 || [{ order: 0, title: 'Item 1' }]
        }
      ]
    }
  ];

  const layerObjConfig = useSelector(
    (state) => getLayerObjConfig(state, layer),
    shallowEqual
  );

  useEffect(() => {
    if (layerObjConfig) {
      const newLayer = {
        ...layer,
        ...layerObjConfig
      };
      dispatch(updateLayer({ stepId: stepId, layer: newLayer }));
    }
  }, [layerObjConfig]);

  const renderContextMenu = () => {
    return (
      <ContextMenu
        options={contextMenuOptions}
        activeLayer={layer}
        stepId={stepId}
        dispatch={dispatch}
        onClick={menuClick}
      />
    );
  };

  const showContextMenu = (e) => {
    e.evt.preventDefault();
    dispatch(setActiveLayer({ stepId: stepId, layerId: layer.id }));
    setMenuVisible(true);
  };

  const menuClick = (e) => {
    setMenuVisible(false);
  };

  useEffect(() => {
    if (isSelected) {
      transformerRef.current.nodes([shapeRef.current]);
      transformerRef.current.getLayer().batchDraw();
    } else {
      dispatch(removeLayerConfig());
      setMenuVisible(false);
    }
  }, [activeLayer]);

  const handleLayerUpdate = (e) => {
    const newLayer = {
      ...layer,
      x: e.target.x(),
      y: e.target.y()
    };
    dispatch(updateLayer({ stepId: stepId, layer: newLayer }));
  };

  const setActive = () => {
    dispatch(setActiveLayer({ stepId: stepId, layerId: layer.id }));
    dispatch(setLayerConfig(defaultConfig));
  };

  const setActiveChild = (e) => {};

  return (
    <>
      <Group
        x={layer.x}
        y={layer.y}
        draggable
        onDragEnd={handleLayerUpdate}
        key={layer.id}
      >
        <Group
          x={0}
          y={0}
          onClick={setActive}
          width={layer.width}
          height={layer.height}
          onContextMenu={showContextMenu}
        >
          <Rect
            width={layer.width}
            height={layer.height}
            rotation={layer.rotation}
            stroke={layer.stroke}
            strokeWidth={layer.strokeWidth}
            cornerRadius={layer.cornerRadius}
            ref={shapeRef}
          />
          <Text
            x={0}
            y={0}
            text={layer.placeholder}
            fontSize={layer.fontSize}
            fill={layer.pFontColor}
            align="center"
            verticalAlign="middle"
          />
        </Group>
        {menuVisible && <Html>{renderContextMenu()}</Html>}
        {isSelected && (
          <Transformer
            ref={transformerRef}
            keepRatio={false}
            ignoreStroke={true}
            boundBoxFunc={(oldBox, newBox) => {
              if (Math.abs(newBox.width) < 2 || Math.abs(newBox.height) < 2) {
                return oldBox;
              }

              dispatch(
                updateLayer({
                  stepId: stepId,
                  layer: {
                    ...layer,
                    width: newBox.width,
                    height: newBox.height
                  }
                })
              );

              return newBox;
            }}
          />
        )}
      </Group>
    </>
  );
};

export default DropdownTool;
