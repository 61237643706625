import React, { useEffect, useState, useRef } from "react";
import { getActiveStep, selectActiveLayer, setActiveLayer } from "../../../../../../../redux/toolsSlice";
import { getLayerObjConfig, removeLayerConfig, setLayerConfig, updateLayer } from '../../../../../../../redux/stepsSlice';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

export const MultiSelectLayerDefaults = (id) => {
  return {
    id: id,
    type: 'multi_select',
    question: 'Enter your question',
    multiSelect: [
      { id: uuidv4(), value: "", isChecked: false },
    ],
  };
}

function MultiSelectContent({ layer }) {

  const [isEditing, setIsEditing] = useState(false);
  const [question, setQuestion] = useState(layer.question);
  const [options, setOptions] = useState(layer.multiSelect || []);

  const questionRef = useRef(null);
  const dispatch = useDispatch();

  const layerObjConfig = useSelector((state) => getLayerObjConfig(state, layer), shallowEqual);
  const activeStep = useSelector((state) => getActiveStep(state));
  const stepId = activeStep.id;
  const activeLayer = useSelector((state) => selectActiveLayer(state));

  const defaultConfig = [
    {
      layerId: layer.id,
      sectionId: 'multiSelect',
      sectionTitle: 'Multi Select Element',
      values: [
        {
          id: 'question',
          title: 'Question',
          type: 'text',
          value: layer.question || ''
        },
        {
          id: 'multiSelect',
          title: '',
          type: 'multiSelect',
          value: layer.multiSelect,
          // options: options,
          direction: 'column'
        },
      ]
    }
  ];

  const EditElement = () => {
    removeFocusElement();
    dispatch(setActiveLayer(layer));
    dispatch(setLayerConfig(defaultConfig));
    setIsEditing(true);
  }

  const removeFocusElement = () => {
    dispatch(setActiveLayer({}));
    dispatch(removeLayerConfig());
    setIsEditing(false);
  }

  const updateText = () => {
    const newLayer = {
      ...layer,
      question: questionRef.current ? questionRef.current.value : layer.question,
      multiSelect: layer.multiSelect ? layer.multiSelect : []
    };
    dispatch(updateLayer({ stepId: stepId, layer: newLayer }));
  };

  useEffect(() => {
    if (layerObjConfig) {
      const newLayer = { ...layer, ...layerObjConfig };
      dispatch(updateLayer({ stepId: stepId, layer: newLayer }));
    }
  }, [layerObjConfig]);

  useEffect(() => {
    setQuestion(layer.question);
  }, [layer]);

  useEffect(() => {
    setOptions(layer.multiSelect || []);
  }, [layer]);

  const handleEditBlur = (e) => {
    removeFocusElement();
    setIsEditing(false);
    updateText();
  };

  const onPressEnter = (e) => {
    if (e.key === 'Enter' || e.key === "Escape") {
      removeFocusElement();
      setIsEditing(false);
      updateText();
    }
  };

  return (
    <>
      <div className="container-large-text" onBlur={removeFocusElement} onKeyDown={onPressEnter} onClick={EditElement}>
        {isEditing ? (
          <>
            <input
              className="mr-2"
              ref={questionRef}
              defaultValue={layer.question}
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              onBlur={handleEditBlur}
              onKeyDown={onPressEnter}
              style={{
                border: 'none',
                padding: 0,
                margin: 0,
                width: '100%',
                height: '100%',
                fontWeight: '400',
                fontSize: '15px'
              }}
              placeholder="Enter your question"
            />
            {options.map((data) => {
              return (
                <>
                  <input
                    className="dropdown-data-checkbox mr-2"
                    type="checkbox"
                    value={data.isChecked}
                  />
                  {data.value}
                  <br />
                </>
              )
            })
            }
          </>
        ) : (
          <MultiSelectContentPreview layer={layer} EditElement={EditElement} />
        )}
      </div >
    </>
  )
}

export const MultiSelectContentPreview = ({ layer, EditElement }) => {
  return (
    <>
      <p
        tabIndex={0}
        onClick={EditElement}
        style={{
          fontWeight: '400',
          fontSize: '15px'
        }}
      >
        {layer.question}
      </p>
      {layer.multiSelect && layer.multiSelect.map((data) => {
        return (
          <>
            <input
              className="dropdown-data-checkbox mr-2"
              type="checkbox"
              value={data.isChecked}
            />
            {data.value}
            <br />
          </>
        )
      })
      }
    </>
  )
}

export default MultiSelectContent;