import React, { useEffect, useState } from 'react';

const TextOption = ({ defaultValue, onChange }) => {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleChange = (event) => {
    setValue(event.target.value);
    onChange(event.target.value);
  };

  return (
    <div className="un-text-option">
      <input type="text" onChange={handleChange} value={value} />
    </div>
  );
};

TextOption.defaultProps = {
  defaultValue: '',
  onChange: () => {}
};

export default TextOption;
