import React, { useEffect, useState } from 'react';
import OnboardingLayout from '../registrations/onboarding-layout';
import { store } from '../../../redux/store';
import { Provider } from 'react-redux';
import { ENDPOINTS, API_ENDPOINTS } from '../utils/endpoints';
import { http_get } from '../utils/api_client';
import Loader from '../../organisms/loader/Loader';

const IndexPage = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        http_get(API_ENDPOINTS.GET_PROFILE)
            .then((response) => {
                setIsLoading(false);
            })
            .catch((error) => {
                window.location.href = ENDPOINTS.ROOT;
            });
    }, []);

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <Provider store={store}>
                    <div className="un-registrations">
                        <OnboardingLayout />
                    </div>
                </Provider>
            )}
        </>
    );
};

export default IndexPage;
