import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const styles = {
  modalHeader: {
    borderBottom: 'none',
    paddingLeft: 32,
    paddingRight: 32,
  },
  modalBody: {
    paddingTop: 10,
  },
  modalFooter: {
    paddingLeft: 32,
    paddingRight: 32,
  },
};

const ElementSelectorModal = ({
  title,
  show,
  onClose,
  children,
  onInsert
}) => {
  return (
    <>
      <Modal show={show} onHide={onClose} backdrop="static" keyboard={false}>
        <Modal.Header style={styles.modalHeader} closeButton>
          <Modal.Title><b>{title}</b></Modal.Title>
        </Modal.Header>
        <Modal.Body style={styles.modalBody}>
          <i>Select an element from the list and select the insert element button to add it to your content area</i>
          {children}
        </Modal.Body>
        <Modal.Footer style={styles.modalFooter}>
          <Button variant="primary" onClick={onInsert}>Insert Element</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
};

export default ElementSelectorModal;