import React from 'react';
import { RedoIcon } from '../../assets/Icons';
import { redo } from '../../../redux/stepsSlice';
import { useDispatch } from 'react-redux';

const Redo = () => {
  const dispatch = useDispatch();

  const handleRedo = () => {
    dispatch(redo());
  };

  // listen to ctrl+shift+z
  window.addEventListener('keydown', (e) => {
    if (e.ctrlKey && e.shiftKey && e.key === 'z') {
      handleRedo();
    }
  });

  return (
    <div
      className="tool__border"
      title="Redo"
      data-tooltip-id="tools-tooltip"
      data-tooltip-content="Redo"
      onClick={handleRedo}
    >
      <RedoIcon />
    </div>
  );
};

export default Redo;
