import React, { useState } from 'react';
import ProfileImage from 'components/assets/images/user-default-photo.png';
import { http_put } from '../../../../utils/api_client';
import { API_ENDPOINTS } from '../../../../../../utils/endpoints';
import TeamMemberModal from './TeamMemberModal';

const roleList = ['Owner', 'Team Admin', 'Member', 'View Only'];

const getRoleName = (role) => {
  if (!role) return '';
  return role.split('_').join(' ');
};

function TeamMemberCard({ user_id, email, profile, role }) {
  const [currentRole, setCurrentRole] = useState(role);
  const [isDropdown, setIsDropdown] = useState(false);

  const [isEditing, setIsEditing] = useState(false);

  const handleRoleChange = (role) => {
    setCurrentRole(role);
    setIsDropdown(false);
  };

  const handleSaveClick = () => {
    http_put(API_ENDPOINTS.PUT_UPDATE_TEAM_MEMBERS_ROLE(user_id), {
      id: user_id,
      email: email,
      role: currentRole,
    })
      .then((res) => {
        if (res.data) {
          setIsEditing(false);
        }
      })
      .catch((err) => {
        throw new Error('Failed to fetch profile');
      });
  };

  const handleDeleteClick = () => {
    $('#teamMemberModal').modal('show');
  };

  return (
    <>
      <div className="tr-table-admin-team">
        <div className="td-table-admin-team">
          <div className="group-admin-check">
            <input
              className="data-checkbox team-checkbox"
              type="checkbox"
              name="all"
              value="all"
            />
            <div className="admin-info-wrapper">
              <div className="admin-image-cover">
                <img
                  className="admin-image"
                  src={
                    profile?.avatar_url === 'missing.png'
                      ? ProfileImage
                      : profile.avatar_url
                  }
                  alt="admin avatar"
                />
              </div>
              <div className="admin-content-cover">
                <h3 className="admin-name">
                  {profile?.first_name} {profile?.last_name}
                </h3>
                <div className="admin-mail-cover">
                  <a className="admin-mail" href="mailto:olivia@untitledui.com">
                    {email}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="td-table-admin-team">
          {!isEditing && (
            <div
              className="text-role-admin-team"
              style={{ textTransform: 'capitalize' }}
            >
              {getRoleName(currentRole)}
            </div>
          )}
          {isEditing && (
            <div className="select-invite-team" style={{ display: 'flex' }}>
              <div className="icon-input-invite-team" style={{ zIndex: 999 }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none"
                >
                  <path
                    d="M15.3457 9.04368C15.3457 7.61203 14.1865 6.45277 12.7548 6.45277C11.3232 6.45277 10.1639 7.61203 10.1639 9.04368C10.1639 10.4753 11.3232 11.6346 12.7548 11.6346C14.1865 11.6346 15.3457 10.4753 15.3457 9.04368ZM16.9821 9.04368C16.9821 11.3791 15.0902 13.271 12.7548 13.271C10.4194 13.271 8.52754 11.3791 8.52754 9.04368C8.52754 6.70829 10.4194 4.81641 12.7548 4.81641C15.0902 4.81641 16.9821 6.70829 16.9821 9.04368ZM7.20936 18.5697C7.20936 19.2686 7.12685 19.18 7.51418 19.18H17.9955C18.3828 19.18 18.3003 19.2686 18.3003 18.5697C18.3003 16.8491 15.7746 15.8164 12.7548 15.8164C9.73499 15.8164 7.20936 16.8491 7.20936 18.5697ZM5.573 18.5697C5.573 15.5453 8.9119 14.18 12.7548 14.18C16.5977 14.18 19.9366 15.5453 19.9366 18.5697C19.9366 20.1475 19.3134 20.8164 17.9955 20.8164H7.51418C6.19621 20.8164 5.573 20.1475 5.573 18.5697Z"
                    fill="currentColor"
                  />
                </svg>
              </div>
              <div className="form-select" style={{ display: 'flex' }}>
                <div
                  className="selected-item"
                  style={{ width: '100%', textTransform: 'capitalize' }}
                  onClick={() => setIsDropdown((prev) => !prev)}
                >
                  {/* Switch with the active state data */}
                  {getRoleName(currentRole)}
                </div>
                <div
                  className="all-items"
                  style={{ display: isDropdown ? 'block' : 'none' }}
                >
                  {roleList.map((role, index) => (
                    <div
                      key={index}
                      className="item-select"
                      onClick={() => handleRoleChange(role)}
                    >
                      {role}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="td-table-admin-team">
          {!isEditing && (
            <button
              onClick={() => setIsEditing(true)}
              className="button-edit-admin"
              type="button"
            >
              Edit
            </button>
          )}
          {isEditing && (
            <button
              onClick={handleSaveClick}
              className="button-save-admin"
              style={{ display: 'block' }}
              type="button"
            >
              Save
            </button>
          )}
        </div>
        <div className="td-table-admin-team">
          <button
            className="button-delete-admin"
            type="button"
            onClick={handleDeleteClick}
          >
            Delete
          </button>
        </div>
      </div>
      <TeamMemberModal id={user_id} />
    </>
  );
}

TeamMemberCard.defaultProps = {};

export default TeamMemberCard;
