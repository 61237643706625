import React from "react";

function RemotePagination({ table, pagination: {range} }) {
  return (
    <section className="section-pagination">
      <div className="page-padding">
        <div className="flex-pagination-row">
          <div className="left-pagination">
            {/* Counter Row Page */}
            <div className="counter-row-page">
              <div className="row-per-page">
                Rows per page:
                <select
                  className="counter-select"
                  defaultValue={`${table.getState().pagination.pageSize}`}
                  onChange={(e) => {
                    table.setPageSize(Number(e.target.value));
                  }}
                >
                  {[10, 20, 30, 40, 50].map((pageSize) => (
                    <option value={`${pageSize}`} key={pageSize}>
                      {pageSize}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {/* End Counter Row Page */}
          </div>
          <div className="right-pagination">
            <div className="data-pagination">
              <button
                onClick={() => table.previousPage()}
                disabled={!table.getCanPreviousPage()}
                type="button"
                className="pagination-prev"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M10.25 12.5L5.75 8L10.25 3.5"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
              <div className="number-data-pagination">
                {range.map((pageNumber, index) => {
                  if (pageNumber === "gap") {
                    return (
                      <div className="text-pagination-number" key={index}>
                        &#8230;
                      </div>
                    );
                  }
                  return (
                    <button
                      className={`pagination-number ${
                        Number(pageNumber) - 1 === table.getState().pagination.pageIndex
                          ? "active"
                          : ""
                      }`}
                      key={index}
                      onClick={() => table.setPageIndex(pageNumber - 1)}
                    >
                      {pageNumber}
                    </button>
                  );
                })}
              </div>
              <button
                onClick={() => table.nextPage()}
                disabled={!table.getCanNextPage()}
                type="button"
                className="pagination-next"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M5.75 3.5L10.25 8L5.75 12.5"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default RemotePagination;
