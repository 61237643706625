import React from "react";
import Accordion from "../Accordion";
import DataTable from "../datatable/DataTable";
import { interactionHistoryColumns } from "./extra/interactionHistoryColumns";
import { videoColumns } from "./extra/videoColumns";

function InteractionHistory({ profile }) {
  const accordions = [
    {
      title: "Interaction History",
      subtitle: `Total time spend: ${profile.totalStudyTime}`,
      component: (
        <DataTable
          columns={interactionHistoryColumns}
          data={profile.interactions}
          toolbarType="history"
        />
      ),
    },
    {
      title: "Video",
      subtitle: `Views: 14`,
      component: (
        <DataTable
          columns={videoColumns}
          data={profile.videos}
          toolbarType="video"
        />
      ),
    },
  ];

  return (
    <div id="tab-2" className="tab-content-avatar-model">
      {accordions.map((accordion, index) => {
        return (
          <Accordion
            key={index}
            title={accordion.title}
            subtitle={accordion.subtitle}
            initActive={index === 0 || index === 1}
          >
            {accordion.component}
          </Accordion>
        );
      })}
    </div>
  );
}

export default InteractionHistory;
