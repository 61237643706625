import React from "react";

function LabelAndTextarea({ title = "", value, setValue, placeholder }) {
  return (
    <div className="item-design-form-path">
      <div className="flex-design-form">
        <div className="label-design-form">
          <div className="size-12">{title}</div>
        </div>
        <div className="value-design-form">
          <textarea
            className="input-design-form height-142"
            placeholder={placeholder}
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
}

export default LabelAndTextarea;
