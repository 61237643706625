import React, { useCallback } from 'react';
import Button from '../../atoms/button/Button';
import { useSelector, useDispatch } from 'react-redux';
import { getMaterial, updateMaterial } from '../../../redux/materialSlice';
import { http_put } from '../../pages/utils/api_client';
import { API_ENDPOINTS } from '../../pages/utils/endpoints';

const Visibility = () => {
  const material = useSelector((state) => getMaterial(state));
  const dispatch = useDispatch();

  const setVisibility = useCallback(
    async (visibility) => {
      try {
        const res = await http_put(
          API_ENDPOINTS.PUT_LEARNING_MATERIAL_VISIBILITY(material.id),
          {
            visibility,
            material_type: material.material_type
          }
        ).then((res) => {
          dispatch(updateMaterial({ visibility }));
        });
      } catch (error) {
        console.error('Error setting visibility:', error);
      }
    },
    [material.id]
  );

  const visibilityOptions = [
    {
      label: 'Private',
      tooltip: 'Learning material is made available to invited members only.',
      value: 'private'
    },
    {
      label: 'Public',
      tooltip: 'Learning material is made available to the public.',
      value: 'public'
    },
    {
      label: 'User Info Required',
      tooltip:
        'Learning material is made available to the public with some basic information collected from the user.',
      value: 'guest'
    }
  ];

  const getClassName = (value) => {
    return `blue ${material.visibility === value ? '' : 'ghost'}`;
  };

  return (
    <div className="un-btn-group left">
      {visibilityOptions.map((option) => (
        <Button
          key={option.value}
          className={getClassName(option.value)}
          data-tooltip-id="settings-tooltip"
          data-tooltip-content={option.tooltip}
          onClick={() => setVisibility(option.value)}
        >
          {option.label}
        </Button>
      ))}
    </div>
  );
};

export default Visibility;
