import React, { useEffect, useRef, useState } from "react";

function DateDropdown({ column, placeholder }) {
  const [isActive, setIsActive] = useState(false);

  const containerRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setIsActive(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [containerRef]);

  const handleUserClick = () => {
    setIsActive((prev) => !prev);
  };

  useEffect(() => {
    // eslint-disable-next-line
    $(".calendar").datepick({
      onSelect: function (dateText) {
        const parsedDate = new Date(dateText[0]).toISOString();
        column.setFilterValue(parsedDate);
      },
    });
  }, [column]);

  return (
    <div className="item-design-form-path">
      <div className="flex-design-form">
        <div className="label-design-form">
          <div className="size-12">Date</div>
        </div>
        <div className="value-design-form">
          <div className="flex-input-design-form">
            <div onClick={handleUserClick} className="item-input-design-form">
              <input
                type="text"
                className="input-design-form calendar"
                placeholder="Set Date"
              />
            </div>
            <div className="button-delete">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M11.8298 4C13.4477 4 14.5023 4.83988 14.8403 6.38266L18.9787 6.38298C19.3547 6.38298 19.6596 6.68781 19.6596 7.06383C19.6596 7.43985 19.3547 7.74468 18.9787 7.74468H18.2264L17.1287 18.1713C17.0192 19.2108 16.1426 20 15.0973 20H8.56224C7.51694 20 6.64033 19.2108 6.53091 18.1713L5.43319 7.74468H4.68085C4.30483 7.74468 4 7.43985 4 7.06383C4 6.68781 4.30483 6.38298 4.68085 6.38298L8.81924 6.38266C9.15732 4.83988 10.2118 4 11.8298 4ZM16.8565 7.74468H6.80238L7.88513 18.0287C7.9216 18.3752 8.21381 18.6383 8.56224 18.6383H15.0973C15.4458 18.6383 15.738 18.3752 15.7744 18.0287L16.8565 7.74468ZM11.8298 9.10638C12.2058 9.10638 12.5106 9.41121 12.5106 9.78723V16.5957C12.5106 16.9718 12.2058 17.2766 11.8298 17.2766C11.4538 17.2766 11.1489 16.9718 11.1489 16.5957V9.78723C11.1489 9.41121 11.4538 9.10638 11.8298 9.10638ZM14.5872 9.10723C14.9627 9.12601 15.252 9.44568 15.2332 9.82123L14.8928 16.6297C14.874 17.0053 14.5543 17.2945 14.1788 17.2757C13.8032 17.257 13.514 16.9373 13.5328 16.5617L13.8732 9.75323C13.892 9.37768 14.2116 9.08845 14.5872 9.10723ZM9.07238 9.10723C9.44794 9.08845 9.76761 9.37768 9.78639 9.75323L10.1268 16.5617C10.1456 16.9373 9.85636 17.257 9.48081 17.2757C9.10525 17.2945 8.78558 17.0053 8.76681 16.6297L8.42638 9.82123C8.4076 9.44568 8.69683 9.12601 9.07238 9.10723ZM11.8298 5.3617C10.9673 5.3617 10.4683 5.67088 10.2283 6.38268H13.4313C13.1913 5.67088 12.6922 5.3617 11.8298 5.3617Z"
                  fill="currentColor"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DateDropdown;
