import React from "react";

// adjust this according to the real rating start and end (0 - 4 or 1 - 5)
function RatingStars({ total = 0 }) {
  return (
    <div className="rating-flex-model">
      {Array.from({ length: 5 }).map((_, index) => {
        return (
          <div
            key={index}
            className={`star-rating-icon ${index <= total - 1 ? "active" : ""}`}
          >
            {index}
          </div>
        );
      })}
    </div>
  );
}

export default RatingStars;
