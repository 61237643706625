import React, { useEffect, useRef, useState } from "react";

function CustomInput({ value, setValue }) {
  const [isActive, setIsActive] = useState(false);

  const containerRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setIsActive(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [containerRef]);

  return (
    <div
      ref={containerRef}
      className={`value-list-details-avatar ${isActive ? "active" : ""}`}
    >
      <input
        className="input-list-details-avatar"
        type="text"
        value={value}
        onChange={(e) => setValue()}
      />
      <div
        className="edit-input-avatar"
        onClick={() => setIsActive((prev) => !prev)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.4608 2.11919C10.8071 1.77324 11.2623 1.6001 11.7155 1.6001C12.1686 1.6001 12.6238 1.77324 12.9702 2.11919L13.8799 3.02783C14.2263 3.37377 14.3996 3.82838 14.3996 4.28102C14.3996 4.73366 14.2263 5.18827 13.8799 5.53421L13.1265 6.28668L5.79047 13.6293C5.29633 14.1229 4.62614 14.4001 3.92735 14.4001H2.39964C1.95781 14.4001 1.59964 14.0419 1.59964 13.6001L1.59963 12.086C1.59677 11.3841 1.87471 10.7103 2.37142 10.2142L8.8702 3.70822L10.4608 2.11919ZM12.2891 5.42701L13.0309 4.68619C13.143 4.57419 13.1989 4.42859 13.1989 4.28102C13.1989 4.13345 13.143 3.98785 13.0309 3.87585L12.1211 2.96721C12.009 2.85521 11.8632 2.79938 11.7155 2.79938C11.5677 2.79938 11.4219 2.85521 11.3098 2.96721L10.5681 3.70803L12.2891 5.42701ZM9.70757 4.5679L3.22044 11.0623C2.95146 11.3309 2.80034 11.6953 2.80034 12.0752L2.79977 12.9607C2.79968 13.0933 2.90716 13.2008 3.03976 13.2008H3.92735C4.30771 13.2008 4.67249 13.0499 4.94145 12.7813L11.4286 6.28687L9.70757 4.5679Z"
            fill="currentColor"
          />
        </svg>
      </div>
    </div>
  );
}

export default CustomInput;
