import React, { useState } from 'react';

const PasswordInput = ({
    name,
    value,
    placeholder,
    label,
    onChange,
    className,
}) => {
    return (
        <div className="un-checkbox-input">
            <input
                id={name}
                type="checkbox"
                className={className}
                name={name}
                placeholder={placeholder}
                onChange={onChange}
                checked={value}
            />
            <label htmlFor={name}>{label}</label>
        </div>
    );
};

PasswordInput.defaultProps = {
    onChange: () => {},
    className: '',
    placeholder: '',
    label: '',
    name: '',
    value: false,
};

export default PasswordInput;
