import React, { useEffect, useState } from 'react';
import { EyeIcon, EyeOffIcon } from '../../assets/Icons';

const PasswordInput = ({
    showStrength,
    name,
    value,
    placeholder,
    label,
    onChange,
    className,
    error,
    validationCallback,
}) => {
    const [isVisible, setIsVisible] = useState(false);
    const [strength, setStrength] = useState(0);
    const [errorMsg, setErrorMsg] = useState(error);

    const switchPasswordView = () => {
        const passwordInput = document.getElementById(name);
        if (passwordInput.type === 'password') {
            passwordInput.type = 'text';
            setIsVisible(true);
        } else {
            passwordInput.type = 'password';
            setIsVisible(false);
        }
    };

    const handleChange = (event) => {
        setErrorMsg('');
        const weaknesses = calculatePasswordStrength(event.target.value);
        const totalDeductions = weaknesses.reduce(
            (total, weakness) => total + weakness,
            0,
        );
        const strength = Math.max(100 - totalDeductions, 0);
        setStrength(strength);
        onChange(event);
    };

    const calculatePasswordStrength = (password) => {
        const weaknesses = [];
        weaknesses.push(lengthWeakness(password));
        weaknesses.push(lowercaseWeakness(password));
        weaknesses.push(uppercaseWeakness(password));
        weaknesses.push(numberWeakness(password));
        weaknesses.push(specialCharactersWeakness(password));
        weaknesses.push(repeatCharactersWeakness(password));
        return weaknesses;
    };

    const lengthWeakness = (password) => {
        const length = password.length;

        if (length <= 5) {
            return 40;
        }

        if (length <= 10) {
            return 15;
        }
        return 0;
    };

    const lowercaseWeakness = (password) => {
        return characterTypeWeakness(
            password,
            /[a-z]/g,
            'lowercase characters',
        );
    };

    const uppercaseWeakness = (password) => {
        return characterTypeWeakness(
            password,
            /[A-Z]/g,
            'uppercase characters',
        );
    };

    const numberWeakness = (password) => {
        return characterTypeWeakness(password, /[0-9]/g, 'numbers');
    };

    const specialCharactersWeakness = (password) => {
        return characterTypeWeakness(
            password,
            /[^0-9a-zA-Z\s]/g,
            'special characters',
        );
    };

    const characterTypeWeakness = (password, regex, type) => {
        const matches = password.match(regex) || [];

        if (matches.length === 0) {
            return 20;
        }

        if (matches.length <= 2) {
            return 5;
        }
        return 0;
    };

    const repeatCharactersWeakness = (password) => {
        const matches = password.match(/(.)\1/g) || [];

        if (matches.length > 0) {
            return matches.length * 10;
        }
        return 0;
    };

    const renderBars = (strength) => {
        const bars = [];
        const barCount = 4;

        for (let i = 0; i < barCount; i++) {
            let barClass = 'bar';

            if (strength > 0 && i <= strength / 25) {
                barClass += ' fill';
            }

            bars.push(<div className={barClass} key={i}></div>);
        }

        return <div className="bars">{bars}</div>;
    };

    useEffect(() => {
        renderBars(strength);
    }, [strength]);

    const handleOnBlur = (e) => {
        if (!validationCallback) {
            return;
        }
        const result = validationCallback(e);
        setErrorMsg(result);
    };

    if (errorMsg) {
        className += ' error';
    }

    return (
        <div className="un-password-input">
            <label htmlFor={name}>{label}</label>
            <div className="input-section">
                <input
                    id={name}
                    type="password"
                    className={className}
                    name={name}
                    placeholder={placeholder}
                    onChange={handleChange}
                    onBlur={handleOnBlur}
                    value={value}
                />
                {isVisible ? (
                    <EyeIcon onClick={switchPasswordView} />
                ) : (
                    <EyeOffIcon onClick={switchPasswordView} />
                )}
            </div>
            <p className="error-message">{errorMsg}</p>
            {showStrength && !errorMsg && (
                <div className="strength-meter">
                    {renderBars(strength)}
                    <p className="text">
                        Use 8 or more characters with letters, numbers &
                        symbols.
                    </p>
                </div>
            )}
        </div>
    );
};

PasswordInput.defaultProps = {
    onChange: () => {},
    className: '',
    placeholder: '',
    label: '',
    name: '',
    value: '',
    error: '',
    validationCallback: () => {},
};

export default PasswordInput;
