import React, { useEffect, useRef, useState } from "react";
import VisitorImage from "../../../../assets/images/visitor-logo-1.png";

function ShareAccess() {
  const [isActive, setIsActive] = useState(false);

  const [isDocActive, setIsDocActive] = useState(false);
  const [isUserActive, setIsUserActive] = useState(false);

  const containerRef = useRef(null);

  const docRef = useRef(null);
  const userRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setIsActive(false);
      }
      if (docRef.current && !docRef.current.contains(event.target)) {
        setIsDocActive(false);
      }
      if (userRef.current && !userRef.current.contains(event.target)) {
        setIsUserActive(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [containerRef]);

  const handleUserClick = () => {
    setIsActive((prev) => !prev);
  };

  return (
    <>
      <section className="">
        <div className="flex-data-toolbar">
          <div className="right-data-toolbar ml-auto">
            <a
              onClick={handleUserClick}
              id="show-share-access"
              className="share-access"
              href="#"
            >
              Share Access
            </a>
          </div>
        </div>
      </section>
      <div className={`modal-export-wrapper ${isActive ? "active" : ""}`}>
        <div ref={containerRef} className="modal-export-data">
          <div className="head-modal-export">
            <h3 className="title-modal-export">Share Access</h3>
            <div
              onClick={() => setIsActive(false)}
              id="close-export-modal"
              className="close-modal"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M6.17498 5L9.75001 1.42502C9.90834 1.27502 10 1.06667 10 0.833333C10 0.375 9.625 0 9.16667 0C8.93333 0 8.72498 0.0916483 8.57498 0.241648L5 3.82502L1.42502 0.241648C1.27502 0.0916483 1.06667 0 0.833333 0C0.375 0 0 0.375 0 0.833333C0 1.06667 0.0916483 1.27502 0.241648 1.42502L3.82502 5L0.24999 8.57498C0.0916565 8.72498 0 8.93333 0 9.16667C0 9.625 0.375 10 0.833333 10C1.06667 10 1.27502 9.90835 1.42502 9.75835L5 6.17498L8.57498 9.75001C8.72498 9.90834 8.93333 10 9.16667 10C9.625 10 10 9.625 10 9.16667C10 8.93333 9.90835 8.72498 9.75835 8.57498L6.17498 5Z"
                  fill="currentColor"
                />
              </svg>
            </div>
          </div>
          <div className="middel-modal-export">
            <form className="form-modal-export">
              <div className="item-form-export">
                <label className="label-modal-export">Doc(s) name</label>
                <div className="relative-input">
                  <input
                    onClick={() => setIsDocActive((prev) => !prev)}
                    className="input-export search-poup"
                    type="text"
                    placeholder="Type the name of the Path and select it from the list..."
                  />
                  <div
                    className="popup-user-select-wrapper"
                    style={{ display: isDocActive ? "block" : "none" }}
                  >
                    <div
                      ref={docRef}
                      className="scroll-block data-popup-user-select"
                    >
                      <label className="item-data-popup-user-select">
                        <div className="data-popup-checkbox-select">
                          <input
                            className="data-checkbox"
                            type="checkbox"
                            name="all"
                            value="all"
                          />
                        </div>
                        <div className="data-popup-user-image">
                          <img
                            className="data-popup-avatar-image"
                            src={VisitorImage}
                          />
                        </div>
                        <div className="data-popup-user-content">
                          <div className="data-popup-user-name">
                            Visitors Log
                          </div>
                        </div>
                      </label>
                      <label className="item-data-popup-user-select">
                        <div className="data-popup-checkbox-select">
                          <input
                            className="data-checkbox"
                            type="checkbox"
                            name="all"
                            value="all"
                          />
                        </div>
                        <div className="data-popup-user-image">
                          <img
                            className="data-popup-avatar-image"
                            src={VisitorImage}
                          />
                        </div>
                        <div className="data-popup-user-content">
                          <div className="data-popup-user-name">
                            Adding Facility Contact
                          </div>
                        </div>
                      </label>
                      <label className="item-data-popup-user-select">
                        <div className="data-popup-checkbox-select">
                          <input
                            className="data-checkbox"
                            type="checkbox"
                            name="all"
                            value="all"
                          />
                        </div>
                        <div className="data-popup-user-image">
                          <img
                            className="data-popup-avatar-image"
                            src={VisitorImage}
                          />
                        </div>
                        <div className="data-popup-user-content">
                          <div className="data-popup-user-name">
                            Seizure Log
                          </div>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item-form-export">
                <label className="label-modal-export">User Access</label>
                <div className="relative-input">
                  <input
                    onClick={() => setIsUserActive((prev) => !prev)}
                    className="input-export search-poup"
                    type="text"
                    placeholder="Type the name or email of the user(s) you want to grant access."
                  />
                  <div
                    className="popup-user-select-wrapper"
                    style={{ display: isUserActive ? "block" : "none" }}
                  >
                    <div
                      ref={userRef}
                      className="scroll-block data-popup-user-select"
                    >
                      <label className="item-data-popup-user-select">
                        <div className="data-popup-checkbox-select">
                          <input
                            className="data-checkbox"
                            type="checkbox"
                            name="all"
                            value="all"
                          />
                        </div>
                        <div className="data-popup-user-image">
                          <img
                            className="data-popup-avatar-image"
                            src={VisitorImage}
                          />
                        </div>
                        <div className="data-popup-user-content">
                          <div className="data-popup-user-name">
                            Visitors Log
                          </div>
                        </div>
                      </label>
                      <label className="item-data-popup-user-select">
                        <div className="data-popup-checkbox-select">
                          <input
                            className="data-checkbox"
                            type="checkbox"
                            name="all"
                            value="all"
                          />
                        </div>
                        <div className="data-popup-user-image">
                          <img
                            className="data-popup-avatar-image"
                            src={VisitorImage}
                          />
                        </div>
                        <div className="data-popup-user-content">
                          <div className="data-popup-user-name">
                            Adding Facility Contact
                          </div>
                        </div>
                      </label>
                      <label className="item-data-popup-user-select">
                        <div className="data-popup-checkbox-select">
                          <input
                            className="data-checkbox"
                            type="checkbox"
                            name="all"
                            value="all"
                          />
                        </div>
                        <div className="data-popup-user-image">
                          <img
                            className="data-popup-avatar-image"
                            src={VisitorImage}
                          />
                        </div>
                        <div className="data-popup-user-content">
                          <div className="data-popup-user-name">
                            Seizure Log
                          </div>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="footer-modal-export">
            <div className="item-footer-modal-export">
              <p className="text-weight-medium">Sharing Access with 2 users</p>
            </div>
            <div className="item-footer-modal-export">
              <div className="flex-button-modal">
                <button
                  onClick={() => setIsActive(false)}
                  className="btn-border"
                  type="button"
                >
                  Discard
                </button>
                <button
                  onClick={() => setIsActive(false)}
                  className="model-export-btn"
                  type="button"
                >
                  Grant Access
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ShareAccess;
