import React from "react";

//add max or ending value if needed
function ProgressBar({ title, index = 1 }) {
  return (
    <div className="question-progress-bar-cover">
      <div className="head-question-progress-bar">
        <div className="text-question-progress">{title}</div>
        <div className="counter-question-progress">{index} of 5</div>
      </div>
      <div className="cover-question-progress-bar">
        <div
          className="question-progress-bar"
          style={{ width: `${index * 10}%` }}
        ></div>
      </div>
    </div>
  );
}

export default ProgressBar;
