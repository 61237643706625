import React, { useEffect, useState } from 'react';
import { API_ENDPOINTS } from '../../../../utils/endpoints';
import { http_get, http_put } from '../../../../utils/api_client';
import DomainLogoUploader from './DomainLogoUploader';
import { toast } from 'react-toastify';

function DomainConfigurationContent(props) {
  const { domainDetail } = props;

  const initialDetailsValue = {
    company_name: "",
    heading_text: "",
    sub_heading_text: ""
  }

  const [fetchDetails, setFetchDetails] = useState(null)
  const [details, setDetails] = useState(initialDetailsValue)
  const [logoDetail, setLogoDetail] = useState(null)
  const [cssDetails, setCssDetails] = useState(null)

  useEffect(() => {
    http_get(API_ENDPOINTS.GET_DOMAINS_SIGN_PAGE_DETAILS(props.domainDetail?.id)).then((res) => {
      if (res.data) {
        const { details, css, logo } = res.data;
        setFetchDetails(res.data);
        setDetails(details ? details : initialDetailsValue);
        setCssDetails(css ? JSON.parse(css) : null);
        setLogoDetail(logo ? logo : null);
      }
    });
  }, []);

  const handleCssInputChange = (event) => {
    setCssDetails(event.target.value)
  }

  const handleDetailsInputChange = (event) => {
    const { name, value } = event.target;
    setDetails((prev) => (
      {
        ...prev,
        [name]: value
      }
    ))
  }

  const handleCancleChanges = () => {
    setDetails(initialDetailsValue)
  }

  const handleSaveChanges = () => {
    const formData = new FormData();
    const payload = {
      details: { ...details },
      css: cssDetails,
    }
    if (fetchDetails.logo === logoDetail) {
      console.log('"LOGO IS NOT CHANGED"', "LOGO IS NOT CHANGED")
    }
    else {
      formData.append('logo', logoDetail);
    }
    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        formData.append(key, JSON.stringify(payload[key]));
      }
    }

    http_put(API_ENDPOINTS.PUT_DOMAINS_SIGN_PAGE_DETAILS(props?.domainDetail?.id), formData).then((res) => {
      // toast.success("Data updated successfully")
      // alert('updated');
    });

  }

  return (
    <>
      <div className="flex-head-hubdesign" style={{ float: 'right' }}>
        <div className="right-flex-head-hubdesign">
          <div className="action-profile-button">
            <button className="button-action-cancel" type="button" onClick={handleCancleChanges}>
              Cancel
            </button>
            <button className="button-action-save" type="button" onClick={handleSaveChanges}>
              Save Changes
            </button>
          </div>
        </div>
      </div>
      <div className="mt-16 color-vampire-grey">{/* learning.example.com */}</div>
      <div className="" style={{ padding: "3rem" }}></div>

      {/* Brand */}
      <div className="list-form-hubdesign mb-3">
        <div className="item-list-form-hubdesign">
          <div className="content-form-hubdesign">
            <label className="label-content-hubdesign">Brand</label>
            <p>Add company logo and name to identify your profile.</p>
          </div>
          <div className="input-form-hubdesign">
            <div className="flex-form-hubdesign">
              <div className="item-form-hubdesign">
                <div className="label-form-hubdesign mb-12">Company Name</div>
                <input
                  className="input-hubdesign"
                  type="text"
                  value={details.company_name}
                  name="company_name"
                  placeholder=""
                  onChange={handleDetailsInputChange}
                />
              </div>
              <DomainLogoUploader label={"Company logo"} value={logoDetail} setLogoDetails={setLogoDetail} />
            </div>
          </div>
        </div>
      </div>
      {/* Brand End */}

      {/* Content */}
      <div className="item-list-form-hubdesign mb-3">
        <div className="content-form-hubdesign">
          <label className="label-content-hubdesign">Content</label>
          <p>Add content and show why you best for the others.</p>
        </div>
        <div className="input-form-hubdesign">
          <div className="flex-form-hubdesign">
            <div className="item-form-hubdesign">
              <div className="label-form-hubdesign mb-12">Heading Text</div>
              <input className="input-hubdesign" type="text" name='heading_text' value={details.heading_text} placeholder="" onChange={handleDetailsInputChange} />
            </div>
            <div className="item-form-hubdesign">
              <div className="label-form-hubdesign mb-12">Sub headline Text</div>
              <textarea
                name='sub_heading_text'
                className="input-hubdesign textarea-hubdesign"
                value={details.sub_heading_text}
                placeholder=""
                onChange={handleDetailsInputChange}
              ></textarea>
            </div>
          </div>
        </div>
      </div>
      {/* Content End */}

      {/* Custom Style */}
      <div className="item-list-form-hubdesign">
        <div className="content-form-hubdesign">
          <label className="label-content-hubdesign">Custom styles</label>
          <p>Add some custom css style to improve your design.</p>
        </div>
        <div className="input-form-hubdesign">
          <div className="flex-form-hubdesign">
            <div className="item-flex-form-hubdesign">
              <div className="label-form-hubdesign mb-8">Custom CSS</div>
              <textarea className="input-hubdesign textarea-hubdesign" value={cssDetails} onChange={handleCssInputChange}>
              </textarea>
            </div>
          </div>
        </div>
      </div>
      {/* Custom Style end */}
    </>
  )
}

export default DomainConfigurationContent;