import React, { useEffect } from 'react';
import Loader from '../../../organisms/loader/Loader';
import { API_ENDPOINTS, CONSTS } from '../../utils/endpoints';
import { DISPLAY_STYLES } from './IndexPage';
import LMGrid from './LMGrid';
import { http_get } from '../../utils/api_client';
import { useDispatch } from 'react-redux';
import { fetchCompanyDetails } from '../../../../redux/companySlice';

const Paths = ({ displayStyle }) => {
  const dispatch = useDispatch();
  const [items, setItems] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [activeStyle, setActiveStyle] = React.useState(displayStyle);

  useEffect(() => {
    dispatch(fetchCompanyDetails());
    setLoading(true);
    http_get(API_ENDPOINTS.GET_LEARNING_MATERIALS, { category: CONSTS.PATHS })
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => { })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (data.length > 0) {
      setItems(data);
    }
  }, [data]);

  const renderGrid = () => {
    return <LMGrid data={data} />;
  };

  const renderList = () => {
    return <div>helloasdfsdfsdf w</div>;
  };

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div>
          {activeStyle === DISPLAY_STYLES.GRID ? renderGrid() : renderList()}
        </div>
      )}
    </div>
  );
};

export default Paths;
