import React from 'react';
import AppRoutes from './routes';
import { AuthContext } from './contexts';

const App = ({token}) => {
  return (
    <AuthContext.Provider value={{token}}>
      <div className="App">
        <main>
          <AppRoutes />
        </main>
      </div>
    </AuthContext.Provider>
  );
}

export default App;
