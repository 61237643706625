import React, { useEffect, useState } from 'react';
import 'react-dropdown/style.css';

const NumberOption = ({ defaultValue, onChange }) => {
  const [value, setValue] = useState(defaultValue);
  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleChange = (event) => {
    setValue(event.target.value);
    onChange(event.target.value);
  };

  return (
    <div className="un-design-option">
      <input type="number" value={value} onChange={handleChange} min={0} />
    </div>
  );
};

export default NumberOption;
