import React from "react";
import { Line } from "react-chartjs-2";
import "chart.js/auto";

function DashboardDataCounter() {
  const DATA_COUNT = 12;
  const labels = [];
  for (let i = 0; i < DATA_COUNT; ++i) {
    labels.push(i.toString());
  }
  const datapoints = [0, 20, 20, 60, 60, 120];
  const data = {
    labels: ["a", "b", "c", "d", "e", "f"],
    datasets: [
      {
        label: "Cubic interpolation",
        data: datapoints,
        fill: false,
        tension: 0.4,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
    interaction: {
      intersect: false,
    },
    scales: {
      x: {
        display: false,
        title: {
          display: false,
        },
      },
      y: {
        grid: {
          color: "black",
        },
        border: {
          dash: [2, 4],
        },
        display: true,
        ticks: { display: false },
        title: {
          display: false,
          text: "Value",
        },
        suggestedMin: -10,
        suggestedMax: 200,
      },
    },
  };

  return (
    <div className="row-dashboard-data-counter custom-dashboard-data-counter mb-16">
      <div className="item-dashboard-data-counter">
        <div className="dashboard-data-counter">
          <div className="header-dashboard-data-counter">
            <div className="item-header-dashboard-data-counter">
              <div className="tagline-dashboard-data-counter">Total Users</div>
              <div className="number-dashboard-data-counter">420</div>
            </div>
          </div>
          <div className="flex-badges-dashboard-data">
            <div className="badges up">
              <div className="icon-badges">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                >
                  <path
                    d="M8.96868 6.50001C8.90287 6.50039 8.83764 6.48778 8.77672 6.46289C8.71581 6.43801 8.6604 6.40135 8.61368 6.35501L5.99868 3.74501L3.38368 6.36001C3.33885 6.41235 3.2837 6.45486 3.22166 6.48487C3.15963 6.51488 3.09206 6.53175 3.0232 6.53441C2.95435 6.53707 2.88568 6.52547 2.82152 6.50033C2.75735 6.47519 2.69908 6.43706 2.65035 6.38833C2.60163 6.33961 2.5635 6.28133 2.53836 6.21717C2.51322 6.15301 2.50162 6.08434 2.50428 6.01548C2.50694 5.94662 2.5238 5.87906 2.55382 5.81702C2.58383 5.75499 2.62634 5.69983 2.67868 5.65501L5.64368 2.68501C5.69016 2.63814 5.74546 2.60095 5.80639 2.57556C5.86732 2.55018 5.93267 2.53711 5.99868 2.53711C6.06468 2.53711 6.13004 2.55018 6.19097 2.57556C6.2519 2.60095 6.3072 2.63814 6.35368 2.68501L9.31868 5.65501C9.4118 5.74869 9.46407 5.87542 9.46407 6.00751C9.46407 6.1396 9.4118 6.26633 9.31868 6.36001C9.22474 6.45055 9.09914 6.50079 8.96868 6.50001Z"
                    fill="currentColor"
                  />
                  <path
                    opacity="0.3"
                    d="M6 9.50004C5.86739 9.50004 5.74021 9.44736 5.64645 9.35359C5.55268 9.25982 5.5 9.13265 5.5 9.00004V3.04004C5.5 2.90743 5.55268 2.78025 5.64645 2.68649C5.74021 2.59272 5.86739 2.54004 6 2.54004C6.13261 2.54004 6.25979 2.59272 6.35355 2.68649C6.44732 2.78025 6.5 2.90743 6.5 3.04004V9.00004C6.5 9.13265 6.44732 9.25982 6.35355 9.35359C6.25979 9.44736 6.13261 9.50004 6 9.50004Z"
                    fill="currentColor"
                  />
                </svg>
              </div>
              <div className="text-badges">5%</div>
            </div>
            <div className="content-badges-dashboard-data">than last week</div>
          </div>
        </div>
      </div>
      <div className="item-dashboard-data-counter">
        <div className="dashboard-data-counter">
          <div className="header-dashboard-data-counter">
            <div className="item-header-dashboard-data-counter">
              <div className="tagline-dashboard-data-counter">Hours learned</div>
              <div className="number-dashboard-data-counter">800</div>
            </div>
          </div>
          <div className="flex-badges-dashboard-data">
            <div className="badges down">
              <div className="icon-badges">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                >
                  <path
                    d="M8.96868 6.50001C8.90287 6.50039 8.83764 6.48778 8.77672 6.46289C8.71581 6.43801 8.6604 6.40135 8.61368 6.35501L5.99868 3.74501L3.38368 6.36001C3.33885 6.41235 3.2837 6.45486 3.22166 6.48487C3.15963 6.51488 3.09206 6.53175 3.0232 6.53441C2.95435 6.53707 2.88568 6.52547 2.82152 6.50033C2.75735 6.47519 2.69908 6.43706 2.65035 6.38833C2.60163 6.33961 2.5635 6.28133 2.53836 6.21717C2.51322 6.15301 2.50162 6.08434 2.50428 6.01548C2.50694 5.94662 2.5238 5.87906 2.55382 5.81702C2.58383 5.75499 2.62634 5.69983 2.67868 5.65501L5.64368 2.68501C5.69016 2.63814 5.74546 2.60095 5.80639 2.57556C5.86732 2.55018 5.93267 2.53711 5.99868 2.53711C6.06468 2.53711 6.13004 2.55018 6.19097 2.57556C6.2519 2.60095 6.3072 2.63814 6.35368 2.68501L9.31868 5.65501C9.4118 5.74869 9.46407 5.87542 9.46407 6.00751C9.46407 6.1396 9.4118 6.26633 9.31868 6.36001C9.22474 6.45055 9.09914 6.50079 8.96868 6.50001Z"
                    fill="currentColor"
                  />
                  <path
                    opacity="0.3"
                    d="M6 9.50004C5.86739 9.50004 5.74021 9.44736 5.64645 9.35359C5.55268 9.25982 5.5 9.13265 5.5 9.00004V3.04004C5.5 2.90743 5.55268 2.78025 5.64645 2.68649C5.74021 2.59272 5.86739 2.54004 6 2.54004C6.13261 2.54004 6.25979 2.59272 6.35355 2.68649C6.44732 2.78025 6.5 2.90743 6.5 3.04004V9.00004C6.5 9.13265 6.44732 9.25982 6.35355 9.35359C6.25979 9.44736 6.13261 9.50004 6 9.50004Z"
                    fill="currentColor"
                  />
                </svg>
              </div>
              <div className="text-badges-dashboard-data">4.7%</div>
            </div>
            <div className="content-badges-dashboard-data">than last week</div>
          </div>
        </div>
      </div>
      <div className="item-dashboard-data-counter">
        <div className="dashboard-data-counter">
          <div className="header-dashboard-data-counter">
            <div className="item-header-dashboard-data-counter">
              <div className="tagline-dashboard-data-counter">
                Avg. Completion rate
              </div>
              <div className="number-dashboard-data-counter">80%</div>
            </div>
          </div>
          <div className="flex-badges-dashboard-data">
            <div className="badges up">
              <div className="icon-badges">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                >
                  <path
                    d="M8.96868 6.50001C8.90287 6.50039 8.83764 6.48778 8.77672 6.46289C8.71581 6.43801 8.6604 6.40135 8.61368 6.35501L5.99868 3.74501L3.38368 6.36001C3.33885 6.41235 3.2837 6.45486 3.22166 6.48487C3.15963 6.51488 3.09206 6.53175 3.0232 6.53441C2.95435 6.53707 2.88568 6.52547 2.82152 6.50033C2.75735 6.47519 2.69908 6.43706 2.65035 6.38833C2.60163 6.33961 2.5635 6.28133 2.53836 6.21717C2.51322 6.15301 2.50162 6.08434 2.50428 6.01548C2.50694 5.94662 2.5238 5.87906 2.55382 5.81702C2.58383 5.75499 2.62634 5.69983 2.67868 5.65501L5.64368 2.68501C5.69016 2.63814 5.74546 2.60095 5.80639 2.57556C5.86732 2.55018 5.93267 2.53711 5.99868 2.53711C6.06468 2.53711 6.13004 2.55018 6.19097 2.57556C6.2519 2.60095 6.3072 2.63814 6.35368 2.68501L9.31868 5.65501C9.4118 5.74869 9.46407 5.87542 9.46407 6.00751C9.46407 6.1396 9.4118 6.26633 9.31868 6.36001C9.22474 6.45055 9.09914 6.50079 8.96868 6.50001Z"
                    fill="currentColor"
                  />
                  <path
                    opacity="0.3"
                    d="M6 9.50004C5.86739 9.50004 5.74021 9.44736 5.64645 9.35359C5.55268 9.25982 5.5 9.13265 5.5 9.00004V3.04004C5.5 2.90743 5.55268 2.78025 5.64645 2.68649C5.74021 2.59272 5.86739 2.54004 6 2.54004C6.13261 2.54004 6.25979 2.59272 6.35355 2.68649C6.44732 2.78025 6.5 2.90743 6.5 3.04004V9.00004C6.5 9.13265 6.44732 9.25982 6.35355 9.35359C6.25979 9.44736 6.13261 9.50004 6 9.50004Z"
                    fill="currentColor"
                  />
                </svg>
              </div>
              <div className="text-badges">1%</div>
            </div>
            <div className="content-badges-dashboard-data">than last week</div>
          </div>
        </div>
      </div>
      <div className="item-dashboard-data-counter">
        <div className="dashboard-data-counter">
          <div className="header-dashboard-data-counter">
            <div className="item-header-dashboard-data-counter">
              <div className="tagline-dashboard-data-counter">Added Users</div>
              <div className="number-dashboard-data-counter">3.1k</div>
            </div>
            <div className="item-header-dashboard-data-counter">
              <div className="icon-dashboard-data-counter">
                <div className="badges up">
                  <div className="icon-badges">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                    >
                      <path
                        d="M8.96868 6.50001C8.90287 6.50039 8.83764 6.48778 8.77672 6.46289C8.71581 6.43801 8.6604 6.40135 8.61368 6.35501L5.99868 3.74501L3.38368 6.36001C3.33885 6.41235 3.2837 6.45486 3.22166 6.48487C3.15963 6.51488 3.09206 6.53175 3.0232 6.53441C2.95435 6.53707 2.88568 6.52547 2.82152 6.50033C2.75735 6.47519 2.69908 6.43706 2.65035 6.38833C2.60163 6.33961 2.5635 6.28133 2.53836 6.21717C2.51322 6.15301 2.50162 6.08434 2.50428 6.01548C2.50694 5.94662 2.5238 5.87906 2.55382 5.81702C2.58383 5.75499 2.62634 5.69983 2.67868 5.65501L5.64368 2.68501C5.69016 2.63814 5.74546 2.60095 5.80639 2.57556C5.86732 2.55018 5.93267 2.53711 5.99868 2.53711C6.06468 2.53711 6.13004 2.55018 6.19097 2.57556C6.2519 2.60095 6.3072 2.63814 6.35368 2.68501L9.31868 5.65501C9.4118 5.74869 9.46407 5.87542 9.46407 6.00751C9.46407 6.1396 9.4118 6.26633 9.31868 6.36001C9.22474 6.45055 9.09914 6.50079 8.96868 6.50001Z"
                        fill="currentColor"
                      />
                      <path
                        opacity="0.3"
                        d="M6 9.50004C5.86739 9.50004 5.74021 9.44736 5.64645 9.35359C5.55268 9.25982 5.5 9.13265 5.5 9.00004V3.04004C5.5 2.90743 5.55268 2.78025 5.64645 2.68649C5.74021 2.59272 5.86739 2.54004 6 2.54004C6.13261 2.54004 6.25979 2.59272 6.35355 2.68649C6.44732 2.78025 6.5 2.90743 6.5 3.04004V9.00004C6.5 9.13265 6.44732 9.25982 6.35355 9.35359C6.25979 9.44736 6.13261 9.50004 6 9.50004Z"
                        fill="currentColor"
                      />
                    </svg>
                  </div>
                  <div className="text-badges">2.2%</div>
                </div>
              </div>
            </div>
          </div>
          {/*<div className="user-chart-dashboard-data">
            {data && <Line data={data} options={options} />}
          </div>*/}
        </div>
      </div>
    </div>
  );
}

export default DashboardDataCounter;
