import React, {useEffect, useState} from 'react';
import ModalWrapper from "../../../../organisms/modal-wrapper/ModalWrapper";
import CopyBox from "../../../../atoms/copy-box/CopyBox";
import {GlobeIcon} from "../../../../assets/Icons";
import {http_post} from "../../../utils/api_client";
import {API_ENDPOINTS} from "../../../utils/endpoints";
import DomainInput from "./DomainInput";
import Button from "../../../../atoms/button/Button";

const DomainModal = ({onCloseModal, onSubmit, domain}) => {
  const modalId = 'instructionSubdomainModal';
  const ipAddress = '44.233.12.82';
  const appDomain = 'usernurture.com';
  const [isCreating, setIsCreating] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [name, setName] = useState('');
  const [submitDisabled, setSubmitDisabled] = useState(true);

  const handleClose = () => {
    $(`#${modalId}`).modal('hide');
    onCloseModal();
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsCreating(true);
    const data = {
      domain_name: name
    };

    http_post(API_ENDPOINTS.POST_DOMAIN, data)
      .then((res) => {
        handleClose();
        window.location.reload();
      })
      .catch((err) => {
        setErrorMsg(err.response.data.error);
      })
      .finally(() => {
        setIsCreating(false);
      });
  };

  const handleDomainChange = (e) => {
    setName(e.value);
    setSubmitDisabled(!e.valid);
    setErrorMsg('');
  }

  useEffect(() => {
    setSubmitDisabled(!!errorMsg.length);
  }, [errorMsg]);

  const footer = (
    <div className={'un-domain-instruction__footer'}>
      <Button onClick={handleClose} className={'ghost'}>Cancel</Button>
      <Button onClick={handleSubmit} isLoading={isCreating} disabled={submitDisabled}>Connect</Button>
    </div>
  );

  return (
    <ModalWrapper modalId={"instructionSubdomainModal"} onCloseModal={onCloseModal} footer={footer}>
      <div className={'un-domain-instruction'}>
        <h4>Connecting Your Domain and Subdomain</h4>

        <section>
          <p>
            <b>Enter your domain/subdomain</b>
          </p>
          <DomainInput onChange={handleDomainChange} errorMsg={errorMsg}/>
        </section>

        <section>
          <p>
            <b>Connect a Domain</b>
          </p>
          <p>
            Copy the following IP address into the A record named <b>{appDomain}</b> for <b>{name}</b>. Set target
            to the IP address below:
          </p>
          <CopyBox icon={<GlobeIcon/>}>
            {ipAddress}
          </CopyBox>
        </section>

        <section>
          <p>
            <b>Connect a Subdomain</b>
          </p>
          <p>
            Copy the following CNAME record into the CNAME record for <b>tester</b> for <b>{appDomain}</b>. If the
            record
            does not exist in your domain DNS settings, create it.
          </p>
          <CopyBox icon={<GlobeIcon/>}>
            {appDomain}
          </CopyBox>
        </section>
      </div>
    </ModalWrapper>
  );
};

DomainModal.defaultProps = {
  onCloseModal: () => {},
  onSubmit: () => {},
  domain: ''
};

export default DomainModal;
