import React from "react";

const options = [
  // {
  //   title: "Elements",
  //   id: "elements",
  // },
  // {
  //   title: "Library",
  //   id: "library",
  // },
  {
    title: "Design",
    id: "design",
  },
];

function Header({ activeSection, setActiveSection }) {
  return (
    <div className="menu-tab switch-component-button-parts">
      {options.map((option) => {
        return (
          <div key={option.id} className="item-tab" style={{ width: "100%"}}>
            <div
              onClick={() => setActiveSection(option.id)}
              className={`menu-tab button-switch-component ${
                option.id === activeSection ? "active" : ""
              }`}
              style={{ cursor: "pointer" }}
            >
              {option.title}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default Header;
