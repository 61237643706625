import React from "react";

export const CongratsScore = () => {
  return (
    <svg width="172" height="159" viewBox="0 0 172 159" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="86" cy="81" r="62" fill="#DCFEFE" fillOpacity="0.6"/>
      <circle cx="85.9999" cy="80.9999" r="46.1395" fill="#BDEDF5" fillOpacity="0.8"/>
      <circle cx="85.9999" cy="80.9999" r="24.5116" fill="#3BA4B4"/>
      <path d="M93.667 75L82.667 86L77.667 81" stroke="white" strokeWidth="3" strokeLinecap="round"
            strokeLinejoin="round"/>
      <path
        d="M30.1665 19L31.1768 22.1094H34.4463L31.8012 24.0312L32.8115 27.1406L30.1665 25.2188L27.5215 27.1406L28.5318 24.0312L25.8867 22.1094H29.1562L30.1665 19Z"
        fill="#DFAE5B"/>
      <path
        d="M145.167 116L146.177 119.109H149.446L146.801 121.031L147.812 124.141L145.167 122.219L142.521 124.141L143.532 121.031L140.887 119.109H144.156L145.167 116Z"
        fill="#A9D9F9"/>
      <path
        d="M10.1665 33L10.9523 35.4184H13.4952L11.438 36.9131L12.2238 39.3316L10.1665 37.8369L8.10926 39.3316L8.89505 36.9131L6.83781 35.4184H9.3807L10.1665 33Z"
        fill="#3292DB"/>
      <path
        d="M26.6665 133L27.5646 135.764H30.4707L28.1196 137.472L29.0176 140.236L26.6665 138.528L24.3154 140.236L25.2134 137.472L22.8623 135.764H25.7684L26.6665 133Z"
        fill="#9591D4"/>
      <path
        d="M109.667 3L110.565 5.76393H113.471L111.12 7.47214L112.018 10.2361L109.667 8.52786L107.315 10.2361L108.213 7.47214L105.862 5.76393H108.768L109.667 3Z"
        fill="#3292DB"/>
      <path
        d="M5.6665 86L6.78907 89.4549H10.4218L7.48286 91.5902L8.60543 95.0451L5.6665 92.9098L2.72758 95.0451L3.85015 91.5902L0.911222 89.4549H4.54393L5.6665 86Z"
        fill="#3292DB"/>
      <path
        d="M103.167 152L103.952 154.418H106.495L104.438 155.913L105.224 158.332L103.167 156.837L101.109 158.332L101.895 155.913L99.8378 154.418H102.381L103.167 152Z"
        fill="#3292DB"/>
      <path
        d="M81.6665 6L82.34 8.07295H84.5197L82.7563 9.3541L83.4299 11.4271L81.6665 10.1459L79.9031 11.4271L80.5767 9.3541L78.8133 8.07295H80.993L81.6665 6Z"
        fill="#3292DB"/>
      <path
        d="M138.167 145L138.952 147.418H141.495L139.438 148.913L140.224 151.332L138.167 149.837L136.109 151.332L136.895 148.913L134.838 147.418H137.381L138.167 145Z"
        fill="#DFAE5B"/>
      <path
        d="M14.5 112L15.2858 114.418H17.8287L15.7714 115.913L16.5572 118.332L14.5 116.837L12.4428 118.332L13.2286 115.913L11.1713 114.418H13.7142L14.5 112Z"
        fill="#DFAE5B"/>
      <path
        d="M167.167 101L167.952 103.418H170.495L168.438 104.913L169.224 107.332L167.167 105.837L165.109 107.332L165.895 104.913L163.838 103.418H166.381L167.167 101Z"
        fill="#DFAE5B"/>
      <path
        d="M55.1665 148L55.9523 150.418H58.4952L56.438 151.913L57.2238 154.332L55.1665 152.837L53.1093 154.332L53.8951 151.913L51.8378 150.418H54.3807L55.1665 148Z"
        fill="#DFAE5B"/>
      <path
        d="M154.667 40L155.565 42.7639H158.471L156.12 44.4721L157.018 47.2361L154.667 45.5279L152.315 47.2361L153.213 44.4721L150.862 42.7639H153.768L154.667 40Z"
        fill="#DFAE5B"/>
      <path
        d="M8.1665 60L9.17682 63.1094H12.4463L9.80122 65.0312L10.8115 68.1406L8.1665 66.2188L5.52147 68.1406L6.53178 65.0312L3.88675 63.1094H7.15619L8.1665 60Z"
        fill="#9591D4"/>
      <path
        d="M139.167 15L139.952 17.4184H142.495L140.438 18.9131L141.224 21.3316L139.167 19.8369L137.109 21.3316L137.895 18.9131L135.838 17.4184H138.381L139.167 15Z"
        fill="#9591D4"/>
      <path
        d="M51.6665 0L53.0136 4.1459H57.3728L53.8461 6.7082L55.1932 10.8541L51.6665 8.2918L48.1398 10.8541L49.4869 6.7082L45.9602 4.1459H50.3194L51.6665 0Z"
        fill="#9591D4"/>
      <path
        d="M157.167 73L158.177 76.1094H161.446L158.801 78.0312L159.812 81.1406L157.167 79.2188L154.521 81.1406L155.532 78.0312L152.887 76.1094H156.156L157.167 73Z"
        fill="#9591D4"/>
    </svg>
  )
}

export const AngryFace = () => {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="28.0001" cy="27.9996" r="21.3492" fill="#D8D8D8"/>
      <circle cx="19.8668" cy="21.8995" r="3.04989" fill="#B9B9B9"/>
      <circle cx="36.1329" cy="21.8995" r="3.04989" fill="#B9B9B9"/>
      <path
        d="M36.4096 35.0021C37.025 36.6556 35.6548 38.1658 33.8905 38.1658C32.1904 38.1658 29.9797 38.1658 27.641 38.1658C25.4989 38.1658 23.5932 38.1658 22.1084 38.1658C20.3441 38.1658 18.9746 36.6556 19.59 35.0021C20.5742 32.3578 22.8389 29.0161 27.9998 29.0161C33.1606 29.0161 35.4253 32.3578 36.4096 35.0021Z"
        fill="#B9B9B9"/>
      <path
        d="M15.1323 14.8172C15.6624 14.1104 17.5 12.3788 20.6098 11.1066C23.7196 9.83445 27.0886 9.752 28.3844 9.86979"
        stroke="#EDEDED" strokeOpacity="0.5" strokeWidth="2.12033" strokeLinecap="round"/>
      <path
        d="M12.4817 18.312C12.4817 18.312 11.4217 19.3722 10.7148 21.8459C10.0079 24.3196 10.1847 25.5565 10.1847 25.5565"
        stroke="#EDEDED" strokeOpacity="0.5" strokeWidth="2.12033" strokeLinecap="round"/>
    </svg>
  )
}

export const SadFace = () => {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="28.0579" cy="28.0203" r="21.3933" fill="#D8D8D8"/>
      <circle cx="19.9078" cy="21.9078" r="3.05619" fill="#B9B9B9"/>
      <circle cx="36.2071" cy="21.9078" r="3.05619" fill="#B9B9B9"/>
      <g clipPath="url(#clip0_2637_1374)">
        <path
          d="M36.6451 37.5919C37.2846 37.4038 37.6703 36.7435 37.4488 36.1148C36.7884 34.2401 35.6736 32.5907 34.2166 31.352C32.4704 29.8675 30.3294 29.055 28.1226 29.0393C25.9158 29.0236 23.7654 29.8057 22.0019 31.2652C20.5302 32.4834 19.396 34.1171 18.7137 35.9827C18.485 36.6081 18.8617 37.2725 19.4978 37.4693C20.1803 37.6805 20.8929 37.2723 21.1698 36.6137C21.6929 35.3697 22.4893 34.2799 23.4948 33.4477C24.8235 32.3479 26.4438 31.7587 28.1065 31.7705C29.7693 31.7823 31.3825 32.3945 32.6982 33.513C33.695 34.3604 34.4791 35.4629 34.9875 36.716C35.2556 37.3766 35.9611 37.7931 36.6451 37.5919Z"
          fill="#B9B9B9"/>
      </g>
      <path
        d="M15.1636 14.8109C15.6948 14.1027 17.5362 12.3675 20.6524 11.0927C23.7686 9.81786 27.1446 9.73523 28.443 9.85327"
        stroke="#EDEDED" strokeOpacity="0.5" strokeWidth="2.12471" strokeLinecap="round"/>
      <path
        d="M12.5074 18.313C12.5074 18.313 11.4453 19.3753 10.7369 21.8542C10.0286 24.333 10.2057 25.5724 10.2057 25.5724"
        stroke="#EDEDED" strokeOpacity="0.5" strokeWidth="2.12471" strokeLinecap="round"/>
      <defs>
        <clipPath id="clip0_2637_1374">
          <rect width="20.1434" height="9.06454" fill="white" transform="translate(17.9858 29.0391)"/>
        </clipPath>
      </defs>
    </svg>
  )
}

export const NeutralFace = () => {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="27.7233" cy="27.607" r="21.3492" fill="#D8D8D8"/>
      <circle cx="19.5899" cy="21.5069" r="3.04989" fill="#B9B9B9"/>
      <circle cx="35.856" cy="21.5069" r="3.04989" fill="#B9B9B9"/>
      <rect x="18.4893" y="28.6235" width="18.4677" height="3.07795" rx="1.42656" fill="#B9B9B9"/>
      <path
        d="M14.8555 14.4246C15.3856 13.7179 17.2232 11.9863 20.333 10.7141C23.4428 9.44188 26.8118 9.35942 28.1075 9.47721"
        stroke="#EDEDED" strokeOpacity="0.5" strokeWidth="2.12033" strokeLinecap="round"/>
      <path
        d="M12.2048 17.9199C12.2048 17.9199 11.1449 18.9801 10.438 21.4538C9.73104 23.9275 9.90782 25.1644 9.90782 25.1644"
        stroke="#EDEDED" strokeOpacity="0.5" strokeWidth="2.12033" strokeLinecap="round"/>
    </svg>
  )
}

export const HappyFace = () => {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="27.7228" cy="27.607" r="21.3492" fill="#D8D8D8"/>
      <circle cx="19.5899" cy="21.5069" r="3.04989" fill="#B9B9B9"/>
      <circle cx="35.856" cy="21.5069" r="3.04989" fill="#B9B9B9"/>
      <g clipPath="url(#clip0_2637_1395)">
        <path
          d="M36.4946 29.1463C37.1478 29.3384 37.5417 30.0128 37.3155 30.655C36.6409 32.5699 35.5023 34.2546 34.014 35.5198C32.2305 37.0361 30.0436 37.8661 27.7895 37.8821C25.5354 37.8981 23.3389 37.0993 21.5377 35.6085C20.0344 34.3642 18.876 32.6955 18.179 30.7899C17.9454 30.1511 18.3301 29.4725 18.9799 29.2714C19.6771 29.0557 20.4049 29.4727 20.6877 30.1454C21.222 31.4161 22.0355 32.5292 23.0625 33.3793C24.4197 34.5026 26.0747 35.1045 27.7731 35.0924C29.4715 35.0803 31.1193 34.455 32.4631 33.3125C33.4813 32.4469 34.2822 31.3209 34.8015 30.041C35.0753 29.3661 35.796 28.9408 36.4946 29.1463Z"
          fill="#B9B9B9"/>
      </g>
      <path
        d="M14.855 14.4246C15.3851 13.7179 17.2227 11.9863 20.3325 10.7141C23.4423 9.44188 26.8113 9.35942 28.107 9.47721"
        stroke="#EDEDED" strokeOpacity="0.5" strokeWidth="2.12033" strokeLinecap="round"/>
      <path
        d="M12.2043 17.9199C12.2043 17.9199 11.1444 18.9801 10.4375 21.4538C9.73055 23.9275 9.90733 25.1644 9.90733 25.1644"
        stroke="#EDEDED" strokeOpacity="0.5" strokeWidth="2.12033" strokeLinecap="round"/>
      <defs>
        <clipPath id="clip0_2637_1395">
          <rect width="20.575" height="9.25875" fill="white" transform="matrix(1 0 0 -1 17.4355 37.8823)"/>
        </clipPath>
      </defs>
    </svg>
  )
}

export const ExcitedFace = () => {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="28.0001" cy="28.0001" r="21.3492" fill="#D8D8D8"/>
      <circle cx="19.8668" cy="21.9" r="3.04989" fill="#B9B9B9"/>
      <circle cx="36.1329" cy="21.9" r="3.04989" fill="#B9B9B9"/>
      <path
        d="M36.5098 32.2175C37.1325 30.5443 35.7459 29.016 33.9606 29.016C32.2402 29.016 30.0031 29.016 27.6365 29.016C25.4688 29.016 23.5404 29.016 22.0379 29.016C20.2526 29.016 18.8667 30.5443 19.4894 32.2175C20.4854 34.8934 22.7772 38.2749 27.9996 38.2749C33.222 38.2749 35.5138 34.8934 36.5098 32.2175Z"
        fill="#B9B9B9"/>
      <path
        d="M15.1323 14.8177C15.6624 14.1109 17.5 12.3793 20.6098 11.1071C23.7196 9.83494 27.0886 9.75249 28.3844 9.87028"
        stroke="#EDEDED" strokeOpacity="0.5" strokeWidth="2.12033" strokeLinecap="round"/>
      <path
        d="M12.4817 18.313C12.4817 18.313 11.4217 19.3732 10.7148 21.8469C10.0079 24.3206 10.1847 25.5574 10.1847 25.5574"
        stroke="#EDEDED" strokeOpacity="0.5" strokeWidth="2.12033" strokeLinecap="round"/>
    </svg>
  )
}

export const CloseIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M3.52876 3.52876C3.78911 3.26841 4.21122 3.26841 4.47157 3.52876L8.00016 7.05735L11.5288 3.52876C11.7891 3.26841 12.2112 3.26841 12.4716 3.52876C12.7319 3.78911 12.7319 4.21122 12.4716 4.47157L8.94297 8.00016L12.4716 11.5288C12.7319 11.7891 12.7319 12.2112 12.4716 12.4716C12.2112 12.7319 11.7891 12.7319 11.5288 12.4716L8.00016 8.94297L4.47157 12.4716C4.21122 12.7319 3.78911 12.7319 3.52876 12.4716C3.26841 12.2112 3.26841 11.7891 3.52876 11.5288L7.05735 8.00016L3.52876 4.47157C3.26841 4.21122 3.26841 3.78911 3.52876 3.52876Z"
            fill="#8B8B8B"/>
    </svg>
  )
}