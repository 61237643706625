import React from 'react'

const DomainConfiguration = (props) => {
  
  return (
    <>
      <div>DomainConfiguration</div>
    </>
  )
}

export default DomainConfiguration