import React, { useState } from "react";

function Header() {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="flex-icon-template-preview">
      <div className="item-icon-template-preview"></div>
      <div className="cover-icon-quiz-form">
        <div className="icon-active-tamplate-preview">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="9"
            viewBox="0 0 14 9"
            fill="none"
          >
            <rect
              x="14"
              width="2.80072"
              height="2.80072"
              rx="0.700065"
              transform="rotate(90 14 0)"
              fill="currentColor"
            />
            <rect
              x="14"
              y="5.60156"
              width="2.80072"
              height="2.80072"
              rx="0.700065"
              transform="rotate(90 14 5.60156)"
              fill="currentColor"
            />
            <rect
              x="8.39948"
              y="5.60059"
              width="2.80072"
              height="2.80072"
              rx="0.700065"
              transform="rotate(90 8.39948 5.60059)"
              fill="currentColor"
            />
            <rect
              x="8.39948"
              width="2.80072"
              height="2.80072"
              rx="0.700065"
              transform="rotate(90 8.39948 0)"
              fill="currentColor"
            />
            <rect
              x="2.79895"
              width="2.80072"
              height="2.80072"
              rx="0.700065"
              transform="rotate(90 2.79895 0)"
              fill="currentColor"
            />
            <rect
              x="2.79895"
              y="5.60059"
              width="2.80072"
              height="2.80072"
              rx="0.700065"
              transform="rotate(90 2.79895 5.60059)"
              fill="currentColor"
            />
          </svg>
        </div>
      </div>
      <div className="cover-button-icon-quiz-form">
        <div
          onClick={() => setIsActive((prev) => !prev)}
          className="button-action-template-preview"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M10.0001 13.3337C9.08342 13.3337 8.33342 14.0837 8.33341 15.0003C8.33341 15.917 9.08341 16.667 10.0001 16.667C10.9167 16.667 11.6667 15.917 11.6667 15.0003C11.6667 14.0837 10.9167 13.3337 10.0001 13.3337ZM10.0001 11.667C10.9167 11.667 11.6667 10.917 11.6667 10.0003C11.6667 9.08366 10.9167 8.33366 10.0001 8.33366C9.08342 8.33366 8.33342 9.08366 8.33342 10.0003C8.33342 10.917 9.08342 11.667 10.0001 11.667ZM10.0001 6.66699C10.9167 6.66699 11.6667 5.91699 11.6667 5.00033C11.6667 4.08366 10.9167 3.33366 10.0001 3.33366C9.08342 3.33366 8.33342 4.08366 8.33342 5.00033C8.33342 5.91699 9.08342 6.66699 10.0001 6.66699Z"
              fill="currentColor"
            />
          </svg>
        </div>
        <div
          className="popup-action-template"
          style={{ display: isActive ? "block" : "none" }}
        >
          <div className="list-popup-action-template">
            <div className="item-list-popup-action-template">
              <div className="button-action-popup">
                <div className="icon-button-action-popup">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M14.7391 4C15.6478 4 16.4209 4.58077 16.7074 5.39137L9.95845 5.3913C8.71808 5.3913 8.26829 5.52045 7.81482 5.76297C7.36136 6.00548 7.00548 6.36136 6.76297 6.81482C6.52045 7.26829 6.3913 7.71808 6.3913 8.95845L6.39137 16.403C5.58077 16.1165 5 15.3435 5 14.4348V8.17391C5 5.86872 6.86872 4 9.17391 4H14.7391ZM17.5217 6.78261C18.6743 6.78261 19.6087 7.71697 19.6087 8.86957V17.913C19.6087 19.0656 18.6743 20 17.5217 20H9.86957C8.71697 20 7.78261 19.0656 7.78261 17.913V8.86957C7.78261 7.71697 8.71697 6.78261 9.86957 6.78261H17.5217ZM17.5217 8.17391H9.86957C9.48537 8.17391 9.17391 8.48537 9.17391 8.86957V17.913C9.17391 18.2972 9.48537 18.6087 9.86957 18.6087H17.5217C17.9059 18.6087 18.2174 18.2972 18.2174 17.913V8.86957C18.2174 8.48537 17.9059 8.17391 17.5217 8.17391Z"
                      fill="currentColor"
                    />
                  </svg>
                </div>
                <div className="text-button-action-popup">Duplicate</div>
              </div>
            </div>
            <div className="item-list-popup-action-template">
              <div className="button-action-popup button-light">
                <div className="icon-button-action-popup">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M17.442 14.3652C17.8014 14.6732 18.3425 14.6316 18.6506 14.2722C18.9586 13.9128 18.917 13.3717 18.5576 13.0636L12.5576 7.92073C12.2366 7.64559 11.7629 7.64559 11.442 7.92073L5.44195 13.0636C5.08253 13.3717 5.04091 13.9128 5.34898 14.2722C5.65706 14.6316 6.19817 14.6732 6.5576 14.3652L11.9998 9.70044L17.442 14.3652Z"
                      fill="currentColor"
                    />
                  </svg>
                </div>
                <div className="text-button-action-popup">Move Up</div>
              </div>
            </div>
            <div className="item-list-popup-action-template">
              <div className="button-action-popup">
                <div className="icon-button-action-popup">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M6.55805 9.63483C6.19862 9.32676 5.65751 9.36838 5.34943 9.7278C5.04136 10.0872 5.08298 10.6283 5.4424 10.9364L11.4424 16.0793C11.7634 16.3544 12.2371 16.3544 12.558 16.0793L18.558 10.9364C18.9175 10.6283 18.9591 10.0872 18.651 9.7278C18.3429 9.36838 17.8018 9.32676 17.4424 9.63483L12.0002 14.2996L6.55805 9.63483Z"
                      fill="currentColor"
                    />
                  </svg>
                </div>
                <div className="text-button-action-popup">Move Down</div>
              </div>
            </div>
            <div className="item-list-popup-action-template">
              <div className="button-action-popup remove">
                <div className="icon-button-action-popup">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M11.8298 4C13.4477 4 14.5023 4.83988 14.8403 6.38266L18.9787 6.38298C19.3547 6.38298 19.6596 6.68781 19.6596 7.06383C19.6596 7.43985 19.3547 7.74468 18.9787 7.74468H18.2264L17.1287 18.1713C17.0192 19.2108 16.1426 20 15.0973 20H8.56224C7.51694 20 6.64033 19.2108 6.53091 18.1713L5.43319 7.74468H4.68085C4.30483 7.74468 4 7.43985 4 7.06383C4 6.68781 4.30483 6.38298 4.68085 6.38298L8.81924 6.38266C9.15732 4.83988 10.2118 4 11.8298 4ZM16.8565 7.74468H6.80238L7.88513 18.0287C7.9216 18.3752 8.21381 18.6383 8.56224 18.6383H15.0973C15.4458 18.6383 15.738 18.3752 15.7744 18.0287L16.8565 7.74468ZM11.8298 9.10638C12.2058 9.10638 12.5106 9.41121 12.5106 9.78723V16.5957C12.5106 16.9718 12.2058 17.2766 11.8298 17.2766C11.4538 17.2766 11.1489 16.9718 11.1489 16.5957V9.78723C11.1489 9.41121 11.4538 9.10638 11.8298 9.10638ZM14.5872 9.10723C14.9627 9.12601 15.252 9.44568 15.2332 9.82123L14.8928 16.6297C14.874 17.0053 14.5543 17.2945 14.1788 17.2757C13.8032 17.257 13.514 16.9373 13.5328 16.5617L13.8732 9.75323C13.892 9.37768 14.2116 9.08845 14.5872 9.10723ZM9.07238 9.10723C9.44794 9.08845 9.76761 9.37768 9.78639 9.75323L10.1268 16.5617C10.1456 16.9373 9.85636 17.257 9.48081 17.2757C9.10525 17.2945 8.78558 17.0053 8.76681 16.6297L8.42638 9.82123C8.4076 9.44568 8.69683 9.12601 9.07238 9.10723ZM11.8298 5.3617C10.9673 5.3617 10.4683 5.67088 10.2283 6.38268H13.4313C13.1913 5.67088 12.6922 5.3617 11.8298 5.3617Z"
                      fill="currentColor"
                    />
                  </svg>
                </div>
                <div className="text-button-action-popup">Remove</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
