// take a screenshot of shared screen
function getUserMedia(options) {
  if (navigator.mediaDevices.getDisplayMedia) {
    return navigator.mediaDevices.getDisplayMedia(options);
  }
  if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
    return navigator.mediaDevices.getUserMedia(options);
  }
  if (navigator.getUserMedia) {
    return navigator.getUserMedia(options);
  }
  if (navigator.webkitGetUserMedia) {
    return navigator.webkitGetUserMedia(options);
  }
  if (navigator.mozGetUserMedia) {
    return navigator.mozGetUserMedia(options);
  }
  if (navigator.msGetUserMedia) {
    return navigator.msGetUserMedia(options);
  }

  throw new Error('getUserMedia is not defined');
}

async function takeScreenshotStream() {
  const width = screen.width * (window.devicePixelRatio || 1);
  const height = screen.height * (window.devicePixelRatio || 1);
  const errors = [];
  let stream;
  try {
    stream = await getUserMedia({
      audio: false,
      video: true
    });
  } catch (ex) {
    errors.push(ex);
  }

  if (errors.length) {
    console.debug(...errors);
  }
  return stream;
}

async function takeScreenshotCanvas() {
  const stream = await takeScreenshotStream();

  if (!stream) {
    return null;
  }

  const video = document.createElement('video');
  const result = await new Promise((resolve, reject) => {
    video.onloadedmetadata = () => {
      video.play();
      video.pause();

      const canvas = document.createElement('canvas');
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const context = canvas.getContext('2d');
      context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
      resolve(canvas);
    };
    video.srcObject = stream;
  });

  stream.getTracks().forEach(function (track) {
    track.stop();
  });

  return result;
}

// from: https://stackoverflow.com/a/46182044/5221762
function getJpegBlob(canvas) {
  return new Promise((resolve, reject) => {
    canvas.toBlob((blob) => resolve(blob), 'image/jpeg', 0.95);
    canvas.toDataURL('image/jpeg');
  });
}

export const takeScreenshotJpegBlob = async () => {
  const canvas = await takeScreenshotCanvas();
  if (!canvas) {
    return null;
  }
  return getJpegBlob(canvas);
};

$('.take-screenshot').on('click', async function () {
  // take the screenshot
  var screenshotJpegBlob = await takeScreenshotJpegBlob();
  if (screenshotJpegBlob) {
    var formData = new FormData();
    var $input, url, id, data_id;
    data_id = $(this).data('id').split('_');
    id = data_id[0];
    learning_id = $(this).data('learning_id');
    if (material == 'demo') {
      url =
        '/' +
        data_id[1] +
        '/d/' +
        learning_id +
        '/add_section_image.js?section_id=' +
        id;
      formData.append('page_image[image]', screenshotJpegBlob);
    } else if (material == 'walkthrough') {
      url =
        '/' +
        data_id[1] +
        '/w/' +
        learning_id +
        '/add_step_image.js?step_id=' +
        id;
      formData.append('page_image[image]', screenshotJpegBlob);
    } else {
      $input = $('.' + material + '_section_image_upload');
      formData.append(
        material + '_section_page[image_video]',
        screenshotJpegBlob
      );
      url =
        '/' +
        data_id[1] +
        '/' +
        material +
        '_section_pages/' +
        id +
        '/upload_image';
    }
    $.ajax({
      url: url,
      method: 'post',
      data: formData,
      contentType: false,
      processData: false
    });
  }
});
