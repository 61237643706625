import React, { useEffect, useState, useRef } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getActiveStep, selectActiveLayer, setActiveLayer } from '../../../../../../../redux/toolsSlice';
import { getLayerObjConfig, removeLayerConfig, setLayerConfig, updateLayer } from '../../../../../../../redux/stepsSlice';
import FontOption from '../../../../../../organisms/editor-design/options/FontOption';

export const BodyTextLayerContentDefaults = (id) => {
  return {
    id: id,
    type: 'body',
    fontSize: 14,
    color: '#000000',
    fontFamily: 'Open Sans',
    content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
  };
}

function BodyTextContent({ layer }) {

  const [draw, setDraw] = useState(false);

  const textRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      setDraw(true);
    }, 1000);
  }, []);

  const dispatch = useDispatch();
  const layerObjConfig = useSelector((state) => getLayerObjConfig(state, layer), shallowEqual);

  const activeStep = useSelector((state) => getActiveStep(state));
  const stepId = activeStep.id;
  const activeLayer = useSelector((state) => selectActiveLayer(state));
  const isEditing = activeLayer && activeLayer.id == layer.id;

  useEffect(() => {
    if (layerObjConfig) {
      const newLayer = { ...layer, ...layerObjConfig };
      dispatch(updateLayer({ stepId, layer: newLayer }));
    }
  }, [layerObjConfig]);

  const defaultConfig = [
    {
      layerId: layer.id,
      sectionId: 'body',
      sectionTitle: 'Body Element Design',
      values: [
        {
          id: 'fill',
          title: 'Text Color',
          type: 'color',
          value: layer.fill || '#000000'
        },
        {
          id: 'font',
          title: 'Font',
          type: 'font',
          value: layer.font || 'Open Sans'
        },
        {
          id: 'fontSize',
          title: 'Font Size',
          type: 'px-size',
          value: layer.fontSize || 14
        },
      ]
    }
  ];

  const EditElement = () => {
    removeFocusElement();
    dispatch(setActiveLayer(layer));
    dispatch(setLayerConfig(defaultConfig));
  }

  const removeFocusElement = () => {
    dispatch(setActiveLayer({}));
    dispatch(removeLayerConfig());
  }

  const updateText = () => {
    const newLayer = {
      ...layer,
      content: textRef.current ? textRef.current.value : layer.text
    };
    dispatch(updateLayer({ stepId: stepId, layer: newLayer }));
  };

  const handleEditBlur = (e) => {
    removeFocusElement();
    updateText();
  };

  const onPressEnter = (e) => {
    if (e.key === "Escape") {
      removeFocusElement();
      updateText();
    }
  };

  // const handleInput = (e) => {
  //   if (textRef.current) {
  //     textRef.current.style.height = "auto";
  //     textRef.current.style.height = `${e.target.scrollHeight - 70}px`;
  //   }
  // };

  return (
    <>
      <div className="container-large-text">
        {isEditing ? (
          <>
            <textarea
              ref={textRef}
              defaultValue={layer.content}
              onBlur={handleEditBlur}
              onKeyDown={onPressEnter}
              // onInput={handleInput}
              style={{
                fontSize: layer.fontSize,
                color: layer.fill,
                fontFamily: layer.font,
                height: '100%',
                width: '100%'
              }}
            />
          </>
        ) : (
          <>
            {draw && <BodyTextContentPreview layer={layer} EditElement={EditElement} />}
          </>
        )}
      </div>
    </>
  )
}

export const BodyTextContentPreview = ({ layer, EditElement }) => {
  return (
    <>
      <p
        tabIndex={0}
        onClick={EditElement}
        style={{
          fontSize: layer.fontSize,
          color: layer.fill,
          fontFamily: layer.font,
        }}>
        {layer.content}
      </p>
      <div hidden>
        <FontOption defaultValue={layer.font} />
      </div>
    </>
  )
}

export default BodyTextContent;