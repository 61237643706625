import axios from 'axios';
import { CONSTS } from './endpoints';

export const http_get = (url, params = {}) => {
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem(CONSTS.AUTH_TOKEN)}`
    },
    params: params
  });
};

export const http_post = (url, data = {}, params = {}) => {
  return axios.post(url, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem(CONSTS.AUTH_TOKEN)}`
    },
    params
  });
};

export const http_multipart_post = (url, data = {}, params = {}) => {
  return axios.post(url, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem(CONSTS.AUTH_TOKEN)}`,
      'Content-Type': 'multipart/form-data'
    },
    params
  });
};

export const http_put = (url, data = {}, params = {}) => {
  return axios.put(url, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem(CONSTS.AUTH_TOKEN)}`
    },
    params
  });
};

export const http_delete = (url) => {
  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem(CONSTS.AUTH_TOKEN)}`
    }
  });
};