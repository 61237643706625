import React, { useState, useEffect } from 'react';

export default function JustifyHorizontal({ defaultValue, onChange, options }) {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleOnClick = (option) => {
    setValue(option);
    onChange(option);
  };

  return (
    <>
      <div className="btn-group btn-group-sm" role="group" aria-label="justifyHorizontal">
        {options.map((option) => {
          return <button
            type="button"
            className="btn btn-outline-primary mr-2 "
            key={option}
            onClick={() => handleOnClick(option)}
            value={option}>
            {option}
          </button>
        })
        }
      </div>
    </>
  )
}