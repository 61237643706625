import React from "react";
import ChatNavbar from "./ChatNavbar";
import ChatFeed from "./ChatFeed";
import ChatInput from "./ChatInput";

function Chat() {
  return (
    <div class="wrapper-message-chat">
      <ChatNavbar />
      <ChatFeed />
      <ChatInput />
    </div>
  );
}

export default Chat;
