import React, { useEffect, useState } from "react";
import ExportModal from "./toolbar-extra/ExportModal";
import SearchInput from "../SearchInput";
import MultiCombobox from "../MultiCombobox";
import DateDropdown from '../DateDropdown';

const activityStatusOptions = [
  {
    value: "engaged",
    label: "Engaged",
  },
  {
    value: "at risk",
    label: "At Risk",
  },
  {
    value: "neutral",
    label: "Neutral",
  },
];

const learningGroupOptions = [
  {
    value: "technology",
    label: "Technology",
  },
  {
    value: "education",
    label: "Education",
  },
  {
    value: "business",
    label: "Business",
  },
  {
    value: "marketing",
    label: "Marketing",
  },
  {
    value: "medicine",
    label: "Medicine",
  },
  {
    value: "contracts",
    label: "Contracts",
  },
  {
    value: "professionals",
    label: "Professionals",
  },
];

function Toolbar({ table }) {
  const [activityFilters, setActivityFilters] = useState([]);
  const [learningGroupFilters, setLearningGroupFilters] = useState([]);

  useEffect(() => {
    const column = table.getColumn("activityStatus");
    if (activityFilters.length > 0) {
      column.setFilterValue(activityFilters);
    } else {
      column.setFilterValue(undefined);
    }
  }, [activityFilters, table]);

  useEffect(() => {
    const column = table.getColumn("learningGroup");
    if (learningGroupFilters.length > 0) {
      column.setFilterValue(learningGroupFilters);
    } else {
      column.setFilterValue(undefined);
    }
  }, [learningGroupFilters, table]);

  return (
    <div className="section-data-toolbar">
      <div className="page-padding">
        <div className="flex-data-toolbar">
          <div className="left-data-toolbar">
            <SearchInput
              placeholder="Search Learner"
              value={table.getColumn("learner")?.getFilterValue() ?? ""}
              setValue={(value) =>
                table.getColumn("learner").setFilterValue(value)
              }
            />
            <div className="toolbar-filter-section">
              <MultiCombobox
                options={activityStatusOptions}
                filters={activityFilters}
                setFilters={setActivityFilters}
                placeholder="Activity Status"
                isSelectAllEnabled
              />
              <MultiCombobox
                options={learningGroupOptions}
                filters={learningGroupFilters}
                setFilters={setLearningGroupFilters}
                placeholder="Learning Group"
                isSelectAllEnabled
              />
              <DateDropdown
                column={table.getColumn("learningStart")}
                placeholder="Learning Start"
              />
              <DateDropdown
                column={table.getColumn("recentActivity")}
                placeholder="Recent Activity"
              />
            </div>
          </div>
          <div className="right-data-toolbar">
            <ExportModal table={table} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Toolbar;
