import React, { useState, useEffect } from 'react';
import { ArrowDown, ArrowUp } from '../../assets/Icons';

const InviteInput = ({
    name,
    value,
    placeholder,
    label,
    onChange,
    className,
    error,
}) => {
    const options = ['Member', 'Team Admin', 'View Only'];
    const [isOpen, setIsOpen] = useState(false);
    const [errorMsg, setErrorMsg] = useState(error);
    const [data, setData] = useState({ email: '', role: 'Member' });

    useEffect(() => {
        // Event listener to close options if clicked outside
        const closeOptions = (e) => {
            if (isOpen && !e.target.closest('.un-invite-input')) {
                setIsOpen(false);
            }
        };
        document.addEventListener('mousedown', closeOptions);

        return () => document.removeEventListener('mousedown', closeOptions);
    }, [isOpen]);

    const validateInput = (e) => {
        const email = e.target.value;
        const regex = /\S+@\S+\.\S+/;
        if (!regex.test(email)) {
            setErrorMsg('Please enter a valid email address');
        } else {
            setData({ ...data, email: email });
            setErrorMsg('');
        }
    };

    const handleOptionClick = (role) => {
        setData({ ...data, role: role });
        setIsOpen(false);
        if (onChange) {
            onChange({
                target: { name: name, value: { ...data, role: role } },
            });
        }
    };

    const renderOptions = () => {
        return options.map((option) => {
            return (
                <div
                    key={option}
                    className="option"
                    onClick={() => handleOptionClick(option)}
                >
                    {option}
                </div>
            );
        });
    };

    const showOptions = () => {
        setIsOpen(!isOpen);
    };

    const handleChange = (e) => {
        setData({ ...data, email: e.target.value });
    };

    useEffect(() => {
        if (onChange) {
            onChange({ target: { name: name, value: data } });
        }
    }, [data]);

    return (
        <div className="un-invite-input">
            <label htmlFor={name}>{label}</label>
            <div className="input-container">
                <input
                    id={name}
                    type="email"
                    className={className}
                    name={name}
                    placeholder={placeholder}
                    onChange={(e) => handleChange(e)}
                    onBlur={(e) => validateInput(e)}
                    value={data.email}
                />
                <div className="role-selector" onClick={showOptions}>
                    {data.role}
                    {isOpen ? <ArrowUp /> : <ArrowDown />}
                </div>
            </div>
            <p className="error-message">{errorMsg}</p>
            {isOpen && <div className="options">{renderOptions()}</div>}
        </div>
    );
};

InviteInput.defaultProps = {
    onChange: () => {},
    className: '',
    placeholder: '',
    label: '',
    name: '',
    value: false,
    error: '',
};

export default InviteInput;
