import React, { useState, useEffect, useRef } from 'react';
import Button from '../../atoms/button/Button';
import { ArrowDown, PlusIcon } from '../../assets/Icons';
import { ENDPOINTS } from '../../pages/utils/endpoints';
import CreateModal from '../create-modal/CreateModal';
import { Provider } from 'react-redux';
import { store } from '../../../redux/store';

const Navbar = ({ configColors, links, profileLinks, rootUrl, userInfo }) => {
  const [dropdown, setDropdown] = useState(false);
  const dropdownRef = useRef(null);

  const logoSrc = require('./default_logo.svg');

  const style = {
    '--background-color': configColors.backgroundColor,
    '--primary-color': configColors.primaryColor,
    '--text-color': configColors.textColor
  };

  const imageSrc = userInfo.avatar || require('./default_avatar.png');

  const toggleDropdown = () => {
    setDropdown(!dropdown);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdown(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const isActive = (url) => {
    if (window.location.pathname === url) {
      return 'active';
    }
  };

  const renderLinks = (links) => {
    if (!links) return null;

    return links.map((link) => {
      return (
        <li key={link.url} className={isActive(link.url)}>
          <a href={link.url}>{link.title}</a>
        </li>
      );
    });
  };

  const handleCreate = () => {
    // show bootstrap modal
    $('#createModal').modal('show');
  };

  return (
    <Provider store={store}>
      <div className="un-navbar" style={style}>
        <div className="left">
          <div className="logo">
            <a href={rootUrl}>
              <img src={logoSrc} alt="logo" />
            </a>
          </div>
          <ul className="menu">{renderLinks(links)}</ul>
        </div>
        <div className="right">
          <CreateModal />
          <Button onClick={handleCreate}>
            <PlusIcon />
            Create
          </Button>
          <div className="separator" />
          <div className="profile" ref={dropdownRef}>
            <div
              className="avatar"
              style={{
                backgroundImage: `url(${imageSrc})`
              }}
            />
            <div className="name" onClick={toggleDropdown}>
              {userInfo.name}
              <ArrowDown />
            </div>
            {dropdown && (
              <div className="dropdown">
                <ul>
                  {renderLinks(profileLinks)}
                  <li>
                    <a
                      rel="nofollow"
                      data-method="delete"
                      href={ENDPOINTS.USER_SIGNOUT}
                    >
                      Logout
                    </a>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </Provider>
  );
};

Navbar.defaultProps = {
  configColors: {
    primaryColor: '#21C5DC',
    textColor: '#FFFFFF',
    backgroundColor: '#21304F'
  },
  rootUrl: '/',
  userInfo: {
    name: 'User',
    avatar: null
  }
};

export default Navbar;
