import React, { useState, useEffect, useLayoutEffect } from 'react';
import { Stage, Layer } from 'react-konva';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import {
  selectLayers,
  addLayer,
  getActiveBackgroundURL
} from '../../../redux/stepsSlice';
import { selectActiveTool, removeActiveTool } from '../../../redux/toolsSlice';
import { RenderSquare, squareLayerDefaults } from '../tools/Square';
import { RenderText, textLayerDefaults } from '../tools/TextTool';
import { RenderHotSpot, hotspotLayerDefaults } from '../tools/HotSpot';
import { RenderTextInput, textInputLayerDefaults } from '../tools/TextInput';
import { RenderDropdown, dropdownLayerDefaults } from '../tools/DropdownTool';
import { getActiveStep } from '../../../redux/toolsSlice';
import EmptyCanvas from '../empty-canvas/EmptyCanvas';
import URLImage, { RenderURLImage } from '../tools/UrlImage';
import { RenderLine, lineLayerDefaults } from '../tools/Line';

const DesignCanvas = () => {
  const dispatch = useDispatch();
  const step = useSelector((state) => getActiveStep(state));
  const layers = useSelector(
    (state) => selectLayers(state, step.id),
    shallowEqual
  );
  const [stageSize, setStageSize] = useState({ width: 1024, height: 768 });
  const [editorScale, setEditScale] = useState(1);
  const selectedTool = useSelector((state) => selectActiveTool(state));
  const [activeTool, setActiveTool] = useState(selectedTool);
  const activeBackgroundURL = useSelector((state) =>
    getActiveBackgroundURL(state, step.id)
  );

  const generateRandomId = () => {
    return (
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15)
    );
  };

  const layerClicked = (e) => {
    if (!activeTool) {
      return;
    }

    const id = generateRandomId();
    const stage = e.target.getStage();

    const pos = stage.getPointerPosition();
    const mousePos = { x: pos.x, y: pos.y };

    let newLayer = null;
    switch (activeTool) {
      case 'square':
        newLayer = squareLayerDefaults(id, mousePos);
        break;
      case 'text':
        newLayer = textLayerDefaults(id, mousePos);
        break;
      case 'hotspot':
        newLayer = hotspotLayerDefaults(id, mousePos);
        break;
      case 'textInput':
        newLayer = textInputLayerDefaults(id, mousePos);
        break;
      case 'dropdown':
        newLayer = dropdownLayerDefaults(id, mousePos);
        break;
      case 'line':
        newLayer = lineLayerDefaults(id, mousePos);
        break;
      default:
        return;
    }

    dispatch(addLayer({ stepId: step.id, layer: newLayer }));
    dispatch(removeActiveTool());
  };

  const renderLayers = () => {
    if (!layers) return null;
    return layers.map((layer, index) => {
      switch (layer.type) {
        case 'image':
          return <RenderURLImage layer={layer} key={index} />;
        case 'rect':
          return <RenderSquare stepId={step.id} layer={layer} key={index} />;
        case 'text':
          return <RenderText stepId={step.id} layer={layer} key={index} />;
        case 'hotspot':
          return <RenderHotSpot stepId={step.id} layer={layer} key={index} />;
        case 'textInput':
          return <RenderTextInput stepId={step.id} layer={layer} key={index} />;
        case 'dropdown':
          return <RenderDropdown stepId={step.id} layer={layer} key={index} />;
        case 'line':
          return <RenderLine stepId={step.id} layer={layer} key={index} />;
        default:
          return null;
      }
    });
  };

  useEffect(() => {
    setActiveTool(selectedTool);
  }, [selectedTool]);

  useLayoutEffect(() => {
    setTimeout(() => {
      function updateSize() {
        const wrapper = document.getElementsByClassName('un-design-canvas')[0];
        const editor = document.getElementsByClassName('un-design-canvas--stage')?.[0];
        const diffWidthScale = wrapper.clientWidth/(editor?.scrollWidth + 30);
        const diffHeightScale = wrapper.clientHeight/(editor?.scrollHeight + 30);
        setEditScale(editor?.scrollWidth ? wrapper.clientWidth/(editor.scrollWidth + 30) : 1);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, 300);
  }, [])

  return (
    <div className="un-design-canvas">
      {!activeBackgroundURL && <EmptyCanvas />}
      {activeBackgroundURL && (
        <Stage
          className="un-design-canvas--stage"
          width={stageSize.width}
          height={stageSize.height}
          style={{ backgroundColor: '#fff', transform: `scale(${editorScale})`, overflow: 'visible' }}
          onClick={layerClicked}
        >
          <Layer>
            <URLImage src={activeBackgroundURL} />
            {renderLayers()}
          </Layer>
        </Stage>
      )}
    </div>
  );
};

export default DesignCanvas;
