import React from "react";
import Avatar from "components/assets/images/rich-Avatar.png";

function ChatInput() {
  return (
    <div class="wrapper-textarea-message-chat">
      <div class="avatar-textarea-message-chat">
        <img src={Avatar} alt="" />
      </div>
      <div class="cover-textarea-message-chat">
        <textarea
          class="textarea-message-chat"
          placeholder="Write your message..."
        ></textarea>
        <div class="flex-action-textarea">
          <div class="button-action-textarea attached-button-action">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
            >
              <path
                d="M16.2 7.77907V15.65C16.2 17.7763 14.4763 19.5 12.35 19.5C10.2237 19.5 8.5 17.7763 8.5 15.65V8.06667C8.5 6.64913 9.64913 5.5 11.0667 5.5C12.4842 5.5 13.6333 6.64913 13.6333 8.06667V15.612C13.6333 16.3208 13.0588 16.8954 12.35 16.8954C11.6413 16.8954 11.0667 16.3208 11.0667 15.612V8.75581"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div class="button-action-textarea emoji-button-action">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
            >
              <path
                d="M9.26162 14.5884C9.61219 14.3079 10.1227 14.3626 10.4061 14.7094C10.4185 14.7238 10.5848 14.912 10.8882 15.1016C11.2363 15.3192 11.7699 15.5455 12.5 15.5455C13.2301 15.5455 13.7637 15.3192 14.1118 15.1016C14.2856 14.993 14.4587 14.8653 14.5939 14.7094C14.8773 14.3626 15.3878 14.3079 15.7384 14.5884C16.0912 14.8707 16.1484 15.3855 15.8662 15.7384C15.6185 16.0369 15.3067 16.2845 14.9791 16.4893C14.4181 16.8399 13.5881 17.1818 12.5 17.1818C11.4119 17.1818 10.5819 16.8399 10.0209 16.4893C9.69452 16.2853 9.37914 16.0376 9.13384 15.7384C8.85156 15.3855 8.90876 14.8707 9.26162 14.5884Z"
                fill="currentColor"
              />
              <path
                d="M10.9091 10.9091C10.9091 11.5367 10.4003 12.0454 9.77272 12.0454C9.14513 12.0454 8.63636 11.5367 8.63636 10.9091C8.63636 10.2815 9.14513 9.77272 9.77272 9.77272C10.4003 9.77272 10.9091 10.2815 10.9091 10.9091Z"
                fill="currentColor"
              />
              <path
                d="M15.2273 12.0454C15.8549 12.0454 16.3636 11.5367 16.3636 10.9091C16.3636 10.2815 15.8549 9.77272 15.2273 9.77272C14.5997 9.77272 14.0909 10.2815 14.0909 10.9091C14.0909 11.5367 14.5997 12.0454 15.2273 12.0454Z"
                fill="currentColor"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12.5 3.5C7.52944 3.5 3.5 7.52944 3.5 12.5C3.5 17.4706 7.52944 21.5 12.5 21.5C17.4706 21.5 21.5 17.4706 21.5 12.5C21.5 7.52944 17.4706 3.5 12.5 3.5ZM5.13636 12.5C5.13636 8.43318 8.43318 5.13636 12.5 5.13636C16.5668 5.13636 19.8636 8.43318 19.8636 12.5C19.8636 16.5668 16.5668 19.8636 12.5 19.8636C8.43318 19.8636 5.13636 16.5668 5.13636 12.5Z"
                fill="currentColor"
              />
            </svg>
          </div>
        </div>
      </div>
      <div class="submit-textarea-message-chat">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="29"
          height="29"
          viewBox="0 0 29 29"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M6.29931 11.8632C5.64309 10.1845 5.18312 8.88638 4.97915 8.05665C4.72759 7.03329 4.30483 5.9002 4.72708 4.86838C4.98367 4.24137 5.52372 3.73587 6.24856 3.5628C7.19858 3.33598 8.09573 3.78502 8.91106 4.22186C10.6891 5.17449 19.9871 10.0378 22.2317 11.2187C23.5425 11.9083 25.4289 12.6467 25.4982 14.3804C25.5653 16.0579 23.9421 16.8122 22.6823 17.4748C18.0792 19.8962 13.5045 22.3727 8.88743 24.7676C8.0481 25.203 7.12375 25.677 6.15181 25.4313C5.3563 25.2302 4.82408 24.6395 4.61315 23.9821C4.42631 23.3998 4.50607 22.8005 4.64542 22.2195C5.0596 20.4925 5.63792 18.8042 6.2797 17.15C6.70923 16.0429 7.47187 15.128 8.43051 14.4953C7.48254 13.8655 6.72766 12.959 6.29931 11.8632ZM12.7164 13.7528C13.741 13.9593 14.2582 14.0706 14.3667 14.2489C14.4645 14.4094 14.4638 14.6119 14.365 14.7718C14.2499 14.958 13.6859 15.0675 12.5581 15.2865C11.7804 15.4375 11.1421 15.5608 10.9458 15.5969C9.66306 15.8329 8.60116 16.6958 8.14428 17.8734C7.59788 19.2818 7.11322 20.6895 6.72807 22.1494C6.33911 23.6237 6.27409 23.8701 7.96653 22.9923C12.6403 20.5679 17.2737 18.0655 21.931 15.6099C22.9672 15.0635 23.4973 14.7705 23.5 14.4727C23.5027 14.1777 22.9879 13.878 21.9356 13.3225C17.2842 10.8674 12.6026 8.46866 7.96653 5.98477C6.30105 5.09245 6.37834 5.40203 6.81996 7.17098C7.15871 8.52785 7.65324 9.83347 8.16205 11.1351C8.62051 12.3079 9.67956 13.1668 10.9581 13.4028C11.5458 13.5113 12.1306 13.6348 12.7164 13.7528Z"
            fill="currentColor"
          />
        </svg>
      </div>
    </div>
  );
}

export default ChatInput;
