export const pathsData = [
    {
      name: "Visitors Log",
      progressPercentage: 90,
      opens: 120,
      completions: 120,
      uniqueVisits: 120,
      currentStep: "",
      stepStatus: "",
      subRows: [
        {
          name: "Meagan Carmichael",
          progressPercentage: 34,
          opens: 30,
          completions: 64,
          uniqueVisits: 10,
          currentStep: "Step 12",
          stepStatus: "Completed",
        },
        {
          name: "Meagan Carmichael",
          progressPercentage: 34,
          opens: 30,
          completions: 64,
          uniqueVisits: 10,
          currentStep: "Step 12",
          stepStatus: "In Progress",
        },
        {
          name: "Meagan Carmichael",
          progressPercentage: 34,
          opens: 30,
          completions: 64,
          uniqueVisits: 10,
          currentStep: "Step 12",
          stepStatus: "Not Started",
        },
      ],
    },
    {
      name: "Visitors Log",
      progressPercentage: 90,
      opens: 120,
      completions: 120,
      uniqueVisits: 120,
      currentStep: "",
      stepStatus: "",
      subRows: [
        {
          name: "Meagan Carmichael",
          progressPercentage: 34,
          opens: 30,
          completions: 64,
          uniqueVisits: 10,
          currentStep: "Step 12",
          stepStatus: "Completed",
        },
        {
          name: "Meagan Carmichael",
          progressPercentage: 34,
          opens: 30,
          completions: 64,
          uniqueVisits: 10,
          currentStep: "Step 12",
          stepStatus: "In Progress",
        },
        {
          name: "Meagan Carmichael",
          progressPercentage: 34,
          opens: 30,
          completions: 64,
          uniqueVisits: 10,
          currentStep: "Step 12",
          stepStatus: "Not Started",
        },
      ],
    },
    {
      name: "Visitors Log",
      progressPercentage: 90,
      opens: 120,
      completions: 120,
      uniqueVisits: 120,
      currentStep: "",
      stepStatus: "",
      subRows: [
        {
          name: "Meagan Carmichael",
          progressPercentage: 34,
          opens: 30,
          completions: 64,
          uniqueVisits: 10,
          currentStep: "Step 12",
          stepStatus: "Completed",
        },
        {
          name: "Meagan Carmichael",
          progressPercentage: 34,
          opens: 30,
          completions: 64,
          uniqueVisits: 10,
          currentStep: "Step 12",
          stepStatus: "In Progress",
        },
        {
          name: "Meagan Carmichael",
          progressPercentage: 34,
          opens: 30,
          completions: 64,
          uniqueVisits: 10,
          currentStep: "Step 12",
          stepStatus: "Not Started",
        },
      ],
    },
  ];
  