export const docsData = [
  {
    name: "Visitors Log",
    progressPercentage: 90,
    opens: 120,
    completions: 120,
    uniqueVisits: 120,
    lastPage: "",
    subRows: [
      {
        name: "Visitors Log",
        progressPercentage: 90,
        opens: 120,
        completions: 120,
        uniqueVisits: 120,
        lastPage: "Medication Log",
      },
      {
        name: "Visitors Log",
        progressPercentage: 90,
        opens: 120,
        completions: 120,
        uniqueVisits: 120,
        lastPage: "How to move folder",
      },
      {
        name: "Visitors Log",
        progressPercentage: 90,
        opens: 120,
        completions: 120,
        uniqueVisits: 120,
        lastPage: "Reviewing a new account",
      },
    ],
  },
  {
    name: "Visitors Log",
    progressPercentage: 90,
    opens: 120,
    completions: 120,
    uniqueVisits: 120,
    lastPage: "",
    subRows: [
      {
        name: "Visitors Log",
        progressPercentage: 90,
        opens: 120,
        completions: 120,
        uniqueVisits: 120,
        lastPage: "Medication Log",
      },
      {
        name: "Visitors Log",
        progressPercentage: 90,
        opens: 120,
        completions: 120,
        uniqueVisits: 120,
        lastPage: "How to move folder",
      },
      {
        name: "Visitors Log",
        progressPercentage: 90,
        opens: 120,
        completions: 120,
        uniqueVisits: 120,
        lastPage: "Reviewing a new account",
      },
    ],
  },
  {
    name: "Visitors Log",
    progressPercentage: 90,
    opens: 120,
    completions: 120,
    uniqueVisits: 120,
    lastPage: "",
    subRows: [
      {
        name: "Visitors Log",
        progressPercentage: 90,
        opens: 120,
        completions: 120,
        uniqueVisits: 120,
        lastPage: "Medication Log",
      },
      {
        name: "Visitors Log",
        progressPercentage: 90,
        opens: 120,
        completions: 120,
        uniqueVisits: 120,
        lastPage: "How to move folder",
      },
      {
        name: "Visitors Log",
        progressPercentage: 90,
        opens: 120,
        completions: 120,
        uniqueVisits: 120,
        lastPage: "Reviewing a new account",
      },
    ],
  },
];
