import React from "react";

function SearchInput({ value, setValue, placeholder = "" }) {
  return (
    <div className="search-input-cover">
      <input
        className="input-search"
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
      <div className="search-input-icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.44472 7.22218C2.44472 9.92222 4.63353 12.111 7.33357 12.111C8.59282 12.111 9.74087 11.6349 10.6075 10.8529C10.6401 10.7755 10.6878 10.703 10.7508 10.64C10.8139 10.577 10.8864 10.5292 10.9638 10.4967C11.7461 9.62995 12.2224 8.48169 12.2224 7.22218C12.2224 4.52214 10.0336 2.33333 7.33357 2.33333C4.63353 2.33333 2.44472 4.52214 2.44472 7.22218ZM12.1793 11.1257C13.0403 10.0582 13.5558 8.70038 13.5558 7.22218C13.5558 3.78576 10.77 1 7.33357 1C3.89715 1 1.11139 3.78576 1.11139 7.22218C1.11139 10.6586 3.89715 13.4444 7.33357 13.4444C8.81148 13.4444 10.169 12.9291 11.2364 12.0684L13.8619 14.6939C14.1223 14.9543 14.5444 14.9543 14.8047 14.6939C15.0651 14.4336 15.0651 14.0115 14.8047 13.7511L12.1793 11.1257Z"
            fill="currentColor"
          />
        </svg>
      </div>
    </div>
  );
}

export default SearchInput;
