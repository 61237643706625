import React from "react";
import { Provider } from "react-redux";
import { store } from "../../../../redux/store";
import WalkthroughContainer from "./WalkthroughContainer";

const ShowPage = ({ id, audience }) => {
  const [style, setStyle] = React.useState({});

  return (
    <Provider store={store}>
      <WalkthroughContainer id={id} audience={audience} />
    </Provider>
  );
}

export default ShowPage;