import { createSlice } from '@reduxjs/toolkit';

export const walkthroughEmbedPreviewSlice = createSlice({
  name: 'walkthroughEmbedPreview',
  initialState: {
    activeStep: {},
    steps: {},
    currPage: {},
    index: {},
    config: {},
    loading: {},
    completed: {}
  },
  reducers: {
    setEmbedActiveStep: (state, action) => {
      const {data, id} = action.payload;
      state.activeStep[id] = data;
    },
    setEmbedSteps: (state, action) => {
      const {data, id} = action.payload;
      state.steps[id] = data;
      state.activeStep[id] = data[0];
      // state.steps = action.payload;
      // state.activeStep = action.payload[0];
    },
    setEmbedCurrPage: (state, action) => {
      const {data, id} = action.payload;
      state.currPage[id] = data;;
    },
    setEmbedIndex: (state, action) => {
      const {data, id} = action.payload;
      if (state.steps[id].length > 0 && data < state.steps[id].length) {
        state.index[id] = data;;
        state.currPage[id] = state.steps[id][data];
      }
    },
    setEmbedNextIndex: (state, action) => {
      if (state.steps[action.payload]?.length > 0 && state.index[action.payload] < state.steps[action.payload].length - 1) {
        state.index[action.payload] = state.index[action.payload] + 1;
        state.currPage[action.payload] = state.steps[action.payload][state.index];
      }else{
        state.completed[action.payload] = true;
      }
    },
    setEmbedPrevIndex: (state, action) => {
      if (state.steps[action.payload]?.length > 0 && state.index[action.payload] > 0) {
        state.index[action.payload] = state.index[action.payload] - 1;
        state.currPage[action.payload] = state.steps[action.payload][state.index];
      }
    },
    setEmbedConfigColors: (state, action) => {
      const { data, id } = action.payload;
      state.configColors[id] = data;
    },
    setEmbedConfig: (state, action) => {
      const { data, id } = action.payload;
      state.config[id] = data;
    },
    setEmbedLoading: (state, action) => {
      const { data, id } = action.payload;
      if(data){
        state.loading[id] = data;
      }else{
        delete state.loading[id];
      }
    },
    setEmbedCompleted: (state, action) => {
      const { data, id } = action.payload;
      state.completed[id] = data;
    }
  }
});

export const {
  setEmbedActiveStep,
  setEmbedSteps,
  setEmbedCurrPage,
  setEmbedIndex,
  setEmbedConfigColors,
  setEmbedNextIndex,
  setEmbedPrevIndex,
  setEmbedConfig,
  setEmbedLoading,
  setEmbedCompleted
} = walkthroughEmbedPreviewSlice.actions;

export const getEmbedSteps = (state, id) => {
  return state.walkthroughEmbedPreview.steps[id] || [];
};

export const getEmbedIndex = (state, id) => state.walkthroughEmbedPreview.index[id] || 0;

export const getEmbedActiveStep = (state, id) => state.walkthroughEmbedPreview.activeStep[id] || state.walkthroughEmbedPreview.steps[id]?.[0];

export const isEmbdCompleted = (state, id) => state.walkthroughEmbedPreview.completed[id];

export const selectEmbedSteps = (state, id) => state.walkthroughEmbedPreview.steps[id]||[];

export const selectEmbedCurrPage = (state, id) => state.walkthroughEmbedPreview.currPage[id];

export const selectEmbedCurrPageIdx = (state, id) =>
  state.walkthroughEmbedPreview.currPageIdx[id];

export const getEmbedConfig = (state, id) => state.walkthroughEmbedPreview.config[id]||[];

export const getEmbedLoading = (state, id) => state.walkthroughEmbedPreview.loading[id] || false;

export default walkthroughEmbedPreviewSlice.reducer;
