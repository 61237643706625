import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { http_get } from '../components/pages/utils/hub_client';
import { HUB_ENDPOINTS } from '../components/pages/utils/endpoints';

export const fetchMaterials = createAsyncThunk(
  'hub/fetchMaterials',
  async () => {
    let materials = [];
    await http_get(HUB_ENDPOINTS.GET_MATERIALS)
      .then((res) => {
        materials = res.data;
      })
      .catch((err) => {
        throw new Error('Failed to fetch materials');
      });
    return materials;
  }
);

export const fetchCompanyDetails = createAsyncThunk(
  'hub/fetchCompanyDetails',
  async () => {
    let company = {};
    await http_get(HUB_ENDPOINTS.GET_COMPANY_DETAILS)
      .then((res) => {
        company = res.data;
      })
      .catch((err) => {
        throw new Error('Failed to fetch company details');
      });
    return company;
  }
);

const hubSlice = createSlice({
  name: 'hub',
  initialState: {
    data: {
      materials: [],
      company: {},
      audience: {}
    },
    search: '',
    activeTab: 'all',
    loading: false,
    error: null,
    responseData: {}
  },
  reducers: {
    setSearch(state, action) {
      state.search = action.payload;
    },
    setActiveTab(state, action) {
      state.activeTab = action.payload;
    },
    setResponseActiveStep: (state, action) => {
      state.responseData = action.payload;
    },
    setAudience: (state, action) => {
      state.data.audience = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMaterials.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchMaterials.fulfilled, (state, action) => {
        state.loading = false;
        state.data.materials = action.payload;
      })
      .addCase(fetchMaterials.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchCompanyDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCompanyDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.data.company = action.payload;
      })
      .addCase(fetchCompanyDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  }
});

export const { setSearch, setActiveTab, setResponseActiveStep, setAudience } = hubSlice.actions;

export default hubSlice.reducer;

export const getMaterials = (state) => {
  const materials = state.hub.data.materials;
  const search = state.hub.search;
  const activeTab = state.hub.activeTab;
  if (search) {
    const keyword = search.toLowerCase();
    return materials.filter((material) => {
      return (material.name && material.name.toLowerCase().includes(keyword)) ||
        (material.description && material.description.toLowerCase().includes(keyword));
    });
  }
  if (activeTab === 'all') {
    return materials;
  }
  return materials.filter((material) => {
    return (material.material_type && material.material_type.toLowerCase() === activeTab);
  });
}

export const getCompanyDetails = (state) => state.hub.data.company;

export const getActiveTab = (state) => state.hub.activeTab;

export const getSearch = (state) => state.hub.search;

export const getResponseData = (state) => state.hub.responseData;

export const getAudienceData = (state) => state.hub.data.audience;