export const ELEMENTS = {
  LARGE_TEXT: "large_text",
  SMALL_TEXT: "small_text",
  BODY: "body",
  QUOTES: "quotes",
  LONG_TEXT_FIELD: "long_text_field",
  INPUT_FIELD: "input_field",
  ATTACHMENT: "attchment",
  TWO_COLUMNS: "two_columns",
  THREE_COLUMNS: "three_columns",
  IMAGE: "image",
  VIDEO: "video",
  LEARNING_MATERIAL: "Learning Material",
  WALKTHROUGH: "walkthrough",
  EMBEDDED_CODE: "embedded_code",
  DATA_ENTRY: "data_entry",
  DATA_SELECTION: "data_selection",
  MULTIPLE_SELECTION: "multiple_selection",
  SURVEY: "survey",
  QUIZ: "quiz",
  UPLOAD_FORM: "upload_form",
  SATISFACTION_SCORE: "satisfaction_score",
  SHORT_ANSWER: "short_answer",
  LONG_ANSWER: "long_answer",
  MULTIPLE_CHOICE: "multiple_choice",
  MULTI_SELECT: "multi_select",
  CHECKBOX: "checkbox",
  FILE_UPLOAD: "file_upload",
  DATE: "date",
  TIME: "time",
};
