import React, {useState} from 'react';

const CopyIcon = ({value}) => {
  const [isVisible, setIsVisible] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(value);

    // set visible for 2 seconds
    setIsVisible(true);

    setTimeout(() => {
      setIsVisible(false);
    }, 2000);
  }

  return (
  <div className={'un-copy-box__icon'} onClick={copyToClipboard}>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M14.6667 3C15.6619 3 16.5086 3.63608 16.8223 4.52388L9.43068 4.52381C8.07218 4.52381 7.57955 4.66526 7.0829 4.93087C6.58625 5.19648 6.19648 5.58625 5.93087 6.0829C5.66526 6.57955 5.52381 7.07218 5.52381 8.43068L5.52388 16.5843C4.63608 16.2705 4 15.4238 4 14.4286V7.57143C4 5.0467 6.0467 3 8.57143 3H14.6667ZM17.7143 6.04762C18.9767 6.04762 20 7.07097 20 8.33333V18.2381C20 19.5005 18.9767 20.5238 17.7143 20.5238H9.33333C8.07097 20.5238 7.04762 19.5005 7.04762 18.2381V8.33333C7.04762 7.07097 8.07097 6.04762 9.33333 6.04762H17.7143ZM17.7143 7.57143H9.33333C8.91255 7.57143 8.57143 7.91255 8.57143 8.33333V18.2381C8.57143 18.6589 8.91255 19 9.33333 19H17.7143C18.1351 19 18.4762 18.6589 18.4762 18.2381V8.33333C18.4762 7.91255 18.1351 7.57143 17.7143 7.57143Z"
        fill="#64BBCD"/>
    </svg>
    {isVisible && <>Copied</>}
  </div>)
}

const CopyBox = ({children, icon}) => {
  return (
    <div className="un-copy-box">
      <div className="un-copy-box__content">
        {icon}
        {children}
      </div>
      <CopyIcon value={children}/>
    </div>
  );
}

export default CopyBox;