import React from "react";
import { Line } from "react-chartjs-2";
import "chart.js/auto";

function EngagedUsers() {
  const data = {
    labels: ["January", "February", "March", "April", "May"],
    datasets: [
      {
        label: "Page Views",
        data: [5000, 5000, 4000, 4000, 3000],
        backgroundColor: (context) => {
          const ctx = context.chart.ctx;
          var gradient = ctx.createLinearGradient(0, 0, 0, 700); // Dimensions of the color rectangle
          gradient.addColorStop(1, "rgba(100, 187, 205, 0)"); // First color
          gradient.addColorStop(0, "rgba(100, 187, 205, 0.5)"); // Second color
          return gradient;
        },
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 2,
        fill: true,
      },
    ],
  };

  const options = {
    elements: {
      point: {
        radius: 0,
      },
    },
    scales: {
      y: {
        grid: {
          color: "rgba(227, 227, 227, 1)",
        },
        border: {
          dash: [2, 4],
        },
        display: true,
        beginAtZero: true,
        stacked: true,
        title: {
          display: false,
          text: "Statistics",
        },
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
    layout: {
      padding: {
        left: 20,
        right: 20,
        top: 20,
        bottom: 20,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <div className="wrapper-engaged-user mb-16">
      <div className="engaged-user">
        <div className="header-engaged-user">
          <div className="item-header-engaged-user">
            <h3 className="title-size-18">Engaged Users</h3>
            <p className="content-size-14">
              These users have accessed your learning content during the
              outlined time period
            </p>
          </div>
        </div>
        <div className="big-chart-engaged-user">
          {data && <Line data={data} options={options} />}
        </div>
      </div>
    </div>
  );
}

export default EngagedUsers;
