import React from 'react';
import { Provider } from 'react-redux';
import { store } from '../../../redux/store';
import Editor from './Editor';

const EditorWrapper = ({ id }) => {
    return (
        <>
            <Provider store={store}>
                <Editor id={id} />
            </Provider>
        </>
    );
};

export default EditorWrapper;
