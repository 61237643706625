import React from "react";
import DragWrapper from "../content/DragWrapper";

function CardIcon({
  title = "",
  icon,
  callback,
  onClick,
  isSelected
}) {
  return (
    <DragWrapper dropCallback={callback}>
      <div
        className={`card-element-group ${isSelected ? "icon-element-group-click" : ""}`}
        style={{ width: 'auto'}}
        onClick={onClick}
      >
        <div className="icon-element-group">{icon}</div>
        <div className="text-element-group">{title}</div>
      </div>
    </DragWrapper>
  );
}

export default CardIcon;
