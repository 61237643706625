import React, { useState } from "react";
import MessagesToolbar from "./components/messages/MessagesToolbar";
import MessagesSidebar from "./components/messages/sidebar";
import EmptyMessage from "./components/messages/EmptyMessage";
import Tabs from "./components/messages/tabs";
import ProfileImage from "components/assets/images/img-1.png";
import Chat from "./components/chat";
function IndexPage() {
  const [activeMessage, setActiveMessage] = useState();
  const [chat, setChat] = useState();

  return (
    <div>
      <MessagesToolbar />
      <div class="wrapper-main-content-messages">
        <div class="page-padding">
          <div class="cover-main-content-messages">
            <MessagesSidebar
              activeMessage={activeMessage}
              setActiveMessage={setActiveMessage}
            />

            <div class="main-content-messages">
              <div class="header-main-content-messages">
                <div class="left-main-content-messages">
                  <div class="user-select-messages">
                    <div class="image-user-select-messages">
                      <img src={ProfileImage} alt="Willard Purnell" />
                    </div>
                    <div class="content-user-select-messages">
                      <div class="name-user-select-messages">
                        Willard Purnell
                      </div>
                      <div class="status-user-select-messages">
                        <div class="icon-status-user-select"></div>
                        <div class="text-status-user-select">Active</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="right-main-content-messages">
                  <div class="action-user-select-messages">
                    <img src="images/action-icon-dots.svg" alt="" />
                  </div>
                </div>
              </div>

              {!activeMessage && <EmptyMessage />}
              {chat ? (
                <Chat />
              ) : (
                <>{activeMessage && <Tabs setChat={setChat} />}</>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IndexPage;
