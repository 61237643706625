import React from "react";
import Undo from '../../../../organisms/tools/Undo'
import Redo from '../../../../organisms/tools/Redo'

function UndoRedo() {
  return (
    <div className="item-buttons-path-authoring">
      <div className="buttons-path-list">
        <div className="item-buttons-path-list">
          <Undo />
        </div>
        <div className="item-buttons-path-list">
          <Redo />
        </div>
      </div>
    </div>
  );
}

export default UndoRedo;
