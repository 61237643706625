import React, { useState } from "react";

function ZoomButton() {
  const [zoomValue, setZoomValue] = useState(100);

  const handleDecreaseZoom = () => {
    setZoomValue((prevZoom) => Math.max(10, prevZoom - 10));
  };

  const handleIncreaseZoom = () => {
    setZoomValue((prevZoom) => Math.min(100, prevZoom + 10));
  };

  return (
    <div className="item-buttons-path-authoring">
      <div className="quantity-path-authoring">
        <div
          onClick={handleIncreaseZoom}
          className="quantity-button quantity-up"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8.00008 2.66663C8.36827 2.66663 8.66675 2.9651 8.66675 3.33329V7.33329H12.6667C13.0349 7.33329 13.3334 7.63177 13.3334 7.99996C13.3334 8.36815 13.0349 8.66663 12.6667 8.66663H8.66675V12.6666C8.66675 13.0348 8.36827 13.3333 8.00008 13.3333C7.63189 13.3333 7.33341 13.0348 7.33341 12.6666V8.66663H3.33341C2.96522 8.66663 2.66675 8.36815 2.66675 7.99996C2.66675 7.63177 2.96522 7.33329 3.33341 7.33329H7.33341V3.33329C7.33341 2.9651 7.63189 2.66663 8.00008 2.66663Z"
              fill="currentColor"
            />
          </svg>
        </div>
        <input
          className="input-quantity"
          type="text"
          min="1"
          max="100"
          step="1"
          value={String(zoomValue)}
          readOnly
        />
        <div
          onClick={handleDecreaseZoom}
          className="quantity-button quantity-down"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M2.66675 8.00004C2.66675 7.63185 2.96522 7.33337 3.33341 7.33337H12.6667C13.0349 7.33337 13.3334 7.63185 13.3334 8.00004C13.3334 8.36823 13.0349 8.66671 12.6667 8.66671H3.33341C2.96522 8.66671 2.66675 8.36823 2.66675 8.00004Z"
              fill="currentColor"
            />
          </svg>
        </div>
      </div>
    </div>
  );
}

export default ZoomButton;
