import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchCompanyDetails, getCompanyDetails,
  setAudience
} from '../../../redux/hubSlice';

const HubLayout = ({ children, headerStyle, hideHeader = false, props }) => {

  const audience = props && props.audience ? props.audience : props;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchCompanyDetails());
    if (audience) {
      dispatch(setAudience({ ...audience }));
    }
  }, []);

  const company = useSelector((state) => getCompanyDetails(state));

  const navHomepage = () => {
    window.location.href = `/${company.slug}`;
  }

  const handleSignInAsLearner = () => {
    const path = `/${company?.slug}/passwordless_sign_in`;
    window.location.href = path;
  }

  const handleSignOut = () => {
    const path = `/${company?.slug}/sign_out`;
    window.location.href = path;
  }

  return (
    <div className="un-hub">
      {!hideHeader &&
        <div className="un-hub__header" style={headerStyle}>
          <img src={company.logo_url} onClick={navHomepage} />
          {audience && audience.email ?
            <>
              <div className='signin-detail'>
                <button>{audience.email} |</button>
                <button
                  style={{ paddingLeft: 5 }}
                  className="un-btn un-btn--primary"
                  onClick={handleSignOut}>
                  Sign out
                </button>
              </div>
            </>
            :
            <button
              className="un-btn un-btn--primary signin-btn-class"
              onClick={handleSignInAsLearner}>
              Sign in as a Learner
            </button>
          }
        </div>
      }
      {children}
    </div >
  );
};

HubLayout.defaultProps = {
  headerStyle: {},
};

export default HubLayout;
