import React, { useState } from "react";
import LabelAndInput from "./form-fields/LabelAndInput";
import LabelAndTextarea from "./form-fields/LabelAndTextarea";
import LabelAndToggle from "./form-fields/LabelAndToggle";

function ContentForm() {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const [isMedia, setIsMedia] = useState(false);

  return (
    <div className="design-form-path">
      <LabelAndInput
        title="Title"
        placeholder="title"
        value={title}
        setValue={setTitle}
      />
      <LabelAndTextarea
        value={description}
        setValue={setDescription}
        title="Description"
        placeholder="description"
      />
      <LabelAndToggle
        title="Media content"
        checked={isMedia}
        setChecked={setIsMedia}
      />
    </div>
  );
}

export default ContentForm;
