import React from 'react';
import { UndoIcon } from '../../assets/Icons';
import { useDispatch } from 'react-redux';
import { undo } from '../../../redux/stepsSlice';

const Undo = () => {
  const dispatch = useDispatch();

  const handleUndo = () => {
    dispatch(undo());
  };

  return (
    <div
      className="tool__border"
      title="Undo"
      data-tooltip-id="tools-tooltip"
      data-tooltip-content="Undo"
      onClick={handleUndo}
    >
      <UndoIcon />
    </div>
  );
};

export default Undo;
