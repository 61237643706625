import React, { useEffect, useState } from "react";
import { AudienceIcon, BillingIcon, ChevonDown, CustomDomainIcon, DashboardIcon, DocsIcon, ExpandArrow, FormsIcon, MessageIcon, PathsIcon, SettingsIcon, TeamIcon, WalkthroughIcon } from "./SidenavIcons";
import ProgressBar from "../../atoms/progress-bar/ProgressBar";
import { ENDPOINTS } from "../../pages/utils/endpoints";
import { useDispatch, useSelector } from "react-redux";
import { fetchCompanyDetails, getCompany } from "../../../redux/companySlice";
import CreateModal from '../create-modal/CreateModal';
import {getCookie, setCookie} from "../../../utils/tools";

const SidenavContent = ({ userInfo }) => {
  const navOpen = getCookie('navOpen') === 'true';
  const dispatch = useDispatch();
  const bodyContent = document.querySelector('.body-content');
  const defaultExpand = bodyContent && bodyContent.classList.contains('is-expanded');
  const [isExpanded, setIsExpanded] = useState(defaultExpand && navOpen);
  const [profileOpen, setProfileOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchCompanyDetails());
  }, [dispatch]);

  const company = useSelector((state) => getCompany(state));
  const userProfile = userInfo.avatar || require('../../assets/images/user-default-photo.png');

  const menuItems = [
    {
      title: "Dashboard",
      icon: <DashboardIcon />,
      path: "/dashboard"
    },
    {
      title: "Audience",
      icon: <AudienceIcon />,
      path: "/audiences"
    },
    {
      title: "Walkthroughs",
      icon: <WalkthroughIcon />,
      path: "/walkthroughs"
    },
    {
      title: "Paths",
      icon: <PathsIcon />,
      path: "/paths"
    },
    /*{
      title: "Docs",
      icon: <DocsIcon />,
      path: "/docs"
    },*/
    /*{
      title: "Forms",
      icon: <FormsIcon />,
      path: "/learning_materials?tab=forms"
    },*/
    /*{
      title: "Team",
      icon: <TeamIcon />,
      path: "/settings/team"
    },*/
    /*{
      title: "Messages",
      icon: <MessageIcon />,
      path: "/messages"
    },*/
    {
      title: "Custom Domain",
      icon: <CustomDomainIcon />,
      path: "/settings/domain"
    },
    {
      title: "Settings",
      icon: <SettingsIcon />,
      path: "/settings/profile"
    },
    /*{
      title: "Billing",
      icon: <BillingIcon />,
      path: "/billing"
    }*/
  ]

  const getPath = (path) => {
    return `/${company?.slug}${path}`
  }

  const isActive = (path) => {
    const currentPath = window.location.pathname + window.location.search;
    return currentPath === getPath(path) ? 'active' : '';
  }

  const renderMenuItems = () => {
    return menuItems.map((item, index) => {
      return (
        <li key={item.title}>
          <a href={getPath(item.path)} className={isActive(item.path)}>
            {item.icon}
            <span>{item.title}</span>
          </a>
        </li>
      )
    })
  }

  const openProfileMenu = () => {
    setProfileOpen(!profileOpen);
  }

  const logoSrc = require('../../assets/default_logo.svg')

  const profileClasses = ['un-sidenav__profile__header'];
  const arrowClasses = ['un-sidenav__arrow'];
  const sideNavClasses = ['un-sidenav']

  if (profileOpen) {
    profileClasses.push('active');
  }

  if (isExpanded) {
    sideNavClasses.push('is-expanded');
    arrowClasses.push('active');
    bodyContent.classList.add('is-expanded');
  }else{
    bodyContent.classList.remove('is-expanded');
  }

  const profileClassName = profileClasses.join(' ');
  const arrowClassName = arrowClasses.join(' ');
  const sideNavClassName = sideNavClasses.join(' ');

  const toggleMenu = () => {
    const result = !isExpanded;
    setIsExpanded(result);
    setCookie('navOpen', result, 1)

    if (result) {
      bodyContent.classList.add('is-expanded');
    } else {
      bodyContent.classList.remove('is-expanded');
    }
  }

  const handleCreate = () => {
    $('#createModal').modal('show');
    const modalBackdrop = document.querySelector('.modal-backdrop');
    if (modalBackdrop) {
      modalBackdrop.remove();
    }
  };

  return (
    <div className={sideNavClassName}>
      <div>
        <div className="un-sidenav__logo">
          <img src={logoSrc} />
          <ExpandArrow onClick={toggleMenu} className={arrowClassName} />
        </div>
        <ul className="un-sidenav__items">
          {renderMenuItems()}
        </ul>
      </div>
      <div>
        {isExpanded && (
            <div className="un-sidenav__footer">
              <button className="un-sidenav__footer__button" onClick={handleCreate}>Create New</button>
              <CreateModal />
              
            </div>
        )}
        <div className="un-sidenav__profile">
          {
            profileOpen && (
              <div className="un-sidenav__profile__menu">
                <div className="un-sidenav__profile__menu-item">
                <a>Profile</a>
                  <a
                    rel="nofollow"
                    data-method="delete"
                    href={ENDPOINTS.USER_SIGNOUT}
                  >
                    Sign Out
                  </a>
                </div>
              </div>
            )
          }
          {isExpanded && (
            <div className={profileClassName} onClick={openProfileMenu}>
              <div>
                <div style={{ backgroundImage: `url('${userProfile}')` }} className="un-sidenav__profile__image"></div>
                <p style={{color : 'white', font : '14px'}}>{userInfo.name}</p>
              </div>
              <ChevonDown />
            </div>
          )}
          {!isExpanded && (
            <div className={profileClassName} onClick={toggleMenu}>
              <div>
                <div style={{ backgroundImage: `url('${userProfile}')` }} className="un-sidenav__profile__image"></div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default SidenavContent;
