import React, { useState } from "react";
import Elements from "../side-toolbar/Elements";
import ElementSelectorModal from "./ElementSelectorModal";
import { addLayerToActive, fetchActiveStep } from "../../../../../../redux/stepsSlice";
import { generateRandomId } from "../../../../../pages/utils/tools";
import { useDispatch } from "react-redux";
import { videoLayerDefaults } from "./fields/VideoContent";
import { LargeTextLayerContentDefaults } from "./fields/LargeTextContent";
import { BodyTextLayerContentDefaults } from "./fields/BodyTextContent";
import { EmbedCodeLayerDefaults } from "./fields/EmbedCodeContent";
import { ImageLayerDefaults } from "./fields/ImageContent";
import { AttachmentLayerDefaults } from "./fields/AttachmentContent.jsx";
import { ShortAnswerLayerDefaults } from "./fields/ShortAnswerContent.jsx";
import { LongAnswerLayerDefaults } from "./fields/LongAnswerContent.jsx";
import { CheckboxLayerDefaults } from "./fields/CheckboxContent.jsx";
import { MultiSelectLayerDefaults } from "./fields/MultiSelectContent.jsx";
import { FileUploadLayerDefaults } from "./fields/FileUploadContent.jsx";

function EmptyContent({
  isEmpty,
  backgroundColor
}) {
  const [isShowModal, setIsShowModal] = useState(false);
  const [selectedElement, setSelectedElement] = useState(null);

  const dispatch = useDispatch();

  const handleClose = () => setIsShowModal(false);
  const handleShow = () => setIsShowModal(true);

  const getLayerDefault = (type) => {
    switch (type) {
      case 'large_text':
        return LargeTextLayerContentDefaults(generateRandomId());
      case 'video':
        return videoLayerDefaults(generateRandomId());
      case 'body':
        return BodyTextLayerContentDefaults(generateRandomId());
      case 'embedded_code':
        return EmbedCodeLayerDefaults(generateRandomId());
      case 'image':
        return ImageLayerDefaults(generateRandomId());
      case 'attachment':
        return AttachmentLayerDefaults(generateRandomId());
      case 'short_answer':
        return ShortAnswerLayerDefaults(generateRandomId());
      case 'long_answer':
        return LongAnswerLayerDefaults(generateRandomId());
      case 'checkbox':
        return CheckboxLayerDefaults(generateRandomId());
      case 'multi_select':
        return MultiSelectLayerDefaults(generateRandomId());
      case 'file_upload':
        return FileUploadLayerDefaults(generateRandomId());
      default:
        return {
          id: generateRandomId(),
          type: selectedElement,
          content: 'Hello world over here',
        }
    }
  }

  const handleAddElementToActiveStep = () => {
    if (selectedElement) {
      dispatch(fetchActiveStep()).then(() => {
        const newLayer = getLayerDefault(selectedElement);
        dispatch(addLayerToActive({ layer: newLayer }));
        setIsShowModal(false);
      });
    }
  };

  return (
    <>
      <div
        className="cover-path-drag-area"
        onClick={handleShow}
      // style={{ display: isEmpty ? "none" : "block" }}
      >
        <div className="path-drag-area" style={{ border: `2px dashed ${backgroundColor}` }}>
          <div className="icon-path-drag-area">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
            >
              <path
                d="M13.8337 10.8335L19.637 14.2185L17.1595 14.9269L18.9303 17.9944L17.487 18.8277L15.7162 15.761L13.8637 17.5527L13.8337 10.8335ZM12.167 5.0002H13.8337V6.66687H18.0003C18.2213 6.66687 18.4333 6.75467 18.5896 6.91095C18.7459 7.06723 18.8337 7.27919 18.8337 7.5002V10.8335H17.167V8.33354H8.83366V16.6669H12.167V18.3335H8.00033C7.77931 18.3335 7.56735 18.2457 7.41107 18.0895C7.25479 17.9332 7.16699 17.7212 7.16699 17.5002V13.3335H5.50033V11.6669H7.16699V7.5002C7.16699 7.27919 7.25479 7.06723 7.41107 6.91095C7.56735 6.75467 7.77931 6.66687 8.00033 6.66687H12.167V5.0002ZM3.83366 11.6669V13.3335H2.16699V11.6669H3.83366ZM3.83366 8.33354V10.0002H2.16699V8.33354H3.83366ZM3.83366 5.0002V6.66687H2.16699V5.0002H3.83366ZM3.83366 1.66687V3.33354H2.16699V1.66687H3.83366ZM7.16699 1.66687V3.33354H5.50033V1.66687H7.16699ZM10.5003 1.66687V3.33354H8.83366V1.66687H10.5003ZM13.8337 1.66687V3.33354H12.167V1.66687H13.8337Z"
                fill="currentColor"
              />
            </svg>
          </div>
          <button className="text-path-drag-area">
            Click to add your first element
          </button>
        </div>
      </div>
      {isShowModal &&
        <ElementSelectorModal
          title="Add Element to Paths"
          show={isShowModal}
          onClose={handleClose}
          onInsert={handleAddElementToActiveStep}
          children={
            <Elements closeModal={handleClose} setSelectedElement={setSelectedElement} />
          }
        />
      }
    </>
  );
}

export default EmptyContent;
