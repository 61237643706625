import React from "react";

function DetailsForm() {
  return (
    <div className="">
      <div className="input-group-preview">
        <div className="item-input-group-preview">
          <div className="text-input-group-preview color-blue-grey">Name</div>
          <input
            className="input-text-preview"
            type="text"
            placeholder="Type name"
          />
        </div>
        <div className="item-input-group-preview">
          <div className="text-input-group-preview color-blue-grey">Email</div>
          <input
            className="input-text-preview"
            type="email"
            placeholder="Type Email"
          />
        </div>
        <div className="item-input-group-preview">
          <div className="text-input-group-preview color-blue-grey">
            Please share anything that will help prepare for our meeting.
          </div>
          <textarea
            className="input-text-preview height-80"
            type="text"
            placeholder="Type Details"
          ></textarea>
        </div>
      </div>
    </div>
  );
}

export default DetailsForm;
