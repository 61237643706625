import React from "react";

const ProgressBar = ({ progress }) => {
  return (
    <div className="un-progress-bar">
      <div className="un-progress-bar__progress" style={{ width: `${progress}%` }} />
    </div>
  );
}

export default ProgressBar;