import React, { useState } from 'react';
import Button from '../../atoms/button/Button';
import { takeScreenshotJpegBlob } from '../../../utils/screenshot';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveStep } from '../../../redux/toolsSlice';
import { updateBackgroundURL } from '../../../redux/stepsSlice';
import axios from 'axios';
import Loader from '../../../components/organisms/loader/Loader';
import { INTERNAL_ENDPOINTS } from '../../pages/utils/endpoints';

const EmptyCanvas = () => {
    const dispatch = useDispatch();
    const activeStep = useSelector((state) => getActiveStep(state));
    const [loading, setLoading] = useState(false);

    const uploadScreenshot = async (screenshotJpegBlob) => {
        const formData = new FormData();
        formData.append('image', screenshotJpegBlob);
        const url = INTERNAL_ENDPOINTS.POST_LIBRARY;
        const response = await axios
            .post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            .finally(() => {
                setLoading(false);
            });
        return response.data;
    };

    const takeScreenshot = async () => {
        const screenshotJpegBlob = await takeScreenshotJpegBlob();
        if (screenshotJpegBlob) {
            setLoading(true);
            const response = await uploadScreenshot(screenshotJpegBlob);
            const url = response.url;
            dispatch(updateBackgroundURL({ stepId: activeStep.id, url: url }));
            setLoading(false);
        }
    };

    const handleFileChange = async (e) => {
        setLoading(true);
        const file = e.target.files[0];
        const response = await uploadScreenshot(file);
        const urlImage = response.url;
        dispatch(updateBackgroundURL({ stepId: activeStep.id, url: urlImage }));
        setLoading(false);
    };

    const handleFileInputClick = () => {
        document.getElementById('imageInput').click();
    };

    return (
        <div className="un-empty-canvas">
            <div className="container">
                <svg
                    width="45"
                    height="44"
                    viewBox="0 0 45 44"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect
                        x="0.5"
                        width="44"
                        height="44"
                        rx="22"
                        fill="#E1F6F8"
                    />
                    <path
                        d="M29.5 12H15.5C14.7044 12 13.9413 12.3161 13.3787 12.8787C12.8161 13.4413 12.5 14.2044 12.5 15V29C12.5 29.7956 12.8161 30.5587 13.3787 31.1213C13.9413 31.6839 14.7044 32 15.5 32H29.5C29.6645 31.9977 29.8284 31.981 29.99 31.95L30.29 31.88H30.36H30.41L30.78 31.74L30.91 31.67C31.01 31.61 31.12 31.56 31.22 31.49C31.3535 31.3918 31.4805 31.2849 31.6 31.17L31.67 31.08C31.7682 30.9805 31.8585 30.8735 31.94 30.76L32.03 30.63C32.0998 30.5187 32.1601 30.4016 32.21 30.28C32.2374 30.232 32.2609 30.1818 32.28 30.13C32.33 30.01 32.36 29.88 32.4 29.75V29.6C32.4567 29.4046 32.4903 29.2032 32.5 29V15C32.5 14.2044 32.1839 13.4413 31.6213 12.8787C31.0587 12.3161 30.2956 12 29.5 12ZM15.5 30C15.2348 30 14.9804 29.8946 14.7929 29.7071C14.6054 29.5196 14.5 29.2652 14.5 29V24.69L17.79 21.39C17.883 21.2963 17.9936 21.2219 18.1154 21.1711C18.2373 21.1203 18.368 21.0942 18.5 21.0942C18.632 21.0942 18.7627 21.1203 18.8846 21.1711C19.0064 21.2219 19.117 21.2963 19.21 21.39L27.81 30H15.5ZM30.5 29C30.4991 29.1233 30.4753 29.2453 30.43 29.36C30.4071 29.4087 30.3804 29.4556 30.35 29.5C30.3232 29.5423 30.2931 29.5825 30.26 29.62L24.91 24.27L25.79 23.39C25.883 23.2963 25.9936 23.2219 26.1154 23.1711C26.2373 23.1203 26.368 23.0942 26.5 23.0942C26.632 23.0942 26.7627 23.1203 26.8846 23.1711C27.0064 23.2219 27.117 23.2963 27.21 23.39L30.5 26.69V29ZM30.5 23.86L28.62 22C28.0477 21.457 27.2889 21.1543 26.5 21.1543C25.7111 21.1543 24.9523 21.457 24.38 22L23.5 22.88L20.62 20C20.0477 19.457 19.2889 19.1543 18.5 19.1543C17.7111 19.1543 16.9523 19.457 16.38 20L14.5 21.86V15C14.5 14.7348 14.6054 14.4804 14.7929 14.2929C14.9804 14.1054 15.2348 14 15.5 14H29.5C29.7652 14 30.0196 14.1054 30.2071 14.2929C30.3946 14.4804 30.5 14.7348 30.5 15V23.86Z"
                        fill="#368F9B"
                    />
                </svg>

                <p className="title">Image Placeholder</p>
                <input
                    type="file"
                    id="imageInput"
                    accept="image/*" // Allow only image files
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                />
                <div className="un-btn-group">
                    {loading && <Loader text={'Uploading...'} />}
                    {!loading && (
                        <>
                            <Button
                                className={'ghost'}
                                onClick={takeScreenshot}
                            >
                                Take a screenshot
                            </Button>
                            <Button className={'ghost'}>
                                Image from Library
                            </Button>
                            <Button
                                className={'ghost blue'}
                                onClick={handleFileInputClick}
                            >
                                Insert an image
                            </Button>
                        </>
                    )}
                </div>
                <p>
                    Or Drag & Drop image from the library or your computer here
                </p>
            </div>
        </div>
    );
};

export default EmptyCanvas;
