import React, { useState, useEffect, useRef } from 'react';
import { TextInputIcon } from '../../assets/Icons';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  setActiveTool,
  selectActiveTool,
  selectActiveLayer,
  setActiveLayer
} from '../../../redux/toolsSlice';
import { Transformer, Rect, Group } from 'react-konva';
import {
  updateLayer,
  removeLayerConfig,
  setLayerConfig,
  getLayerObjConfig
} from '../../../redux/stepsSlice';
import { Html } from 'react-konva-utils';
import ContextMenu from '../context-menu/ContextMenu';

const TextInput = () => {
  const dispatch = useDispatch();
  const activeTool = useSelector((state) => selectActiveTool(state));
  const [className, setClassName] = useState('tool');

  const setActive = () => {
    dispatch(setActiveTool('textInput'));
  };

  useEffect(() => {
    if (activeTool === 'textInput') {
      setClassName('tool active');
    } else {
      setClassName('tool');
    }
  }, [activeTool]);

  return (
    <div
      className={className}
      title="Input Field"
      data-tooltip-id="tools-tooltip"
      data-tooltip-content="Input Field"
      onClick={setActive}
    >
      <TextInputIcon />
    </div>
  );
};

export default TextInput;

export const textInputLayerDefaults = (id, mouseDown) => {
  const width = 150;
  const height = 30;
  const pos = mouseDown;

  return {
    id: id,
    type: 'textInput',
    x: pos.x,
    y: pos.y,
    width: width,
    height: height,
    fill: '#ffffff',
    textColor: '#000000',
    stroke: '#cccccc',
    strokeWidth: 1,
    rotation: 0
  };
};

export const RenderTextInput = ({ layer, stepId }) => {
  const shapeRef = useRef();
  const transformerRef = useRef();
  const dispatch = useDispatch();
  const activeLayer = useSelector((state) => selectActiveLayer(state));
  const isSelected =
    activeLayer &&
    activeLayer.layerId === layer.id &&
    activeLayer.stepId === stepId;
  const [menuVisible, setMenuVisible] = useState(false);
  const contextMenuOptions = ['Delete'];
  const defaultConfig = [
    {
      layerId: layer.id,
      sectionId: 'hotspot',
      sectionTitle: 'Element > HotSpot',
      values: [
        {
          id: 'placeholder',
          title: 'Placeholder',
          type: 'text',
          value: layer.placeholder || ''
        },
        {
          id: 'pfontColor',
          title: 'Placeholder Font Color',
          type: 'color',
          value: layer.pfontColor || '#000000'
        },
        {
          id: 'textColor',
          title: 'Font Color',
          type: 'color',
          value: layer.textColor || '#000000'
        },
        {
          id: 'fill',
          title: 'BG Color',
          type: 'color',
          value: layer.fill || '#ffffff'
        },
        {
          id: 'stroke',
          title: 'Border Color',
          type: 'color',
          value: layer.stroke || '#ffffff'
        },
        {
          id: 'fontSize',
          title: 'Font Size',
          type: 'px-size',
          value: layer.fontSize || 12
        }
      ]
    }
  ];
  const layerObjConfig = useSelector(
    (state) => getLayerObjConfig(state, layer),
    shallowEqual
  );

  useEffect(() => {
    if (layerObjConfig) {
      const newLayer = {
        ...layer,
        ...layerObjConfig
      };
      dispatch(updateLayer({ stepId: stepId, layer: newLayer }));
    }
  }, [layerObjConfig]);

  const renderContextMenu = () => {
    return (
      <ContextMenu
        options={contextMenuOptions}
        activeLayer={layer}
        stepId={stepId}
        dispatch={dispatch}
        onClick={menuClick}
      />
    );
  };

  const showContextMenu = (e) => {
    e.evt.preventDefault();
    dispatch(setActiveLayer({ stepId: stepId, layerId: layer.id }));
    setMenuVisible(true);
  };

  const menuClick = (e) => {
    setMenuVisible(false);
  };

  useEffect(() => {
    if (isSelected) {
      transformerRef.current.nodes([shapeRef.current]);
      transformerRef.current.getLayer().batchDraw();
    } else {
      if(!activeLayer?.stepId && !activeLayer?.layerId){
        dispatch(removeLayerConfig());
      }
      setMenuVisible(false);
    }
  }, [isSelected]);

  const handleLayerUpdate = (e) => {
    const newLayer = {
      ...layer,
      x: e.target.x(),
      y: e.target.y()
    };
    dispatch(updateLayer({ stepId: stepId, layer: newLayer }));
  };

  const setActive = () => {
    dispatch(setActiveLayer({ stepId: stepId, layerId: layer.id }));
    dispatch(setLayerConfig(defaultConfig));
  };

  const renderNode = () => {
    return (
      <Group x={layer.x} y={layer.y} draggable onDragEnd={handleLayerUpdate}>
        <Rect
          width={layer.width}
          height={layer.height}
          rotation={layer.rotation}
          fill={layer.fill}
          stroke={layer.stroke}
          strokeWidth={layer.strokeWidth}
          cornerRadius={layer.cornerRadius}
          key={layer.id}
          onContextMenu={showContextMenu}
          onClick={setActive}
          ref={shapeRef}
        />
        {menuVisible && <Html>{renderContextMenu()}</Html>}
        {isSelected && (
          <Transformer
            ref={transformerRef}
            keepRatio={false}
            ignoreStroke={true}
            boundBoxFunc={(oldBox, newBox) => {
              if (Math.abs(newBox.width) < 2 || Math.abs(newBox.height) < 2) {
                return oldBox;
              }

              dispatch(
                updateLayer({
                  stepId: stepId,
                  layer: {
                    ...layer,
                    width: newBox.width,
                    height: newBox.height
                  }
                })
              );

              return newBox;
            }}
          />
        )}
      </Group>
    );
  };

  return <>{renderNode()}</>;
};

export const RenderPreviewTextInput = ({ layer }) => {
  const renderNode = () => {
    return (
      <Group x={layer.x} y={layer.y}>
        <Html
          divProps={{
            style: {
              position: 'absolute',
              top: 10,
              left: 10
            }
          }}
        >
          <input
            autoFocus
            style={{
              backgroundColor: layer.fill,
              border: `${layer.strokeWidth}px solid ${layer.stroke}`,
              fontSize: layer.fontSize,
              fontFamily: layer.fontFamily,
              color: layer.textColor,
              padding: 0,
              margin: 0,
              width: layer.width,
              height: layer.height,
              borderRadius: layer.cornerRadius,
              padding: 4
            }}
            placeholder={layer.placeholder}
          />
        </Html>
      </Group>
    );
  };

  return <>{renderNode()}</>;
};
