import React, { useState } from 'react';
import { ArrowDown } from '../../assets/Icons';

const Accordion = ({ title, children, initActive = false, onToggle }) => {
    const [open, setOpen] = useState(initActive || true);

    const toggleAccordion = () => {
        setOpen(!open);
        if(onToggle){
            onToggle();
        }
    };

    return (
        <div className="un-accordion">
            <div
                className={`accordion-header ${open ? 'open' : ''}`}
                onClick={toggleAccordion}
            >
                {title}
                <ArrowDown />
            </div>
            {open && <div className="accordion-content">{children}</div>}
        </div>
    );
};

export default Accordion;
