import React, { useState, useEffect } from 'react';
import LineTab from './LineTab';
import { useSelector } from 'react-redux';
import { getCompany } from '../../../redux/companySlice';
import { ManageIcon, MaterialIcon } from '../../assets/Icons';
import { API_ENDPOINTS, CONSTS } from '../../pages/utils/endpoints';
import { http_get } from '../../pages/utils/api_client';

const LMLineTab = ({ tabName, defaultTab }) => {
  const company = useSelector((state) => getCompany(state));
  const [countData, setCountData] = React.useState(0);

  useEffect(() => {
    http_get(API_ENDPOINTS.GET_LEARNING_MATERIALS_COUNT)
      .then((response) => {
        switch (tabName) {
          case CONSTS.WALKTHROUGHS:
            setCountData(response.data.walkthroughs_count);
            break;
          case CONSTS.PATHS:
            setCountData(response.data.paths_count);
            break;
          case CONSTS.DOCS:
            setCountData(response.data.videos_count);
            break;
          default:
            console.log("Not found tab name");
        }
      })
  }, []);

  const tabs = [
    {
      name: (
        <div className="lm-tab">
          <MaterialIcon /> {tabName}
          <div className="counter">{countData}</div>
        </div>
      ),
      path: `/${company.slug}/${tabName}`,
    },
    {
      name: (
        <div className="lm-tab">
          <ManageIcon /> Manage Access
        </div>
      ),
      path: `/${company.slug}/${tabName}/manage-access`,
    }
  ];

  const handleTabChange = (tabName) => {
    const tab = tabs.find((tab) => tab.name === tabName);
    window.location.href = tab.path;
  }

    return (
        <LineTab
          tabs={tabs.map((tab) => tab.name)}
          defaultTab={defaultTab}
          onChange={handleTabChange}
        />
    );
};

LMLineTab.defaultProps = {
    tabName: '',
    defaultTab: 0,
};

export default LMLineTab;
