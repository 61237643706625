import React from 'react';
import { AddUserIcon } from '../../assets/Icons';

const Collaborators = () => {
    return (
        <div className="un-collaborators">
            <div className="user">U1</div>
            <div className="user">
                <AddUserIcon />
            </div>
        </div>
    );
};

export default Collaborators;
