import React, { useEffect, useState } from 'react';

const TextInput = ({
    name,
    value,
    type,
    placeholder,
    label,
    onChange,
    onValidation,
    className,
    required,
    lineInput,
    error,
    validationCallback,
    prefix,
    readOnly
}) => {
    const style = {};
    const [errorMsg, setErrorMsg] = useState(error);

    useEffect(() => {
        setErrorMsg(error);
    }, [error]);

    if (lineInput) {
        className += ' line-input';
    }

    if (errorMsg) {
        className += ' error';
    }

    const handleChange = (e) => {
        onChange(e);
        setErrorMsg('');
    };

    const handleMouseout = (e) => {
        if (required && !e.target.value) {
            setErrorMsg('This field is required');
            onValidation(e.target.name, false);
            return;
        }

        // validate with html5 validation
        if (e.target.validity && !e.target.validity.valid) {
            setErrorMsg(e.target.validationMessage);
            onValidation(e.target.name, false);
            return;
        }

        if (validationCallback) {
            const result = validationCallback(e);
            setErrorMsg(result);
            onValidation(e.target.name, false);
            return;
        }

        onValidation(e.target.name, true);
    };

    return (
        <div className="un-text-input">
            <label htmlFor={name}>
                {label}
                {required && <span className="required">*</span>}
            </label>
            <div>
                {
                    prefix && <div className='un-text-input__prefix'>{prefix}</div>
                }
                <input
                    id={name}
                    type={type}
                    style={style}
                    className={className}
                    name={name}
                    placeholder={placeholder}
                    onChange={handleChange}
                    onBlur={handleMouseout}
                    value={value}
                    readOnly={readOnly}
                    data-validity={errorMsg ? 'invalid' : 'valid'}
                />
            </div>
            <p className="error-message">{errorMsg}</p>
        </div>
    );
};

TextInput.defaultProps = {
    onChange: () => { },
    onValidation: () => { },
    className: '',
    placeholder: '',
    label: '',
    name: '',
    type: 'text',
    value: '',
    required: false,
    lineInput: false,
    readOnly: false,
    error: '',
    validationCallback: () => { },
};

export default TextInput;
