import React, { useEffect, useState } from 'react';
import Tools from '../tools/Tools';
import { ArrowRight, ThunderIcon } from '../../assets/Icons';
import Collaborators from '../collaborators/Collaborators';
import Button from '../../atoms/button/Button';
import Actions from '../actions/Actions';
import { useSelector } from 'react-redux';
import { allSteps } from '../../../redux/stepsSlice';
import { getMainConfig } from '../../../redux/stepsSlice';
import { API_ENDPOINTS, ENDPOINTS } from '../../pages/utils/endpoints';
import { http_put } from '../../pages/utils/api_client';
import { getCompany } from '../../../redux/companySlice';

const Toolbar = ({ id, companyName, materialName }) => {
  const company = useSelector((state) => getCompany(state));
  const steps = useSelector((state) => allSteps(state));
  const config = useSelector((state) => getMainConfig(state));
  const [isPublishing, setIsPublishing] = useState(false);

  const handleSave = () => {
    if (id === null) return;

    http_put(API_ENDPOINTS.PUT_WALKTHROUGH(id), { data: steps, config: config })
      .then((res) => {
        setTimeout(() => { }, 2000);
      })
      .catch((err) => { });
  };

  const handlePublish = () => {
    setIsPublishing(true);
    http_put(API_ENDPOINTS.PUBLISH_WALKTHROUGH(id))
      .then((res) => { })
      .catch((err) => { })
      .finally(() => {
        setIsPublishing(false);
      });
  };

  // save after 5 seconds
  useEffect(() => {
    const timeout = setTimeout(() => {
      handleSave();
    }, 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, [steps, config]);

  return (
    <div className="un-toolbar">
      <div className="left">
        <a href={ENDPOINTS.VIEW_LEARNING_MATERIALS(company.slug)}>
          {companyName}
        </a>
        <ArrowRight />
        <p>{materialName}</p>
      </div>
      <div className="center">
        <Tools />
      </div>
      <div className="right">
        <Collaborators />
        <Actions />
        <div className="un-btn-group">
          <Button className={'transparent blue'} onClick={handleSave}>
            Save
          </Button>
          <Button
            className={'ghost blue'}
            onClick={handlePublish}
            isLoading={isPublishing}
          >
            <ThunderIcon />
            Publish
          </Button>
        </div>
      </div>
    </div>
  );
};

Toolbar.defaultProps = {
  id: null,
  company_name: '',
  material_name: ''
};

export default Toolbar;
