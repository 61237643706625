import React from 'react';
import { Provider } from 'react-redux';
import { store } from '../../../redux/store';
import CreateAccount from './create-account';

const Registrations = () => {
    return (
        <Provider store={store}>
            <div className="un-registration">
                <CreateAccount />
            </div>
        </Provider>
    );
};

export default Registrations;
