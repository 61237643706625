import React, { useState } from "react";
import Walkthroughs from "./Walkthroughs";

const tabsList = [
  {
    title: "Walkthroughs",
  },
  {
    title: "Docs",
  },
  {
    title: "Paths",
  },
];

function Tabs({ setChat }) {
  const [activeTab, setActiveTab] = useState(tabsList[0].title);

  return (
    <div>
      <div className="wrapper-tab-chat-menu">
        {tabsList.map((tab, index) => {
          const isActive = activeTab === tab.title;

          return (
            <div
              onClick={() => {
                setActiveTab(tab.title);
              }}
              key={index}
              className="item-tab-chat-menu"
            >
              <a
                className={`menu-tab-chat ${isActive ? "active" : ""}`}
                href="#tabchat-pane1"
              >
                <span className="text-menu-tab-chat">{tab.title}</span>
                <span className="counter-menu-tab-chat">6</span>
              </a>
            </div>
          );
        })}
      </div>
      {activeTab === "Walkthroughs" && <Walkthroughs setChat={setChat} />}
    </div>
  );
}

export default Tabs;
