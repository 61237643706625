import React from "react";
import Video from "../../../../assets/images/video-img.png";

function VideoPlayer() {
  return (
    <div className="video-full">
      <img className="image-full" src={Video} alt="" />
    </div>
  );
}

export default VideoPlayer;
