import React, { useEffect, useState } from "react";
import { API_ENDPOINTS } from "../../../../../../utils/endpoints";
import { http_get } from "../../../../utils/api_client";
import TeamMemberCard from "./TeamMemberCard";

function AdminUsers() {
  const [listTeamMembers, setListTeamMembers] = useState([]);

  useEffect(() => {
      http_get(API_ENDPOINTS.GET_TEAM_MEMBERS, {
        page: 1,
        limit: 20
      })
      .then((res) => {
        if (res.data.members) {
          setListTeamMembers(res.data.members);
        }
      })
      .catch((err) => {
        throw new Error('Failed to fetch profile');
      });
  }, [])

  return (
    <div className="flex-admin-team mt-16">
      <div className="left-flex-admin-team">
        <h4 className="label-invite-team">Admin users</h4>
        <p className="text-invite-team">
          Manage your existing team and change roles/permissions.
        </p>
      </div>
      <div className="right-flex-admin-team">
        <div className="scroll-block cover-table-admin-team">
          <div className="table-admin-team">
            <div className="head-table-admin-team">
              <div className="tr-table-admin-team">
                <div className="td-table-admin-team">
                  <div className="group-admin-check">
                    <input
                      className="data-checkbox team-checkbox"
                      type="checkbox"
                      name="all"
                      value="all"
                    />
                    <div className="text-head-table-admin">Name</div>
                  </div>
                </div>
                <div className="td-table-admin-team">
                  <div className="role-table-admin">
                    <div className="text-role-table-admin">Role</div>
                    <div className="icon-role-table-admin">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="26"
                        height="25"
                        viewBox="0 0 26 25"
                        fill="none"
                      >
                        <path
                          d="M10.0765 9.29319C10.3168 8.60992 10.7913 8.03376 11.4157 7.66676C12.0402 7.29977 12.7744 7.16561 13.4882 7.28806C14.2021 7.41051 14.8497 7.78167 15.3161 8.33579C15.7826 8.88991 16.0379 9.59123 16.0368 10.3155C16.0368 12.3603 12.9697 13.3826 12.9697 13.3826M13.0515 17.472H13.0617M23.2751 12.3603C23.2751 18.0066 18.6978 22.5838 13.0515 22.5838C7.40522 22.5838 2.82799 18.0066 2.82799 12.3603C2.82799 6.71395 7.40522 2.13672 13.0515 2.13672C18.6978 2.13672 23.2751 6.71395 23.2751 12.3603Z"
                          stroke="#98A2B3"
                          strokeWidth="2.04471"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="td-table-admin-team"></div>
                <div className="td-table-admin-team"></div>
              </div>
            </div>
            <div className="body-table-admin-team">
              {listTeamMembers.map((user, index) => {
                return (
                  <TeamMemberCard key={index} {...user} />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminUsers;
