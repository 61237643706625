import React, { useState } from 'react';
import Button from '../../atoms/button/Button';
import { API_ENDPOINTS } from '../../pages/utils/endpoints';
import { http_post } from '../../pages/utils/api_client';
import { materialEditPath } from '../../../utils/endpoints';
import { useSelector } from 'react-redux';
import { getCompany } from '../../../redux/companySlice';

const CreateModal = () => {
  const [name, setName] = useState('');
  const [materialType, setMaterialType] = useState('paths');
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const company = useSelector((state) => getCompany(state));

  const handleNameChange = (e) => {
    const newName = e.target.value;
    setName(newName);
    setIsButtonDisabled(newName.trim() === '');
  };

  const handleSubmit = (e) => {
    if (name.trim().length === 0) {
      alert("Please first enter the name of the material.");
      return;
    }
    e.preventDefault();
    setIsCreating(true);
    const data = {
      name,
      material_type: materialType
    };

    http_post(API_ENDPOINTS.GET_LEARNING_MATERIALS, data)
      .then((res) => {
        const pathRedirect = materialEditPath({
          companySlug: company.slug,
          materialType: res.data.material_type,
          materialSlug: res.data.slug
        });
        window.location.href = pathRedirect;
      })
      .finally(() => {
        setIsCreating(false);
      });
  };

  const types = ['walkthrough', 'paths'];
  const description = {
    paths: 'Documentation base with section and subsection',
    /*docs: 'Contains modules with lessons and learner progress',*/
    walkthrough: 'Guide tour across your product with instruction',
    /*video: 'Video content with onboarding information'*/
  };

  return (
    <div
      className="modal fade"
      id="createModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
    >
      <div className="modal-dialog" role="document" id="modal_dialog">
        <div className="modal-content">
          <button
            className="close"
            type="button"
            data-dismiss="modal"
            aria-label="Close"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <g fill="none" fillRule="evenodd">
                <path d="M0 0L24 0 24 24 0 24z" />
                <path
                  fill="#657489"
                  d="M12 10.586L16.95 5.636 18.364 7.05 13.414 12 18.364 16.95 16.95 18.364 12 13.414 7.05 18.364 5.636 16.95 10.586 12 5.636 7.05 7.05 5.636z"
                />
              </g>
            </svg>
          </button>
          <div className="modal-body">
            <div className="m-body">
              <div className="form-group lm-name">
                <label className="label">Name</label>
                <input
                  type="text"
                  placeholder="Enter name"
                  className="form-control"
                  value={name}
                  onChange={handleNameChange}
                />
                <span
                  id="name_error"
                  style={{
                    display: isButtonDisabled ? 'block' : 'none'
                  }}
                >
                  Name can't be blank
                </span>
              </div>
              <label className="label lbltypes">Type of material</label>
              <div className="type-material-bx">
                {types.map((type, index) => (
                  <div
                    className={`tm-item ${materialType === type ? 'active' : ''
                      }`}
                    key={index}
                    onClick={() => setMaterialType(type)}
                  >
                    <div className="img">
                      <img
                        src={require(`./${type}-icon.png`)}
                        alt={`${type} icon`}
                      />
                    </div>
                    <h4>{type.charAt(0).toUpperCase() + type.slice(1)}</h4>
                    <p>{description[type]}</p>
                  </div>
                ))}
              </div>
              <input type="hidden" value={materialType} />
            </div>
            <div className="m-bottom">
              <button
                className="btn btn-bgnone"
                type="button"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <Button
                disabled={isButtonDisabled}
                isLoading={isCreating}
                onClick={handleSubmit}
                style={{ marginLeft: '10px' }}
              >
                Create
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateModal;
