import React from 'react';
import { Provider } from 'react-redux';
import { store } from '../../../../redux/store';
import LMLineTab from '../../../organisms/line_tab/LMLineTab';
import { CONSTS } from '../../utils/endpoints';
import ShareAccess from '../../../organisms/learning_materials/ShareAccess';
import LMTable from '../../../organisms/learning_materials/LMTable';
import { walkthroughsData } from './data';

const IndexPage = () => {
  return (
    <Provider store={store}>
      
      <div className="un-page-container__learning-materials">
        <div className="header">
          <div className="title">
            <h1>Walkthroughs</h1>
          
          </div>
        </div>
        <LMLineTab defaultTab={1} tabName={CONSTS.WALKTHROUGHS} />
        <ShareAccess />
        <LMTable LMType={CONSTS.WALKTHROUGHS} data={walkthroughsData} />
      </div>
    </Provider>
  );
};

export default IndexPage;
