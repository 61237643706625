import React, { useEffect } from 'react';
import DropdownSelect from '../../atoms/dropdown-select/DropdownSelect';
import { useSelector } from 'react-redux';
import { getCompanyDomains } from '../../../redux/companySlice';

const DomainSelector = ({ name, value, onChange }) => {
  const handleChange = (data) => {
    const result = {
      target: {
        name: name,
        value: data.value,
        label: data.label
      }
    };
    onChange(result);
  };

  const domains = useSelector((state) => getCompanyDomains(state)) || [];
  let options = domains.map((domain) => {
    return {
      value: domain.id,
      label: domain.name
    };
  });

  const selected = options.find((option) => option.value === value);

  useEffect(() => {
    if (value) {
      onChange({
        target: {
          name: name,
          value: value,
          label: selected?.label
        }
      });
    }
  }, []);

  return (
    <DropdownSelect
      name={name}
      value={selected}
      label={'Select a domain or subdomain'}
      placeholder={'Select a domain or subdomain'}
      options={options}
      onChange={handleChange}
    />
  );
};

DomainSelector.defaultProps = {
  name: '',
  value: '',
  onChange: () => { }
};

export default DomainSelector;
