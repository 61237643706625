import React from 'react';
import { CropIcon } from '../../assets/Icons';

const Crop = () => {
  return (
    <div
      className="tool"
      title="Crop"
      data-tooltip-id="tools-tooltip"
      data-tooltip-content="Crop"
    >
      <CropIcon />
    </div>
  );
};

export default Crop;
