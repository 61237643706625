import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getSteps } from '../../../redux/walkthroughPreviewSlice';
import StepPreviewContent from './StepPreviewContent';
import { getActiveStep, setActiveStep } from '../../../redux/toolsSlice';
import { getMaterial } from '../../../redux/materialSlice';
import { getValue } from '../utils/tools';
import { CompleteIcon } from '../../assets/Icons';
import { HUB_ENDPOINTS } from '../utils/endpoints';
import { http_get, http_post } from '../utils/hub_client';
import { setResponseActiveStep } from '../../../redux/hubSlice';

const StepViewer = () => {

  const dispatch = useDispatch();
  const steps = useSelector((state) => getSteps(state));
  const activeStep = useSelector((state) => getActiveStep(state));
  const material = useSelector((state) => getMaterial(state));
  const { config } = material;

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const hubUrl = window.location.pathname.split('/')[2];
    if (hubUrl == 'hub' && material.id) {
      fetchResponse();
    }
    if (steps.length > 0) {
      dispatch(setActiveStep(steps[0]));
      setCurrentIndex(0);
    }
  }, [steps, material.id]);

  const fetchResponse = async () => {
    const url = HUB_ENDPOINTS.GET_RESPONSE(material.id);
    try {
      const res = await http_get(url);
      const responseData = {
        minutes: res.data.data.minutes,
        response: res.data.data.hasOwnProperty('response') ? res.data.data.response : null
      };
      const completeStatus = responseData.response.reduce((acc, current) => {
        acc[current.id] = current.completion;
        return acc;
      }, {});
      dispatch(setResponseActiveStep({ ...responseData, completeStatus }));
      return responseData;
    } catch (error) {
      console.error("Error :", error);
    }
  }

  const getResponse = useSelector((state) => state.hub.responseData.completeStatus);

  const defaultColors = {
    primary: '#3BA4B4',
    background: '#F6F8F9',
    text: '#363841',
    btnColor: '#3BA4B4',
    btnTextColor: '#FFFFFF',
    config: config
  };

  const caHeaderColors = getValue(defaultColors, 'ca', 'caHeaderColor');
  const caHeaderFontSize = getValue(defaultColors, 'ca', 'caHeaderFontSize');
  const caTextColor = getValue(defaultColors, 'ca', 'caTextColor');

  const accordionBgColorstyle = {
    backgroundColor: caHeaderColors || 'white',
  }

  const textStyle = {
    fontSize: caHeaderFontSize || 14,
    color: caTextColor || 'white'
  }

  const handleStepChange = (step, index) => {
    dispatch(setActiveStep(step));
    setCurrentIndex(index);
  }

  const handleNextStep = async () => {
    const hubUrl = window.location.pathname.split('/')[2];
    if (hubUrl == 'hub') {
      await saveResponse();
    }
    if (currentIndex < steps.length - 1) {
      const nextStep = steps[currentIndex + 1];
      handleStepChange(nextStep, currentIndex + 1);
    }
  }

  const saveResponse = async () => {
    const url = HUB_ENDPOINTS.SAVE_RESPONSE(material.id);
    const previousData = await fetchResponse();

    let newData;
    if (previousData && previousData.hasOwnProperty('response')) {
      newData = [...previousData.response]; // Create a copy of the previous response
      const existingIndex = newData.findIndex((e) => e.id === activeStep.id);
      if (existingIndex !== -1) {
        newData[existingIndex] = { "id": activeStep.id, "completion": true };
      } else {
        newData.push({ "id": activeStep.id, "completion": true });
      }
    } else {
      newData = [{ "id": activeStep.id, "completion": true }];
    }

    const newResponse = {
      "minutes": previousData ? previousData.minutes : 0,
      "response": newData
    }

    try {
      const res = await http_post(url, newResponse);
      const materials = res.data.data;
      dispatch(setResponseActiveStep({ ...materials }));
      const updatedGetResponse = { ...getResponse, [activeStep.id]: true };
      dispatch(setResponseActiveStep({ ...materials, completeStatus: updatedGetResponse }));
    } catch (error) {
      console.error("Error completing step:", error);
    }
  }

  return (
    <div className='step-list' >
      {steps.map((step, index) => {
        const isComplete = getResponse && getResponse[step.id] ? getResponse[step.id] : false;
        return (
          <div className={`accordion-cover step ${activeStep.id === step.id ? "active" : ""}`} >
            <div
              onClick={() => handleStepChange(step, index)}
              className="header-accordion header-switch-accordion"
              style={accordionBgColorstyle}
            >
              <div className="text-switch-accordion" style={textStyle}>{`${index + 1}. ${step.title}`}</div>
              {isComplete ? <div><CompleteIcon /></div> :
                <div className="icon-accordion" style={textStyle}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M5.21967 12.2803C5.51256 12.5732 5.98744 12.5732 6.28033 12.2803L9.99998 8.56066L13.7197 12.2803C14.0126 12.5732 14.4874 12.5732 14.7803 12.2803C15.0732 11.9874 15.0732 11.5126 14.7803 11.2197L10.5303 6.96967C10.2374 6.67678 9.76254 6.67678 9.46965 6.96967L5.21967 11.2197C4.92678 11.5126 4.92678 11.9874 5.21967 12.2803Z"
                      fill="currentColor"
                    />
                  </svg>
                </div>
              }
            </div>
            <div
              className={`body-accordion ${activeStep.id === step.id ? "active body-switch-accordion" : ""
                }`}
              id={`accordion-body-${step.title.toLowerCase()}`}
            >
              {activeStep.id === step.id && <StepPreviewContent moveToNextStep={handleNextStep} isComplete={isComplete} />}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default StepViewer