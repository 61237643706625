import React from 'react';
import PropTypes from 'prop-types';

const Header = ({ title, configColors }) => {
    const logo = require('./default_logo.png');

    const style = {
        '--primary-color': configColors.primary,
        '--background-color': configColors.background,
    };

    return (
        <div className="header">
            <div className="left">
                <a href="/">
                    <img src={logo} alt="logo" className="logo" />
                </a>
                <p className="page_title">{title}</p>
            </div>
            <div className="right">
                <a className="link" style={style}>
                    Sign in as Guest
                </a>{' '}
                |{' '}
                <a className="link" style={style} href="/users/sign_in">
                    Sign in as a Learner
                </a>
            </div>
        </div>
    );
};

Header.defaultProps = {
    title: '',
    configColors: {
        primary: '#000000',
        background: '#ffffff',
    },
};

Header.propTypes = {
    greeting: PropTypes.string,
    title: PropTypes.string,
};

export default Header;
