import React, { useEffect, useRef, useState } from 'react';
import { SquareIcon } from '../../assets/Icons';
import { Rect, Transformer, Group } from 'react-konva';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  setActiveTool,
  selectActiveTool,
  setActiveLayer,
  selectActiveLayer
} from '../../../redux/toolsSlice';
import {
  updateLayer,
  setLayerConfig,
  getLayerObjConfig,
  removeLayerConfig
} from '../../../redux/stepsSlice';
import { Html } from 'react-konva-utils';
import ContextMenu from '../context-menu/ContextMenu';

const Square = () => {
  const dispatch = useDispatch();
  const activeTool = useSelector((state) => selectActiveTool(state));
  const [className, setClassName] = useState('tool');

  const setActive = () => {
    dispatch(setActiveTool('square'));
  };

  useEffect(() => {
    if (activeTool === 'square') {
      setClassName('tool active');
    } else {
      setClassName('tool');
    }
  }, [activeTool]);

  return (
    <div
      className={className}
      title="Square"
      data-tooltip-id="tools-tooltip"
      data-tooltip-content="Shape Tool"
      onClick={setActive}
    >
      <SquareIcon />
    </div>
  );
};

export const squareLayerDefaults = (id, mouseDown) => {
  const width = 100;
  const height = 100;
  const pos = mouseDown;

  return {
    id: id,
    type: 'rect',
    x: pos.x,
    y: pos.y,
    width: width,
    height: height,
    fill: '#ffffff',
    stroke: '#000000',
    strokeWidth: 1,
    rotation: 0,
    cornerRadius: 0
  };
};

// Published version of RenderSquare
export const RenderPreviewSquare = ({ layer }) => {
  const renderNode = () => {
    return (
      <>
        <Rect
          x={layer.x}
          y={layer.y}
          width={layer.width}
          height={layer.height}
          rotation={layer.rotation}
          fill={layer.fill}
          stroke={layer.stroke}
          strokeWidth={layer.strokeWidth}
          cornerRadius={layer.cornerRadius}
          key={layer.id}
        />
      </>
    );
  };
  return <>{renderNode()}</>;
};

// Author version of RenderSquare
export const RenderSquare = ({ stepId, layer }) => {
  const shapeRef = useRef();
  const transformerRef = useRef();
  const dispatch = useDispatch();
  const activeLayer = useSelector(
    (state) => selectActiveLayer(state),
    shallowEqual
  );
  const isSelected =
    activeLayer &&
    activeLayer.layerId === layer.id &&
    activeLayer.stepId === stepId;
  const [menuVisible, setMenuVisible] = useState(false);
  const contextMenuOptions = ['Delete'];
  const defaultConfig = [
    {
      layerId: layer.id,
      sectionId: 'square',
      sectionTitle: 'Elements > Shape Tool',
      values: [
        {
          id: 'fill',
          title: 'BG Color',
          type: 'color',
          value: layer.fill || '#ffffff'
        },
        {
          id: 'stroke',
          title: 'Border Color',
          type: 'color',
          value: layer.stroke || '#000000'
        },
        {
          id: 'strokeWidth',
          title: 'Border Weight',
          type: 'px-size',
          value: layer.strokeWidth || 2
        },
        {
          id: 'cornerRadius',
          title: 'Border Radius',
          type: 'px-size',
          value: layer.cornerRadius || 0
        }
      ]
    }
  ];

  const layerObjConfig = useSelector(
    (state) => getLayerObjConfig(state, layer),
    shallowEqual
  );

  useEffect(() => {
    if (layerObjConfig) {
      const newLayer = {
        ...layer,
        ...layerObjConfig
      };
      dispatch(updateLayer({ stepId: stepId, layer: newLayer }));
    }
  }, [layerObjConfig]);

  const renderContextMenu = () => {
    return (
      <ContextMenu
        options={contextMenuOptions}
        activeLayer={layer}
        stepId={stepId}
        dispatch={dispatch}
        onClick={menuClick}
      />
    );
  };

  useEffect(() => {
    if (isSelected) {
      transformerRef.current.nodes([shapeRef.current]);
      transformerRef.current.getLayer().batchDraw();
      dispatch(setLayerConfig(defaultConfig));
    } else {
      if(!activeLayer?.stepId && !activeLayer?.layerId){
        dispatch(removeLayerConfig());
      }
      setMenuVisible(false);
    }
  }, [isSelected]);

  const handleLayerUpdate = (e) => {
    const newLayer = {
      ...layer,
      x: e.target.x(),
      y: e.target.y()
    };
    dispatch(updateLayer({ stepId: stepId, layer: newLayer }));
  };

  const setActive = () => {
    dispatch(setActiveLayer({ stepId: stepId, layerId: layer.id }));
    dispatch(setLayerConfig(defaultConfig));
  };

  const showContextMenu = (e) => {
    e.evt.preventDefault();
    dispatch(setActiveLayer({ stepId: stepId, layerId: layer.id }));
    setMenuVisible(true);
  };

  const menuClick = () => {
    setMenuVisible(false);
  };

  return (
    <>
      <Group x={layer.x} y={layer.y} draggable onDragEnd={handleLayerUpdate}>
        <Rect
          width={layer.width}
          height={layer.height}
          rotation={layer.rotation}
          fill={layer.fill}
          stroke={layer.stroke}
          strokeWidth={layer.strokeWidth}
          cornerRadius={layer.cornerRadius}
          key={layer.id}
          onClick={setActive}
          ref={shapeRef}
          onContextMenu={showContextMenu}
        />
        {menuVisible && <Html>{renderContextMenu()}</Html>}
        {isSelected && (
          <Transformer
            ref={transformerRef}
            keepRatio={false}
            ignoreStroke={true}
            boundBoxFunc={(oldBox, newBox) => {
              if (Math.abs(newBox.width) < 2 || Math.abs(newBox.height) < 2) {
                return oldBox;
              }

              dispatch(
                updateLayer({
                  stepId: stepId,
                  layer: {
                    ...layer,
                    width: newBox.width,
                    height: newBox.height
                  }
                })
              );

              return newBox;
            }}
          />
        )}
      </Group>
    </>
  );
};

export default Square;
