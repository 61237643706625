import React from 'react'
import { useDrag } from 'react-dnd'

const style = {
  cursor: 'move',
  float: 'left',
}

const DragWrapper = ({ children, dropCallback }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'card',
    item: { children },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult()
      if (item && dropResult) {
        dropCallback()
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }))
  const opacity = isDragging ? 0.4 : 1
  return (
    <div ref={drag} style={{ ...style, opacity }} data-testid={`box`}>
      {children}
    </div>
  )
}

DragWrapper.defaultProps = {
  dropCallback: () => { },
}

export default DragWrapper;