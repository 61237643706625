import React from "react";
import SidebarCard from "./SidebarCard";

function Sidebar({ steps, setSteps, activeStep, setActiveStep }) {
  return (
    <div className="sidebar-path-authoring scroll-block">
      <div className="step-sidebar-path">
        <div className="text-step-sidebar-path">Steps</div>
        <div className="icon-step-sidebar-path">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10.0007 3.3335C10.4609 3.3335 10.834 3.70659 10.834 4.16683V9.16683H15.834C16.2942 9.16683 16.6673 9.53993 16.6673 10.0002C16.6673 10.4604 16.2942 10.8335 15.834 10.8335H10.834V15.8335C10.834 16.2937 10.4609 16.6668 10.0007 16.6668C9.54041 16.6668 9.16732 16.2937 9.16732 15.8335V10.8335H4.16732C3.70708 10.8335 3.33398 10.4604 3.33398 10.0002C3.33398 9.53993 3.70708 9.16683 4.16732 9.16683H9.16732V4.16683C9.16732 3.70659 9.54041 3.3335 10.0007 3.3335Z"
              fill="currentColor"
            />
          </svg>
        </div>
      </div>
      <div className="list-step-sidebar">
        {steps.map((step, index) => {
          return (
            <SidebarCard
              key={index}
              index={index + 1}
              title={step.title}
              description={step.description}
              isActive={index === activeStep}
              onClick={() => setActiveStep(index)}
            />
          );
        })}
      </div>
    </div>
  );
}

export default Sidebar;
