import React from 'react'
import Header from './fields/Header'
import TitleAndDescription from './fields/TitleAndDescription'
import DetailsForm from './fields/DetailsForm'
import ButtonGroup from './fields/ButtonGroup'

const DetailsContent = () => {
  return (
    <div className="cover-template-preview">
      <Header />
      <div className="template-preview gap-32">
        <TitleAndDescription
          title="Upload your brand guideline"
          description="description"
        />
        <DetailsForm />
      </div>
      <ButtonGroup />
    </div>
  )
}

export default DetailsContent