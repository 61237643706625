import React from "react";

function SelectedWrapper({ children, visibility = true }) {
  return (
    <div className={visibility ? "wrapper-template-preview active" : ''} style={{ width: '100%', cursor: 'pointer' }}>
      <div className={visibility ? "inner-template-preview" : ''}>
        <div className={visibility ? "chips-template-preview" : ''}>{visibility ? 'Selected' : ''}</div>
        <div className={visibility ? "cover-template-preview" : ''}>{children}</div>
      </div>
    </div>
  );
}

export default SelectedWrapper;
