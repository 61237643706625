import React, { useEffect, useState } from 'react'
import { getActiveLayers, removeActiveTool, selectActiveLayer, setActiveLayer } from '../../../../../../../redux/toolsSlice';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getActiveStep, getLayerObjConfig, removeLayerConfig, setLayerConfig, updateLayer } from '../../../../../../../redux/stepsSlice';
 
export const videoLayerDefaults = (id) => {
  return {
    id: id,
    type: 'video',
    source: ''
  };
}

function VideoContent({ layer }) {
  const dispatch = useDispatch();
  const layerObjConfig = useSelector(
    (state) => getLayerObjConfig(state, layer),
    shallowEqual
  );
  const activeStep = useSelector((state) => getActiveStep(state));
  const stepId = activeStep.id;
  const activeLayer = useSelector((state) => selectActiveLayer(state));
  const defaultConfig = [
    {
      layerId: layer.id,
      sectionId: 'video',
      sectionTitle: 'Video Element',
      values: [
        {
          id: 'source',
          title: 'Video Link',
          type: 'text',
          value: layer.source || ""
        }
      ]
    }
  ];
  
  const editElement  = (e) => {
    removeFocusElement();
    dispatch(setActiveLayer(layer));
    dispatch(setLayerConfig(defaultConfig));
  }

  const removeFocusElement = () => {
    dispatch(setActiveLayer({}));
    dispatch(removeLayerConfig());
  }

  useEffect(() => {
    if (layerObjConfig) {
      const newLayer = {
        ...layer,
        ...layerObjConfig
      };
      dispatch(updateLayer({ stepId: stepId, layer: newLayer }));
    }
  }, [layerObjConfig]);
  
  return (
    <div className='video-wrapper'>
      {layer.source ?
        <VideoContentPreview
          layer={layer}
          onClick={editElement}
        />
      :
        <div className="cover-upload-form" tabIndex={0} onClick={editElement}>
          <div className="filed-upload-form icon-filed-upload-form">
            <header className="text-upload-form">
              Use the sidebar to add the video url. <br/>It will appear here once added
            </header>
          </div>
        </div>
      }
      {/* {activeLayer && activeLayer.id == layer.id && 
        <button className='btn btn-primary save-btn' onClick={removeFocusElement}>Save</button>
      } */}
    </div>
  )
}

export const VideoContentPreview = ({layer, onClick}) => {
  const [video_type, setVideoType] = useState('');
  const [video_source, setVideoSource] = useState('');

  const getSource = (source) => {
    const source_chunk_for_wistia = source.split('.');
    const source_chunk_for_youtube = source.split('/');
    if(source_chunk_for_wistia.filter(ele => ele == 'wistia').length > 0){
      setVideoType('wistia');
      const video_id = source_chunk_for_wistia[source_chunk_for_wistia.length - 1].split('/')[2];
      return `https://fast.wistia.net/embed/iframe/${video_id}?seo=true&videoFoam=false`;    
    }else if(source_chunk_for_youtube.filter(ele => ele == 'youtu.be').length > 0){
      setVideoType('youtube');
      const video_id_chunks = source_chunk_for_youtube[source_chunk_for_youtube.indexOf('youtu.be') + 1];
      const video_id = video_id_chunks.split('?')?.[0];
      return `https://www.youtube.com/embed/${video_id}`;
    }else if(source_chunk_for_youtube.indexOf('vimeo.com') > 0){
      setVideoType('vimeo');
      const video_id = source_chunk_for_youtube[source_chunk_for_youtube.indexOf('vimeo.com') + 1];
      return `https://player.vimeo.com/video/${video_id}`;
    }else{
      setVideoType('normal');
      return source;
    }
  }

  useEffect(() => {
    setVideoSource(getSource(layer?.source))
  }, [])
  
  return(
    <div onClick={onClick}>
      {video_type && <>
        {video_type == 'normal' ?
          <video class="video-player" controls>
            <source src={video_source} />
            Your browser does not support the video tag.
          </video>
        :
          <iframe class="video-player" src={video_source} frameborder="0" allow="fullscreen; picture-in-picture"></iframe>
        }
      </>}
    </div>
  )
}

export default VideoContent