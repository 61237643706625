import React, { useState } from "react";

function Alignment() {
  const [isFullscreen, setIsFullscreen] = useState(false);

  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement
        .requestFullscreen()
        .then(() => {
          setIsFullscreen(true);
        })
        .catch((err) => {
          console.error(
            `Error attempting to enable full-screen mode: ${err.message} (${err.name})`
          );
        });
    } else {
      if (document.exitFullscreen) {
        document
          .exitFullscreen()
          .then(() => {
            setIsFullscreen(false);
          })
          .catch((err) => {
            console.error(
              `Error attempting to disable full-screen mode: ${err.message} (${err.name})`
            );
          });
      }
    }
  };

  return (
    <div className="item-buttons-path-authoring">
      <div className="align-buttons-path">
        <div className="item-align-buttons-path">
          <div className="button-align-buttons">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M0 10C0 9.44772 0.447715 9 1 9H13C13.5523 9 14 9.44772 14 10C14 10.5523 13.5523 11 13 11H1C0.447716 11 0 10.5523 0 10Z"
                fill="currentColor"
              />
              <path
                d="M0 5C0 4.44772 0.447715 4 1 4H19C19.5523 4 20 4.44772 20 5C20 5.55228 19.5523 6 19 6H1C0.447716 6 0 5.55228 0 5Z"
                fill="currentColor"
              />
              <path
                d="M0 15C0 14.4477 0.447715 14 1 14H19C19.5523 14 20 14.4477 20 15C20 15.5523 19.5523 16 19 16H1C0.447716 16 0 15.5523 0 15Z"
                fill="currentColor"
              />
            </svg>
          </div>
        </div>
        <div className="item-align-buttons-path">
          <div className="button-align-buttons">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M3 10C3 9.44772 3.44772 9 4 9H16C16.5523 9 17 9.44772 17 10C17 10.5523 16.5523 11 16 11H4C3.44772 11 3 10.5523 3 10Z"
                fill="currentColor"
              />
              <path
                d="M0 5C0 4.44772 0.447715 4 1 4H19C19.5523 4 20 4.44772 20 5C20 5.55228 19.5523 6 19 6H1C0.447716 6 0 5.55228 0 5Z"
                fill="currentColor"
              />
              <path
                d="M0 15C0 14.4477 0.447715 14 1 14H19C19.5523 14 20 14.4477 20 15C20 15.5523 19.5523 16 19 16H1C0.447716 16 0 15.5523 0 15Z"
                fill="currentColor"
              />
            </svg>
          </div>
        </div>
        <div className="item-align-buttons-path">
          <div className="button-align-buttons">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M6 10C6 9.44772 6.44772 9 7 9H19C19.5523 9 20 9.44772 20 10C20 10.5523 19.5523 11 19 11H7C6.44772 11 6 10.5523 6 10Z"
                fill="currentColor"
              />
              <path
                d="M0 5C0 4.44772 0.447715 4 1 4H19C19.5523 4 20 4.44772 20 5C20 5.55228 19.5523 6 19 6H1C0.447716 6 0 5.55228 0 5Z"
                fill="currentColor"
              />
              <path
                d="M0 15C0 14.4477 0.447715 14 1 14H19C19.5523 14 20 14.4477 20 15C20 15.5523 19.5523 16 19 16H1C0.447716 16 0 15.5523 0 15Z"
                fill="currentColor"
              />
            </svg>
          </div>
        </div>
        <div className="divider-align-buttons-path"></div>
        <div className="item-align-buttons-path">
          <div onClick={toggleFullscreen} className="button-align-buttons">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.15901 2.15901C2.58097 1.73705 3.15326 1.5 3.75 1.5H6C6.41421 1.5 6.75 1.83579 6.75 2.25C6.75 2.66421 6.41421 3 6 3H3.75C3.55109 3 3.36032 3.07902 3.21967 3.21967C3.07902 3.36032 3 3.55109 3 3.75V6C3 6.41421 2.66421 6.75 2.25 6.75C1.83579 6.75 1.5 6.41421 1.5 6V3.75C1.5 3.15326 1.73705 2.58097 2.15901 2.15901ZM11.25 2.25C11.25 1.83579 11.5858 1.5 12 1.5H14.25C14.8467 1.5 15.419 1.73705 15.841 2.15901C16.2629 2.58097 16.5 3.15326 16.5 3.75V6C16.5 6.41421 16.1642 6.75 15.75 6.75C15.3358 6.75 15 6.41421 15 6V3.75C15 3.55109 14.921 3.36032 14.7803 3.21967C14.6397 3.07902 14.4489 3 14.25 3H12C11.5858 3 11.25 2.66421 11.25 2.25ZM2.25 11.25C2.66421 11.25 3 11.5858 3 12V14.25C3 14.4489 3.07902 14.6397 3.21967 14.7803C3.36032 14.921 3.55109 15 3.75 15H6C6.41421 15 6.75 15.3358 6.75 15.75C6.75 16.1642 6.41421 16.5 6 16.5H3.75C3.15326 16.5 2.58097 16.2629 2.15901 15.841C1.73705 15.419 1.5 14.8467 1.5 14.25V12C1.5 11.5858 1.83579 11.25 2.25 11.25ZM15.75 11.25C16.1642 11.25 16.5 11.5858 16.5 12V14.25C16.5 14.8467 16.2629 15.419 15.841 15.841C15.419 16.2629 14.8467 16.5 14.25 16.5H12C11.5858 16.5 11.25 16.1642 11.25 15.75C11.25 15.3358 11.5858 15 12 15H14.25C14.4489 15 14.6397 14.921 14.7803 14.7803C14.921 14.6397 15 14.4489 15 14.25V12C15 11.5858 15.3358 11.25 15.75 11.25Z"
                fill="currentColor"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Alignment;
