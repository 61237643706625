import React, { useState } from "react";
import VisitorLogo1 from "components/assets/images/visitor-logo-1.png";
import VisitorLogo2 from "components/assets/images/visitor-logo-2.png";
import VisitorLogo3 from "components/assets/images/visitor-logo-3.png";
import VisitorLogo4 from "components/assets/images/visitor-logo-4.png";

const filterOptions = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Walkthrough",
    value: "walkthrough",
  },
  {
    label: "Docs",
    value: "docs",
  },
  {
    label: "Paths",
    value: "paths",
  },
  {
    label: "Form",
    value: "form",
  },
];

const contentData = [
  {
    material: {
      name: "Visitors Log",
      title: "Visitors Log",
    },
    uniques: 25,
    opens: 205,
    nps: 25,
    completions: 100,
    imageUrl: VisitorLogo1,
  },
  {
    material: {
      name: "Medications",
      title: "Docs",
    },
    uniques: 25,
    opens: 54,
    nps: 15,
    completions: 80,
    imageUrl: VisitorLogo2,
  },
  {
    material: {
      name: "Facility Inventories",
      title: "Paths",
    },
    uniques: 25,
    opens: 30,
    nps: 25,
    completions: 100,
    imageUrl: VisitorLogo3,
  },
  {
    material: {
      name: "Communications",
      title: "Form",
    },
    uniques: 25,
    opens: 205,
    nps: 8,
    completions: 67,
    imageUrl: VisitorLogo4,
  },
  {
    material: {
      name: "Adding Facility Contact",
      title: "Walkthrough",
    },
    uniques: 25,
    opens: 205,
    nps: 25,
    completions: 100,
    imageUrl: VisitorLogo1,
  },
];

function TopPerformingContent() {
  const [activeTab, setActiveTab] = useState(filterOptions[0].value);

  return (
    <div className="item-engaged-material">
      <div className="material-stat">
        <div className="header-engaged-user">
          <div className="item-header-engaged-user">
            <h3 className="title-size-18">Top Performing Content</h3>
            <p className="content-size-14">
              Analytics compared to the previous period
            </p>
          </div>
          <div className="item-header-engaged-user">
            <div className="filter-table-compared">
              {filterOptions.map((option) => {
                const isActive = activeTab === option.value;
                return (
                  <div key={option.value} className="item-filter-table-compared">
                    <button
                      onClick={() => setActiveTab(option.value)}
                      className={`btn-filter-table-compared ${
                        isActive ? "active" : ""
                      }`}
                      type="button"
                    >
                      {option.label}
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="full-width scroll-block">
          <div className="table-dashboard custom-table-performing">
            <div className="head-table-dashboard">
              <div className="tr-table-dashboard">
                <div className="td-table-dashboard">
                  <div className="text-table-dashboard">Material</div>
                </div>
                <div className="td-table-dashboard">
                  <div className="text-table-dashboard">Uniques</div>
                </div>
                <div className="td-table-dashboard">
                  <div className="text-table-dashboard">Opens</div>
                </div>
                <div className="td-table-dashboard">
                  <div className="text-table-dashboard">NPS</div>
                </div>
                <div className="td-table-dashboard">
                  <div className="text-table-dashboard">Completions</div>
                </div>
              </div>
            </div>
            <div className="body-table-dashboard">
              {contentData.map((data, index) => {
                return (
                  <div key={index} className="tr-table-dashboard">
                    <div className="td-table-dashboard">
                      <div className="visitor">
                        <div className="icon-visitor">
                          <img src={data.imageUrl} alt="Visitors Log" />
                        </div>
                        <div className="content-visitor">
                          <div className="title-visitor">{data.material.name}</div>
                          <div className="subtitle-visitor">
                            {data.material.title}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="td-table-dashboard">
                      <div className="text-table-dashboard">{data.uniques}</div>
                    </div>
                    <div className="td-table-dashboard">
                      <div className="flex-badges">
                        <div className="text-table-dashboard">{data.opens}</div>
                      </div>
                    </div>
                    <div className="td-table-dashboard">
                      <div className="flex-badges">
                        <div className="text-table-dashboard">{data.nps}</div>
                      </div>
                    </div>
                    <div className="td-table-dashboard">
                      <div className="flex-badges">
                        <div className="text-table-dashboard">
                          {data.completions}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopPerformingContent;
