import React from "react";
import ButtonGroup from "./fields/ButtonGroup";
import Header from "./fields/Header";
import FileUpload2 from "./fields/FileUpload2";
import FileUpload1 from "./fields/FileUpload1";
import TitleAndDescription from "./fields/TitleAndDescription";

const MediaContent = () => {
  return (
    <div className="cover-template-preview">
      <Header />
      <div className="template-preview gap-32">
        <TitleAndDescription
          title="Upload your brand guideline"
          description="description"
        />
        <FileUpload1 />
        <FileUpload2 title="Brand Guideline" />
        <FileUpload2 title="Logo" />
        <ButtonGroup />
      </div>
    </div>
  );
}

export default MediaContent;
