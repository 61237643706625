import React from 'react';
import { Provider } from 'react-redux';
import Hub from './Hub';
import { store } from '../../../redux/store';
import HubLayout from './HubLayout';

const IndexPage = (props) => {
  return (
    <>
      <Provider store={store}>
        <HubLayout props={props}>
          <Hub />
        </HubLayout>
      </Provider>
    </>
  );
};

export default IndexPage;
