import React, { useEffect, useState } from 'react';
import Button from '../../atoms/button/Button';
import { API_ENDPOINTS, ENDPOINTS } from '../utils/endpoints';
import { useSelector } from 'react-redux';
import { getOnboardingData } from '../../../redux/onboardingSlice';
import { http_post, http_put, http_get } from '../utils/api_client';
import Loader from '../../organisms/loader/Loader';

const EmailVerification = () => {
    const logo = require('../../assets/logo.png');
    const data = useSelector((state) => getOnboardingData(state));
    const [isLoading, setIsLoading] = useState(true);

    const resendVerification = () => {
        http_post(API_ENDPOINTS.RESEND_VERIFICATION);
    };

    const activateUser = () => {
        http_put(API_ENDPOINTS.PUT_PROFILE_ACTIVATE);
    };

    const checkUserVerification = () => {
        http_get(API_ENDPOINTS.GET_PROFILE).then((res) => {
            if (res.data.user.confirmed_at) {
                window.location.href = ENDPOINTS.ROOT;
            } else {
                setIsLoading(false);
            }
            activateUser();
        });
    };

    useEffect(() => {
        checkUserVerification();
    }, []);

    const gotoLogin = () => {
        window.location.href = ENDPOINTS.LOGIN;
    };

    if (isLoading) {
        return <Loader text={'Finalising your onboarding'} />;
    }

    return (
        <div className="un-registration-layout">
            <div className="header">
                <a href={ENDPOINTS.ROOT}>
                    <img src={logo} alt="logo" />
                </a>
            </div>
            <div className="content">
                <div className="un-verify-email">
                    <h2>We’ve sent a verification email</h2>
                    <p>
                        Click the link in your email to verify your account. If
                        you can’t find the email check your spam folder or{' '}
                        <a onClick={resendVerification}>click here to resent</a>
                        .
                    </p>
                    <Button onClick={gotoLogin}>
                        After verifying your email, Login
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default EmailVerification;
