import React, { useState } from "react";
import { useDrop } from "react-dnd";

const dropStyle = {
  backgroundColor: '#FFFFFF',
  height: '40px',
  width: '100%',
  border: '2px dashed #F0F0F0',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '8px',
}

const DropWrapper = ({ children }) => {
  const [showDropZone, setShowDropZone] = useState(false)
  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: 'card',
    drop: () => ({ name: 'ElementDropzone' }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }))
  const isActive = canDrop && isOver
  let backgroundColor = '#CBD0D7'

  if (isActive) {
    backgroundColor = 'darkgreen'
  } else if (canDrop) {
    backgroundColor = 'darkkhaki'
  }

  const handleDragOver = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setShowDropZone(true)
  }

  const handleDragExit = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setShowDropZone(false)
  }

  document.addEventListener('mouseup', (e) => {
    setShowDropZone(false)
    setBefore(false)
    setAfter(false)
  })

  const [before, setBefore] = useState(false)
  const [after, setAfter] = useState(false)

  const BeforeDropArea = (
    <div style={{ ...dropStyle }} className="drop-area">
      {before && <p>Area active</p>}
    </div>
  )

  const AfterDropArea = (
    <div style={{ ...dropStyle }}>
      {after && <p>Area active</p>}
    </div>
  )

  return (
    <div ref={drop} onDragOverCapture={handleDragOver} onDragLeaveCapture={handleDragExit}>
      {(showDropZone && canDrop) && BeforeDropArea}
      {children}
      {(showDropZone && canDrop) && AfterDropArea}
    </div>
  )
}

DropWrapper.defaultProps = {
  dropCallback: () => { },
}

export default DropWrapper;