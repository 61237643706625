import React, { useEffect, useState } from 'react';
import TextInput from '../../../atoms/text-input/TextInput';
import Button from '../../../atoms/button/Button';
import { ArrowRight } from '../../../assets/Icons';
import { API_ENDPOINTS } from '../../utils/endpoints';
import { useDispatch, useSelector } from 'react-redux';
import {
  getOnboardingData,
  setOnboardingData
} from '../../../../redux/onboardingSlice';
import FileUploader from '../../../atoms/file-uploader/FileUploader';
import { IMAGE_TYPES } from '../../../../constants/image_types';
import { http_put } from '../../utils/api_client';

export const CompanyDetailsTitle = () => {
  return (
    <div className="header">
      <h1 className="title">Add company details</h1>
      <p className="small">
        Customizing your subdomain and the
        <br />
        look and feel of your company
      </p>
    </div>
  );
};

const CompanyDetails = ({ onChangeStep }) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => getOnboardingData(state));
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  const saveData = () => {
    setIsLoading(true);
    http_put(API_ENDPOINTS.PUT_COMPANY, {
      domain: data.domain
    })
      .then((res) => {
        onChangeStep();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(setOnboardingData({ [name]: value }));
  };

  useEffect(() => {
    if (data.companyName) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [data]);

  return (
    <>
      <TextInput
        label="Domain"
        name="domain"
        placeholder="Enter your company domain"
        onChange={handleChange}
        value={data.domain}
        lineInput
      />
      <FileUploader
        label="Logo"
        name="logoUrl"
        onChange={handleChange}
        value={data.logoUrl}
        image_type={IMAGE_TYPES.COMPANY_LOGO}
      />
      <div className="divider" />
      <FileUploader
        label="Favicon"
        name="faviconUrl"
        onChange={handleChange}
        value={data.faviconUrl}
        image_type={IMAGE_TYPES.FAVICON}
      />
      <Button
        className="lg"
        disabled={isDisabled}
        isLoading={isLoading}
        onClick={saveData}
      >
        Next
        <ArrowRight />
      </Button>
    </>
  );
};

export default CompanyDetails;
