import React, { useEffect, useState } from 'react';

const Tabs = ({ tabs, onChange }) => {
  const [currentTab, setCurrentTab] = useState(0);
  const isActiveTab = (index) => {
    return currentTab === index ? 'active' : '';
  };

  const handleChange = (index) => {
    setCurrentTab(index);
    onChange(tabs[index]);
  };

  useEffect(() => {
    onChange(tabs[currentTab]);
  }, []);

  const renderTabs = () => {
    return tabs.map((tab, index) => {
      return (
        <div
          className={['tab', isActiveTab(index)].join(' ')}
          key={index}
          onClick={() => handleChange(index)}
        >
          {tab}
        </div>
      );
    });
  };
  return (
    <div className="un-tabs">
      <div className="tabs-container">{renderTabs()}</div>
    </div>
  );
};

Tabs.defaultProps = {
  tabs: [],
  onChange: () => {}
};

export default Tabs;
