import React from "react";
import DataTable from "../datatable/DataTable";
import { satisfactionScoreColumns } from "./extra/satisfactionScoreColumns";

export default function SatisfactionScore({ profile }) {
  return (
    <div id="tab-5" className="tab-content-avatar-model">
      <div className="header-tab-avatar mb-8">
        <div className="item-header-tab-avatar">
          <h3 className="heading-tab-avatar">Satisfaction Score</h3>
        </div>
      </div>
      <DataTable
        columns={satisfactionScoreColumns}
        data={profile.materials}
        toolbarType="satisfaction"
      />
    </div>
  );
}
