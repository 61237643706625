import React from 'react';
import { Provider } from 'react-redux';
import { store } from '../../../redux/store';
import EditorPreview from './EditorPreview';

const EditorPreviewWrapper = ({ id }) => {
  return (
    <Provider store={store}>
      <EditorPreview id={id} />
    </Provider>
  );
};

export default EditorPreviewWrapper;
