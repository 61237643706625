import React, { useEffect, useState, useRef } from "react";
import { getActiveStep, selectActiveLayer, setActiveLayer } from "../../../../../../../redux/toolsSlice";
import { getLayerObjConfig, removeLayerConfig, setLayerConfig, updateLayer } from '../../../../../../../redux/stepsSlice';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

export const ShortAnswerLayerDefaults = (id) => {
  return {
    id: id,
    type: 'short_answer',
    question: 'Enter your question.',
    answer: 'Enter your answer.',
  };
}

function ShortAnswerContent({ layer }) {

  const [isEditingQuestion, setIsEditingQuestion] = useState(false);

  const questionRef = useRef(null);
  const answerRef = useRef(null);

  const dispatch = useDispatch();
  const layerObjConfig = useSelector(
    (state) => getLayerObjConfig(state, layer),
    shallowEqual
  );

  const activeStep = useSelector((state) => getActiveStep(state));
  const stepId = activeStep.id;
  const activeLayer = useSelector((state) => selectActiveLayer(state));

  const defaultConfig = [
    {
      layerId: layer.id,
      sectionId: 'shortAnswer',
      sectionTitle: 'Short Answer Element',
      values: [
        {
          id: 'question',
          title: 'Question',
          type: 'text',
          value: layer.question || ''
        }
      ]
    }
  ];

  const EditQuestionElement = () => {
    removeFocusElement();
    dispatch(setActiveLayer(layer));
    dispatch(setLayerConfig(defaultConfig));
    setIsEditingQuestion(true);
  }

  const removeFocusElement = () => {
    dispatch(setActiveLayer({}));
    dispatch(removeLayerConfig());
    setIsEditingQuestion(false);
  }

  const updateText = () => {
    const newLayer = {
      ...layer,
      question: questionRef.current ? questionRef.current.value : layer.question,
    };
    dispatch(updateLayer({ stepId: stepId, layer: newLayer }));
  };

  useEffect(() => {
    if (layerObjConfig) {
      const newLayer = {
        ...layer,
        ...layerObjConfig
      };
      dispatch(updateLayer({ stepId: stepId, layer: newLayer }));
    }
  }, [layerObjConfig]);

  const handleEditBlur = (e) => {
    setIsEditingQuestion(false);
    removeFocusElement()
    updateText();
  };

  const onPressEnter = (e) => {
    if (e.key === 'Enter' || e.key === "Escape") {
      setIsEditingQuestion(false);
      removeFocusElement();
      updateText();
    }
  };

  return (
    <>
      <div className="container-large-text">
        {isEditingQuestion ? (
          <input
            ref={questionRef}
            defaultValue={layer.question}
            onBlur={handleEditBlur}
            onKeyDown={onPressEnter}
            className="text-input-group-preview color-blue-grey"
            style={{
              border: 'none',
              width: '100%',
              height: '100%',
            }}
            placeholder="Enter your question"
          />
        ) : (
          <p
            tabIndex={0}
            onClick={EditQuestionElement}
            className="text-input-group-preview color-blue-grey"
          >
            {layer.question}
          </p>
        )}
        <input
          ref={answerRef}
          // defaultValue={layer.answer}
          onBlur={handleEditBlur}
          onKeyDown={onPressEnter}
          className="input-text-preview"
          placeholder="Enter your answer"
        />
      </div>
    </>
  )
}

export const ShortAnswerContentPreview = ({ layer }) => {
  return (
    <div class="item-input-group-preview">
      <div class="text-input-group-preview color-blue-grey">{layer?.question}</div>
      <input class="input-text-preview" type="text" placeholder="Enter your answer" />
    </div>
  )
}

export default ShortAnswerContent;