import React, { useMemo } from "react";

const DOTS = "...";

const range = (start, end) => {
  let length = end - start;
  return Array.from({ length }, (_, idx) => idx + start);
};

function Pagination({ table }) {
  const usePagination = ({
    totalCount,
    pageSize,
    siblingCount = 1,
    currentPage,
  }) => {
    const paginationRange = useMemo(() => {
      const totalPageCount = Math.ceil(totalCount / pageSize);
      const totalPageNumbers = siblingCount + 5;

      if (totalPageNumbers >= totalPageCount) {
        return range(0, totalPageCount);
      }

      const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
      const rightSiblingIndex = Math.min(
        currentPage + siblingCount,
        totalPageCount
      );

      const shouldShowLeftDots = leftSiblingIndex > 2;
      const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

      const firstPageIndex = 0;
      const lastPageIndex = totalPageCount - 1;

      if (!shouldShowLeftDots && shouldShowRightDots) {
        let leftItemCount = 3 + 2 * siblingCount;
        let leftRange = range(0, leftItemCount);

        return [...leftRange, DOTS, lastPageIndex];
      }

      if (shouldShowLeftDots && !shouldShowRightDots) {
        let rightItemCount = 3 + 2 * siblingCount;
        let rightRange = range(
          totalPageCount - rightItemCount + 1,
          totalPageCount
        );
        return [firstPageIndex, DOTS, ...rightRange];
      }

      if (shouldShowLeftDots && shouldShowRightDots) {
        let middleRange = range(leftSiblingIndex, rightSiblingIndex);
        return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
      }
    }, [totalCount, pageSize, siblingCount, currentPage]);

    return paginationRange;
  };

  const paginationRange = usePagination({
    totalCount: table.getFilteredRowModel().rows.length, // total number of items
    pageSize: table.getState().pagination.pageSize,
    siblingCount: 1,
    currentPage: table.getState().pagination.pageIndex,
  });

  return (
    <section className="section-pagination">
      <div className="page-padding">
        <div className="flex-pagination-row">
          <div className="left-pagination">
            {/* Counter Row Page */}
            <div className="counter-row-page">
              <div className="row-per-page">
                Rows per page:
                <select
                  className="counter-select"
                  defaultValue={`${table.getState().pagination.pageSize}`}
                  onChange={(e) => {
                    table.setPageSize(Number(e.target.value));
                  }}
                >
                  {[10, 20, 30, 40, 50].map((pageSize) => (
                    <option value={`${pageSize}`} key={pageSize}>
                      {pageSize}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {/* End Counter Row Page */}
          </div>
          <div className="right-pagination">
            <div className="data-pagination">
              <button
                onClick={() => table.previousPage()}
                disabled={!table.getCanPreviousPage()}
                type="button"
                className="pagination-prev"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M10.25 12.5L5.75 8L10.25 3.5"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
              <div className="number-data-pagination">
                {paginationRange.map((pageNumber, index) => {
                  if (pageNumber === DOTS) {
                    return (
                      <div className="text-pagination-number" key={index}>
                        &#8230;
                      </div>
                    );
                  }
                  return (
                    <button
                      className={`pagination-number ${
                        pageNumber === table.getState().pagination.pageIndex
                          ? "active"
                          : ""
                      }`}
                      key={index}
                      onClick={() => table.setPageIndex(pageNumber)}
                    >
                      {pageNumber + 1}
                    </button>
                  );
                })}
              </div>
              <button
                onClick={() => table.nextPage()}
                disabled={!table.getCanNextPage()}
                type="button"
                className="pagination-next"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M5.75 3.5L10.25 8L5.75 12.5"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Pagination;
