import React, { useEffect, useRef, useState } from "react";

// pass filters
function MultiCombobox({
  options,
  placeholder = "",
  filters,
  setFilters,
  isSelectAllEnabled = false,
}) {
  const [isActive, setIsActive] = useState(false);

  const containerRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setIsActive(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [containerRef]);

  const handleUserClick = () => {
    setIsActive((prev) => !prev);
  };

  return (
    <div className="toolbar-filter-section" ref={containerRef}>
      <div className="item-data-filter">
        <div className="data-filter" onClick={handleUserClick}>
          <div className="icon-data-filter">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.1663 11.6665H13.833C14.293 11.6665 14.6663 12.0398 14.6663 12.4998V15.8332C14.6663 16.2932 14.293 16.6665 13.833 16.6665H12.1663C11.7063 16.6665 11.333 16.2932 11.333 15.8332V12.4998C11.333 12.0398 11.7063 11.6665 12.1663 11.6665Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M18.0003 14.1665H14.667"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M11.3333 14.1665H3"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.83301 8.3335H7.16634C6.70634 8.3335 6.33301 7.96016 6.33301 7.50016V4.16683C6.33301 3.70683 6.70634 3.3335 7.16634 3.3335H8.83301C9.29301 3.3335 9.66634 3.70683 9.66634 4.16683V7.50016C9.66634 7.96016 9.29301 8.3335 8.83301 8.3335Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M3 5.8335H6.33333"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9.66699 5.8335H18.0003"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div className="content-data-filter">{placeholder}</div>
          <div className="arrow-data-filter">
            <div className="data-filter-text-arrow">All</div>
            <div className="data-filter-arrow">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none"
              >
                <path
                  d="M6.675 6.5332L10.5 10.3499L14.325 6.5332L15.5 7.7082L10.5 12.7082L5.5 7.7082L6.675 6.5332Z"
                  fill="currentColor"
                />
              </svg>
            </div>
          </div>
        </div>
        <div className={`dropdown-data-filter ${isActive ? "active" : ""}`}>
          <div className="list-dropdown-data-filter">
            {options.map((option, index) => {
              const isSelected =
                filters.find((e) => e === option.value) ?? false;

              return (
                <div key={index} className="item-dropdown-data-filter">
                  <label
                    className={`dropdown-data-filter-button ${
                      isSelected ? "active" : ""
                    }`}
                    htmlFor={option.value}
                  >
                    <div className="cover-dropdown-data-checkbox">
                      <input
                        className={`dropdown-data-checkbox `}
                        type="checkbox"
                        multiple
                        id={option.value}
                        name={option.value}
                        checked={isSelected}
                        onChange={() => {
                          if (isSelected) {
                            setFilters((prev) =>
                              prev.filter((e) => e !== option.value)
                            );
                          } else {
                            setFilters((prev) => [...prev, option.value]);
                          }
                        }}
                      />
                    </div>
                    <div className="text-dropdown-data-checkbox">
                      {option.label}
                    </div>
                  </label>
                </div>
              );
            })}
            {isSelectAllEnabled ? (
              <div className="item-dropdown-data-filter">
                <button
                  onClick={() => {
                    setFilters(options.map((e) => e.value));
                  }}
                  type="button"
                  className="all-dropdown-data"
                >
                  Select All
                </button>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MultiCombobox;
