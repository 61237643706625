import React, { useState } from 'react';
import { store } from '../../../redux/store';
import HubLayout from './HubLayout';
import { Provider } from 'react-redux';
import messageLogo from "../../../../assets/images/signin_message_logo.svg";
import { http_post } from '../utils/api_client';
import { API_ENDPOINTS } from '../utils/endpoints';

const PasswordlessSignIn = (props) => {

  const { design } = props;
  const [email, setEmail] = useState('');
  const [isSendingLink, setIsSendingLink] = useState(false);
  const [emailError, setEmailError] = useState(null);
  const [linkSent, setLinkSent] = useState(false);

  const CustomCSS = JSON.parse(design.css);

  const configColors = {
    primary: '#3BA4B4',
    background: 'red',
    text: '#363841',
    btnColor: '#3BA4B4',
    btnTextColor: '#FFFFFF',
  };

  const style = {
    backgroundColor: configColors.background,
    color: configColors.text
  };

  const handleEmailChange = (e) => {
    const emailValue = e.target.value;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (emailRegex.test(emailValue)) {
      setEmail(emailValue);
      setEmailError(null);
    } else {
      setEmail(emailValue);
      setEmailError('Invalid email address');
    }
  }

  const handleGetMagicLink = async (event) => {
    event.preventDefault();
    if (!email) {
      setEmailError('Email is required');
      return;
    }
    // if (emailError) {
    //   return;
    // }
    setIsSendingLink(true);
    try {
      const response = await http_post(API_ENDPOINTS.SEND_MAGIC_LINK, { email });
      setLinkSent(true); // set linkSent to true
    } catch (error) {
      console.error(error);
      alert('Error sending magic link');
    } finally {
      setIsSendingLink(false);
    }
  };

  return (
    <Provider store={store}>
      <HubLayout hideHeader={true} headerStyle={style} >
        <>
          <div className="un-signin__signin">
            <div style={{
            }}>
              <img
                className='company-logo'
                src={design.logo}
                style={{
                  display: "block",
                  margin: "0 auto",
                  height: 60,
                }} />
            </div>
          </div>
          <div className="container">
            <div className="un-signin__magiclinkcard">
              <div className='content-wrapper'>
                <div className='logo-image'>
                  <img height={"110px"} width={"110px"} src={messageLogo} />
                </div>
                {linkSent ?
                  <>
                    <div className='magiccard_header'>
                      <h2>{"Check your Email!"}</h2>
                    </div>
                    <div className='magiccard_subheader'>
                      <h3>{"We’ve sent a new login link to your email inbox. Please click the link in the button to sign in."}</h3>
                    </div>
                  </>
                  :
                  <>
                    <div className='magiccard_header'>
                      <h2>{design.details.heading_text ? design.details.heading_text : "Looks like you need a new link"}</h2>
                    </div>
                    <div className='magiccard_subheader'>
                      <h3>{design.details.sub_heading_text ? design.details.sub_heading_text : "Enter your email address and we’ll send you a new link to sign in"}</h3>
                    </div>
                    <div className='auth-form'>
                      <form className='form-group'>
                        <div className='input-container'>
                          <label className='label-email' htmlFor='email'>Email Address</label>
                          <input
                            type='email'
                            name='email'
                            className='card-input email-input'
                            value={email}
                            onChange={handleEmailChange}
                          />
                          {emailError && <span className='error-message'>{emailError}</span>}
                          <button
                            // type='button'
                            className='send-button'
                            onClick={handleGetMagicLink}
                            disabled={isSendingLink || emailError}

                          >
                            {isSendingLink ? 'Sending...' : 'SEND LINK'}
                          </button>
                        </div>
                      </form>
                    </div>
                  </>
                }
              </div>
            </div>
          </div>
          <style dangerouslySetInnerHTML={{ __html: CustomCSS }}></style>
        </>
      </HubLayout>
    </Provider>
  )
}

export default PasswordlessSignIn