import React, { useState } from "react";

function ScreenToggle() {
  return (
    <div className="item-buttons-path-authoring">
      <div className="switch-screen-toolbar">
        <div className="item-switch-screen-toolbar">
          <div  className="button-switch-screen active">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M15.8334 2.5H4.16675C3.50371 2.5 2.86782 2.76339 2.39898 3.23223C1.93014 3.70107 1.66675 4.33696 1.66675 5V11.6667C1.66675 12.3297 1.93014 12.9656 2.39898 13.4344C2.86782 13.9033 3.50371 14.1667 4.16675 14.1667H9.16675V15.8333H5.83342C5.6124 15.8333 5.40044 15.9211 5.24416 16.0774C5.08788 16.2337 5.00008 16.4457 5.00008 16.6667C5.00008 16.8877 5.08788 17.0996 5.24416 17.2559C5.40044 17.4122 5.6124 17.5 5.83342 17.5H14.1667C14.3878 17.5 14.5997 17.4122 14.756 17.2559C14.9123 17.0996 15.0001 16.8877 15.0001 16.6667C15.0001 16.4457 14.9123 16.2337 14.756 16.0774C14.5997 15.9211 14.3878 15.8333 14.1667 15.8333H10.8334V14.1667H15.8334C16.4965 14.1667 17.1323 13.9033 17.6012 13.4344C18.07 12.9656 18.3334 12.3297 18.3334 11.6667V5C18.3334 4.33696 18.07 3.70107 17.6012 3.23223C17.1323 2.76339 16.4965 2.5 15.8334 2.5ZM16.6667 11.6667C16.6667 11.8877 16.579 12.0996 16.4227 12.2559C16.2664 12.4122 16.0544 12.5 15.8334 12.5H4.16675C3.94573 12.5 3.73377 12.4122 3.57749 12.2559C3.42121 12.0996 3.33341 11.8877 3.33341 11.6667V5C3.33341 4.77899 3.42121 4.56702 3.57749 4.41074C3.73377 4.25446 3.94573 4.16667 4.16675 4.16667H15.8334C16.0544 4.16667 16.2664 4.25446 16.4227 4.41074C16.579 4.56702 16.6667 4.77899 16.6667 5V11.6667Z"
                fill="currentColor"
              />
            </svg>
          </div>
        </div>
        <div className="item-switch-screen-toolbar">
          <div className="button-switch-screen">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5 2.50004C4.53976 2.50004 4.16667 2.87314 4.16667 3.33337V16.6667C4.16667 17.1269 4.53976 17.5 5 17.5H15C15.4602 17.5 15.8333 17.1269 15.8333 16.6667V3.33337C15.8333 2.87314 15.4602 2.50004 15 2.50004H5ZM2.5 3.33337C2.5 1.95266 3.61929 0.833374 5 0.833374H15C16.3807 0.833374 17.5 1.95266 17.5 3.33337V16.6667C17.5 18.0474 16.3807 19.1667 15 19.1667H5C3.61929 19.1667 2.5 18.0474 2.5 16.6667V3.33337ZM9.16667 15C9.16667 14.5398 9.53976 14.1667 10 14.1667H10.0083C10.4686 14.1667 10.8417 14.5398 10.8417 15C10.8417 15.4603 10.4686 15.8334 10.0083 15.8334H10C9.53976 15.8334 9.16667 15.4603 9.16667 15Z"
                fill="currentColor"
              />
            </svg>
          </div>
        </div>
        <div className="item-switch-screen-toolbar">
          <div className="button-switch-screen">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.83325 2.50004C5.37301 2.50004 4.99992 2.87314 4.99992 3.33337V16.6667C4.99992 17.1269 5.37301 17.5 5.83325 17.5H14.1666C14.6268 17.5 14.9999 17.1269 14.9999 16.6667V3.33337C14.9999 2.87314 14.6268 2.50004 14.1666 2.50004H5.83325ZM3.33325 3.33337C3.33325 1.95266 4.45254 0.833374 5.83325 0.833374H14.1666C15.5473 0.833374 16.6666 1.95266 16.6666 3.33337V16.6667C16.6666 18.0474 15.5473 19.1667 14.1666 19.1667H5.83325C4.45254 19.1667 3.33325 18.0474 3.33325 16.6667V3.33337ZM9.16658 15C9.16658 14.5398 9.53968 14.1667 9.99992 14.1667H10.0083C10.4685 14.1667 10.8416 14.5398 10.8416 15C10.8416 15.4603 10.4685 15.8334 10.0083 15.8334H9.99992C9.53968 15.8334 9.16658 15.4603 9.16658 15Z"
                fill="currentColor"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ScreenToggle;
