const baseUrl = '/api/v1';
export const API_ENDPOINTS = {
  REGISTER: `${baseUrl}/registration`,
  RESEND_VERIFICATION: `${baseUrl}/registration/send_confirmation_email`,
  LOGIN: `/sign_in`,
  PUT_PROFILE: `${baseUrl}/profile`,
  GET_PROFILE: `${baseUrl}/profile`,
  PUT_PROFILE_ACTIVATE: `${baseUrl}/profile/activate`,
  DELETE_PROFILE_AVATAR: `${baseUrl}/profile/remove_avatar`,
  PUT_USER_BIO: `${baseUrl}/profile/bio`,
  GET_COMPANY: `${baseUrl}/company`,
  PUT_COMPANY: `${baseUrl}/company`,
  POST_UPLOAD_FILE: `${baseUrl}/uploads`,
  POST_INVITE_TEAM: `${baseUrl}/team/invite`,
  GET_TEAM_MEMBERS: `${baseUrl}/team/members`,
  PUT_UPDATE_TEAM_MEMBERS_ROLE: (id) => `${baseUrl}/team/members/${id}/update_role`,
  DELETE_TEAM_MEMBER: (id) => `${baseUrl}/team/members/${id}`,
  GET_LEARNING_MATERIALS: `${baseUrl}/learning_materials`,
  GET_LEARNING_MATERIALS_COUNT: `${baseUrl}/learning_materials/count`,
  LEARNING_MATERIAL: (id) => `${baseUrl}/learning_materials/${id}`,
  PUT_LEARNING_MATERIAL_VISIBILITY: (id) =>
    `${baseUrl}/learning_materials/${id}/visibility`,
  GET_WALKTHROUGH: (id) => `${baseUrl}/walkthroughs/${id}`,
  PUT_WALKTHROUGH: (id) => `${baseUrl}/walkthroughs/${id}`,
  PUT_WALKTHROUGH_SETTINGS: (id) => `${baseUrl}/walkthroughs/${id}/settings`,
  PUBLISH_WALKTHROUGH: (id) => `${baseUrl}/walkthroughs/${id}/publish`,
  POST_DOMAIN: `${baseUrl}/domains`,
  GET_DOMAINS: `${baseUrl}/domains`
};

const hubBaseUrl = '/api/v1/hub';
export const HUB_ENDPOINTS = {
  GET_MATERIALS: `${hubBaseUrl}/materials`,
  GET_COMPANY_DETAILS: `${hubBaseUrl}/company`,
  GET_MATERIAL: (slug) => `${hubBaseUrl}/materials/${slug}`,
};

export const HUB_VIEW_ENDPOINTS = {
  VIEW_MATERIAL: (company_slug, slug) => `/${company_slug}/hub/${slug}`,
};

export const ENDPOINTS = {
  LOGIN: `/sign_in`,
  USER_SIGNOUT: `/sign_out`,
  VIEW_WALKTHROUGH: (company_slug, slug) => `/${company_slug}/walkthroughs/${slug}`,
  EDIT_WALKTHROUGH: (company_slug, slug) => `/${company_slug}/walkthroughs/${slug}/edit`,
  VIEW_PATHS: (company_slug, slug) => `/${company_slug}/paths/${slug}`,
  EDIT_PATHS: (company_slug, slug) => `/${company_slug}/paths/${slug}/edit`,
  VIEW_LEARNING_MATERIALS: (slug) => `/${slug}/learning_materials`,
  ONBOARDING: `/onboarding`,
  ROOT: `/`
};

export const INTERNAL_ENDPOINTS = {
  POST_LIBRARY: `${baseUrl}/library`
};

export const CONSTS = {
  AUTH_TOKEN: 'auth_token',
  AUDIENCE_TOKEN: 'aud_token',
  WALKTHROUGHS: 'walkthroughs',
  VIDEOS: 'videos',
  PATHS: 'paths',
  DOCS: 'docs'
};

export const materialEditPath = ({ companySlug, materialType, materialSlug }) => {
  switch (materialType) {
    case 'Walkthrough':
      return ENDPOINTS.EDIT_WALKTHROUGH(companySlug, materialSlug);
    case 'course':
      return ENDPOINTS.EDIT_COURSE(companySlug, materialSlug);
    case 'Paths':
      return ENDPOINTS.EDIT_PATHS(companySlug, materialSlug);
    default:
      return '';
  }
}
