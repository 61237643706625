import React, { useEffect, useState } from 'react';
import { GlobeIcon, StorageIcon } from '../../../assets/Icons';
import Button from '../../../atoms/button/Button';
import DomainModal from './components/DomainModal';
import { http_get, http_post } from '../../utils/api_client';
import { API_ENDPOINTS } from '../../../pages/utils/endpoints';
import { getCompany } from '../../../../redux/companySlice';
import { useSelector } from 'react-redux';

const DomainPage = () => {
  const [domains, setDomains] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [emptyMsg, setEmptyMsg] = useState('Loading domains...');

  const company = useSelector((state) => getCompany(state));

  const showInstructionModal = () => {
    $('#instructionSubdomainModal').modal({ backdrop: 'static' });
  };

  useEffect(() => {
    setIsLoading(true);
    http_get(API_ENDPOINTS.GET_DOMAINS).then((res) => {
      setDomains(res.data);
      setIsLoading(false);
      setEmptyMsg('No domains found');
    });
  }, []);

  const handleManageSignIn = (id) => {
    const path = `/${company?.slug}/domains/${id}/domain_page_configuration`;
    window.location.pathname = path;
  }

  const renderDomains = () => {
    return domains.map((domain) => {
      const statusClass = domain.status.toLowerCase();
      return (
        <tr key={domain.id}>
          <td>
            <div>
              <GlobeIcon />
              {domain.domain}
            </div>
          </td>
          <td>
            <div>
              <StorageIcon />
              {domain.subdomain}
            </div>
          </td>
          <td>
            <div className={`un-status__${statusClass}`}>{domain.status}</div>
          </td>
          <td>
            <div className='un-domain-options'>
              <button className="un-btn un-btn--primary">Edit</button>
              <button className="un-btn un-btn--danger">Delete</button>
              <button
                className="un-btn un-btn--primary"
                onClick={() => handleManageSignIn(domain.id)}>
                Manage Sign in Configuration
              </button>
            </div>
          </td>
        </tr >
      );
    });
  };

  return (
    <div className="un-settings">
      <div className="flex-profile-data">
        <DomainModal />
        <section>
          <div className="section-heading">
            <p>Add your Domain or Subdomain</p>
            <Button className="ghost" onClick={showInstructionModal}>
              Add Subdomain
            </Button>
          </div>
        </section>
        <table className="un-table">
          <thead>
            <tr>
              <th>Domain</th>
              <th>Subdomain</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {domains.length > 0 ? (
              renderDomains()
            ) : (
              <tr>
                <td colSpan="4" className="text-center">{emptyMsg}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DomainPage;
