import React from "react";

const UpArrow = () => {
  return (
    <div className="arrow-progress-kpi-avatar">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="10"
        height="11"
        viewBox="0 0 10 11"
        fill="none"
      >
        <path
          d="M8.96003 6.16668C8.87229 6.16719 8.78532 6.15037 8.70409 6.11719C8.62287 6.08402 8.54899 6.03513 8.48669 5.97334L5.00003 2.49334L1.51336 5.98001C1.4536 6.0498 1.38005 6.10648 1.29734 6.1465C1.21463 6.18651 1.12454 6.209 1.03273 6.21254C0.940917 6.21609 0.849363 6.20062 0.763813 6.1671C0.678263 6.13359 0.600564 6.08275 0.535594 6.01778C0.470624 5.95281 0.419786 5.87511 0.386269 5.78956C0.352753 5.70401 0.337282 5.61246 0.340829 5.52064C0.344375 5.42883 0.366862 5.33874 0.406877 5.25603C0.446893 5.17332 0.503574 5.09978 0.573361 5.04001L4.52669 1.08001C4.58867 1.01753 4.6624 0.96793 4.74364 0.934084C4.82488 0.900238 4.91202 0.882812 5.00003 0.882812C5.08804 0.882812 5.17517 0.900238 5.25641 0.934084C5.33765 0.96793 5.41139 1.01753 5.47336 1.08001L9.42669 5.04001C9.55086 5.16492 9.62056 5.33389 9.62056 5.51001C9.62056 5.68614 9.55086 5.8551 9.42669 5.98001C9.30145 6.10074 9.13398 6.16772 8.96003 6.16668Z"
          fill="currentColor"
        />
        <path
          d="M4.99992 10.1667C4.82311 10.1667 4.65354 10.0965 4.52851 9.97146C4.40349 9.84643 4.33325 9.67686 4.33325 9.50005V1.55339C4.33325 1.37657 4.40349 1.20701 4.52851 1.08198C4.65354 0.956957 4.82311 0.886719 4.99992 0.886719C5.17673 0.886719 5.3463 0.956957 5.47132 1.08198C5.59635 1.20701 5.66659 1.37657 5.66659 1.55339V9.50005C5.66659 9.67686 5.59635 9.84643 5.47132 9.97146C5.3463 10.0965 5.17673 10.1667 4.99992 10.1667Z"
          fill="currentColor"
        />
      </svg>
    </div>
  );
};

const DownArrow = () => {
  return (
    <div className="arrow-progress-kpi-avatar down-arrow">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="10"
        height="11"
        viewBox="0 0 10 11"
        fill="none"
      >
        <path
          d="M8.96003 6.16668C8.87229 6.16719 8.78532 6.15037 8.70409 6.11719C8.62287 6.08402 8.54899 6.03513 8.48669 5.97334L5.00003 2.49334L1.51336 5.98001C1.4536 6.0498 1.38005 6.10648 1.29734 6.1465C1.21463 6.18651 1.12454 6.209 1.03273 6.21254C0.940917 6.21609 0.849363 6.20062 0.763813 6.1671C0.678263 6.13359 0.600564 6.08275 0.535594 6.01778C0.470624 5.95281 0.419786 5.87511 0.386269 5.78956C0.352753 5.70401 0.337282 5.61246 0.340829 5.52064C0.344375 5.42883 0.366862 5.33874 0.406877 5.25603C0.446893 5.17332 0.503574 5.09978 0.573361 5.04001L4.52669 1.08001C4.58867 1.01753 4.6624 0.96793 4.74364 0.934084C4.82488 0.900238 4.91202 0.882812 5.00003 0.882812C5.08804 0.882812 5.17517 0.900238 5.25641 0.934084C5.33765 0.96793 5.41139 1.01753 5.47336 1.08001L9.42669 5.04001C9.55086 5.16492 9.62056 5.33389 9.62056 5.51001C9.62056 5.68614 9.55086 5.8551 9.42669 5.98001C9.30145 6.10074 9.13398 6.16772 8.96003 6.16668Z"
          fill="currentColor"
        />
        <path
          d="M4.99992 10.1667C4.82311 10.1667 4.65354 10.0965 4.52851 9.97146C4.40349 9.84643 4.33325 9.67686 4.33325 9.50005V1.55339C4.33325 1.37657 4.40349 1.20701 4.52851 1.08198C4.65354 0.956957 4.82311 0.886719 4.99992 0.886719C5.17673 0.886719 5.3463 0.956957 5.47132 1.08198C5.59635 1.20701 5.66659 1.37657 5.66659 1.55339V9.50005C5.66659 9.67686 5.59635 9.84643 5.47132 9.97146C5.3463 10.0965 5.17673 10.1667 4.99992 10.1667Z"
          fill="currentColor"
        />
      </svg>
    </div>
  );
};

function KpiBox({ value = 0, title = "", arrowType = "up" }) {
  return (
    <div className="progress-kpi-avatar">
      <div className="flex-progress-kpi-avatar">
        {arrowType === "up" ? <UpArrow /> : <DownArrow />}
        <div className="text-progress-kpi-avatar">{value}</div>
      </div>
      <div className="name-kpi-avatar">{title}</div>
    </div>
  );
}

export default KpiBox;
