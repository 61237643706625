import React, { useEffect } from 'react';
import StepsViewer from './StepsViewer';
import {
  setSteps,
  setConfig,
  setLoading
} from '../../../redux/walkthroughPreviewSlice';
import { API_ENDPOINTS, HUB_ENDPOINTS } from '../utils/endpoints';
import { useDispatch } from 'react-redux';
import { setEmbedConfig, setEmbedIndex, setEmbedLoading, setEmbedSteps } from '../../../redux/walkthroughEmbedPreviewSlice';
import { http_get } from '../utils/hub_client';
// import { http_get } from '../utils/api_client';

const EditorPreviewEmbed = ({ id }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setEmbedLoading({data: false, id: id}));
    const area = window.location.pathname.split('/')[2];
    
    if(area == 'hub'){
      const url = HUB_ENDPOINTS.GET_MATERIAL_SEARCH;
      const params = {by: 'id', keyword: id};

      http_get(url, params)
        .then((response) => {
          const { config, data, learning_material_id } = response.data;
          dispatch(setEmbedSteps({data: data, id: learning_material_id }));
          dispatch(setEmbedConfig({data: config, id: learning_material_id }));
          dispatch(setEmbedIndex({data: 0, id: learning_material_id}));
        })
        .finally(() => {
          dispatch(setLoading(false));
        });
      }else{
        const url = `${API_ENDPOINTS.PUBLISHED_DATA_WALKTHROUGH(id)}`;
        http_get(url)
          .then((response) => {
            const { config, data } = response.data;
            dispatch(setEmbedSteps({data: data, id: id }));
            dispatch(setEmbedConfig({data: config, id: id }));
            dispatch(setEmbedIndex({data: 0, id: id}));
          })
          .finally(() => {
            dispatch(setEmbedLoading({data: false, id: id}));
          });
      }

  }, []);

  return (
    <StepsViewer id={id} embed={true} />
  );
};

export default EditorPreviewEmbed;
